import { Box, Skeleton } from "@mui/material";

const BuildingDirectorySkeleton = (props: any) => {
  return (
    <div className="myAccount">
      <Box className="myAccountBox_title" sx={{ mx: 4 }}>
        <Box>
          <Skeleton
            variant={"text"}
            width={200}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
            }}
          />
          <Skeleton
            variant={"text"}
            width={300}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={500}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
              marginTop: "30px",
            }}
          />
          <Box sx={{ marginTop: "20px" }}>
              <Skeleton
                variant="rectangular"
                height={60}
                sx={{
                  backgroundColor: "rgb(64 64 64 / 34%)",
                  marginX: "auto",
                  marginTop: "5px",
                }}
              />
              <Skeleton
                variant="rectangular"
                height={60}
                sx={{
                  backgroundColor: "rgb(64 64 64 / 34%)",
                  marginX: "auto",
                  marginTop: "5px",
                }}
              />
              <Skeleton
                variant="rectangular"
                height={60}
                sx={{
                  backgroundColor: "rgb(64 64 64 / 34%)",
                  marginX: "auto",
                  marginTop: "5px",
                }}
              />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BuildingDirectorySkeleton;
