import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import Toast from "../../../Component/Common/Toast/Toast";
import {
  GetTransactions,
  UpdateTransaction,
  getAllBudgets,
} from "../../../Redux/Feature/Finance-slice";
import "./transactions.scss";
import AssociationSkeleton from "../../../Component/Skeletons/AssociationSkeleton";
import { Clear, Search } from "@mui/icons-material";
import { RootState } from "../../../Redux/Store";
import UpdateTransactionBudgetField from "./UpdateTransactionBudgetField";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const transactions: any = useSelector(
    (state: RootState) => state?.Finances?.transactions
  );

  const budgetOptions: any = useSelector(
    (state: RootState) => state?.Finances?.budgetCategories
  );
  // const userData = useSelector((state: any) => state.Auth.user);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [updateFieldProgress, setUpdateFieldProgress] =
    useState<boolean>(false);
  const [editBudget, setEditBudget] = useState<boolean | null>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});

  useEffect(() => {
    fetchTransactions();
  }, [currentAssociation]);

  const fetchTransactions = async () => {
    setLoading(true);
    await dispatch(GetTransactions(currentAssociation?.associationId));
    const getAllBudgetsPayload = {
      associationId: currentAssociation?.associationId,
    };
    await dispatch(getAllBudgets(getAllBudgetsPayload));
    setLoading(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const accountColumns = [
    {
      id: "date",
      label: t("Date"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "institution",
      label: t("Institution"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "description",
      label: t("Description"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "budget",
      label: t("Budget"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "status",
      label: t("Status"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "amount",
      label: t("Amount"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
  ];

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedTransactions = stableSort(
    transactions,
    getComparator(order, orderBy)
  );
  const filteredTransactions = sortedTransactions?.filter((tx: any) => {
    if (!tx) {
      return false;
    }
    const { amount, institution_name, description, status } = tx;
    const lowerCaseSearch = search?.toLowerCase();
    return (
      institution_name?.toLowerCase().includes(lowerCaseSearch) ||
      amount == search ||
      description?.toLowerCase().includes(lowerCaseSearch) ||
      status?.toLowerCase().includes(lowerCaseSearch) ||
      status?.toLowerCase().includes(lowerCaseSearch)
    );
  });

  let paginatedData: any = filteredTransactions?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClearSearch = () => {
    setSearch("");
  };

  if (loading) {
    return <AssociationSkeleton />;
  }

  const editBudgetHandler = (value: any) => {
    setEditBudget(false);
    setSelectedTransaction(null);
    return;
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const UpdateTransactionHandler = async (updatePayload: any) => {
    try {
      setUpdateFieldProgress(true);

      const response = await dispatch(UpdateTransaction(updatePayload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Transaction Updated"), "success");
      } else if (response.error) {
        showToast(t("Error"), "error");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateFieldProgress(false);
      setEditBudget(false);
    }
  };

  return (
    <div className="profile">
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Typography
        className="transactions__title page_title"
        sx={{ color: `${theme.palette.activeColor}` }}
      >
        {t("Transactions")}
      </Typography>
      <Box
        className="TableConnect board_wrapper board-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="board-wrap gridTable">
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <OutlinedInput
                sx={{
                  margin: "0 !important",
                  border: "unset !important",
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.activeColor}`,
                  },
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                      borderWidth: "1px",
                    },
                  },
                }}
                placeholder={t("Search")}
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    {search ? (
                      <IconButton
                        onClick={handleClearSearch}
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Clear
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Search
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {accountColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            {t("No Transactions Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedData?.map((row: any) => (
                          <TableRow
                            key={row.transactionId}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {new Date(
                                row.transacted_at * 1000
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.institution_name}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {editBudget &&
                              selectedTransaction?.transactionId ===
                                row?.transactionId ? (
                                <UpdateTransactionBudgetField
                                  updateFieldProgress={updateFieldProgress}
                                  budgets={budgetOptions}
                                  transactionId={row?.transactionId}
                                  editBudgetHandler={editBudgetHandler}
                                  updateBudgetHandler={UpdateTransactionHandler}
                                  budgetId={row?.budget?.budgetId}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {row?.budget?.name ?? "No Budget"}
                                  {!editBudget && (
                                    <Button
                                      sx={{
                                        justifyContent: "end",
                                        alignContent: "end",
                                      }}
                                      className="support-edit-icon"
                                      onClick={() => {
                                        setSelectedTransaction(row);
                                        setEditBudget(true);
                                      }}
                                    >
                                      <ModeEditIcon
                                        fontSize="small"
                                        sx={{
                                          alignContent: "end",
                                          color: `${theme.palette.activeColor}`,
                                        }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: row.amount >= 0 ? "green" : "red",
                                }}
                              >
                                {row.amount && !isNaN(row.amount)
                                  ? formatter.format(row.amount)
                                  : "NA"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredTransactions?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Transactions;
