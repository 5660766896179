import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import "../assetModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateCommonAsset from "../../../../Pages/Assets/CommonAssets/CreateCommonAsset";
import { useTranslation } from "react-i18next";
const CommonAssetModal = (props: any) => {
  const { t } = useTranslation();
  const { isOpen, onClose, createAssetHandler, loading } = props;
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="common-asset-modal">
        <Box className="common-asset-modal-title">
          <Typography
            variant="h6"
            gutterBottom
            className="document-title"
            sx={{
              color: "var(--mui-palette-text-primary)",
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "0",
              textAlign: "center",
            }}
          >
            {t("Create Common Asset")}
          </Typography>
          <Box sx={{ textAlign: "end" }} onClick={onClose}>
            <CancelIcon className="close-icon" />
          </Box>
        </Box>
        <Box className="scroll-wrap">
          <CreateCommonAsset
            createAssetHandler={createAssetHandler}
            loading={loading}
            onClose={onClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonAssetModal;
