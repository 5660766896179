import React from "react";

import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material";

const DatePickerComp = (props: any) => {
  const theme = useTheme();
  const {
    label,
    value,
    onChange,
    disabled,
    className,
    isEditMode,
    disableFuture,
    disablePast,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disablePast={disablePast}
        disableFuture={disableFuture}
        label={label}
        value={value}
        onChange={(date) => onChange(date)}
        disabled={disabled}
        sx={{
          width: "50%",
        }}
        slotProps={{
          layout: {
            sx: {
              backgroundColor: `${
                isEditMode
                  ? theme.palette.editCalenderBg
                  : theme.palette.calenderBg
              }`,
            },
          },
          textField: {
            sx: {
              '& .MuiOutlinedInput-root': {
                border: '1px solid',
                '& fieldset': {
                  borderColor: `${theme.palette.modelInputBorderColor}`, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: `${theme.palette.modelInputBorderColor}`, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.mode === "light" ? "#a1a1a1 !important" : "#fff !important", // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                // color: isEditMode ? theme.palette.text.primary : "white",
              },
              
            },
          },
        }}
        className={className}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComp;
