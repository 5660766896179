import {
  Box,
  Typography,
  Drawer,
  useTheme,
  Popper,
  Grid,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../Pages/Settings/setting.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getVote } from "../../Redux/Feature/MyAccount-slice";

import { MEMBER_TYPE } from "../../Utils/utils";
ChartJS.register(ArcElement, Tooltip, Legend);

const CompletedDrawer = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    selectedRow,
    setSelectedRow,
    completedDrawer,
    toggleCompletedDrawer,
    showToast,
    owners,
  } = props;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const groupOptions = [
    { value: MEMBER_TYPE.BOARD, label: "Board" },
    { value: MEMBER_TYPE.GENERAL, label: "General" },
  ];
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const selectedVote = useSelector(
    (state: RootState) => state?.MyAccount?.selectedVote
  );

  useEffect(() => {
    if (selectedRow?.voteId) {
      const fetchVote = async () => {
        await dispatch(getVote(selectedRow?.voteId));
      };
      fetchVote();
    }
  }, [selectedRow?.voteId]);

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={completedDrawer}
        onClose={() => toggleCompletedDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box className="sidebarVote__details" sx={{ marginLeft: "20px" }}>
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="cross"
                  onClick={() => toggleCompletedDrawer(false)}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ marginLeft: "20px" }}
                  >
                    <strong>{t("Title")}:</strong>{" "}
                    {selectedRow?.title ? selectedRow.title : t("No Title")}
                  </Typography>
                </div>
              </Box>
              <Grid item></Grid>
            </div>
            <div className="detailsSidebar__content">
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    <strong>{t("Type")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedRow?.type ? t(selectedRow.type) : t("No Type")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    <strong>{t("Description")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedRow?.description
                      ? selectedRow.description
                      : t("No Description")}
                  </Typography>
                </Grid>
              </Grid>
              {selectedRow?.type === "Rule Change" && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Rule")}:</strong>{" "}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} lg={3} md={6} sm={6}>
                    <Typography variant="h5" gutterBottom>
                      {selectedRow?.rule ? selectedRow.rule : t("No Rule")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {selectedRow?.type === "Assessment" && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Amount")}:</strong>{" "}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} lg={3} md={6} sm={6}>
                    <Typography variant="h5" gutterBottom>
                      {selectedRow?.amount
                        ? formatter.format(selectedRow.amount)
                        : t("No Amount")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("Voting Dates")}:</strong>{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <Typography variant="h5" gutterBottom>
                    {selectedRow?.startDate
                      ? new Date(selectedRow.startDate).toLocaleDateString()
                      : t("No Start Date")}{" "}
                    -{" "}
                    {selectedRow?.endDate
                      ? new Date(selectedRow.endDate).toLocaleDateString()
                      : t("No End Date")}
                  </Typography>
                </Grid>
              </Grid>
              {selectedRow?.type === "Position Change" && (
                <Grid container direction="row">
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      <strong>{t("Persons")}:</strong>
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} lg={3} md={6} sm={6}>
                    <List>
                      {selectedRow?.persons?.length > 0 ? (
                        selectedRow?.persons.map((person: any, index: any) => (
                          <ListItem key={index}>
                            <ListItemText primary={t(person.name)} />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText primary={t("No Persons")} />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row">
                <Grid item xs={12} lg={4} md={4} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    <strong>{t("Ballot Counts")}:</strong>
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={6}>
                  <List>
                    {selectedVote?.ballotCounts?.length > 0 ? (
                      selectedVote?.ballotCounts.map(
                        (ballot: any, index: any) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={t(ballot.name)}
                              secondary={ballot.count}
                            />
                          </ListItem>
                        )
                      )
                    ) : (
                      <ListItem>
                        <ListItemText primary={t("No Ballots")} />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default CompletedDrawer;
