import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MyAccount from "../Service/MyAccount-service";
import SupportTicketService from "../Service/Ticket-service";
import Governance from "../Service/Governance-service";

interface supportTicket {
  title: string;
  type: string;
  date: Date;
  description: string;
  ticketId: string;
  name: string;
  personId: string;
  status: string;
  attachments: [];
  allComments: comment[];
}

interface comment {
  comment: string;
  commentId: string;
  date: Date;
  personId: string;
  personName: string;
  ticketId: string;
}

interface MyAccountState {
  isFetching: boolean;
  isFetchingComments: boolean;
  isFetchingRequestTableData: boolean;
  isFetchingRequest: boolean;
  error: string;
  successMessage: string;
  isError: boolean;
  isSuccess: boolean;
  allMySupportTickets: supportTicket[];
  myPersonalAssets: any[];
  commentData: {
    allComments: comment[];
    rules: [];
    vendors: [];
    assets: [];
  };
  selectedVote: any;
  commentDataByCommentId: comment[];
  AddAttachmentMyAccountSupportTicketResponse: any;
  allMyInvoices: any[];
  allMyTransactions: any[];
}

const initialState: MyAccountState = {
  allMyInvoices: [],
  allMySupportTickets: [],
  allMyTransactions: [],
  myPersonalAssets: [],
  isFetching: false,
  isFetchingComments: false,
  isFetchingRequestTableData: false,
  isFetchingRequest: false,
  successMessage: "",
  error: "",
  isError: false,
  isSuccess: false,
  selectedVote: {},
  commentData: {
    allComments: [],
    rules: [],
    vendors: [],
    assets: [],
  },
  commentDataByCommentId: [],
  AddAttachmentMyAccountSupportTicketResponse: {},
};

export const getVote: any = createAsyncThunk(
  "getVote/get",
  async (id, thunkAPI) => {
    const data = await Governance.getVote(id);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createBallot: any = createAsyncThunk(
  "createBallot/post",
  async (data, thunkAPI) => {
    const res = await Governance.createBallot(data);
    if (res?.statusCode === 200) {
      return res?.data;
    } else {
      throw thunkAPI.rejectWithValue(res?.response);
    }
  }
);

export const getMyAccount: any = createAsyncThunk(
  "myAccount/get",
  async (associationId, thunkAPI) => {
    const data = await MyAccount.getMyAccount(associationId);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const HandleMyAccountSupportTicketStatus: any = createAsyncThunk(
  "HandleMyAccountSupportTicketStatus/put",
  async (obj, thunkAPI) => {
    const data = await SupportTicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateMyAccountSupportTicketTextFields: any = createAsyncThunk(
  "updateMyAccountSupportTicketTextFields/put",
  async (obj, thunkAPI) => {
    const data = await SupportTicketService.updateTicketTextFields(obj);

    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const HandleMyAccountSupportTicketPrivatePublicToggle: any =
  createAsyncThunk(
    "HandleMyAccountSupportTicketPrivatePublicToggle/put",
    async (obj, thunkAPI) => {
      const data = await SupportTicketService.updateTicket(obj);
      if (data.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    }
  );

export const HandleMyAccountSupportTicketAttachments: any = createAsyncThunk(
  "HandleMyAccountSupportTicketAttachments/put",
  async (obj, thunkAPI) => {
    const data = await SupportTicketService.updateTicket(obj);

    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const AddAttachmentMyAccountSupportTicket: any = createAsyncThunk(
  "AddAttachmentMyAccountSupportTicket/post",
  async (initialObj, thunkAPI) => {
    const data = await SupportTicketService.addAttachmentTicket(initialObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const postMyAccountSupportTicket: any = createAsyncThunk(
  "postMyAccountSupportTicket/post",
  async (reqPayload, thunkAPI) => {
    const data = await SupportTicketService.createTicket(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const postMyAccountCommentRequest: any = createAsyncThunk(
  "postMyAccountCommentRequest/post",
  async (reqPayload, thunkAPI) => {
    const data = await SupportTicketService.createComment(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getRequestMyAccountComments: any = createAsyncThunk(
  "getRequestMyAccountComments/get",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await SupportTicketService.getTicket(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const HandleMyAccountSupportTicketType: any = createAsyncThunk(
  "HandleMyAccountSupportTicketType/put",
  async (obj, thunkAPI) => {
    const data = await SupportTicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateTicket: any = createAsyncThunk(
  "UpdateTicket/put",
  async (obj, thunkAPI) => {
    const data = await SupportTicketService.updateTicket(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
const MyAccountSlice = createSlice({
  name: "MyAccount",
  initialState,
  reducers: {
    resetTicketDrawerState: (state) => {
      state.commentData = {
        allComments: [],
        rules: [],
        vendors: [],
        assets: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBallot.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createBallot.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        const { voterId, voteId } = action.payload;
        state.myPersonalAssets = state.myPersonalAssets.map((asset) => {
          if (asset.assetId === voterId) {
            if (asset.openVotes && asset.openVotes.length > 0) {
              return {
                ...asset,
                openVotes: asset.openVotes.filter(
                  (vote: any) => vote?.voteId !== voteId
                ),
              };
            }
          }
          return asset;
        });

        state.selectedVote = {};
      })
      .addCase(createBallot.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getMyAccount.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMyAccount.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allMyInvoices = action.payload.userInvoices;
        state.myPersonalAssets = action.payload.assets;
        state.allMySupportTickets = action.payload.tickets;
        state.allMyTransactions = action.payload.transactions;
      })
      .addCase(getMyAccount.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(HandleMyAccountSupportTicketStatus.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(
        HandleMyAccountSupportTicketStatus.fulfilled,
        (state, action) => {
          const updatedTicket = action?.payload?.data;
          state.isFetching = false;
          state.successMessage = action?.payload.message;
          state.error = "";
          const updatedSupportTicket = state.allMySupportTickets.findIndex(
            (ticket) => ticket.ticketId === updatedTicket.ticketId
          );
          if (updatedSupportTicket !== -1) {
            state.allMySupportTickets = state.allMySupportTickets.map(
              (ticket, i) =>
                i === updatedSupportTicket ? updatedTicket : ticket
            );
          } else {
            state.allMySupportTickets.push(updatedTicket);
          }
        }
      )
      .addCase(HandleMyAccountSupportTicketStatus.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(HandleMyAccountSupportTicketType.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(HandleMyAccountSupportTicketType.fulfilled, (state, action) => {
        const updatedTicket = action?.payload?.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedSupportTicket = state.allMySupportTickets.findIndex(
          (ticket) => ticket.ticketId === updatedTicket.ticketId
        );
        if (updatedSupportTicket !== -1) {
          state.allMySupportTickets = state.allMySupportTickets.map(
            (ticket, i) => (i === updatedSupportTicket ? updatedTicket : ticket)
          );
        } else {
          state.allMySupportTickets.push(updatedTicket);
        }
      })

      .addCase(HandleMyAccountSupportTicketType.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateMyAccountSupportTicketTextFields.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(
        updateMyAccountSupportTicketTextFields.fulfilled,
        (state, action) => {
          const updatedTicket = action?.payload?.data;
          state.isFetching = false;
          state.successMessage = action?.payload.message;
          state.error = "";
          const updatedSupportTicket = state.allMySupportTickets.findIndex(
            (ticket) => ticket.ticketId === updatedTicket.ticketId
          );
          if (updatedSupportTicket !== -1) {
            state.allMySupportTickets = state.allMySupportTickets.map(
              (ticket, i) =>
                i === updatedSupportTicket ? updatedTicket : ticket
            );
          } else {
            state.allMySupportTickets.push(updatedTicket);
          }
        }
      )

      .addCase(
        updateMyAccountSupportTicketTextFields.rejected,
        (state, action) => {
          state.isFetching = false;
          state.error = action?.payload?.data?.message;
        }
      );

    builder
      .addCase(
        HandleMyAccountSupportTicketPrivatePublicToggle.pending,
        (state) => {
          // state.isFetching = true;
        }
      )
      .addCase(
        HandleMyAccountSupportTicketPrivatePublicToggle.fulfilled,
        (state, action) => {
          const updatedTicket = action?.payload?.data;
          state.isFetching = false;
          state.successMessage = action?.payload.message;
          state.error = "";
          const updatedSupportTicket = state.allMySupportTickets.findIndex(
            (ticket) => ticket.ticketId === updatedTicket.ticketId
          );
          if (updatedSupportTicket !== -1) {
            state.allMySupportTickets = state.allMySupportTickets.map(
              (ticket, i) =>
                i === updatedSupportTicket ? updatedTicket : ticket
            );
          } else {
            state.allMySupportTickets.push(updatedTicket);
          }
        }
      )

      .addCase(
        HandleMyAccountSupportTicketPrivatePublicToggle.rejected,
        (state, action) => {
          state.isFetching = false;
          state.error = action?.payload?.data?.message;
        }
      );

    builder
      .addCase(HandleMyAccountSupportTicketAttachments.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(
        HandleMyAccountSupportTicketAttachments.fulfilled,
        (state, action) => {
          const updatedTicket = action?.payload?.data;
          state.isFetching = false;
          state.successMessage = action?.payload.message;
          state.error = "";
          const updatedSupportTicket = state.allMySupportTickets.findIndex(
            (ticket) => ticket.ticketId === updatedTicket.ticketId
          );
          if (updatedSupportTicket !== -1) {
            state.allMySupportTickets = state.allMySupportTickets.map(
              (ticket, i) =>
                i === updatedSupportTicket ? updatedTicket : ticket
            );
          } else {
            state.allMySupportTickets.push(updatedTicket);
          }
        }
      )

      .addCase(
        HandleMyAccountSupportTicketAttachments.rejected,
        (state, action) => {
          state.isFetching = false;
          state.error = action?.payload?.data?.message;
        }
      );

    builder
      .addCase(postMyAccountSupportTicket.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(postMyAccountSupportTicket.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.allMySupportTickets = [
          ...state.allMySupportTickets,
          action?.payload.data,
        ];
        state.error = "";
      })
      .addCase(postMyAccountSupportTicket.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(postMyAccountCommentRequest.pending, (state) => {
        // state.isFetchingRequest = true;
      })
      .addCase(postMyAccountCommentRequest.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        if (!state.commentData) {
          state.commentData = {
            allComments: [],
            rules: [],
            vendors: [],
            assets: [],
          };
        } else if (!state.commentData.allComments) {
          state.commentData.allComments = [];
        }

        state.commentData.allComments.unshift(action?.payload.data);
        state.error = "";
      })
      .addCase(postMyAccountCommentRequest.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getRequestMyAccountComments.pending, (state) => {
        // state.isFetchingRequest = true;
      })
      .addCase(getRequestMyAccountComments.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.commentData = action?.payload?.data;
      })
      .addCase(getRequestMyAccountComments.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getVote.pending, (state) => {
        state.isFetchingRequest = true;
      })
      .addCase(getVote.fulfilled, (state, action) => {
        state.isFetchingRequest = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.selectedVote = action?.payload?.vote;
      })
      .addCase(getVote.rejected, (state, action) => {
        state.isFetchingRequest = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(AddAttachmentMyAccountSupportTicket.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(
        AddAttachmentMyAccountSupportTicket.fulfilled,
        (state, action) => {
          state.AddAttachmentMyAccountSupportTicketResponse =
            action.payload.data;
          state.isFetching = false;
          state.successMessage = action?.payload?.message;
          state.error = "";
        }
      )
      .addCase(
        AddAttachmentMyAccountSupportTicket.rejected,
        (state, action) => {
          state.isFetching = false;
          state.error = action?.payload?.data?.message;
        }
      );

    builder
      .addCase(updateTicket.pending, (state) => {
        // state.isFetching = true;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.commentData.assets = action?.payload?.data?.assets;
        state.commentData.vendors = action?.payload?.data?.vendors;
        state.commentData.rules = action?.payload?.data?.rules;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export const { resetTicketDrawerState } = MyAccountSlice.actions;

export default MyAccountSlice;
