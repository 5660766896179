import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import associationService from "../Service/Association-service";

export const stripeSafeSubscriptionSession: any = createAsyncThunk(
  "StripeSafeSubscriptionSession/post",
  async (subscriptionObject, thunkAPI) => {
    const data = await associationService.stripeSafeSubscriptionSession(
      subscriptionObject
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const stripeSafeUpdateSession: any = createAsyncThunk(
  "stripeSafeUpdateSession/post",
  async (subscriptionObject, thunkAPI) => {
    const data = await associationService.stripeSafeUpdateSession(
      subscriptionObject
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const stripeSafeCancelSession: any = createAsyncThunk(
  "stripeSafeCancelSession/post",
  async (subscriptionObject, thunkAPI) => {
    const data = await associationService.stripeSafeCancelSession(
      subscriptionObject
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const stripeAssociationBillingSession: any = createAsyncThunk(
  "stripeAssociationBillingSession/post",
  async (subscriptionObject, thunkAPI) => {
    const data = await associationService.stripeAssociationBillingSession(
      subscriptionObject
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

const AssociationSlice = createSlice({
  name: "Association",
  initialState: {
    association: {
      title: "",
      address: "",
    },
    isFetching: false,
    error: "",
    successMessage: "",
    isError: false,
    isSuccess: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(stripeSafeSubscriptionSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(stripeSafeSubscriptionSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(stripeSafeSubscriptionSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(stripeSafeUpdateSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(stripeSafeUpdateSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(stripeSafeUpdateSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(stripeAssociationBillingSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(stripeAssociationBillingSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(stripeAssociationBillingSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default AssociationSlice;
