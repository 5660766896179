import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, useTheme } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const EditSupportTicketTableTitle = (props: any) => {
  const {
    title,
    editTitleHandler,
    ticketId,
    updateSupportTicketHandler,
    updateFieldProgress,
  } = props;
  const theme = useTheme();

  const { t } = useTranslation();
  const validationSchema = yup.object({
    title: yup.string().required(t("Title is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const updatePayload = {
        ticketId,
        payload: {
          title: values.title,
        },
      };
      updateSupportTicketHandler(updatePayload);
    },
  });

  useEffect(() => {
    formik.setValues({ title });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Box
          className="edit-title-wrap support-edit"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            fullWidth
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            sx={{
              fontSize: 30,
              "& .MuiInput-root": {
                margin: "0 !important",
                border:
                  formik.errors.title &&
                  `1px solid ${theme.palette.deleteText} !important`,
              },
            }}
          />
          <Box className="title-action-icons">
            <Button
              className="icon"
              type="submit"
              disabled={updateFieldProgress}
            >
              {updateFieldProgress ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "green",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <DoneIcon
                  fontSize="medium"
                  sx={{ color: `${theme.palette.activeColor}` }}
                />
              )}
            </Button>
            <Button className="icon" onClick={() => editTitleHandler(false)}>
              {updateFieldProgress ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "green",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <CloseIcon
                  fontSize="medium"
                  sx={{ color: `${theme.palette.activeColor}` }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default EditSupportTicketTableTitle;
