import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import "../assetModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";

interface DeleteCommonAssetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  loading: boolean;
}

const DeleteCommonAssetModal: React.FC<DeleteCommonAssetModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="deleteUniWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Typography
          id="delete-unit-description"
          sx={{ mt: 2, marginBottom: "30px" }}
        >
          {t("Are you sure you want to remove this common asset?")}
        </Typography>
        <Button
          variant="outlined"
          onClick={onDelete}
          className="deleteUnitButtonYes"
          type="submit"
          sx={{ marginLeft: "12px" }}
        >
          {loading ? (
            <CircularProgress
              size={33}
              sx={{ color: `${theme.palette.textWhite}` }}
            />
          ) : (
            t("Yes")
          )}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          className="deleteUnitButtonCancel"
        >
          {t("Cancel")}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteCommonAssetModal;
