import { useEffect } from "react";
import { Box, Button, Typography, TextField, useTheme } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

export default function BuildingModal({
  formik,
  mapRef,
  selectedPlace,
  show,
  setShow,
  serverError,
  serverErrorMsg,
  isEditMode,
  selectedBuilding,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  useEffect(() => {
    if (isEditMode && selectedBuilding) {
      formik.setFieldValue("title", selectedBuilding.title || "");
    }
  }, [isEditMode, selectedBuilding]);

  const mapStyle = {
    display: show ? "block" : "none",
  };
  const innerStyle = {
    backgroundColor: `${theme.palette.background.default}`, // Change to a different color
    border: `1px solid ${theme.palette.modelBorderColor}`,
  };

  const outlineFieldStyle = {
    "& .MuiInputBase-root": {
      color: theme.palette.text.primary,
      borderRadius: "10px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.activeColor}`,
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.activeColor}`,
        },
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary, // Set text color based on theme
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: ` ${theme.palette.activeColor}`,
        },
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.text.primary, // Set label color based on theme
    },
    "& .MuiInputLabel-outlined": {
      color: "#FFF",
      "&.Mui-focused": {
        color: "#FFF",
      },
    },
  };

  const renderServerError = (errorCode: any) => {
    switch (errorCode) {
      case 500:
        return <p style={{ color: "#DE1344" }}>Server Error</p>;
      case 400:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 401:
        return <p style={{ color: "#DE1344" }}>{t("Unauthorized")}</p>;
      case 403:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 404:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="signupDetails" style={mapStyle}>
        <div className="build_steprfrom_wrapper" style={innerStyle}>
          <Typography
            sx={{ color: `${theme.palette.text.primary}`, mb: "10px" }}
          >
            {t("Fill in the fields below to continue")}
          </Typography>

          <div className="build_stepr_row align_iterms_center">
            <form className="form_col_7" onSubmit={formik.handleSubmit}>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="address"
                  name="address"
                  label={t("Address")}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label={t("City")}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  fullWidth
                  id="state"
                  name="state"
                  label={t("State")}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  // notched={true}
                  fullWidth
                  id="postalCode"
                  name="postalCode"
                  label={t("Zip Code")}
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                  // sx={inputFieldStyle}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  // notched={true}
                  fullWidth={true}
                  id="country"
                  name="country"
                  label={t("Country")}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="formGroup" sx={{ paddingBottom: "10px" }}>
                <TextField
                  // notched={true}
                  fullWidth
                  id="title"
                  name="title"
                  label={t("Title")}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  sx={outlineFieldStyle}
                  InputProps={{ notched: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className="BtnPart">
                <Button
                  type="submit"
                  variant="contained"
                  // className="buildingModalBtn"
                >
                  {isEditMode ? t("Update") : t("Validate")}
                </Button>
                <Button
                  variant="contained"
                  // className="buildingModalBtn"
                  onClick={() => setShow(false)}
                >
                  {t("Back")}
                </Button>
              </Box>
            </form>
            {renderServerError(serverError)}
            <div className="form_col_5">
              <div className="build-map">
                <div className="google">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={(map: any) => (mapRef.current = map)}
                    center={
                      selectedPlace ? selectedPlace.geometry.location : center
                    }
                    zoom={20}
                  >
                    {selectedPlace && (
                      <Marker
                        position={selectedPlace.geometry.location}
                        title={selectedPlace.formatted_address}
                        visible={true}
                      />
                    )}
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
