import React, { useState, useEffect, useRef } from "react";
import "./RuleSortableList.scss";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Rule {
  createdDate: string;
  associationId: string;
  ruleSet: string;
  text: string;
  ruleId: string;
  updatedDate: string;
  parentRuleId: string;
  title: string;
  ruleidattr: string;
}

interface UpDownRuleListProps {
  ruleList: Rule[];
  selectedItem: Rule | null;
  handleItemClick: (item: Rule) => void;
  ruleSet: string;
  heading: string;
  loading: boolean;
  route: string;
  updateRuleSetOrderHandler: (updateRuleSetOrderPayload: any) => Promise<void>;
  isBoardRole: boolean;
}

let activeIndex: any;

const UpdownRuleList: React.FC<UpDownRuleListProps> = (props) => {
  const theme = useTheme();
  const {
    ruleList,
    selectedItem,
    handleItemClick,
    ruleSet,
    heading,
    updateRuleSetOrderHandler,
    loading,
    route,
    isBoardRole,
  } = props;
  const [rules, setRules] = useState<Rule[]>(ruleList);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setRules(ruleList);
  }, [ruleList]);

  useEffect(() => {
    if (selectedItem) {
      const index = rules.findIndex(
        (rule) => rule.ruleId === selectedItem.ruleId
      );
      if (index !== -1) {
        setActiveIndex(index);
      }
    }
  }, [selectedItem, rules]);

  const handleItemClickWithActive = (index: number, rule: Rule) => {
    handleItemClick(rules[index]);
    setActiveIndex(index);
  };

  const handleUpClick = async (index: number) => {
    if (index === 0 || loading) return;
    const newRules = [...rules];
    const temp = newRules[index];
    newRules[index] = newRules[index - 1];
    newRules[index - 1] = temp;
    setRules(newRules);
    setSelectedIndex(index - 1);
    try {
      const ruleId = temp.ruleId;
      const newParentRuleId = newRules[index].parentRuleId;
      const updateRuleSetOrderPayload = {
        ruleId: ruleId,
        payload: {
          ruleSet: ruleSet,
          newParentRuleId: newParentRuleId,
        },
      };
      await updateRuleSetOrderHandler(updateRuleSetOrderPayload);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleDownClick = async (index: number) => {
    if (index === rules?.length - 1) return;
    const newRules = [...rules];
    const temp = newRules[index];
    newRules[index] = newRules[index + 1];
    newRules[index + 1] = temp;
    setRules(newRules);
    setSelectedIndex(index + 1);
    // activeIndex=index+1;
    try {
      const ruleId = temp.ruleId;
      const newParentRuleId = newRules[index].ruleId;
      const updateRuleSetOrderPayload = {
        ruleId: ruleId,
        payload: {
          ruleSet: ruleSet,
          newParentRuleId: newParentRuleId,
        },
      };
      await updateRuleSetOrderHandler(updateRuleSetOrderPayload);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div style={{ paddingTop: "20px", position: "sticky", top: "0px" }}>
      <Typography className="ccr_heading" sx={{ color: "text.primary" }}>
        {t("Table of Contents")}
      </Typography>
      <div className="list">
        {loading ? (
          <div style={{ height: "317px", overflow: "hidden" }}>
            {Array.from(Array(rules?.length).keys()).map((index) => (
              <Skeleton
                variant="text"
                sx={{
                  width: "300px",
                  padding: "16px 20px",
                  margin: "0px 0",
                  backgroundColor: " rgb(64 64 64 / 34%)",
                }}
                key={index}
              />
            ))}
          </div>
        ) : (
          <ul className="sortable-list">
            {rules && rules?.length > 0 ? (
              rules.map((rule, index) => (
                <li
                  key={rule.ruleId}
                  className={"list-item"}
                  onClick={() => handleItemClickWithActive(index, rule)}
                >
                  <Box
                    className={`details titles ${
                      activeIndex === index ? "active-rule" : ""
                    }`}
                    sx={{
                      justifyContent: "space-between",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span
                      className="title-name"
                      onClick={(e) => {
                        e.preventDefault();
                        const selectedElement = document.getElementById(
                          rule.ruleId
                        ) as HTMLElement;
                        selectedElement.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      {rule.title}
                    </span>
                    {isBoardRole && (
                      <span className="movable-action-icons">
                        <Button
                          className="icon"
                          type="submit"
                          onClick={() => handleUpClick(index)}
                        >
                          <ArrowUpwardIcon
                            fontSize="medium"
                            sx={{ color: `${theme.palette.activeColor}` }}
                          />
                        </Button>
                        <Button
                          className="icon"
                          onClick={() => handleDownClick(index)}
                        >
                          <ArrowDownwardIcon
                            fontSize="medium"
                            sx={{ color: `${theme.palette.activeColor}` }}
                          />
                        </Button>
                      </span>
                    )}
                  </Box>
                </li>
              ))
            ) : (
              <Typography className="no-title-found">
                {t("No Rule Found For ") + `${heading}`}
              </Typography>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UpdownRuleList;
