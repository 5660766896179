import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  TableSortLabel,
  useTheme,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import Toast from "../../Component/Common/Toast/Toast";
import { Clear, Search } from "@mui/icons-material";
import "./map.scss";
import "../Settings/setting.scss";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ProposalTable = () => {
  const theme = useTheme();
  const associations = useSelector((state: RootState) => state.Map.tickets);
  const { t } = useTranslation();

  const accountColumns = [
    {
      id: "description",
      label: t("Description"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "address",
      label: t("Address"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "budget",
      label: t("Budget"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "deadline",
      label: t("Proposal Deadline"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
  ];
  // const userData = useSelector((state: any) => state.Auth.user);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const sortedAssociations = stableSort(
    associations,
    getComparator(order, orderBy)
  );

  let paginatedData: any = sortedAssociations?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleClearSearch = () => {
    setSearch("");
  };
  return (
    <Box sx={{ marginTop: 2, marginX: 2 }}>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Typography
        className="transactions__title page_title"
        sx={{ color: `${theme.palette.activeColor}` }}
      >
        {t("Proposals")}
      </Typography>
      <Box
        className="TableConnect board_wrapper board-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="board-wrap gridTable">
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <OutlinedInput
                sx={{
                  margin: "0 !important",
                  border: "unset !important",
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.activeColor}`,
                  },
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                      borderWidth: "1px",
                    },
                  },
                }}
                placeholder={t("Search")}
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    {search ? (
                      <IconButton
                        onClick={handleClearSearch}
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Clear
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        className="search-icon-button-hover"
                      >
                        <Search
                          style={{ color: `${theme.palette.activeColor}` }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {accountColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            {t("No Proposals Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        // paginatedData.map((ticket: any) =>
                        paginatedData.map((row: any) => (
                          <TableRow
                            key={row?.associationId}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row.address}
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              {row?.minBudget
                                ? formatter.format(row?.minBudget)
                                : formatter.format(0)}
                              {" - "}
                              {row?.maxBudget
                                ? formatter.format(row?.maxBudget)
                                : formatter.format(0)}
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              {row.proposalDeadline}
                            </TableCell>
                          </TableRow>
                        ))
                        // )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={associations?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProposalTable;
