import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
} from "@mui/material";
import "./boardModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface BOIInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const BOIInformationModal: React.FC<BOIInformationModalProps> = ({
  isOpen,
  onClose,
  onRemove,
}) => {
  const BOIInformation = useSelector(
    (state: any) => state.Manage.boiInformation
  );
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="removeBoardWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Typography
          id="remove-member-modal-description"
          sx={{ mt: 2, marginBottom: "30px", color: "white" }}
        >
          {t("Beneficial Owner Information")}
        </Typography>
        <Table sx={{ mb: "24px" }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("No.")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Date of Birth Submitted")}</TableCell>
              <TableCell>{t("Verification Document Submitted")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BOIInformation.map((item: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.dateOfBirthStatus ? t("Yes") : t("No")}
                </TableCell>
                <TableCell>
                  {item.verificationStatus ? t("Yes") : t("No")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="outlined"
          onClick={onRemove}
          className="removeboardButtonYes"
        >
          {t("Yes")}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          className="removeboardButtonCancel"
        >
          {t("Cancel")}
        </Button>
      </Box>
    </Modal>
  );
};

export default BOIInformationModal;
