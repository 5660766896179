import http from "../api";

const getAllTickets = async (getTicketObj: any) => {
  try {
    const response = await http.get(
      `/get-all-tickets/?associationId=${getTicketObj.associationId}&status=${getTicketObj.status}`
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
const updateTicketTextFields = async (obj: any) => {
  try {
    const ticketId = obj.ticketId;
    const payload = obj.payload;
    const response = await http.put(`/update-ticket/${ticketId}`, payload);
    return response.data;
  } catch (e: any) {
    return e;
  }
};

const createTicket = async (reqPayload: any) => {
  try {
    const response = await http.post(`/create-ticket`, reqPayload);
    return response.data;
  } catch (e) {
    return e;
  }
};

const createComment = async (reqPayload: any) => {
  try {
    const response = await http.post(`/create-comment`, reqPayload);
    return response.data;
  } catch (e) {
    return e;
  }
};

const getCommentById = async (id: string) => {
  try {
    const response = await http.get(`/get-comment/${id}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const getTicket = async (getTicketObj: any) => {
  try {
    const response = await http.get(`/get-ticket/${getTicketObj}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const getAllAssetByAssociationId = async (id: string) => {
  try {
    const response = await http.get(`/get-all-asset?associationId=${id}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const addAttachmentTicket = async (initialObj: any) => {
  try {
    const response = await http.post(`/upload-attachments`, {
      ...initialObj,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const updateTicket = async (obj: any) => {
  try {
    const response = await http.put(`/update-ticket/${obj.ticketId}`, obj);
    return response.data;
  } catch (e: any) {
    return e;
  }
};

const TicketService = {
  getAllTickets,
  createTicket,
  createComment,
  getCommentById,
  getTicket,
  getAllAssetByAssociationId,
  addAttachmentTicket,
  updateTicketTextFields,
  updateTicket,
};
export default TicketService;
