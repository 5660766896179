import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingService from "../Service/Setting-service";

export const GetOrganization: any = createAsyncThunk(
  "GetOrganization/get",
  async (id: any, thunkAPI) => {
    const data = await settingService.GetOrganization(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const PreSignedUrlForUploadPicture: any = createAsyncThunk(
  "PreSignedUrlForUploadPicture/post",
  async (initialObj, thunkAPI) => {
    const data = await settingService.PreSignedUrlForUploadPicture(initialObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const uploadProfilePicture: any = createAsyncThunk(
  "uploadProfilePicture/put",
  async (key, thunkAPI) => {
    try {
      const data = await settingService.uploadProfilePicture(key);
      if (data.statusCode === 200) {
        return data;
      } else {
        throw new Error(data?.response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createOrganization: any = createAsyncThunk(
  "createOrganization/post",
  async (mapDetails: any, thunkAPI) => {
    const data = await settingService.createOrganization(mapDetails);
    if (data?.statusCode === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdateOrganization: any = createAsyncThunk(
  "UpdateOrganization/post",
  async (obj: any, thunkAPI) => {
    const data = await settingService.UpdateOrganization(obj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdateOrganizationService: any = createAsyncThunk(
  "UpdateOrganizationService/post",
  async (obj: any, thunkAPI) => {
    const data = await settingService.UpdateOrganizationService(obj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data?.response);
    }
  }
);
const SettingSlice = createSlice({
  name: "Setting-Slice",
  initialState: {
    isFetching: false,
    PreSignedUrlForUploadPictureResponse: {},
    uploadedProfilePicURL: "",
    organization: {
      title: "",
      address: "",
      services: [],
      stripeConnectId: "",
    },
    error: "",
    successMessage: "",
    isError: false,
    isSuccess: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PreSignedUrlForUploadPicture.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(PreSignedUrlForUploadPicture.fulfilled, (state, action) => {
        state.PreSignedUrlForUploadPictureResponse = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(PreSignedUrlForUploadPicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(uploadProfilePicture.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.uploadedProfilePicURL = action?.payload?.data?.url;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(uploadProfilePicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(createOrganization.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(UpdateOrganizationService.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(UpdateOrganizationService.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        // state.organization = action?.payload?.data;
      })
      .addCase(UpdateOrganizationService.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(UpdateOrganization.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(UpdateOrganization.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.organization = action?.payload?.data;
      })
      .addCase(UpdateOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(GetOrganization.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetOrganization.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(GetOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default SettingSlice;
