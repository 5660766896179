import { Box, Button, Typography, Modal, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { logoutUser } from "../../Redux/Feature/Auth-slice";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const ConfirmLogout = (props: any) => {
  const { logout } = useAuth0();
  const { showConfirm, setShowConfirm } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const handleLogout = () => {
    dispatch(logoutUser());
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: `${theme.palette.customBackground}`, //theme.palette.customBackground
    border: `1px solid ${theme.palette.modelBorderColor}`,
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };
  return (
    <Modal
      open={showConfirm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={{ ...style, pt: "10px", px: "15px" }}>
          <Box
            sx={{}}
            justifyContent={"right"}
            display="flex"
            onClick={() => setShowConfirm(false)}
          >
            <CancelIcon sx={{ color: `${theme.palette.textWhite}` }} />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            align="center"
            sx={{ mb: "10px", color: `${theme.palette.text.primary}` }}
          >
            {t("Are you sure?")}
          </Typography>
          <Box sx={{ p: 2 }} justifyContent={"center"} display="flex">
            <Button
              variant="contained"
              className="button"
              sx={{
                backgroundColor: "background.button",
                color: "textWhite",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "background.button",
                  color: "textWhite",
                },
                mr: "20px",
              }}
              type="button"
              onClick={() => setShowConfirm(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                backgroundColor: `${theme.palette.continueButton}`,
                border: `1px solid ${theme.palette.continueButton}`,
                color: `${theme.palette.textWhite}`,
                borderRadius: "6px",
                boxShadow: `0px 8px 15px ${theme.palette.continueBoxShadow}`,
                fontSize: "16px",
              }}
              onClick={() => {
                handleLogout();
                setShowConfirm(false);
              }}
            >
              {t("Continue")}
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default ConfirmLogout;
