import { Box, Skeleton } from "@mui/material";

const AddMemberSkeleton = () => {
  return (
    <div>
      <Box sx={{ mx: 4 }}>
        <Box>
          <Skeleton
            variant={"text"}
            width={200}
            height={50}
            sx={{
              backgroundColor: "rgb(25 24 24 / 34%)",
              marginX: "auto",
            }}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{
            backgroundColor: "rgb(25 24 24 / 34%)",
            marginX: "auto",
            marginTop: "20px",
          }}
        />
      </Box>
    </div>
  );
};

export default AddMemberSkeleton;
