import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { COUNTRY_CODES } from "../../../Utils/utils";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";

const SettingField = (props: any) => {
  const {
    isEditField,
    fieldLabel,
    fieldValue,
    fieldName,
    placeholder,
    handleChange,
    setisEditField,
    handleSaveField,
    resetUserDataOnCancel,
  } = props;
  const [error, setError] = useState<string>("");

  const { t } = useTranslation();

  const handleSave = () => {
    if (
      (fieldName === "phone" || fieldName === "emergencyContactPhone") &&
      (fieldValue === null || !isValidPhoneNumber(fieldValue))
    ) {
      setError("Please enter a valid phone number.");
      return;
    }
    setError("");
    handleSaveField();
  };

  const handleCancel = () => {
    setisEditField(false);
    setError("");
    resetUserDataOnCancel();
  };

  return (
    <Box className="formGroup">
      <Grid container spacing={2}>
        <Grid item md={10} lg={10}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid item md={4} lg={4}>
              <Typography
                className="formlabel"
                sx={{ color: "text.primary", mr: "10px" }}
              >
                {t(fieldLabel)}:
              </Typography>
            </Grid>
            <Grid item md={8} lg={8}>
              {!isEditField ? (
                <Typography
                  className="formlabel"
                  sx={{ color: "text.primary" }}
                >
                  {fieldName === "phone" ||
                  fieldName === "emergencyContactPhone"
                    ? formatPhoneNumberIntl(fieldValue)
                    : fieldValue || ""}
                </Typography>
              ) : (
                <Box className="formGroup_inner">
                  {fieldName === "phone" ||
                  fieldName === "emergencyContactPhone" ? (
                    <PhoneInput
                      className="phone-input"
                      international
                      defaultCountry="US"
                      value={fieldValue}
                      onChange={(value) =>
                        handleChange({
                          target: { name: fieldName, value: value || "" },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      placeholder={placeholder}
                      countries={COUNTRY_CODES}
                    />
                  ) : (
                    <TextField
                      type="text"
                      fullWidth
                      name={fieldName}
                      placeholder={placeholder}
                      value={fieldValue || ""}
                      onChange={(e) => handleChange(e)}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: "text.primary",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "text.primary",
                        },
                        "& .input": {
                          color: "text.primary",
                        },
                      }}
                    />
                  )}
                </Box>
              )}
              {(fieldName === "phone" ||
                fieldName === "emergencyContactPhone") &&
                error && <FormHelperText error>{error}</FormHelperText>}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          {!isEditField ? (
            <Button
              sx={{
                width: "auto !important",
                paddingX: "16px !important",
              }}
              variant="contained"
              className="btn"
              onClick={() => setisEditField(!isEditField)}
            >
              {t("Edit")}
            </Button>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  width: "auto !important",
                  paddingX: "16px !important",
                  mr: "10px",
                }}
                variant="contained"
                className="btn"
                onClick={handleSave}
              >
                {t("Save")}
              </Button>
              <Button
                sx={{
                  width: "auto !important",
                  paddingX: "16px !important",
                }}
                variant="contained"
                className="btn"
                onClick={() => handleCancel()}
              >
                {t("Cancel")}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingField;
