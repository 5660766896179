import { Box } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LibraryMusicSharpIcon from '@mui/icons-material/LibraryMusicSharp';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import FormatAlignJustifySharpIcon from '@mui/icons-material/FormatAlignJustifySharp';
import GifBoxIcon from '@mui/icons-material/GifBox';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';

const FileExtensionView = ({fileType}:{fileType:string|null|undefined}) => {
    if(fileType==='pdf'){
        return <PictureAsPdfIcon />
    }
    if(fileType==='doc' || fileType==='docx'){
        return <SummarizeIcon />
    }
    if(fileType==='xls' || fileType==='xlsx'){
        return <SummarizeIcon />
    }
    if(fileType==='txt'){
        return <FormatAlignJustifySharpIcon />
    }
    if(fileType==='csv'){
        return <FileCopySharpIcon />
    }
    if(fileType==='ppt' || fileType==='pptx'){
        return <InsertDriveFileSharpIcon />
    }
    if(fileType==='zip'){
        return <FolderZipIcon />
    }
    if(fileType==='gif'){
        return <GifBoxIcon />
    }
    if(fileType==='mp4' || fileType==='mp3'){
        return <LibraryMusicSharpIcon />
    }
    if(fileType==='html' || fileType=== 'htm'){
        return <IntegrationInstructionsIcon />
    }
    if(fileType==='json'){
        return <TextSnippetSharpIcon />
    }
    if(fileType==='xml'){
        return <CodeSharpIcon />
    }

    return <></>    

}

export default FileExtensionView;