import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import "./attachmentModal.scss";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { addAttachmentTicket } from "../../../Redux/Feature/Ticket-slice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AddAttachmentMyAccountSupportTicket } from "../../../Redux/Feature/MyAccount-slice";
import { allowedFileTypes, imageFileExtensions } from "../../../Utils/utils";
import FileExtensionView from "../../FileExtensionView/FileExtensionView";
import { useTranslation } from "react-i18next";

interface UploadAttachmentModalProps {
  isOpen: boolean;
  // onClose: () => void;
  // onClose: (keys: string[]) => void;
  onClose: (uploadedAttachments: string[]) => void;
  supportTicketId: string;
}

const AddAttachmentModal: React.FC<UploadAttachmentModalProps> = ({
  isOpen,
  onClose,
  supportTicketId,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFiles([]);
      setError(null);
    }
  }, [isOpen]);

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const validFiles = Array.from(files).filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return allowedFileTypes.includes(`.${fileExtension}`);
    });
    if (validFiles.length === files.length) {
      setError(null);
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(validFiles),
      ]);
    } else {
      setError(
        `Please select a valid file type. Valid file types includes only ${allowedFileTypes.join(
          ", "
        )}`
      );
    }

    // event.target.value = "";
  };
  const handleRemoveFile = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmit = () => {
    if (selectedFiles.length > 0) {
      setLoading(true);
      const action = location.pathname.includes("/account")
        ? AddAttachmentMyAccountSupportTicket
        : addAttachmentTicket;
      Promise.all(
        selectedFiles.map((elem: any) =>
          dispatch(action({ fileName: elem.name })).then((res: any) => {
            if (res.payload.statusCode === 200) {
              const signedUrl = res.payload.data.signedUrl;
              const key = res.payload.data.s3Key;
              return fetch(signedUrl, {
                method: "PUT",
                body: elem,
              }).then((response) => {
                if (response.status === 200) {
                  return key;
                }
              });
            }
          })
        )
      )
        .then((keys) => {
          setLoading(false);
          const uploadedAttachments = keys.filter((key: any) => key);
          if (uploadedAttachments.length > 0) {
            onClose(uploadedAttachments);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error occurred:", error);
        });
    } else {
    }
  };
  return (
    <Modal open={isOpen}>
      <Box
        className="changeBoardWrapper add-attachment"
        sx={{ width: "1000px" }}
      >
        <Box sx={{ textAlign: "end" }}>
          <CancelIcon className="close-icon" onClick={() => onClose([])} />
        </Box>
        <Typography variant="h6" gutterBottom className="attachment-title">
          {t("Attachments")}
        </Typography>
        {selectedFiles.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", margin: "20px 0" }}>
            {selectedFiles.map((file, index) => (
              <div className="selected-image" key={index}>
                <Box sx={{ display: "contents" }}>
                  {imageFileExtensions.includes(
                    file.name.split(".").pop()?.toLowerCase()
                  ) ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Selected"
                      className="selected-image-preview"
                    />
                  ) : (
                    <FileExtensionView
                      fileType={file.name.split(".").pop()?.toLowerCase()}
                    />
                  )}
                </Box>

                <CloseIcon onClick={() => handleRemoveFile(index)} />
              </div>
            ))}
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" gutterBottom className="no-item-selected">
              {t("No attachment selected")}
            </Typography>
          </Box>
        )}
        {error && (
          <Box sx={{ textAlign: "justify", textJustify: "inter-word" }}>
            <Typography
              variant="h6"
              sx={{
                color: `${theme.palette.textWhite}`,
                padding: "5px 5px",
                border: "0.5px solid",
                marginBottom: "12px",
                borderRadius: "8px",
              }}
            >
              {error}
            </Typography>
          </Box>
        )}

        <Box sx={{ textAlign: "end" }}>
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />

          <Button
            variant="outlined"
            className="browseButton"
            onClick={handleBrowseClick}
          >
            {t("Browse")}
          </Button>
          <Button
            variant="outlined"
            className="browseButton submitButton"
            onClick={handleSubmit}
            disabled={selectedFiles.length === 0 || loading}
          >
            {loading && (
              <CircularProgress
                size={23}
                sx={{ color: `${theme.palette.textWhite}`, marginRight: "6px" }}
              />
            )}
            {t("Submit")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAttachmentModal;
