import {
  FaWalking,
  FaTree,
  FaHome,
  FaDoorClosed,
  FaRestroom,
  FaSwimmer,
  FaHotTub,
  FaTruckLoading,
  FaBasketballBall,
  FaChild,
  FaFutbol,
  FaGolfBall,
  FaBuilding,
  FaDog,
  FaTint,
  FaPoop,
  FaWater,
  FaPlug,
  FaGasPump,
  FaBroadcastTower,
  FaShieldAlt,
  FaVideo,
  FaFireExtinguisher,
  FaLightbulb,
  FaBell,
  FaHeartbeat,
  FaRoad,
  FaParking,
  FaBicycle,
  FaWarehouse,
  FaTools,
  FaBroom,
  FaChair,
  FaDumbbell,
  FaRecycle,
  FaSolarPanel,
  FaCloudRain,
  FaSeedling,
  FaClipboard,
  FaDesktop,
  FaFileAlt,
  FaPrint,
  FaUtensils,
  FaShoppingCart,
  FaLeaf,
  FaAnchor,
  FaShip,
  FaFlag,
  FaClipboardList,
  FaWindowMaximize,
} from "react-icons/fa";

import {
  MdSecurity,
  MdFireExtinguisher,
  MdPlumbing,
  MdEscalator,
  MdElevator,
  MdLocalCafe,
  MdMeetingRoom,
  MdNetworkWifi,
  MdPhone,
  MdFactory,
} from "react-icons/md";

import {
  GiWaterRecycling,
  GiRollingSuitcase,
  GiTennisCourt,
  GiMeshNetwork,
  GiStairs,
  GiCartwheel,
  GiTrashCan,
  GiShop,
  GiFactory,
  GiWateringCan,
  GiSofa,
  GiWoodenSign,
  GiPowerGenerator,
  GiWindTurbine,
  GiElectric,
  GiLifeJacket,
  GiFilmProjector,
  GiSpeaker,
  GiColdHeart,
  GiExitDoor,
  GiRotaryPhone,
  GiMedicalPack,
  GiCrane,
  GiServerRack,
  GiOfficeChair,
  GiCubeforce,
  GiVendingMachine,
  GiCargoCrate,
  GiForklift,
  GiRecycle,
} from "react-icons/gi";

export const assetCategories = [
  {
    key: "stairCases",
    value: "Stair Cases",
    type: "residential",
    icon: <GiStairs />,
  },
  {
    key: "golfCartPaths",
    value: "Golf Cart Paths",
    type: "residential",
    icon: <GiCartwheel />,
  },
  {
    key: "wasteDisposalBins",
    value: "Waste Disposal Bins",
    type: "residential",
    icon: <GiTrashCan />,
  },
  {
    key: "retailSpaces",
    value: "Retail Spaces",
    type: "commercial",
    icon: <GiShop />,
  },
  {
    key: "industrialUnits",
    value: "Industrial Units",
    type: "commercial",
    icon: <GiFactory />,
  },
  {
    key: "sprinklerSystems",
    value: "Sprinkler Systems",
    type: "commercial",
    icon: <GiWateringCan />,
  },
  {
    key: "employeeLounge",
    value: "Employee Lounge",
    type: "commercial",
    icon: <GiSofa />,
  },
  {
    key: "corporateSignage",
    value: "Corporate Signage",
    type: "commercial",
    icon: <GiWoodenSign />,
  },
  {
    key: "emergencyExitSigns",
    value: "Emergency Exit Signs",
    type: "commercial",
    icon: <GiExitDoor />,
  },
  {
    key: "backupGenerators",
    value: "Backup Generators",
    type: "commercial",
    icon: <GiPowerGenerator />,
  },
  {
    key: "conferencePhones",
    value: "Conference Phones",
    type: "commercial",
    icon: <GiRotaryPhone />,
  },
  {
    key: "wasteManagementSystems",
    value: "Waste Management Systems",
    type: "commercial",
    icon: <GiRecycle />,
  },
  {
    key: "emergencyKits",
    value: "Emergency Kits",
    type: "commercial",
    icon: <GiMedicalPack />,
  },
  {
    key: "highVoltageEquipment",
    value: "High Voltage Equipment",
    type: "commercial",
    icon: <GiElectric />,
  },
  {
    key: "materialHandlingEquipment",
    value: "Material Handling Equipment",
    type: "commercial",
    icon: <GiCrane />,
  },
  {
    key: "loadingRamps",
    value: "Loading Ramps",
    type: "commercial",
    icon: <GiStairs />,
  },
  {
    key: "distributionSystems",
    value: "Distribution Systems",
    type: "commercial",
    icon: <GiRollingSuitcase />,
  },
  {
    key: "serverRooms",
    value: "Server Rooms",
    type: "commercial",
    icon: <GiServerRack />,
  },
  {
    key: "fences",
    value: "Fences",
    type: "commercial",
    icon: <GiMeshNetwork />,
  },
  {
    key: "cart",
    value: "Cart",
    type: "commercial",
    icon: <FaShoppingCart />,
  },
  {
    key: "officeDesks",
    value: "Office Desks",
    type: "commercial",
    icon: <GiOfficeChair />,
  },
  {
    key: "cubicles",
    value: "Cubicles",
    type: "commercial",
    icon: <GiCubeforce />,
  },
  {
    key: "safetyEquipment",
    value: "Safety Equipment",
    type: "commercial",
    icon: <GiLifeJacket />,
  },
  {
    key: "signageAndDisplays",
    value: "Signage and Displays",
    type: "commercial",
    icon: <GiWoodenSign />,
  },
  {
    key: "conferenceProjectors",
    value: "Conference Projectors",
    type: "commercial",
    icon: <GiFilmProjector />,
  },
  {
    key: "audioVisualEquipment",
    value: "Audio-Visual Equipment",
    type: "commercial",
    icon: <GiSpeaker />,
  },
  {
    key: "vendingMachines",
    value: "Vending Machines",
    type: "commercial",
    icon: <GiVendingMachine />,
  },
  {
    key: "shippingContainers",
    value: "Shipping Containers",
    type: "commercial",
    icon: <GiCargoCrate />,
  },
  {
    key: "industrialCoolingSystems",
    value: "Industrial Cooling Systems",
    type: "commercial",
    icon: <GiColdHeart />,
  },
  {
    key: "forklifts",
    value: "Forklifts",
    type: "commercial",
    icon: <GiForklift />,
  },
  {
    key: "sidewalks",
    value: "Sidewalks and Pathways",
    type: "residential",
    icon: <FaWalking />,
  },
  {
    key: "commonAreaLighting",
    value: "Common Area Lighting",
    type: "residential",
    icon: <FaLightbulb />,
  },
  {
    key: "picnicAreas",
    value: "Benches and Picnic Areas",
    type: "residential",
    icon: <FaTree />,
  },
  {
    key: "waterFeatures",
    value: "Water Features",
    type: "residential",
    icon: <FaWater />,
  },
  {
    key: "clubhouse",
    value: "Clubhouse",
    type: "residential",
    icon: <FaHome />,
  },
  {
    key: "fitnessCenter",
    value: "Fitness Center",
    type: "residential",
    icon: <FaDumbbell />,
  },
  {
    key: "communityRoom",
    value: "Community Room",
    type: "residential",
    icon: <FaDoorClosed />,
  },
  {
    key: "restrooms",
    value: "Restrooms",
    type: "residential",
    icon: <FaRestroom />,
  },
  {
    key: "maintenanceSheds",
    value: "Maintenance Sheds",
    type: "residential",
    icon: <FaTools />,
  },
  {
    key: "swimmingPool",
    value: "Swimming Pool",
    type: "residential",
    icon: <FaSwimmer />,
  },
  {
    key: "hotTubSpa",
    value: "Hot Tub/Spa",
    type: "residential",
    icon: <FaHotTub />,
  },
  {
    key: "tennisCourts",
    value: "Tennis Courts",
    type: "residential",
    icon: <GiTennisCourt />,
  },
  {
    key: "basketballCourts",
    value: "Basketball Courts",
    type: "residential",
    icon: <FaBasketballBall />,
  },
  {
    key: "playgrounds",
    value: "Playgrounds",
    type: "residential",
    icon: <FaChild />,
  },
  {
    key: "sportsFields",
    value: "Sports Fields",
    type: "residential",
    icon: <FaFutbol />,
  },
  {
    key: "golfCourse",
    value: "Golf Course",
    type: "residential",
    icon: <FaGolfBall />,
  },
  {
    key: "balconies",
    value: "Balconies",
    type: "residential",
    icon: <FaBuilding />,
  },
  { key: "dogPark", value: "Dog Park", type: "residential", icon: <FaDog /> },
  {
    key: "waterSupplySystems",
    value: "Water Supply Systems",
    type: "residential",
    icon: <FaTint />,
  },
  {
    key: "sewerSystems",
    value: "Sewer Systems",
    type: "residential",
    icon: <FaPoop />,
  },
  {
    key: "stormwaterManagementSystems",
    value: "StormWater Management Systems",
    type: "residential",
    icon: <GiWaterRecycling />,
  },
  {
    key: "electricalSystems",
    value: "Electrical Systems",
    type: "residential",
    icon: <FaPlug />,
  },
  {
    key: "gasSystems",
    value: "Gas Systems",
    type: "residential",
    icon: <FaGasPump />,
  },
  {
    key: "communicationSystems",
    value: "Communication Systems",
    type: "residential",
    icon: <FaBroadcastTower />,
  },
  {
    key: "window",
    value: "Window",
    type: "residential",
    icon: <FaWindowMaximize />,
  },
  {
    key: "securityGates",
    value: "Security Gates",
    type: "residential",
    icon: <FaShieldAlt />,
  },
  {
    key: "surveillanceCameras",
    value: "Surveillance Cameras",
    type: "residential",
    icon: <FaVideo />,
  },
  {
    key: "fireExtinguishers",
    value: "Fire Extinguishers",
    type: "residential",
    icon: <FaFireExtinguisher />,
  },
  {
    key: "emergencyLighting",
    value: "Emergency Lighting",
    type: "residential",
    icon: <FaLightbulb />,
  },
  {
    key: "alarmSystems",
    value: "Alarm Systems",
    type: "residential",
    icon: <FaBell />,
  },
  {
    key: "defibrillators",
    value: "Defibrillators",
    type: "residential",
    icon: <FaHeartbeat />,
  },
  {
    key: "roadsAndStreets",
    value: "Roads and Streets",
    type: "residential",
    icon: <FaRoad />,
  },
  {
    key: "parkingLots",
    value: "Parking Lots",
    type: "residential",
    icon: <FaParking />,
  },
  {
    key: "bikePaths",
    value: "Bike Paths",
    type: "residential",
    icon: <FaBicycle />,
  },
  {
    key: "garagesAndCarports",
    value: "Garages and Carports",
    type: "residential",
    icon: <FaWarehouse />,
  },
  {
    key: "officeFurniture",
    value: "Office Furniture",
    type: "residential",
    icon: <FaChair />,
  },
  {
    key: "maintenanceEquipment",
    value: "Maintenance Equipment",
    type: "residential",
    icon: <FaTools />,
  },
  {
    key: "cleaningSupplies",
    value: "Cleaning Supplies",
    type: "residential",
    icon: <FaBroom />,
  },
  {
    key: "poolFurniture",
    value: "Pool Furniture",
    type: "residential",
    icon: <FaChair />,
  },
  {
    key: "gymEquipment",
    value: "Gym Equipment",
    type: "residential",
    icon: <FaDumbbell />,
  },
  {
    key: "recyclingStations",
    value: "Recycling Stations",
    type: "residential",
    icon: <FaRecycle />,
  },
  {
    key: "solarPanels",
    value: "Solar Panels",
    type: "residential",
    icon: <FaSolarPanel />,
  },
  {
    key: "rainwaterHarvestingSystems",
    value: "Rainwater Harvesting Systems",
    type: "residential",
    icon: <FaCloudRain />,
  },
  {
    key: "compostingFacilities",
    value: "Composting Facilities",
    type: "residential",
    icon: <FaSeedling />,
  },
  {
    key: "officeSupplies",
    value: "Office Supplies",
    type: "residential",
    icon: <FaClipboard />,
  },
  {
    key: "computersAndItEquipment",
    value: "Computers and IT Equipment",
    type: "residential",
    icon: <FaDesktop />,
  },
  {
    key: "filingCabinets",
    value: "Filing Cabinets",
    type: "residential",
    icon: <FaFileAlt />,
  },
  {
    key: "printersAndCopiers",
    value: "Printers and Copiers",
    type: "residential",
    icon: <FaPrint />,
  },
  {
    key: "bbqPicnicAreas",
    value: "BBQ/Picnic Areas",
    type: "residential",
    icon: <FaUtensils />,
  },
  {
    key: "communityGardens",
    value: "Community Gardens",
    type: "residential",
    icon: <FaLeaf />,
  },
  {
    key: "boatDocksMarinas",
    value: "Boat Docks/Marinas",
    type: "residential",
    icon: <FaAnchor />,
  },
  {
    key: "rvBoatStorageAreas",
    value: "RV/Boat Storage Areas",
    type: "residential",
    icon: <FaShip />,
  },
  {
    key: "holidayDecorations",
    value: "Holiday Decorations",
    type: "residential",
    icon: <FaFlag />,
  },
  {
    key: "bannersAndFlags",
    value: "Banners and Flags",
    type: "residential",
    icon: <FaFlag />,
  },
  {
    key: "plantersAndFlowerBeds",
    value: "Planters and Flower Beds",
    type: "residential",
    icon: <FaSeedling />,
  },
  {
    key: "bulletinBoards",
    value: "Bulletin Boards",
    type: "residential",
    icon: <FaClipboardList />,
  },
  {
    key: "officeBuildings",
    value: "Office Buildings",
    type: "commercial",
    icon: <FaBuilding />,
  },
  {
    key: "conferenceRooms",
    value: "Conference Rooms",
    type: "commercial",
    icon: <MdMeetingRoom />,
  },
  {
    key: "loadingDocks",
    value: "Loading Docks",
    type: "commercial",
    icon: <FaTruckLoading />,
  },
  {
    key: "elevators",
    value: "Elevators",
    type: "commercial",
    icon: <MdElevator />,
  },
  {
    key: "escalators",
    value: "Escalators",
    type: "commercial",
    icon: <MdEscalator />,
  },
  {
    key: "parkingGarages",
    value: "Parking Garages",
    type: "commercial",
    icon: <FaParking />,
  },
  {
    key: "securitySystems",
    value: "Security Systems",
    type: "commercial",
    icon: <MdSecurity />,
  },
  // {
  //   key: "surveillanceCameras",
  //   value: "Surveillance Cameras",
  //   type: "commercial",
  //   icon: <FaVideo />,
  // },
  {
    key: "hvacSystems",
    value: "HVAC Systems",
    type: "commercial",
    icon: <GiWindTurbine />,
  },
  {
    key: "plumbingSystems",
    value: "Plumbing Systems",
    type: "commercial",
    icon: <MdPlumbing />,
  },
  {
    key: "fireAlarmSystems",
    value: "Fire Alarm Systems",
    type: "commercial",
    icon: <MdFireExtinguisher />,
  },
  {
    key: "conferenceRoomEquipment",
    value: "Conference Room Equipment",
    type: "commercial",
    icon: <FaDesktop />,
  },
  {
    key: "cafeterias",
    value: "Cafeterias",
    type: "commercial",
    icon: <MdLocalCafe />,
  },
  {
    key: "landscaping",
    value: "Landscaping",
    type: "commercial",
    icon: <FaTree />,
  },
  {
    key: "roof",
    value: "Roof",
    type: "commercial",
    icon: <FaHome />,
  },
  {
    key: "rooftopSolarPanels",
    value: "Rooftop Solar Panels",
    type: "commercial",
    icon: <FaSolarPanel />,
  },
  {
    key: "networkingEquipment",
    value: "Networking Equipment",
    type: "commercial",
    icon: <MdNetworkWifi />,
  },
  {
    key: "telecommunicationSystems",
    value: "Telecommunication Systems",
    type: "commercial",
    icon: <MdPhone />,
  },
  {
    key: "waterFiltrationSystems",
    value: "Water Filtration Systems",
    type: "commercial",
    icon: <FaTint />,
  },
  {
    key: "lightingSystems",
    value: "Lighting Systems",
    type: "commercial",
    icon: <FaLightbulb />,
  },
  {
    key: "firstAidStations",
    value: "First Aid Stations",
    type: "commercial",
    icon: <FaHeartbeat />,
  },
  {
    key: "warehouseShelving",
    value: "Warehouse Shelving",
    type: "commercial",
    icon: <FaWarehouse />,
  },
  {
    key: "conferenceRoomFurniture",
    value: "Conference Room Furniture",
    type: "commercial",
    icon: <FaChair />,
  },
  {
    key: "industrialMachinery",
    value: "Industrial Machinery",
    type: "commercial",
    icon: <MdFactory />,
  },
  {
    key: "customerWaitingAreas",
    value: "Customer Waiting Areas",
    type: "commercial",
    icon: <FaChair />,
  },
  {
    key: "bikeRacks",
    value: "Bike Racks",
    type: "commercial",
    icon: <FaBicycle />,
  },
  {
    key: "publicRestrooms",
    value: "Public Restrooms",
    type: "commercial",
    icon: <FaRestroom />,
  },
  {
    key: "cafeteriaFurniture",
    value: "Cafeteria Furniture",
    type: "commercial",
    icon: <FaChair />,
  },
  {
    key: "dockLevelers",
    value: "Dock Levelers",
    type: "commercial",
    icon: <FaTruckLoading />,
  },
];

export const vendorTypes = [
  { key: "landscaping", value: "Landscaping and Grounds Maintenance" },
  { key: "pestControl", value: "Pest Control Services" },
  { key: "janitorial", value: "Janitorial and Cleaning Services" },
  { key: "poolMaintenance", value: "Pool Maintenance and Repair" },
  { key: "security", value: "Security Services" },
  { key: "roofing", value: "Roofing and Waterproofing" },
  { key: "plumbing", value: "Plumbing Services" },
  { key: "electrical", value: "Electrical Services" },
  { key: "generalContractor", value: "General Contractor Services" },
  { key: "hvac", value: "HVAC Services" },
  { key: "painting", value: "Painting Services" },
  { key: "windowCleaning", value: "Window Cleaning Services" },
  { key: "concrete", value: "Concrete and Paving Services" },
  { key: "asphalt", value: "Asphalt Repair and Maintenance" },
  { key: "treeServices", value: "Tree Trimming and Removal" },
  { key: "irrigation", value: "Irrigation Systems and Maintenance" },
  { key: "fencing", value: "Fencing Installation and Repair" },
  { key: "exteriorCleaning", value: "Exterior Building Cleaning" },
  { key: "fireProtection", value: "Fire Protection Services" },
  { key: "locksmith", value: "Locksmith Services" },
  { key: "wasteManagement", value: "Waste Management and Recycling" },
  { key: "snowRemoval", value: "Snow Removal Services" },
  { key: "gutterCleaning", value: "Gutter Cleaning and Maintenance" },
  { key: "lighting", value: "Outdoor and Street Lighting" },
  { key: "signage", value: "Signage and Wayfinding" },
  { key: "playgroundMaintenance", value: "Playground Equipment Maintenance" },
  { key: "paving", value: "Paving Services" },
  { key: "masonry", value: "Masonry Services" },
  { key: "insurance", value: "Insurance Services" },
  { key: "financial", value: "Financial and Accounting Services" },
  { key: "legal", value: "Legal Services" },
  { key: "management", value: "Property Management Services" },
  { key: "reserves", value: "Reserves Studies" },
];
