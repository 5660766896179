import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  CircularProgress,
  Modal,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "../HeaderModals/headerModal.scss";

import Toast from "../../Common/Toast/Toast";
import AddMemberSkeleton from "../../Skeletons/AddMemberSkeleton";
import DatePickerComp from "../../Common/DatePicker/DatePicker";
import dayjs from "dayjs";
import { createMeeting } from "../../../Redux/Feature/Meeting-slice";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../../Utils/utils";
interface AddMeetingModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
}

const AddMeetingModal: React.FC<AddMeetingModalProps> = ({
  isOpen,
  onClose,
  associationId,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<any>({});

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  // Function to get the browser's time zone
  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const [meetingData, setMeetingData] = useState<{
    title: string;
    description: string;
    type: number;
    startTime: Date | null;
    startDate: string;
    duration: number;
    meetingGroup: string;
    recurringType: number;
    repeatInterval: number;
    monthlyWeekDay: number;
    monthlyWeek: number;
    weeklyDay: number;
    timeZone: string;
  }>({
    title: "",
    description: "",
    type: 8,
    startTime: null,
    startDate: "",
    duration: 0,
    meetingGroup: "general",
    recurringType: 3,
    repeatInterval: 1,
    monthlyWeekDay: 1,
    monthlyWeek: 1,
    weeklyDay: 1,
    timeZone: getTimeZone(),
  });
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const typeOptions = [
    { key: 8, value: "Recurring" },
    { key: 2, value: "One Time" },
  ];
  const [isEditMode, setIsEditMode] = useState(false);

  const groupOptions = [
    { value: MEMBER_TYPE.BOARD, label: "Board" },
    { value: MEMBER_TYPE.GENERAL, label: "General" },
  ];
  const recurringTypeOptions = [
    { key: 1, value: "Daily" },
    { key: 2, value: "Weekly" },
    { key: 3, value: "Monthly" },
  ];
  const weeksOfTheMonth = [
    { key: 1, value: "First" },
    { key: 2, value: "Second" },
    { key: 3, value: "Third" },
    { key: 4, value: "Fourth" },
    { key: -1, value: "Last" },
  ];
  const weekDays = [
    { key: 1, value: "Sunday" },
    { key: 2, value: "Monday" },
    { key: 3, value: "Tuesday" },
    { key: 4, value: "Wednesday" },
    { key: 5, value: "Thursday" },
    { key: 6, value: "Friday" },
    { key: 7, value: "Saturday" },
  ];

  useEffect(() => {
    reset();
  }, []);
  const reset = () => {
    setMeetingData({
      title: "",
      description: "",
      type: 8,
      startTime: null,
      startDate: "",
      duration: 0,
      meetingGroup: "general",
      recurringType: 3,
      repeatInterval: 1,
      monthlyWeekDay: 1,
      monthlyWeek: 1,
      weeklyDay: 1,
      timeZone: getTimeZone(),
    });
  };
  function dialogClose() {
    reset();
    setLoading(false);
    onClose();
  }
  const handleTimeChange = (e: any) => {
    const { name, value } = e.target;
    const newTimeValue = value + ":00";
    setMeetingData((prevState) => ({
      ...prevState,
      [name]: newTimeValue,
    }));
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setMeetingData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  function onInputFocus(args: any) {
    args.target.parentElement.classList.add("e-input-focus");
  }
  function onInputBlur(args: any) {
    args.target.parentElement.classList.remove("e-input-focus");
  }
  function onIconMouseDown(args: any) {
    args.persist();
    setTimeout(() => {
      args.target.classList.add("e-input-btn-ripple");
    }, 300);
  }
  function onIconMouseUp(args: any) {
    args.target.classList.remove("e-input-btn-ripple");
  }
  const handleRecurringTypeChange = (value: number) => {
    setMeetingData((prevState) => ({
      ...prevState,
      recurringType: value,
    }));
  };
  const handleTypeChange = (value: number) => {
    setMeetingData((prevState) => ({
      ...prevState,
      type: value,
    }));
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const handleClose = () => {
    onClose();
    handleCloseToast();
  };
  const validateForm = (data: any) => {
    const errors: any = {};

    if (!data.title) {
      errors.title = "Title is required";
    }
    if (!data.startTime) {
      errors.startTime = "Start Time is required";
    }
    if (!data.startDate) {
      errors.startDate = "Start Date is required";
    }
    return errors;
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const validationData: any = validateForm(meetingData);
    if (Object.keys(validationData).length > 0) {
      setValidationErrors(validationData);
      setLoading(false);
    } else {
      try {
        const updatedMeetingData = {
          startTime: meetingData.startTime,
          startDate: meetingData.startDate,
          title: meetingData.title,
          description: meetingData.description,
          duration: meetingData.duration,
          type: meetingData.type,
          meetingGroup: meetingData.meetingGroup,
          recurrence: {
            monthlyWeek: meetingData.monthlyWeek,
            monthlyWeekDay: meetingData.monthlyWeekDay,
            repeatInterval: meetingData.repeatInterval,
            type: meetingData.recurringType,
            weeklyDay: meetingData.weeklyDay,
          },
          timeZone: meetingData.timeZone,
          associationId: associationId,
        };
        // setMeetingData(updatedMeetingData);
        const response = await dispatch(createMeeting(updatedMeetingData));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Meeting Created"), "success");
        } else if (
          response?.payload?.error === "Association email is required"
        ) {
          showToast(t("Association email is required"), "error");
        } else {
          showToast(t("Failed to create meeting"), "error");
        }
        reset();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };
  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  const handleGroupChange = (value: string) => {
    setMeetingData((prevState) => ({
      ...prevState,
      meetingGroup: value,
    }));
  };
  const handleDateChange = (field: any, value: any) => {
    setMeetingData({ ...meetingData, [field]: value });
  };
  return (
    <div>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="ticketModel" sx={{ pt: "10px", px: "15px" }}>
          <Box className="scroll-wrap">
            {" "}
            <Box sx={{ textAlign: "end" }} onClick={handleClose}>
              <Typography
                variant="h6"
                gutterBottom
                className="add-member-title"
              >
                {t("Create Meeting")}
              </Typography>
              <CancelIcon className="close-icon" />
            </Box>
            <Box>
              <Box>
                <div className="formDropdownField margin-bottom">
                  <label>{t("Title")}</label>
                  <OutlinedInput
                    name="title"
                    onChange={handleChange}
                    fullWidth
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    className="add-document-title"
                    sx={{ width: "50%" }}
                  />
                  {validationErrors.title && (
                    <div style={{ color: `${theme.palette.invalid}` }}>
                      {t(validationErrors.title)}
                    </div>
                  )}
                </div>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <InputLabel id="typeId1">{t("Meeting Type")}</InputLabel>
                <Select
                  labelId="typeId1"
                  id="typeId"
                  value={meetingData.type}
                  placeholder={t("Select Meeting Type")}
                  name="typeId"
                  displayEmpty
                  onChange={(event) =>
                    handleTypeChange(Number(event.target.value))
                  }
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    sx: {
                      "& .MuiMenu-paper": {
                        backgroundColor: `${theme.palette.textWhite}`,
                        color: `${theme.palette.textBlack}`,
                      },
                      "& .MuiMenuItem-root.Mui-disabled": {
                        opacity: 0.7,
                      },
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: `${theme.palette.selectedItem}`,
                      },
                      "& .MuiSvgIcon-root-MuiSelect-icon": {
                        color: `${theme.palette.textWhite}`,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t("Select Meeting Type")}
                  </MenuItem>

                  {typeOptions.map((type: any) => (
                    <MenuItem
                      className="SelectBox"
                      key={type.key}
                      value={type.key}
                    >
                      {t(type.value)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <div className="formDropdownField margin-bottom">
                  <label>{t("Duration")}</label>
                  <OutlinedInput
                    id="duration"
                    name="duration"
                    onChange={handleChange}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    className="add-document-duration"
                    sx={{ width: "50%" }}
                    endAdornment={
                      <InputAdornment position="end">
                        {" "}
                        {t("Minutes")}
                      </InputAdornment>
                    }
                  />
                  {validationErrors.duration && (
                    <div style={{ color: `${theme.palette.invalid}` }}>
                      {t(validationErrors.duration)}
                    </div>
                  )}
                </div>
              </Box>
              <div className="formDropdownField margin-bottom">
                <InputLabel id="meetingGroupId">
                  {t("Meeting Group")}
                </InputLabel>
                <Select
                  labelId="meetingGroupId"
                  id="meetingGroupId"
                  value={meetingData.meetingGroup}
                  placeholder={t("Select Voting Group")}
                  name="votingGroupId"
                  displayEmpty
                  onChange={(event) => handleGroupChange(event.target.value)}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    sx: {
                      "& .MuiMenu-paper": {
                        backgroundColor: `${theme.palette.textWhite}`,
                        color: "text.primary",
                      },
                      "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                        color: `${theme.palette.text.primary}`,
                        border: `1px solid ${theme.palette.activeColor}`,
                        width: "100%",
                        backgroundColor: `${theme.palette.activeColor}`,
                        display: "block",
                        textAlign: "center",
                        margin: "8px",
                        "&:hover": {
                          color: `${theme.palette.text.primary}`,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t("Select Voting Group")}
                  </MenuItem>

                  {groupOptions.map((votingGroup: any) => (
                    <MenuItem
                      className="SelectBox"
                      key={votingGroup.value}
                      value={votingGroup.value}
                    >
                      {t(votingGroup.label)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <TextField
                label={t("Start Time")}
                name="startTime"
                type="time"
                value={meetingData.startTime}
                onChange={handleTimeChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                helperText={`Time Zone: ${getTimeZone()}`}
              />
              {validationErrors.startTime && (
                <div style={{ color: `${theme.palette.invalid}` }}>
                  {t(validationErrors.startTime)}
                </div>
              )}
              <label>
                <Typography
                  id="assetId1"
                  sx={{
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                >
                  {t("Start Date")}
                </Typography>
              </label>
              <DatePickerComp
                disablePast={true} // Enable this to disallow past dates
                disableFuture={false} // Disable this to allow future dates
                isEditMode={isEditMode}
                className="custom-datepicker"
                value={
                  meetingData.startDate ? dayjs(meetingData.startDate) : null
                }
                onChange={(newValue: any) => {
                  const formattedDate = newValue
                    ? newValue.format("YYYY-MM-DD")
                    : null;
                  handleDateChange("startDate", formattedDate);
                }}
              />
              {validationErrors.startDate && (
                <div style={{ color: `${theme.palette.invalid}` }}>
                  {t(validationErrors.startDate)}
                </div>
              )}
              {meetingData.type === 8 && (
                <>
                  <Box sx={{ marginBottom: "16px", marginTop: "16px" }}>
                    <InputLabel id="typeId1">{t("Recurring Type")}</InputLabel>
                    <Select
                      labelId="typeId1"
                      id="typeId"
                      value={meetingData.recurringType}
                      placeholder={t("Select Recurring Type")}
                      name="typeId"
                      displayEmpty
                      onChange={(event) =>
                        handleRecurringTypeChange(Number(event.target.value))
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: `${theme.palette.textBlack}`,
                          },
                          "& .MuiMenuItem-root.Mui-disabled": {
                            opacity: 0.7,
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: `${theme.palette.selectedItem}`,
                          },
                          "& .MuiSvgIcon-root-MuiSelect-icon": {
                            color: `${theme.palette.textWhite}`,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        {t("Select Recurring Type")}
                      </MenuItem>

                      {recurringTypeOptions.map((type: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={type.key}
                          value={type.key}
                        >
                          {t(type.value)}
                        </MenuItem>
                      ))}
                    </Select>

                    {meetingData.recurringType === 3 && (
                      <>
                        <Box sx={{ marginBottom: "16px", marginTop: "16px" }}>
                          <InputLabel id="typeId1">
                            {t("Select Week of the Month")}
                          </InputLabel>
                          <Select
                            labelId="typeId1"
                            id="typeId"
                            value={meetingData.monthlyWeek}
                            placeholder={t("Select Week of the Month")}
                            name="typeId"
                            displayEmpty
                            onChange={(event) => handleChange(event)}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              sx: {
                                "& .MuiMenu-paper": {
                                  backgroundColor: `${theme.palette.textWhite}`,
                                  color: `${theme.palette.textBlack}`,
                                },
                                "& .MuiMenuItem-root.Mui-disabled": {
                                  opacity: 0.7,
                                },
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: `${theme.palette.selectedItem}`,
                                },
                                "& .MuiSvgIcon-root-MuiSelect-icon": {
                                  color: `${theme.palette.textWhite}`,
                                },
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              {t("Select Week of the Month")}
                            </MenuItem>

                            {weeksOfTheMonth.map((type: any) => (
                              <MenuItem
                                className="SelectBox"
                                key={type.key}
                                value={type.key}
                              >
                                {t(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box sx={{ marginBottom: "16px", marginTop: "16px" }}>
                          <InputLabel id="typeId1">
                            {t("Select Day of the Week")}
                          </InputLabel>
                          <Select
                            labelId="typeId1"
                            id="typeId"
                            value={meetingData.monthlyWeekDay}
                            placeholder={t("Select Day of the Week")}
                            name="typeId"
                            displayEmpty
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              sx: {
                                "& .MuiMenu-paper": {
                                  backgroundColor: `${theme.palette.textWhite}`,
                                  color: `${theme.palette.textBlack}`,
                                },
                                "& .MuiMenuItem-root.Mui-disabled": {
                                  opacity: 0.7,
                                },
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: `${theme.palette.selectedItem}`,
                                },
                                "& .MuiSvgIcon-root-MuiSelect-icon": {
                                  color: `${theme.palette.textWhite}`,
                                },
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              {t("Select Day of the Week")}
                            </MenuItem>

                            {weekDays.map((type: any) => (
                              <MenuItem
                                className="SelectBox"
                                key={type.key}
                                value={type.key}
                              >
                                {t(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </>
                    )}
                    {meetingData.recurringType === 2 && (
                      <>
                        <Box sx={{ marginBottom: "16px", marginTop: "16px" }}>
                          <InputLabel id="typeId1">
                            {t("Select Day of the Week")}
                          </InputLabel>
                          <Select
                            labelId="typeId1"
                            id="typeId"
                            value={meetingData.weeklyDay}
                            placeholder={t("Select Day of the Week")}
                            name="typeId"
                            displayEmpty
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              sx: {
                                "& .MuiMenu-paper": {
                                  backgroundColor: `${theme.palette.textWhite}`,
                                  color: `${theme.palette.textBlack}`,
                                },
                                "& .MuiMenuItem-root.Mui-disabled": {
                                  opacity: 0.7,
                                },
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: `${theme.palette.selectedItem}`,
                                },
                                "& .MuiSvgIcon-root-MuiSelect-icon": {
                                  color: `${theme.palette.textWhite}`,
                                },
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              {t("Select Day of the Week")}
                            </MenuItem>

                            {weekDays.map((type: any) => (
                              <MenuItem
                                className="SelectBox"
                                key={type.key}
                                value={type.key}
                              >
                                {t(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
              <div className="formField">
                <label>{t("Description")}</label>
                <textarea
                  className="textAreaInput"
                  name="description"
                  value={meetingData?.description || ""}
                  onChange={handleChange}
                  placeholder={t("Write your Description here...")}
                />
              </div>
            </Box>
            <Box>
              <Box>
                {" "}
                <div className="SubmitBtn ticketModel__footer">
                  <Button
                    className="sBtn e-control e-btn"
                    id="targetButton1"
                    onClick={handleSubmit}
                    disabled={isFetching === true}
                  >
                    {loading ? (
                      <CircularProgress
                        size={23}
                        sx={{ color: `${theme.palette.textWhite}` }}
                      />
                    ) : (
                      t("Submit")
                    )}
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddMeetingModal;
