import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, useTheme } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const EditBudgetAllocated = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    allocatedAmount,
    editAllocatedHandler,
    budgetId,
    showToast,
    updateBudgetHandler,
    setUpdateAllocatedProgress,
    updateAllocatedProgress,
    monthYear,
  } = props;
  const theme = useTheme();
  const validationSchema = yup.object({
    allocatedAmount: yup.number().required(t("Allocated Amount is required")),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allocatedAmount: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setUpdateAllocatedProgress(true);
      const obj = {
        budgetId,
        allocatedAmount: values.allocatedAmount,
        monthYear,
      };
      const response = await dispatch(updateBudgetHandler(obj));
      setUpdateAllocatedProgress(false);
      editAllocatedHandler(false);
      if (response?.payload?.statusCode === 200) {
        showToast(t("Budget Updated"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    },
  });
  useEffect(() => {
    formik.setValues({ allocatedAmount });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="formGroup"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            fullWidth
            id="allocatedAmount"
            name="allocatedAmount"
            value={formik.values.allocatedAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            error={
              formik.touched.allocatedAmount &&
              Boolean(formik.errors.allocatedAmount)
            }
            helperText={
              formik.touched.allocatedAmount && formik.errors.allocatedAmount
            }
            sx={{
              // fontSize: 30,
              "& .MuiInput-root .MuiInput-input": {
                color: `${theme.palette.text.primary}`,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateAllocatedProgress}
          >
            {" "}
            {t("Save")}
            {updateAllocatedProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            onClick={() => editAllocatedHandler(false)}
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateAllocatedProgress}
          >
            {" "}
            {t("Cancel")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditBudgetAllocated;
