import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import building from "../../../Assets/Images/PNG/buildingSmall.png";
import door from "../../../Assets/Images/PNG/door.png";
import directory from "../../../Assets/Images/SVG/directory.svg";
import Stairs from "../../../Assets/Images/SVG/stairs.svg";
import "../../../Component/Modals/AssetModals/assetModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addBuildingAPI,
  getAllBuildingDirectory,
  deleteUnit,
  updateBuilding,
  deleteBuilding,
} from "../../../Redux/Feature/Asset-slice";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditUnitPersonModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/EditPersonModal";
import EditUnitModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/EditUnitModal";
import AddUnitModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/AddUnitModal";
import { Clear, Search } from "@mui/icons-material";
import DeleteUnitModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/DeleteUnitModal";

import DeleteBuildingModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/DeleteBuildingModal";
import Toast from "../../../Component/Common/Toast/Toast";
import UnitPersonList from "./Components/UnitPersonList";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../../Utils/utils";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import BuildingModal from "../../../Component/Modals/AssetModals/UnitDirectoryModals/BuildingModal";
import Building from "../../../Assets/Images/PNG/building.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./unitDirectory.scss";
import "./createBuilding.scss";
import BuildingDirectorySkeleton from "../../../Component/Skeletons/BuildingDirectorySkeleton";
import SpinnerLoader from "../../../Component/Common/SpinnerLoader/SpinnerLoader";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

interface unitPersonObject {
  email: string;
  name: string;
}
interface PersonObject {
  assetId: string;
  unitPersons: unitPersonObject[];
  role: string;
}
interface UnitUpdateField {
  description: string | null;
  title: string;
  floor: string;
  address2: string;
  ownershipShare: string;
  assetId: string;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const BuildingDetail = () => {
  const [spinnerLoader, setSpinnerLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const validationSchema = yup.object({
    address: yup.string().required(t("Address is required")),
    city: yup.string().required(t("City is required")),
    state: yup.string().required(t("State is required")),
    postalCode: yup.string().trim().required(t("Zip code is required")),
    country: yup.string().required(t("Country is required")),
    title: yup.string().required(t("Title is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSpinnerLoader(true);
      setSelectedAddress({
        ...selectedAddress,
        ...values,
      });
      formHandleSubmit({
        ...selectedAddress,
        ...values,
      });
    },
  });
  const theme = useTheme();
  const mapRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [showUnitEditModal, setShowUnitEditModal] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error" | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [editPerson, setEditPerson] = useState<PersonObject>({
    assetId: "",
    unitPersons: [],
    role: "",
  });
  const [editUnitFields, setEditUnitFields] = useState<UnitUpdateField>({
    assetId: "",
    description: "",
    title: "",
    floor: "",
    address2: "",
    ownershipShare: "",
  });
  const [showDeleteUnitModal, setShowDeleteUnitModal] =
    useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [showDeleteBuildingModal, setShowDeleteBuildingModal] =
    useState<boolean>(false);
  const [selectedBuilding, setSelectedBuilding] = useState<any>(null);

  const BuildingDirectory = useSelector(
    (store: any) => store?.Asset?.buildingDirectory
  );

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );
  const unitCount = useSelector((state: any) => state?.Asset?.unitCount);
  const unitSubscriptionCount = useSelector(
    (state: any) => state?.Asset?.unitSubscriptionCount
  );

  const [expanded, setExpanded] = useState<number | false>(false);
  const [addUnitButton, setAddUnitButton] = useState<Boolean>(false);
  const [search, setSearch] = useState<String>("");
  const [buildingId, setBuildingId] = useState("");
  const [filteredBuildings, setFilteredBuildings] = useState<any[]>([]);
  const [serverError, setServerError] = React.useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [registerAssociation] = useState<any>({
    associationId: currentAssociation?.associationId,
    type: "",
    title: "",
    establishedDate: Date.now(),
    description: "",
    address: "",
    latitude: null,
    longitude: null,
    latitudeDelta: null,
    longitudeDelta: null,
  });
  const [showAddBuilding, setShowAddBuilding] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const autocomplete: any = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchAllBuildingDirectory();
  }, [currentAssociation.associationId]);

  const fetchAllBuildingDirectory = async () => {
    setLoading(true);
    if (currentAssociation) {
      await dispatch(
        getAllBuildingDirectory({
          associationId: currentAssociation.associationId,
        })
      );
    }
    setLoading(false);
  };

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleAddUnit = (accordionIndex: number) => {
    setAddUnitButton(true);
  };

  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const editPersonClickHandler = (
    unitPersons: any,
    assetId: string,
    role: string
  ) => {
    setShowPersonModal(true);
    setEditPerson({
      assetId,
      unitPersons: [...unitPersons],
      role,
    });
  };
  const editUnitClickHandler = (unitAsset: any) => {
    setShowUnitEditModal(true);
    setEditUnitFields({
      assetId: unitAsset.assetId,
      description: unitAsset?.description,
      title: unitAsset?.title,
      floor: unitAsset.floor,
      address2: unitAsset.address2,
      ownershipShare: unitAsset.ownershipShare,
    });
  };
  const handleClearSearch = () => {
    setSearch("");
  };

  const filterBuildings = (buildingList: any, search: any) => {
    if (!buildingList) {
      return [];
    }

    return buildingList?.filter((building: any) => {
      const lowerCaseSearch = search?.toLowerCase();
      const lowerCaseTitle = building?.title?.toLowerCase();
      const lowerCaseAddress = building?.address?.toLowerCase();
      const lowerCaseCity = building?.city?.toLowerCase();

      const titleMatches = lowerCaseTitle?.includes(lowerCaseSearch);
      const addressMatches = lowerCaseAddress?.includes(lowerCaseSearch);
      const cityMatches = lowerCaseCity?.includes(lowerCaseSearch);

      const unitMatches = building.units?.some((unit: any) => {
        const unitTitleMatches = unit.title?.toLowerCase()
          .includes(lowerCaseSearch);
        const address2Matches = unit.address2
          .toString().toLowerCase()
          .includes(lowerCaseSearch);
        const floorMatches = unit.floor.toString().includes(lowerCaseSearch);
        const personMatches = unit?.persons?.some((person: any) => {
          return person?.name?.toLowerCase()?.includes(lowerCaseSearch);
        });
        return unitTitleMatches || address2Matches || floorMatches || personMatches;
      });
      return titleMatches || addressMatches || cityMatches || unitMatches;
    });
  };

  useEffect(() => {
    setFilteredBuildings(filterBuildings(BuildingDirectory, search));
  }, [BuildingDirectory, search]);

  const handleDeleteUnitIconClick = (unitAsset: any) => {
    if (unitAsset?.persons?.length > 0) {
      showToast(
        t("This unit has active persons hence it can't be deleted."),
        "error"
      );
    } else {
      setShowDeleteUnitModal(true);

      setSelectedUnit(unitAsset);
    }
  };

  const handleDeleteUnit = async () => {
    try {
      if (selectedUnit && selectedUnit?.assetId) {
        const unitId = selectedUnit?.assetId;
        const response = await dispatch(deleteUnit(unitId));

        if (response?.payload?.statusCode === 200) {
          showToast(t("Unit Deleted"), "success");
        } else if (response?.payload?.status === 400) {
          showToast(t("Error"), "error");
        } else if (response.error) {
          handleError(response.payload.data);
        }
        setShowDeleteUnitModal(false);
      }
    } catch (error) {}
  };

  const closeUnitModal = () => {
    setShowDeleteUnitModal(false);
    setSelectedUnit(null);
  };

  const handleDeleteBuildingIconClick = (buildingData: any) => {
    setSelectedBuilding(buildingData);
    if (buildingData?.units?.length > 0) {
      showToast(
        t("This building has active units hence it can't be deleted."),
        "error"
      );
    } else {
      setShowDeleteBuildingModal(true);
    }
  };

  const handleEditBuildingIconClick = (buildingData: any) => {
    setIsEditMode(true);
    setSelectedBuilding(buildingData);
    setShowAddBuilding(true);
    setShowSuccess(false);
  };
  const handleDeleteBuilding = async () => {
    try {
      if (selectedBuilding && selectedBuilding?.assetId) {
        const assetId = selectedBuilding?.assetId;
        const response: any = await dispatch(deleteBuilding(assetId));
        if (response?.payload?.status === 400) {
          showToast(t("Please delete associated units first."), "error");
        } else {
          showToast(t("Building deleted successfully"), "success");
        }
      }
      setShowDeleteBuildingModal(false);
    } catch (err) {
      showToast(t("Error"), "error");
    }
  };
  const closeDeleteBuildingModal = () => {
    setShowDeleteBuildingModal(false);
    setSelectedBuilding(null);
  };

  const formHandleSubmit = async (formData: any) => {
    if (!currentAssociation?.associationId) {
      alert(t("Invalid Association"));
      setSpinnerLoader(false);
      return;
    }

    try {
      registerAssociation.address = selectedPlace.formatted_address;
      registerAssociation.associationId = currentAssociation?.associationId;
      if (selectedBuilding && selectedBuilding?.assetId) {
        const updatedBuildingPayload = {
          assetId: selectedBuilding?.assetId,
          ...formData,
          associationId: currentAssociation?.associationId,
        };
        const responseData: any = await dispatch(
          updateBuilding(updatedBuildingPayload)
        );
        if (responseData?.payload?.statusCode === 200) {
          showToast(t("Building updated successfully"), "success");
          setLoading(false);
        } else if (responseData.error) {
          handleError(responseData.data);
        }
      } else {
        const newBuilding = {
          ...formData,
          type: "building",
          establishedDate: Date.now(),
          associationId: currentAssociation?.associationId,
        };
        const response = await dispatch(addBuildingAPI(newBuilding));
        if (response?.payload?.statusCode === 200) {
          showToast(t("Building added successfully"), "success");
          setLoading(false);
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }

      setIsEditMode(false);
      setSelectedBuilding(null);
      setShowAddBuilding(false);
      setShowSuccess(true);
      setShow(false);

      navigate("/assets/directory");
    } catch (err) {
      setSpinnerLoader(false);
      const error = err as AxiosError;
      if (error.response) {
        setServerError(error.response.status);
        if (err instanceof AxiosError) {
          if (err?.response?.data?.error) {
            setServerErrorMsg(err?.response?.data?.error);
          }
        }
      }
    } finally {
      setSpinnerLoader(false);
    }
  };

  const handlePlaceChanged = () => {
    if (autocomplete.current) {
      const place = autocomplete.current.getPlace();
      if (!place || !place?.geometry || !place?.geometry?.location) {
        showToast(
          t("Please select an address from the dropdown list"),
          "error"
        );
        return;
      }
      const addressData: any = {};
      addressData.address = place?.formatted_address;
      var bounds = place.geometry.viewport.toJSON();

      addressData.latitude = (bounds.north + bounds.south) / 2;
      addressData.longitude = (bounds.east + bounds.west) / 2;
      addressData.latitudeDelta = bounds.north - bounds.south;
      addressData.longitudeDelta = bounds.east - bounds.west;
      setSelectedPlace(place);

      if (mapRef?.current) {
        mapRef?.current?.panTo(place?.geometry?.location);
      }
      place.address_components.forEach((component: any) => {
        const types = component.types;

        types.forEach((type: any) => {
          switch (type) {
            case "street_number": {
              addressData.streetNumber = `${component.long_name}`;
              break;
            }
            case "route": {
              addressData.streetName = component.short_name;
              break;
            }
            case "locality":
              addressData.city = component.long_name;
              break;
            case "administrative_area_level_1":
              addressData.state = component.long_name;
              break;
            case "country":
              addressData.country = component.long_name;
              break;
            case "postal_code":
              addressData.postalCode = component.long_name;
              break;
            default:
              break;
          }
        });
      });
      formik.setValues({
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        postalCode: addressData.postalCode,
        country: addressData.country,
        title: "",
      });
      setSelectedAddress(addressData);
      setSelectedPlace(autocomplete.current.getPlace());
      setShow(true);
    }
  };

  const showToast = (message: any, type: "success" | "error") => {
    setToastMessage(message);
    setToastType(type);
    setOpenToast(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleAddBuildingClick = () => {
    setShowAddBuilding(true);
    setShowSuccess(false);
    setSelectedBuilding(null);
    setIsEditMode(false);
  };

  const handleBackToListingClick = () => {
    setShowAddBuilding(false);
  };
  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  if (loading) {
    return <BuildingDirectorySkeleton />;
  }
  return (
    <>
      <SpinnerLoader loading={spinnerLoader} />
      <Toast
        type={toastType as "success" | "error"}
        open={openToast}
        message={`${toastMessage}`}
        handleClose={handleCloseToast}
      />
      <AddUnitModal
        showAddUnitModal={addUnitButton}
        setShowAddUnitModal={setAddUnitButton}
        associationId={currentAssociation.associationId}
        showToast={showToast}
        buildingId={buildingId}
      />
      <Box className="buildingBox unitCountBuildings">
        {showPersonModal && (
          <EditUnitPersonModal
            editPerson={editPerson}
            showPersonModal={showPersonModal}
            setShowPersonModal={setShowPersonModal}
            associationId={currentAssociation.associationId}
            showToast={showToast}
          />
        )}
        {showUnitEditModal && (
          <EditUnitModal
            showUnitEditModal={showUnitEditModal}
            setShowUnitEditModal={setShowUnitEditModal}
            editUnitFields={editUnitFields}
            showToast={showToast}
          />
        )}
        <DeleteUnitModal
          isOpen={showDeleteUnitModal}
          onClose={closeUnitModal}
          onDelete={handleDeleteUnit}
        />
        <DeleteBuildingModal
          isOpen={showDeleteBuildingModal}
          onClose={closeDeleteBuildingModal}
          onDelete={handleDeleteBuilding}
        />
        {!showAddBuilding && (
          <Typography
            sx={{
              fontSize: "20px",
              color: `${theme.palette.activeColor}`,
            }}
          >
            {t("Unit Count")} :{" "}
            <span style={{ color: `${theme.palette.activeColor}` }}>
              {unitCount | 0}
            </span>
          </Typography>
        )}
        <Box sx={{ paddingTop: "60px" }}>
          <div>
            {!showAddBuilding ? (
              <>
                <Box className="buildingBox__title">
                  <img src={building} alt="building-img" />
                  <Typography className="buildingBox__title__heading page_title">
                    {t("Buildings Directory")}
                  </Typography>
                </Box>
                <Box className="buildingBox__searchDiv">
                  <img src={directory} alt="Directory-age" />
                  <OutlinedInput
                    className="buildingBox__searchDivInput"
                    placeholder={t("Search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        {search ? (
                          <IconButton
                            onClick={handleClearSearch}
                            edge="end"
                            className="search-icon-button-hover"
                          >
                            <Clear style={{ color: "#20c1f5" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            edge="end"
                            className="search-icon-button-hover"
                          >
                            <Search style={{ color: "#20c1f5" }} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                  />
                  {isBoardRole ? (
                    <>
                      <Button
                        className="buildingBox__btnBoxItem"
                        onClick={handleAddBuildingClick}
                      >
                        {t("Add Building")}
                      </Button>
                    </>
                  ) : null}
                </Box>
              </>
            ) : (
              <div className="empty add-building-box">
                {!show && !showSuccess && showAddBuilding && (
                  <div className="bild_section_wrapper">
                    <div className="build_img_wrapper">
                      <img
                        src={Building}
                        alt="bank"
                        className="img-responsive"
                      />
                    </div>
                    <h4 className="build_heading_wrapper">
                      {isEditMode ? t("Edit Building") : t("Add Building")}
                    </h4>
                    <p className="build_text_wrapper">
                      {t("Type a building address")}
                    </p>
                    <div className="input_build_wrapper">
                      <Autocomplete
                        onLoad={(auto) => (autocomplete.current = auto)}
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <input type="text" placeholder={t("Enter address")} />
                      </Autocomplete>
                    </div>

                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleBackToListingClick}
                    >
                      <KeyboardBackspaceIcon sx={{ marginRight: "10px" }} />
                      {t("Back To listing")}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            {show && showAddBuilding && (
              <BuildingModal
                formik={formik}
                mapRef={mapRef}
                selectedPlace={selectedPlace}
                show={show}
                setShow={setShow}
                serverError={serverError}
                serverErrorMsg={serverErrorMsg}
                isEditMode={isEditMode}
                selectedBuilding={isEditMode ? selectedBuilding : null}
              />
            )}
          </div>
          {!showAddBuilding && (
            <>
              <Box className="buildingBox__card">
                {filteredBuildings?.length === 0 ? (
                  <Typography className="no-building-found">
                    {t("No Building found")}
                  </Typography>
                ) : (
                  filteredBuildings?.map((elem: any, index: any) => {
                    const fullAddress = `${elem?.address}`;
                    return (
                      <Accordion
                        className="buildingBox__cardItem"
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        key={index}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index + 1}bh-content`}
                          id={`panel${index + 1}bh-header`}
                          className="buildingBox__cardHead"
                        >
                          <img src={building} alt="building-logo" />
                          {expanded !== index ? (
                            <Box>
                              <Box>
                                <Typography
                                  className="buildingBox__cardHead-title"
                                  sx={{ color: "text.secondary" }}
                                >
                                  {elem?.title}
                                </Typography>
                                <Typography
                                  className="buildingBox__cardHead-title"
                                  sx={{
                                    color: "text.secondary",
                                    fontSize: "16px !important",
                                  }}
                                >
                                  {elem?.address}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box className="openHead-info">
                                <Box className="headField">
                                  <Typography>
                                    <span>{t("Title")}:</span>
                                    {elem.title ? elem.title : ""}{" "}
                                  </Typography>
                                  <Typography>
                                    <span>{t("Address 1")}:</span>
                                    {elem.streetNumber
                                      ? elem.streetNumber
                                      : ""}{" "}
                                    {elem.streetName ? elem.streetName : ""}
                                  </Typography>
                                </Box>
                                <Box className="headField">
                                  <Typography>
                                    <span>{t("City")}:</span>
                                    {elem.city ? elem.city : ""}
                                  </Typography>
                                  <Typography>
                                    <span>{t("State")}:</span>
                                    {elem.state ? elem.state : ""}
                                  </Typography>
                                </Box>
                                <Box className="headField">
                                  <Typography>
                                    <span>{t("Zip Code")}:</span>
                                    {elem.postalCode ? elem.postalCode : ""}
                                  </Typography>
                                  <Typography>
                                    <span>{t("Country")}:</span>
                                    {elem.country ? elem.country : ""}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {isBoardRole ? (
                                  <div style={{ display: "flex" }}>
                                    <button
                                      onClick={() =>
                                        handleEditBuildingIconClick(elem)
                                      }
                                    >
                                      <ModeEditIcon />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDeleteBuildingIconClick(elem)
                                      }
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                ) : null}
                              </Box>
                            </Box>
                          )}
                        </AccordionSummary>
                        <AccordionDetails className="buildingBox__cardBody">
                          <Typography className="buildingBox__cardBody-title">
                            {t("Added Units")}
                          </Typography>

                          <Box className="unitsBox">
                            {elem?.units?.length > 0 ? (
                              elem?.units
                                ?.filter((unitAsset: any) => {
                                  const lowerCaseSearch = search?.toLowerCase();
                                  const unitTitleMatches = unitAsset.title?.toLowerCase().includes(lowerCaseSearch);
                                  const address2Matches = unitAsset.address2
                                    .toString().toLowerCase()
                                    .includes(lowerCaseSearch);
                                  const floorMatches = unitAsset.floor
                                    .toString()
                                    .includes(lowerCaseSearch);
                                  const personMatches =
                                    unitAsset?.persons?.some((person: any) => {
                                      return person?.name
                                        ?.toLowerCase()
                                        ?.includes(lowerCaseSearch);
                                    });

                                  return (
                                    unitTitleMatches ||
                                    address2Matches ||
                                    floorMatches ||
                                    personMatches
                                  );
                                })
                                ?.map((unitAsset: any) => {
                                  return (
                                    <Accordion className="asset">
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="asset__top"
                                      >
                                        <div
                                          className="topHead"
                                          style={{
                                            minWidth: "320px",
                                            maxWidth: "420px",
                                          }}
                                        >
                                          <img src={door} alt="door Logo" />
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span>
                                              {t("Title")}: {unitAsset.title}
                                            </span>
                                            <span>
                                              {t("Address 2 (Unit Number)")}:{" "}
                                              {unitAsset?.address2}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="topHead">
                                          <img src={Stairs} alt="stairs Logo" />
                                          {t("Floor")}: {unitAsset?.floor}
                                        </div>

                                        {isBoardRole ? (
                                          <div className="topHead">
                                            <button
                                              onClick={() =>
                                                editUnitClickHandler(unitAsset)
                                              }
                                            >
                                              <ModeEditIcon />
                                            </button>
                                            <button
                                              style={{ marginLeft: "10px" }}
                                              onClick={() =>
                                                handleDeleteUnitIconClick(
                                                  unitAsset
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </button>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="accordionContainer">
                                          <div className="accordionDetails">
                                            <div>
                                              <UnitPersonList
                                                isBoardRole={isBoardRole}
                                                unitAsset={unitAsset}
                                                editPersonClickHandler={
                                                  editPersonClickHandler
                                                }
                                                setLoading={setLoading}
                                                associationId={
                                                  currentAssociation.associationId
                                                }
                                                showToast={showToast}
                                              />
                                            </div>
                                            <div className="accordionDetails__address">
                                              <Typography className="accordionDetails__contain">
                                                <span>
                                                  {t("Building Address")}:{" "}
                                                </span>
                                                <p>
                                                  {fullAddress
                                                    ? fullAddress
                                                    : ""}
                                                </p>
                                              </Typography>
                                            </div>
                                            <div>
                                              {isBoardRole ? (
                                                <>
                                                  <Typography className="accordionDetails__contain">
                                                    <span>
                                                      {t("Ownership Share")}:{" "}
                                                    </span>
                                                    <p>
                                                      {unitAsset?.ownershipShare
                                                        ? `${unitAsset?.ownershipShare} %`
                                                        : ""}
                                                    </p>
                                                  </Typography>
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                })
                            ) : (
                              <h4>{t("No Unit Please Add First")}</h4>
                            )}
                          </Box>
                          {isBoardRole ? (
                            <>
                              <Button
                                className="buildingBox__cardBodyBtn"
                                variant="contained"
                                disabled={
                                  unitCount >= unitSubscriptionCount ||
                                  unitCount === undefined ||
                                  unitSubscriptionCount === undefined
                                }
                                onClick={() => {
                                  handleAddUnit(index);
                                  setBuildingId(elem.assetId);
                                }}
                              >
                                {t("Add Unit")}
                              </Button>
                            </>
                          ) : null}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                )}
              </Box>
              <Box className="buildingBox__btnBox"></Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BuildingDetail;
