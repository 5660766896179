import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  useTheme,
} from "@mui/material";
import addIcon from "../../../../Assets/Images/PNG/add-icon.png";
import "./boardModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store";
import {
  addBoardMember,
  getAllBoard,
  getGeneralMembers,
} from "../../../../Redux/Feature/Roster-slice";
import CancelIcon from "@mui/icons-material/Cancel";
import AddMemberSkeleton from "../../../Skeletons/AddMemberSkeleton";
import { ERROR_MESSAGES } from "../../../../Utils/utils";
import Toast from "../../../Common/Toast/Toast";
import { useTranslation } from "react-i18next";

interface AddMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const AllGeneralMembers = useSelector(
    (state: RootState) => state?.Manage?.allGeneralMembers
  );
  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const handleAddMember = async (personId: string) => {
    setLoading(true);
    try {
      const addDataObj: any = {
        associationId: currentAssociation?.associationId,
        personId: personId,
      };
      const data = await dispatch(addBoardMember(addDataObj));
      if (data?.payload?.statusCode === 200) {
        showToast(t("Board Member added successfully"), "success");
        await dispatch(getAllBoard(currentAssociation?.associationId));
        handleClose();
      } else if (data.error) {
        handleError(data.payload.data);
      }
      if (!loading) {
        dispatch(getGeneralMembers(currentAssociation?.associationId));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGeneralMembers();
  }, [currentAssociation?.associationId]);

  const fetchAllGeneralMembers = async () => {
    setLoading(true);
    if (currentAssociation) {
      dispatch(getGeneralMembers(currentAssociation?.associationId));
    }
    setLoading(false);
  };

  const generalMemberColumn = [
    {
      id: "name",
      label: t("Person"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "buildingNumber",
      label: t("Building No."),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "address2",
      label: t("Unit No."),
      width: "220px",
      minWidth: "220px",
      maxWidth: "220px",
    },
    {
      id: "Action",
      label: t("Action"),
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
    },
  ];

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = stableSort(
    AllGeneralMembers,
    getComparator(order, orderBy)
  );
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const customGeneralNameCell = (member: any) => {
    const displayName: any =
      typeof member.name === "undefined" ||
      member.name === "undefined undefined"
        ? "Name not set"
        : member.name;

    return displayName;
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const handleClose = () => {
    onClose();
    handleCloseToast();
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div>
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />
        <Box className="changeBoardWrapper" sx={{ width: "1000px" }}>
          <Box sx={{ textAlign: "end" }} onClick={handleClose}>
            <CancelIcon className="close-icon" />
          </Box>
          {loading || isFetching ? (
            <AddMemberSkeleton />
          ) : (
            <>
              <Typography
                variant="h6"
                gutterBottom
                className="add-member-title"
              >
                {t("Add Member To Board")}
              </Typography>
              <Box
                className="TableConnect board_wrapper board-member-table add-member new-support-table"
                sx={{
                  border: "unset !important",
                }}
              >
                <Box className="TableConnectInner">
                  <Box className="gridTable">
                    <Paper
                      sx={{
                        width: "100%",
                        mb: 2,
                        border: `var(--mui-palette-modalBorderColor-primary)`,
                      }}
                    >
                      <Box>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {generalMemberColumn.map((column, index) => (
                                  <TableCell
                                    sx={{ padding: "16px 8px" }}
                                    key={column.id}
                                    style={{
                                      width: column.minWidth,
                                      maxWidth: column.maxWidth,
                                      minWidth: column.minWidth,
                                    }}
                                    sortDirection={
                                      orderBy === column.id ? order : false
                                    }
                                  >
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={
                                        orderBy === column.id ? order : "asc"
                                      }
                                      onClick={() =>
                                        handleRequestSort(column.id)
                                      }
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedData?.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    {t("No Member Found")}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                paginatedData.map((row: any) => (
                                  <TableRow
                                    key={row.id}
                                    sx={{
                                      "&. MuiTableCell-root": {
                                        height: "70px",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        height: "64px",
                                        padding: "4px 8px",
                                      }}
                                    >
                                      {customGeneralNameCell(row)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        height: "64px",
                                        padding: "4px 8px",
                                      }}
                                    >
                                      {row.buildingNumber}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        height: "64px",
                                        padding: "4px 8px",
                                      }}
                                    >
                                      {row.address2}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        height: "60px",
                                        padding: "4px 8px",
                                      }}
                                    >
                                      <img
                                        src={addIcon}
                                        alt="Add"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                          filter:
                                            theme.palette.mode === "light"
                                              ? "invert(1)"
                                              : "invert(0)",
                                        }}
                                        onClick={() =>
                                          handleAddMember(row.personId)
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[25, 50, 100]}
                          component="div"
                          count={sortedData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={t("Rows per page")}
                        />
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
