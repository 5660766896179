import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Typography, Button, TextField, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const DrawerTitle = (props: any) => {
  const {
    title,
    editTitleHandler,
    idField,
    updateHandler,
    idValue,
    updateTitleProgress,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const validationSchema = yup.object({
    title: yup.string().required(t("Title is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let obj: any = {
        payload: { title: values.title },
      };
      obj[idField] = idValue;
      updateHandler(obj);
    },
  });
  useEffect(() => {
    formik.setValues({ title });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="formGroup"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="h6" component="h6" sx={{ mr: "10px" }}>
            {t("Title")}:{" "}
          </Typography>
          <TextField
            fullWidth
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            sx={{
              fontSize: 30,
              "& .MuiInput-root .MuiInput-input": {
                color: `${theme.palette.text.primary}`,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateTitleProgress}
          >
            {" "}
            {t("Save")}
            {updateTitleProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            onClick={() => editTitleHandler(false)}
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateTitleProgress}
          >
            {" "}
            {t("Cancel")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default DrawerTitle;
