import { Box, Skeleton } from "@mui/material";

const SkeletonLoader = (props: any) => {
  const { variants } = props;
  return (
    <Box sx={{ width: "100%" }}>
      {variants.map((variant: any, index: any) => (
        <Skeleton
          key={index}
          variant={variant.type}
          width={variant.width}
          height={variant.height}
          sx={{
            backgroundColor: "rgb(64 64 64 / 34%)",
            mb: variant.type === "rectangular" ? "5px" : "0",
            fontSize: variant.type === "text" ? "1rem" : "0",
          }}
        />
      ))}
    </Box>
  );
};

export default SkeletonLoader;
