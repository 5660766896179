import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BannerState {
  isVisible: boolean;
}

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    isVisible: sessionStorage.getItem("displayIntroBanner") === "true",
  } as BannerState,
  reducers: {
    hideBanner: (state) => {
      state.isVisible = false;
      sessionStorage.setItem("displayIntroBanner", "false");
    },
    setBannerVisibility: (state, action: PayloadAction<boolean>) => {
        
      state.isVisible = action.payload;
      sessionStorage.setItem(
        "displayIntroBanner",
        action.payload ? "true" : "false"
      );
    },
  },
});

export const { hideBanner, setBannerVisibility } = bannerSlice.actions;
export default bannerSlice;
