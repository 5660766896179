import { Box, Skeleton } from "@mui/material";

const BoardSkeleton = () => {
  return (
    <div className="myAccount">
      <Box className="myAccountBox_title" sx={{ mx: 4 }}>
        <Box>
          <Skeleton
            variant={"text"}
            width={200}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
          <Box
            sx={{
              marginBottom: "24px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant={"text"}
              width={360}
              height={50}
              sx={{
                backgroundColor: "rgb(64 64 64 / 34%)",
                marginTop: "30px",
              }}
            />
            <Skeleton
              variant={"text"}
              width={360}
              height={50}
              sx={{
                backgroundColor: "rgb(64 64 64 / 34%)",
                marginLeft: "auto",
                marginTop: "30px",
              }}
            />
          </Box>
        </Box>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{
            backgroundColor: "rgb(64 64 64 / 34%)",
            marginX: "auto",
          }}
        />
      </Box>
    </div>
  );
};

export default BoardSkeleton;
