import http from "../api";

const stripeSafeSubscriptionSession = async (subscriptionObject: any) => {
  try {
    const response = await http.post(
      `/stripe-safe-subscription-session`,
      subscriptionObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const stripeAssociationBillingSession = async (subscriptionObject: any) => {
  try {
    const response = await http.post(
      `/stripe-association-billing-session`,
      subscriptionObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const stripeSafeUpdateSession = async (subscriptionObject: any) => {
  try {
    const response = await http.post(
      `/stripe-update-safe-session`,
      subscriptionObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};
const stripeSafeCancelSession = async (subscriptionObject: any) => {
  try {
    const response = await http.post(
      `/stripe-cancel-safe-session`,
      subscriptionObject
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const associationService = {
  stripeSafeSubscriptionSession,
  stripeSafeUpdateSession,
  stripeSafeCancelSession,
  stripeAssociationBillingSession,
};
export default associationService;
