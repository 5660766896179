import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Autocomplete,
  TextField,
  Paper,
  Popper,
  Link,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../Redux/Store";
import { useTranslation } from "react-i18next";
import Toast from "../../../Common/Toast/Toast";
import { vendorTypes } from "../../../../Utils/assets";
import { addVendor } from "../../../../Redux/Feature/Roster-slice";
import styled from "@emotion/styled";
interface AddVendorModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
  emails: string[];
  setIsInitialLoad: (value: boolean) => void;
}

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "0.2vw",
  },
}));
const AddVendorModal: React.FC<AddVendorModalProps> = ({
  isOpen,
  onClose,
  associationId,
  emails,
  setIsInitialLoad,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [, setOpenToast] = useState<boolean>(false);
  const [, setToastMessage] = useState<string>("");
  const [, setToastType] = useState("success");

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Vendor Name is required")),
    email: Yup.string()
      .required(t("Vendor Email is required"))
      .email(t("Enter valid email"))
      .test(
        "unique-email",
        t("Email already exists"),
        (value) => !emails.includes(value)
      ),
    serviceType: Yup.string().required(t("Vendor Service Type is required")),
  });

  const formik = useFormik({
    initialValues: {
      serviceType: "",
      name: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { serviceType, name, email } = values;

      const associationPayload = {
        serviceType: serviceType,
        name: name,
        email: email,
        associationId: associationId,
      };
      dispatch(addVendor(associationPayload))
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          resetForm();
          onClose();
          setLoading(false);
          showToast(t("Successfully added vendor"), name);
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const autoCompletePaperStyle = {
    width: "100%",
    background: "var(--mui-palette-autoCompleteBackground)",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: "var(--mui-palette-autocompletetextcolor)",
    },
  };
  const autocompleteChipStyle = {
    "& .MuiInputBase-root": {
      color: `${theme.palette.text.secondary}`,
      border: "var(--mui-palette-inputBorderColor)",
    },
  };
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box
        className="add-document add-association-model"
        sx={{ width: "500px !important", paddingRight: "40px" }}
      >
        <Box
          sx={{ m: "20px 30px 40px" }}
          justifyContent={"center"}
          display="flex"
        >
          <p
            style={{
              color: `${theme.palette.text.primary}`,
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {t("Add Vendor")}
          </p>
          <Link sx={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
            <CancelIcon className="support-ticket-cross-icon" />
          </Link>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("First & Last Name")}
            </label>
            <input
              id="name"
              name="name"
              placeholder={t("Vendor Name")}
              style={{ width: "100%" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.name && formik.touched.name && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.name}
              </Typography>
            )}
          </div>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Email")}
            </label>
            <input
              id="email"
              name="email"
              placeholder={t("Vendor Email")}
              style={{ width: "100%" }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.email && formik.touched.email && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.email}
              </Typography>
            )}
          </div>
          <Box
            className="select-serviceType formDropdownField"
            sx={{
              width: "100% !important",
              marginBottom: "10px",
            }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Service Type")}
            </label>

            <Autocomplete
              PopperComponent={MuiDropDownPopper}
              sx={autocompleteChipStyle}
              disablePortal
              id="serviceType"
              options={vendorTypes}
              getOptionLabel={(option) => t(option.value)}
              value={
                vendorTypes.find(
                  (option) => option.key === formik.values.serviceType
                ) || null
              }
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "serviceType",
                  newValue ? newValue.key : ""
                );
              }}
              PaperComponent={(props) => (
                <Paper sx={autoCompletePaperStyle} {...props} />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("Select Service Type")}
                  onBlur={formik.handleBlur}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: `${theme.palette.text.primary}`,
                      borderRadius: "10px",
                      border: "var(--mui-palette-inputBorderColor) !important",
                      background: `${theme.palette.inputBackground}`,
                      padding: "12px",
                      fontSize: "15px",
                      input: {
                        padding: "0px !important",
                        "&::placeholder": {
                          color: "#ccc",
                          opacity: 1,
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </Box>
          {formik.errors.serviceType && formik.touched.serviceType && (
            <Typography color={`${theme.palette.invalid}`}>
              {formik.errors.serviceType}
            </Typography>
          )}

          <Box sx={{ marginTop: "30px", marginBottom: "40px" }}>
            <div className="SubmitBtn ticketModel__footer">
              <Button
                className="sBtn e-control e-btn"
                id="targetButton1"
                type="submit"
                disabled={isFetching === true}
              >
                {loading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: `${theme.palette.text.primary}` }}
                  />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddVendorModal;
