import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";

interface DeleteRuleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
  heading: string;
}

const DeleteRuleModal: React.FC<DeleteRuleModalProps> = ({
  isOpen,
  onClose,
  onRemove,
  heading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <Box className="removeDocumentWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Typography
          id="remove-document-modal-description"
          sx={{ mt: 2, marginBottom: "30px" }}
        >
          {`Are you sure you want to delete this Rule For ${heading}?`}
        </Typography>
        <Button
          variant="outlined"
          onClick={onRemove}
          className="removeDocumentmodalButtonYes"
        >
          {t("Yes")}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          className="removeDocumentmodalButtonCancel"
        >
          {t("No")}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteRuleModal;
