import http from "../api";
import axios from "axios";

const PreSignedUrlForUploadPicture = async (initialObj: any) => {
  try {
    const response = await http.post(`/initiate-file-upload`, {
      ...initialObj,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const uploadProfilePicture = async (key: any) => {
  try {
    const response = await http.post(`/upload-profile-picture`, { key });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const createOrganization = async (obj: any) => {
  try {
    const response = await http.post(`/create-organization`, obj);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const UpdateOrganizationService = async (obj: any) => {
  try {
    const response = await http.put(
      `/update-organization-service/${obj.organizationId}`,
      { ...obj }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const UpdateOrganization = async (obj: any) => {
  try {
    const response = await http.put(
      `/update-organization/${obj.organizationId}`,
      { ...obj }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const GetOrganization = async (id: any) => {
  try {
    const response = await http.get(`/get-organization/${id}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const settingService = {
  PreSignedUrlForUploadPicture,
  GetOrganization,
  uploadProfilePicture,
  createOrganization,
  UpdateOrganization,
  UpdateOrganizationService,
};
export default settingService;
