import { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EditSupportTicketDescription from "../../Pages/Tickets/components/EditSupportTicketDescription";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import EditSupportTicketTitle from "../../Pages/Tickets/components/EditSupportTicketTitle";
import AttachmentPreviewModal from "../Modals/TicketsModals/AttachmentPreviewModal";
import AddAttachmentModal from "../Modals/TicketsModals/addAttachmentModal";
import CommentForm from "../../Pages/Tickets/components/CommentForm";
import moment from "moment";
import SupportTicketDrawerSkelton from "../Skeletons/SupportTicketDrawerSkelton";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import FileExtensionView from "../FileExtensionView/FileExtensionView";
import { imageFileExtensions } from "../../Utils/utils";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DatePickerComp from "../Common/DatePicker/DatePicker";
import { updateTicket } from "../../Redux/Feature/Ticket-slice";

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%", // Adjust the width as needed
    // width: "unset",
    marginLeft: "-18px",
    marginTop: "0.9vw",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));
const SupportTicketDrawer = (props: any) => {
  const {
    isUpdateInProgress,
    rightDrawer,
    toggleDrawer,
    rowSelect,
    setRowSelected,
    editTitle,
    editTitleHandler,
    updateTitleHandler,
    handleTypeChange,
    handleVisibilityToggle,
    handleValueChange,
    editDescription,
    editDescriptionHandler,
    updateDescriptionHandler,
    commentDataById,
    handleImageClick,
    handleRemoveAttachment,
    selectedImageUrl,
    isAttachmentPreviewOpen,
    setIsAttachmentPreviewOpen,
    handleClick,
    isAddAttachmentModalOpen,
    closeAddAttachmentModal,
    selectedTicketId,
    setCommentData,
    commentData,
    handleAssetChange,
    handleVendorChange,
    handleRuleChange,
    assets,
    vendors,
    rules,
    handlePostComment,
    loading,
    allCommentData,
    isFetchingRequest,
    showToast,
    updateFieldProgress,
    updateDescriptionProgress,
    updateTitleProgress,
    assetsLoading,
    vendorsLoading,
    rulesLoading,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const statusData: string[] = ["To Do", "In Progress", "Done"];
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectedVendors, setSelectedVendors] = useState<any>([]);
  const [selectedRules, setSelectedRules] = useState<any>([]);
  const [mergedAssets, setMergedAssets] = useState<any>([]);
  const allVendors = useSelector(
    (state: RootState) => state?.Manage?.allVendors
  );
  const allRules = useSelector((state: RootState) => state?.Auth?.allRules);

  const { t } = useTranslation();
  const myAssets: any = useSelector(
    (state: RootState) => state?.Auth?.allAssets
  );

  const handleDateChange = async (param: string, date: any) => {
    try {
      // setUpdateTitleProgress(true);
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          [param]: date,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setRowSelected([response?.payload?.data]);
        showToast(t("Ticket Updated"), "success");
      }
      // setUpdateTitleProgress(false);
      // setLoading(false);
      editTitleHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    const assMerged = [
      ...(myAssets?.units || []),
      ...(myAssets?.buildings || []),
      ...(myAssets?.remaining || []),
    ];
    setMergedAssets(assMerged);
  }, [myAssets]);

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );

  useEffect(() => {
    const selOp = mergedAssets?.filter((asset: any) =>
      assets.find((selected: any) => selected.assetId === asset.assetId)
    );
    setSelectedAssets(selOp);
  }, [mergedAssets, assets?.length]);
  useEffect(() => {
    const selOp = allVendors?.filter((vendor: any) =>
      vendors.find((selected: any) => selected.personId === vendor.personId)
    );
    setSelectedVendors(selOp);
  }, [allVendors, vendors?.length]);
  useEffect(() => {
    const selOp = allRules?.filter((rule: any) =>
      rules.find((selected: any) => selected.ruleId === rule.ruleId)
    );
    setSelectedRules(selOp);
  }, [allRules, rules?.length]);

  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const statusType: string[] = isBoardRole
    ? [
        "Service Request",
        "Dispute Resolution",
        "Violation",
        "Architecture Request",
        "Request for Proposal",
      ]
    : ["Service Request", "Violation", "Architecture Request"];

  const capitalizeFirstLetter = (string: any) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const autocompleteChipStyle = {
    marginTop: "0px",
    "& .MuiOutlinedInput-root": {
      paddingRight: "19px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "6px",
    border: "1px solid var(--mui-palette-divider)",
    background:
      theme.palette.mode === "dark"
        ? "var(--mui-palette-sidebarAutoCompleteBg)"
        : "var(--mui-palette-textWhite)",
    padding: "5px 10px",
    "& .MuiAutocomplete-tag": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.background.default
          : "#ccc",
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };

  const handleBudgetChange = async (e: any) => {
    const { name, value } = e.target;

    const response = await dispatch(
      await updateTicket({
        ticketId: rowSelect[0]?.ticketId,
        [name]: value,
      })
    );
    if (response?.payload?.statusCode === 200) {
      setRowSelected([response?.payload?.data]);
      showToast(t("Ticket Updated"), "success");
    }
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };

  const autoCompleteTextInputStyle = {
    paddingRight: "0px",
    "& .MuiAutocomplete-inputRoot": {
      background: "transparent",
    },
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
      paddingRight: "46px !important",
    },
  };
  {
    /** Do not remove this comment. I may required in future */
  }
  {
    /** 
  if (isFetchingRequest && !loading) {
    return (
      <>
        <Drawer
          anchor={"right"}
          open={rightDrawer}
          onClose={() => toggleDrawer(false)}
          sx={{
            zIndex: "9",
            "& .MuiBackdrop-root": {
              opacity: "0 !important",
            },
          }}
        >
          <div
            className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
            style={{ width: "280px" }}
          >
            <Box>
              <div className="detailsSidebar__head">
                <div className="cross" onClick={() => toggleDrawer(false)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </div>
              </div>
              <SupportTicketDrawerSkelton />
            </Box>
          </div>
        </Drawer>
      </>
    );
  }
*/
  }

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={rightDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box>
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="title-div"
                  style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
                >
                  {editTitle ? (
                    <EditSupportTicketTitle
                      title={rowSelect[0]?.title || ""}
                      editTitleHandler={editTitleHandler}
                      ticketId={rowSelect[0]?.ticketId}
                      setRowSelected={setRowSelected}
                      updateSupportTicketHandler={updateTitleHandler}
                      updateTitleProgress={updateTitleProgress}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" component="h5">
                        {t("Title")}: {rowSelect[0]?.title || ""}
                      </Typography>
                      <Button onClick={() => editTitleHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </>
                  )}
                </div>
                <div className="cross" onClick={() => toggleDrawer(false)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </div>
              </Box>
              <div
                className="sidetext"
                style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
              >
                <div className="sideStatus">
                  <label>{t("Type")}: </label>
                  <FormControl variant="standard" fullWidth>
                    <Select
                      labelId="typeId1"
                      id="typeId"
                      value={rowSelect[0]?.type}
                      placeholder="Select type"
                      name="type"
                      displayEmpty
                      onChange={handleTypeChange}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: "text.dark",
                          },
                          "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                            color: `${theme.palette.textWhite}`,
                            border: `1px solid ${theme.palette.activeColor}`,
                            width: "100%",
                            backgroundColor: `${theme.palette.activeColor}`,
                            display: "block",
                            textAlign: "center",
                            margin: "8px",
                            "&:hover": {
                              color: `${theme.palette.textWhite}`,
                            },
                          },
                        },
                      }}
                    >
                      {statusType?.map((type: string) => (
                        <MenuItem className="SelectBox" key={type} value={type}>
                          {t(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="toggle-container"
                  style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
                >
                  <Switch
                    color="secondary"
                    checked={rowSelect[0]?.isPrivate}
                    onChange={handleVisibilityToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span>{rowSelect[0]?.isPrivate ? "Private" : "Public"}</span>
                </div>
              </div>
              <div
                className="sidetext"
                style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
              >
                <div className="sideStatus">
                  <label>{t("Status")}:</label>
                  <FormControl variant="standard" fullWidth>
                    <Select
                      labelId="statusId1"
                      id="statusId"
                      value={rowSelect[0]?.status}
                      placeholder="Select Status"
                      name="status"
                      displayEmpty
                      onChange={handleValueChange}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: "text.dark",
                          },

                          "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                            color: `${theme.palette.textWhite}`,
                            border: `1px solid ${theme.palette.activeColor}`,
                            width: "100%",
                            backgroundColor: `${theme.palette.activeColor}`,
                            display: "block",
                            textAlign: "center",
                            margin: "8px",
                            "&:hover": {
                              color: `${theme.palette.textWhite}`,
                            },
                          },
                        },
                      }}
                    >
                      {statusData?.map((status: string) => (
                        <MenuItem
                          className="SelectBox"
                          key={status}
                          value={status}
                        >
                          {t(status)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {rowSelect[0].type === "Request for Proposal" && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "16px",
                      width: "100% !important",
                    }}
                  >
                    <label>
                      <Typography id="assetId1" sx={{ marginLeft: "16px" }}>
                        {t("Start Date")}
                      </Typography>
                    </label>
                    <DatePickerComp
                      disablePast={true} // Enable this to disallow past dates
                      disableFuture={false} // Disable this to allow future dates
                      className="custom-datepicker"
                      value={
                        rowSelect[0].startDate
                          ? dayjs(rowSelect[0].startDate)
                          : null
                      }
                      onChange={(newValue: any) => {
                        const formattedDate = newValue
                          ? newValue.format("MM-DD-YYYY")
                          : null;
                        handleDateChange("startDate", formattedDate);
                      }}
                      disabled={!isBoardRole}
                    />
                    <label>
                      <Typography id="assetId1" sx={{ marginLeft: "16px" }}>
                        {t("End Date")}
                      </Typography>
                    </label>
                    <DatePickerComp
                      disablePast={true} // Enable this to disallow past dates
                      disableFuture={false} // Disable this to allow future dates
                      className="custom-datepicker"
                      value={
                        rowSelect[0].endDate
                          ? dayjs(rowSelect[0].endDate)
                          : null
                      }
                      onChange={(newValue: any) => {
                        const formattedDate = newValue
                          ? newValue.format("MM-DD-YYYY")
                          : null;
                        handleDateChange("endDate", formattedDate);
                      }}
                      disabled={!isBoardRole}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "16px",
                      width: "100% !important",
                    }}
                  >
                    <label>
                      <Typography id="assetId1" sx={{ marginRight: "16px" }}>
                        {t("Proposal Deadline")}
                      </Typography>
                    </label>
                    <DatePickerComp
                      disablePast={true}
                      disableFuture={false}
                      className="custom-datepicker"
                      value={
                        rowSelect[0].proposalDeadline
                          ? dayjs(rowSelect[0].proposalDeadline)
                          : null
                      }
                      onChange={(newValue: any) => {
                        const formattedDate = newValue
                          ? newValue.format("MM-DD-YYYY")
                          : null;
                        handleDateChange("proposalDeadline", formattedDate);
                      }}
                      disabled={!isBoardRole}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "16px",
                      width: "100% !important",
                    }}
                  >
                    <label>
                      <Typography id="assetId1" sx={{ marginRight: "16px" }}>
                        {t("Minimum Budget")}
                      </Typography>
                    </label>
                    <TextField
                      id="outlined-basic"
                      label="Minimum Budget"
                      variant="outlined"
                      name="minBudget"
                      value={rowSelect[0]?.minBudget}
                      onChange={handleBudgetChange}
                      disabled={!isBoardRole}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "16px",
                      width: "100% !important",
                    }}
                  >
                    <label>
                      <Typography id="assetId1" sx={{ marginRight: "16px" }}>
                        {t("Maximum Budget")}
                      </Typography>
                    </label>
                    <TextField
                      id="outlined-basic"
                      label="Maximum Budget"
                      variant="outlined"
                      name="maxBudget"
                      value={rowSelect[0]?.maxBudget}
                      onChange={handleBudgetChange}
                      disabled={!isBoardRole}
                    />
                  </Box>
                </Box>
              )}

              <div
                className="sideAssets"
                style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
              >
                <FormControl variant="standard" fullWidth>
                  <div className="SectionBox_title">
                    <label>{t("Assets")}:</label>
                  </div>
                  {assetsLoading || isUpdateInProgress ? (
                    <Skeleton variant="rectangular" width="100%" height={56} />
                  ) : (
                    <Box style={{ overflow: "visible" }}>
                      <Autocomplete
                        multiple
                        // appendTo={document.body}
                        options={mergedAssets}
                        // disablePortal
                        // zIndex={1000}
                        getOptionLabel={(option) => option?.title || ""}
                        defaultValue={[...selectedAssets]}
                        getOptionKey={(option: any) => option?.assetId}
                        filterSelectedOptions
                        PopperComponent={MuiDropDownPopper}
                        sx={autocompleteChipStyle}
                        id="assetsId1"
                        onChange={handleAssetChange}
                        value={selectedAssets || null}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={autoCompleteTextInputStyle}
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper sx={autoCompletePaperStyle} {...props} />
                        )}
                      />
                    </Box>
                  )}
                </FormControl>
              </div>
              <div
                className="sideVendors"
                style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
              >
                <FormControl variant="standard" fullWidth>
                  <div className="SectionBox_title">
                    <label>{t("Vendors")}:</label>
                  </div>
                  {vendorsLoading || isUpdateInProgress ? (
                    <Skeleton variant="rectangular" width="100%" height={56} />
                  ) : (
                    <Box style={{ overflow: "visible" }}>
                      <Autocomplete
                        multiple
                        options={allVendors}
                        getOptionLabel={(option) => option?.name || ""}
                        defaultValue={selectedVendors}
                        getOptionKey={(option: any) => option?.personId}
                        filterSelectedOptions
                        sx={autocompleteChipStyle}
                        PopperComponent={MuiDropDownPopper}
                        id="vendorsId1"
                        onChange={handleVendorChange}
                        value={selectedVendors || null}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={autoCompleteTextInputStyle}
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper sx={autoCompletePaperStyle} {...props} />
                        )}
                      />
                    </Box>
                  )}
                </FormControl>
              </div>
              <div
                className="sideRules"
                style={{ pointerEvents: isBoardRole ? "auto" : "none" }}
              >
                <FormControl variant="standard" fullWidth>
                  <div className="SectionBox_title">
                    <label>{t("Rules")}:</label>
                  </div>
                  {rulesLoading || isUpdateInProgress ? (
                    <Skeleton variant="rectangular" width="100%" height={56} />
                  ) : (
                    <Box style={{ overflow: "visible" }}>
                      <Autocomplete
                        multiple
                        options={allRules}
                        getOptionLabel={(option) => option?.title || ""}
                        defaultValue={selectedRules}
                        getOptionKey={(option: any) => option?.ruleId}
                        filterSelectedOptions
                        sx={autocompleteChipStyle}
                        PopperComponent={MuiDropDownPopper}
                        id="rulesId1"
                        onChange={handleRuleChange}
                        value={selectedRules || null}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={autoCompleteTextInputStyle}
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper sx={autoCompletePaperStyle} {...props} />
                        )}
                      />
                    </Box>
                  )}
                </FormControl>
              </div>
              <div>
                {editDescription ? (
                  <EditSupportTicketDescription
                    description={rowSelect[0]?.description?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )}
                    editDescriptionHandler={editDescriptionHandler}
                    ticketId={rowSelect[0]?.ticketId}
                    updateSupportTicketHandler={updateDescriptionHandler}
                    updateDescriptionProgress={updateDescriptionProgress}
                  />
                ) : (
                  <>
                    <div className="SectionBox_title">
                      <label>{t("Description")}:</label>
                      <Button onClick={() => editDescriptionHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </div>
                    <textarea
                      value={
                        rowSelect[0]?.description !== undefined &&
                        rowSelect[0]?.description !== ""
                          ? rowSelect[0]?.description?.replace(
                              /(<([^>]+)>)/gi,
                              ""
                            )
                          : t("No data")
                      }
                      onChange={(e) => {
                        const updatedRowSelect = [...rowSelect];
                        updatedRowSelect[0].description = e.target.value;
                        setRowSelected(updatedRowSelect);
                      }}
                      disabled={!editDescription}
                    ></textarea>
                  </>
                )}

                <div className="SectionBox">
                  <div
                    className="SectionBox_title"
                    data-testid="attachments-div"
                  >
                    {t("Attachments")}
                  </div>
                  <div className="attachment-list">
                    {commentDataById.attachments &&
                    commentDataById.attachments.length > 0 ? (
                      commentDataById.attachments.map(
                        (attachment: any, index: number) => (
                          <div key={index} className="selected-image">
                            <Box
                              sx={{ display: "contents" }}
                              onClick={() =>
                                handleImageClick(
                                  attachment.signedUrl,
                                  attachment.fileName
                                )
                              }
                            >
                              {imageFileExtensions.includes(
                                attachment.fileName
                                  .split(".")
                                  .pop()
                                  ?.toLowerCase()
                              ) ? (
                                <img
                                  src={attachment.signedUrl}
                                  alt={attachment.fileName}
                                  className="selected-image-preview"
                                />
                              ) : (
                                <FileExtensionView
                                  fileType={attachment.fileName
                                    .split(".")
                                    .pop()
                                    ?.toLowerCase()}
                                />
                              )}
                            </Box>
                            <CloseIcon
                              onClick={() => handleRemoveAttachment(index)}
                            />
                          </div>
                        )
                      )
                    ) : (
                      <p>{t("No attachments available")}</p>
                    )}
                    <AttachmentPreviewModal
                      imageObj={selectedImageUrl}
                      isOpen={isAttachmentPreviewOpen}
                      onClose={() => setIsAttachmentPreviewOpen(false)}
                      theme={theme}
                    />
                  </div>
                  <div className="attachmentBtn_section">
                    <button
                      type="button"
                      className="attachmentBtn"
                      onClick={handleClick}
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M10.2702 1.75775C12.6117 -0.585841 16.4107 -0.585842 18.7538 1.7573C21.0369 4.04037 21.0954 7.70558 18.9294 10.0595L18.7413 10.2569L9.94127 19.0551L9.9047 19.0853C8.44346 20.4021 6.18946 20.3574 4.78208 18.95C3.46307 17.631 3.34095 15.5683 4.41573 14.1116C4.43908 14.0662 4.46733 14.0225 4.50054 13.9813L4.55409 13.9217L4.641 13.8339L4.78208 13.6861L4.785 13.6889L12.2207 6.23463C12.4867 5.96801 12.9033 5.94325 13.1972 6.16072L13.2814 6.23322C13.548 6.49913 13.5728 6.91576 13.3553 7.20967L13.2828 7.29388L5.68819 14.907C4.97056 15.7826 5.02044 17.0768 5.83783 17.8942C6.6669 18.7233 7.98655 18.7628 8.86233 18.0127L17.695 9.18221C19.4505 7.42457 19.4505 4.57532 17.6932 2.81796C15.9907 1.11552 13.2636 1.06232 11.4971 2.65836L11.3292 2.81796L11.3167 2.83228L1.78033 12.3686C1.48744 12.6615 1.01256 12.6615 0.71967 12.3686C0.453403 12.1023 0.429197 11.6857 0.647052 11.3921L0.71967 11.3079L10.2685 1.7573L10.2702 1.75775Z"
                          fill={`${theme.palette.text.primary}`}
                        />
                      </svg>
                      {t("Add Attachment")}
                    </button>
                  </div>
                </div>
                <AddAttachmentModal
                  isOpen={isAddAttachmentModalOpen}
                  onClose={closeAddAttachmentModal}
                  supportTicketId={selectedTicketId ?? ""}
                />
              </div>
              <div className="SectionBox">
                <div className="SectionBox_title" data-testid="comment-div">
                  {t("Comments")}
                </div>
                <div
                  className="SectionBox_inner"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <CommentForm
                    setCommentData={setCommentData}
                    commentData={commentData}
                    rowSelect={rowSelect}
                    handlePostComment={handlePostComment}
                    loading={loading}
                    showToast={showToast}
                  />
                </div>
              </div>
            </div>
            <div className="detailsSidebar__body">
              <div className="SectionBox">
                <div
                  className="SectionBox_inner"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  {!isFetchingRequest && (
                    <div data-testid="addedcomments-div">
                      {allCommentData?.map((user: any, i: number) => (
                        <div className="CommentPart" key={i}>
                          <Box className="CommentPart_header">
                            <div className="profile-img">
                              <img
                                src={user?.profilePicture}
                                height={"40px"}
                                width={"40px"}
                                style={{ borderRadius: "100%" }}
                                loading="lazy"
                              />
                            </div>
                            <Box sx={{ display: "flex", width: "88%" }}>
                              <div className="Name">{user?.personName}</div>
                              <div className="Name">
                                {capitalizeFirstLetter(user?.commenterRole)}
                              </div>
                              <div className="time">
                                <span>{moment(user?.date).format("ll")}</span>
                              </div>
                            </Box>
                          </Box>
                          <p style={{ marginLeft: "6px", fontSize: "14px" }}>
                            {user?.comment}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default SupportTicketDrawer;
