import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, useTheme } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const EditBudgetName = (props: any) => {
  const dispatch = useDispatch();
  const {
    name,
    setEditName,
    budgetId,
    showToast,
    updateBudgetHandler,
    setUpdateNameProgress,
    updateNameProgress,
    monthYear,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const validationSchema = yup.object({
    name: yup.string().required(t("Name is required")),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setUpdateNameProgress(true);
      const obj = {
        budgetId,
        name: values.name,
        monthYear,
      };
      const response = await dispatch(updateBudgetHandler(obj));
      setUpdateNameProgress(false);
      setEditName(false);
      if (response?.payload?.statusCode === 200) {
        showToast(t("Budget Updated"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    },
  });
  useEffect(() => {
    formik.setValues({ name });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="formGroup"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{
              // fontSize: 30,
              "& .MuiInput-root .MuiInput-input": {
                color: `${theme.palette.text.primary}`,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateNameProgress}
          >
            {" "}
            {t("Save")}
            {updateNameProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            onClick={() => setEditName(false)}
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateNameProgress}
          >
            {" "}
            {t("Cancel")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default EditBudgetName;
