import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const DrawerDescription = (props: any) => {
  const {
    description,
    editHandler,
    idField,
    idValue,
    updateHandler,
    updateProgress,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const validationSchema = yup.object({
    description: yup.string().required(t("Description is required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let response;
      setLoading(true);
      let obj: any = {
        payload: { description: values.description },
      };
      obj[idField] = idValue;
      updateHandler(obj);
    },
  });
  useEffect(() => {
    formik.setValues({ description });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="SectionBox_title">
          <label>Description:</label>
          <textarea
            // fullWidth
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && Boolean(formik.errors.description) ? (
            <div style={{ color: `${theme.palette.invalid}` }}>
              {formik.errors.description}
            </div>
          ) : null}
          <Button
            type="submit"
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateProgress}
          >
            {t("Save")}
            {updateProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            onClick={() => editHandler(false)}
            variant="contained"
            className="button"
            sx={{ ml: "10px" }}
            disabled={updateProgress}
          >
            {t("Cancel")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default DrawerDescription;
