import { Box, Button, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePickerComp from "../DatePicker/DatePicker";
import dayjs from "dayjs";

const DateTimeDetailField = (props: any) => {
  const {
    isEditField,
    fieldLabel,
    fieldValue,
    setIsEditField,
    handleSaveField,
    isValueEdit,
  } = props;

  const { t } = useTranslation();

  return (
    <Box className="formGroup">
      <Grid container spacing={2}>
        <Grid item md={10} lg={10}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid item md={4} lg={4}>
              <Typography
                className="formlabel"
                sx={{ color: "text.primary", mr: "10px" }}
              >
                {t(fieldLabel)}:
              </Typography>
            </Grid>
            <Grid item md={8} lg={8}>
              {!isEditField ? (
                <Typography
                  className="formlabel"
                  sx={{ color: "text.primary" }}
                >
                  {t(fieldValue)}
                </Typography>
              ) : (
                <Box className="formGroup_inner">
                  <div className="input_build_wrapper">
                    <Box sx={{ marginBottom: "16px" }}>
                      <DatePickerComp
                        disablePast={false}
                        disableFuture={true}
                        className="custom-datepicker"
                        // isEditMode={isEditMode}
                        value={fieldValue ? dayjs(fieldValue) : null}
                        onChange={(newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          // formik.setFieldValue(
                          //   "nextReplacementYear",
                          //   formattedDate
                          // );
                          handleSaveField(formattedDate);
                        }}
                      />
                      {/* {formik.errors.nextReplacementYear &&
                        formik.touched.nextReplacementYear && (
                          <Typography color={errorColor}>
                            {formik.errors.nextReplacementYear}
                          </Typography>
                        )} */}
                    </Box>
                  </div>
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          {isValueEdit ? (
            <>
              {!isEditField ? (
                <Button
                  sx={{
                    width: "auto !important",
                    paddingX: "16px !important",
                  }}
                  variant="contained"
                  className="btn"
                  onClick={() => setIsEditField(!isEditField)}
                >
                  {t("Edit")}
                </Button>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Button
                    sx={{
                      width: "auto !important",
                      paddingX: "16px !important",
                    }}
                    variant="contained"
                    className="btn"
                    onClick={() => setIsEditField(!isEditField)}
                  >
                    {t("Cancel")}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DateTimeDetailField;
