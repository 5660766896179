import { useRef, useState } from "react";
import {
  Box,
  Drawer,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import profilePhoto from "../../Assets/Images/PNG/profilePhoto.png";
import { useTheme } from "@mui/material/styles";
import { PreSignedUrlForUploadPicture } from "../../Redux/Feature/Setting-slice";
import { uploadAssociationProfilePicture } from "../../Redux/Feature/Map-slice";
import { useDispatch } from "react-redux";
import uploadPhoto from "../../Assets/Images/SVG/uploadPhoto.svg";
import SpinnerLoader from "../Common/SpinnerLoader/SpinnerLoader";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE_MB = 3;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const MapDrawer = (props: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageError, setImageError] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { rightDrawer, toggleDrawer, associations, isVerifier, showToast } =
    props;
  const [searchTerm, setSearchTerm] = useState("");
  const [maxFee, setMaxFee] = useState("");
  const [yearBuilt, setYearBuilt] = useState("");
  const [verifiedTrue, setVerifiedTrue] = useState(true);
  const [verifiedFalse, setVerifiedFalse] = useState(true);
  const [sortCriteria, setSortCriteria] = useState("title");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [currentProfileImage, setCurrentProfileImage] = useState(profilePhoto);
  const [highlightedAssociationId, setHighlightedAssociationId] =
    useState<string>("");

  const [, setPreSignedPicResURL] = useState({});

  const sortAssociations = (a: any, b: any) => {
    let comparison = 0;
    if (sortCriteria === "title") {
      comparison = a.title.localeCompare(b.title);
    } else if (sortCriteria === "MinFee") {
      comparison = a.MinFee - b.MinFee;
    } else if (sortCriteria === "yearBuilt") {
      comparison = a.yearBuilt - b.yearBuilt;
    }

    return sortOrder === "asc" ? comparison : -comparison;
  };
  const filteredAssociations = associations
    .filter((association: any) => {
      const matchesSearchTerm =
        association?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        association?.address
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        association?.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        association?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        association?.website
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        association?.description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        association?.associationType
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

      const matchesMaxFee =
        maxFee === "" || association?.maxFee <= parseFloat(maxFee);

      const matchesYearBuilt =
        yearBuilt === "" || association?.yearBuilt <= parseInt(yearBuilt);

      const matchesVerified =
        (verifiedTrue && association?.verified === "True") ||
        (verifiedFalse && association?.verified !== "True") ||
        (!verifiedTrue && !verifiedFalse);

      return matchesSearchTerm && matchesMaxFee && matchesVerified;
    })
    .sort(sortAssociations);

  const handleSortCriteriaChange = (event: any) => {
    setSortCriteria(event.target.value);
  };
  const handleSortOrderToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileObj: any = event.target.files?.[0];
    if (fileObj) {
      if (!ALLOWED_FILE_TYPES.includes(fileObj.type)) {
        showToast(t("Invalid file type"), "error");
        return;
      }

      if (fileObj.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setImageError(true);
        showToast(t("File size exceeds"), "error");

        return;
      }
    }

    setLoading(true);
    const inputElement = event.target as HTMLInputElement;

    dispatch(
      PreSignedUrlForUploadPicture({
        fileName: fileObj.name,
      })
    ).then((res: any) => {
      if (res.payload.statusCode === 200) {
        setPreSignedPicResURL(res.payload.data);
        const signedUrl = res.payload.data.signedUrl;
        const key = res.payload.data.key;
        setLoading(true);
        fetch(signedUrl, {
          method: "PUT",
          body: fileObj,
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch(
                uploadAssociationProfilePicture({
                  key,
                  associationId: highlightedAssociationId,
                })
              );
              showToast(t("Success Uploading"), "success");
            } else if (response.status === 403) {
              showToast(t("Error while updating"), "error");
            }
            event.target.value = "";
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Drawer
      className={rightDrawer ? "common-asset-drawer" : ""}
      anchor={"right"}
      open={rightDrawer}
      variant="persistent"
      sx={{
        zIndex: "9",
        "& .MuiBackdrop-root": {
          opacity: "0 !important",
        },
      }}
    >
      <div
        className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
        style={{ width: "350px" }}
      >
        <SpinnerLoader loading={loading} />
        <Box>
          <div className="detailsSidebar__head">
            <Box
              className="sticky-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 16px",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
            >
              <Typography variant="h5" component="h5">
                {t("Associations")}
              </Typography>
              <IconButton onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", gap: "16px", padding: "8px 16px" }}>
              <Typography>{t("Search")}</Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("Search Associations")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "16px", padding: "8px 16px" }}>
              <Typography>{t("Max Fee")}</Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("Max Fee")}
                type="number"
                value={maxFee}
                onChange={(e) => setMaxFee(e.target.value)}
              />
              <Typography>{t("Year Built")}</Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("Year Built")}
                type="number"
                value={maxFee}
                onChange={(e) => setYearBuilt(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "16px",
                padding: "8px 16px",
                flexWrap: "wrap",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={verifiedTrue}
                    onChange={(e) => setVerifiedTrue(e.target.checked)}
                    sx={{
                      color: theme.palette.text.primary,
                      "&.Mui-checked": {
                        color: theme.palette.text.primary,
                      },
                    }}
                  />
                }
                label="Verified"
                sx={{ color: theme.palette.text.primary }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={verifiedFalse}
                    onChange={(e) => setVerifiedFalse(e.target.checked)}
                    sx={{
                      color: theme.palette.text.primary,
                      "&.Mui-checked": {
                        color: theme.palette.text.primary,
                      },
                    }}
                  />
                }
                label="Not Verified"
                sx={{ color: theme.palette.text.primary }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "16px", padding: "8px 16px" }}>
              <FormControl fullWidth>
                <InputLabel>{t("Sort By")}</InputLabel>
                <Select
                  value={sortCriteria}
                  onChange={handleSortCriteriaChange}
                  label={t("Sort By")}
                >
                  <MenuItem value="title">{t("Title")}</MenuItem>
                  <MenuItem value="MinFee">{t("Min Fee")}</MenuItem>
                  <MenuItem value="yearBuilt">{t("Year Built")}</MenuItem>
                </Select>
              </FormControl>
              <Button
                onClick={handleSortOrderToggle}
                sx={{ color: theme.palette.text.primary }}
              >
                {sortOrder === "asc" ? t("Ascending") : t("Descending")}
              </Button>
            </Box>
          </div>

          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 144px)",
              padding: "16px",
            }}
          >
            {filteredAssociations.map((association: any, index: number) => (
              <Card
                key={index}
                sx={{
                  position: "relative",
                  marginBottom: "16px",
                  backgroundColor: `${theme.palette.background.default}`,
                }}
              >
                <Box className="map-accordian">
                  <Accordion
                    expanded={expanded === association.place_id}
                    onChange={handleChange(association.place_id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={association.profile || profilePhoto}
                          alt="Profile"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            marginRight: "16px",
                          }}
                        />
                        <Box>
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ fontWeight: "bold" }}
                          >
                            {association.title}
                          </Typography>
                          <Typography variant="body2">
                            <strong>{t("Address")}:</strong>{" "}
                            {association.formatted_address}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent>
                        <Grid container spacing={2}>
                          {isVerifier && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                paddingLeft: "6px !important",
                                paddingRight: "16px",
                              }}
                            >
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                              />
                              <Button
                                className="btn btn-theme"
                                sx={{
                                  color: "text.primary",
                                  marginBottom: "12px",
                                }}
                                onClick={() => {
                                  setHighlightedAssociationId(
                                    association.associationId
                                  );
                                  setImageError(false);
                                  fileInputRef.current?.click();
                                }}
                              >
                                <img
                                  src={uploadPhoto}
                                  alt="upload Profile"
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    marginLeft: "10px",
                                    mb: 0,
                                  }}
                                >
                                  {t("Upload Photo")}
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              paddingLeft: "6px !important",
                              paddingRight: "16px",
                            }}
                          >
                            <Typography variant="body2">
                              <strong className="map-details">Fees:</strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(association.minFee)}{" "}
                              -{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(association.maxFee)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              paddingLeft: "6px !important",
                              paddingRight: "16px",
                            }}
                          >
                            <Typography variant="body2">
                              <strong className="map-details">
                                {t("Property Type")}:
                              </strong>{" "}
                              {association.propertySubType}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              paddingLeft: "6px !important",
                              paddingRight: "16px",
                            }}
                          >
                            <Typography variant="body2">
                              <strong className="map-details">
                                {t("Year Built")}:
                              </strong>{" "}
                              {association.yearBuilt}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              paddingLeft: "6px !important",
                              paddingRight: "16px",
                            }}
                          >
                            <Typography variant="body2">
                              <strong className="map-details">
                                {t("Unit Count")}:
                              </strong>{" "}
                              {association.unitCount}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              paddingLeft: "6px !important",
                              paddingRight: "16px",
                            }}
                          >
                            <Typography variant="body2">
                              <strong className="map-details">Pool:</strong>{" "}
                              {association.pool ? "Yes" : "No"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                {association.verified ? (
                  <Typography
                    sx={{
                      color: "green",
                      position: "absolute",
                      top: "12px",
                      right: "30px",
                    }}
                  >
                    {t("Verified")}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "red",
                      position: "absolute",
                      top: "12px",
                      right: "30px",
                    }}
                  >
                    {t("Unverified")}
                  </Typography>
                )}
              </Card>
            ))}
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default MapDrawer;
