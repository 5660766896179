import {
  Box,
  Typography,
  Drawer,
  useTheme,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useTranslation } from "react-i18next";
import "../../Pages/Settings/setting.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import EditBudgetName from "../../Pages/Finances/Budgets/EditBudgetName";
import { UpdateBudget, getBudget } from "../../Redux/Feature/Finance-slice";
import { getAssetsByPerson } from "../../Redux/Feature/Auth-slice";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);

const BudgetDrawer = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const {
    selectedRow,
    rightDrawer,
    toggleDrawer,
    // editName,
    // editAllocatedAmount,
    // editNameHandler,
    // updateAllocatedAmountHandler,
    // editAllocatedAmountHandler,
    // updateNameHandler,
    // updateAllocatedAmountProgress,
    // updateNameProgress,
    handleAssetChange,
  } = props;
  const budget = useSelector(
    (state: RootState) => state?.Finances?.selectedBudget
  );
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const myAssets: any = useSelector(
    (state: RootState) => state?.Auth?.allAssets
  );
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedAsset, setSelectedAsset] = useState<string>("");

  useEffect(() => {
    setSelectedAsset(selectedRow?.asset?.assetId);
  }, [selectedRow]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={rightDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box className="sidebarBudget__details">
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="title-div">
                  {/* {editName ? (
                    <EditBudgetName
                      name={selectedRow[0]?.name || ""}
                      editNameHandler={editNameHandler}
                      ticketId={selectedRow[0]?.budgetId}
                      updateNameHandler={updateNameHandler}
                      updateNameProgress={updateNameProgress}
                      updateBudgetHandler={UpdateBudget}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" component="h5">
                        Name: {selectedRow[0]?.name || ""}
                      </Typography>
                      <Button onClick={() => editNameHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </>
                  )} */}
                </div>
                <div className="cross" onClick={() => toggleDrawer(false)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </div>
              </Box>

              <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                      {selectedRow?.name} {t("Actual Amount")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{
                        color:
                          selectedRow?.actualAmount >= 0
                            ? "#15bf81"
                            : "#ff7979",
                        opacity: 1,
                        fontWeight: 500,
                      }}
                    >
                      {selectedRow?.actualAmount !== null &&
                      selectedRow?.actualAmount !== undefined &&
                      !isNaN(selectedRow?.actualAmount)
                        ? formatter.format(selectedRow?.actualAmount)
                        : formatter.format(0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                      {selectedRow?.name} Allocated Amount:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{
                        color:
                          selectedRow?.allocatedAmount >= 0
                            ? "#15bf81"
                            : "#ff7979",
                        opacity: 1,
                        fontWeight: 500,
                      }}
                    >
                      {selectedRow?.allocatedAmount !== null &&
                      selectedRow?.allocatedAmount !== undefined &&
                      !isNaN(selectedRow?.allocatedAmount)
                        ? formatter.format(selectedRow?.allocatedAmount)
                        : formatter.format(0)}
                    </Typography>
                  </Grid>

                  {/* <div className="title-div">
                    {editAllocatedAmount ? (
                      <EditBudgetName
                        name={selectedRow[0]?.allocatedAmount || ""}
                        editNameHandler={editAllocatedAmountHandler}
                        ticketId={selectedRow[0]?.budgetId}
                        updateNameHandler={updateAllocatedAmountHandler}
                        updateNameProgress={updateAllocatedAmountProgress}
                        updateBudgetHandler={UpdateBudget}
                      />
                    ) : (
                      <>
                        <Typography variant="h5" component="h5">
                          Allocated Amount:{" "}
                          {selectedRow[0]?.allocatedAmount || ""}
                        </Typography>
                        <Button
                          onClick={() => editAllocatedAmountHandler(true)}
                        >
                          <ModeEditIcon
                            fontSize="medium"
                            sx={{ color: `${theme.palette.activeColor}` }}
                          />
                        </Button>
                      </>
                    )}
                  </div> */}
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                      {selectedRow?.name} Budget Difference:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{
                        color:
                          selectedRow?.difference >= 0 ? "#15bf81" : "#ff7979",
                        opacity: 1,
                        fontWeight: 500,
                      }}
                    >
                      {selectedRow?.difference !== null &&
                      selectedRow?.difference !== undefined &&
                      !isNaN(selectedRow?.difference)
                        ? formatter.format(selectedRow?.difference)
                        : formatter.format(0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box
                className="TableConnect board_wrapper board-member-table new-support-table"
                sx={{
                  border: "unset !important",
                }}
              >
                <div className="sideAssets">
                  <div className="formDropdownField">
                    <InputLabel id="assetId1">
                      <Typography
                        variant="h5"
                        component="h5"
                        marginTop={"20px"}
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        Asset
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="assetId1"
                      id="assetId"
                      value={selectedAsset}
                      placeholder="Select Asset"
                      name="assetId"
                      displayEmpty
                      onChange={handleAssetChange}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: `${theme.palette.textBlack}`,
                          },
                          "& .MuiMenuItem-root.Mui-disabled": {
                            opacity: 0.7,
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: `${theme.palette.selectedItem}`,
                          },
                          "& .MuiSvgIcon-root-MuiSelect-icon": {
                            color: `${theme.palette.textWhite}`,
                          },
                        },
                      }}
                    >
                      {myAssets?.units && myAssets?.units.length > 0 && (
                        <MenuItem
                          value=""
                          disabled
                          style={{
                            fontWeight: "625",
                            color: "black",
                          }}
                        >
                          My Units
                        </MenuItem>
                      )}
                      {myAssets?.units?.map((asset: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={asset.assetId}
                          value={asset.assetId}
                        >
                          {asset.title}
                        </MenuItem>
                      ))}
                      {myAssets?.buildings &&
                        myAssets?.buildings.length > 0 && (
                          <MenuItem
                            value=""
                            disabled
                            style={{
                              fontWeight: "625",
                              color: "black",
                            }}
                          >
                            My Buildings
                          </MenuItem>
                        )}
                      {myAssets?.buildings?.map((asset: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={asset.assetId}
                          value={asset.assetId}
                        >
                          {asset.title}
                        </MenuItem>
                      ))}
                      {myAssets?.remaining &&
                        myAssets?.remaining.length > 0 && (
                          <MenuItem value="" disabled>
                            <Divider
                              style={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: "divider",
                              }}
                            />
                          </MenuItem>
                        )}
                      {myAssets?.remaining?.map((asset: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={asset.assetId}
                          value={asset.assetId}
                        >
                          {asset.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <Box className="TableConnectInner">
                  <Box className="document-wrap gridTable">
                    <Paper
                      sx={{
                        width: "100%",
                        mb: 2,
                        border: `1px solid ${theme.palette.activeColor}`,
                      }}
                    >
                      <Box>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  Transactions
                                </TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Amount</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {budget?.transactions?.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan={3} align="center">
                                    No Budgets Found
                                  </TableCell>
                                </TableRow>
                              ) : (
                                budget?.transactions?.map(
                                  (transaction: any) => (
                                    <TableRow key={transaction.id}>
                                      <TableCell align="left">
                                        {transaction?.description}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          transaction?.transacted_at
                                        ).toLocaleDateString("en-US", {
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </TableCell>
                                      <TableCell align="right">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(transaction?.amount)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          sx={{}}
                          rowsPerPageOptions={[25, 50, 100]}
                          component="div"
                          count={budget?.transactions?.length || 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default BudgetDrawer;
