import http from "../api";

const createMeeting = async (object: any) => {
  try {
    const response = await http.post(`/create-meeting/`, object);
    return response.data;
  } catch (e) {
    return e;
  }
};

const deleteMeeting = async (obj: any) => {
  try {
    const response = await http.delete(
      `/delete-meeting/${obj.meetingId}?associationId=${obj.associationId}`
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

const updateMeeting = async (payload: any) => {
  try {
    const response = await http.put(`/update-meeting/${payload.meetingId}`, {
      ...payload,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getMeeting = async (payload: any) => {
  try {
    const response = await http.get(
      `/get-meeting/${payload.meetingId}?associationId=${payload.associationId}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getMeetings = async (associationId: string) => {
  try {
    const response = await http.get(`/get-meetings/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getMeetingSummaries = async (associationId: string) => {
  try {
    const response = await http.get(`/get-meeting-summaries/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const NotifyMeeting = async (obj: any) => {
  try {
    const response = await http.post(
      `/notify-meeting/${obj.meetingId}?associationId=${obj.associationId}`,
      { ...obj }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const MeetingService = {
  getMeetings,
  createMeeting,
  deleteMeeting,
  getMeetingSummaries,
  NotifyMeeting,
  getMeeting,
  updateMeeting,
};
export default MeetingService;
