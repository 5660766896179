import React, { useState } from "react";
import { AxiosError } from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, Modal, TextField, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import SpinnerLoader from "../../../Common/SpinnerLoader/SpinnerLoader";
import { updateUnit } from "../../../../Redux/Feature/Asset-slice";
import { useTranslation } from "react-i18next";

interface UnitUpdateField {
  description: string | null;
  title: string;
  floor: string;
  address2: string;
  ownershipShare: string;
}
const EditUnitModal = (props: any) => {
  const { editUnitFields, showUnitEditModal, setShowUnitEditModal, showToast } =
    props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = React.useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState("");

  const renderServerError = (errorCode: Number) => {
    switch (errorCode) {
      case 500:
        return <p style={{ color: "#DE1344" }}>Server Error</p>;
      case 400:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 401:
        return <p style={{ color: "#DE1344" }}>{t("Unauthorized")}</p>;
      case 403:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 404:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      default:
        return "";
    }
  };

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    title: Yup.string(),
    floor: Yup.number()
      .integer(t("* Not valid number."))
      .typeError(t("* Not valid number."))
      .moreThan(-6, t("Floor values can't be less than -5"))
      .required(t("Floor is required")),
    address2: Yup.string().required(t("Address2 is required")),
    ownershipShare: Yup.number()
      .typeError(t("* Not valid number."))
      .required(t("Ownership Share is required")),
  });
  const onHandleSubmit = async (values: UnitUpdateField) => {
    setLoading(true);
    try {
      const requestObject: any = {
        description: values.description,
        title: values.title,
        floor: values.floor,
        address2: values.address2,
        ownershipShare: values.ownershipShare,
        assetId: editUnitFields.assetId,
      };
      const response = await dispatch(updateUnit(requestObject));

      if (response?.payload?.statusCode === 200) {
        showToast(t("Unit Updated Successfully"), "success");
        setShowUnitEditModal(false);
      } else if (response?.error) {
        showToast(t("Error while updating"), "error");
      }
      setShowUnitEditModal(false);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        setServerError(error.response.status);
        if (err instanceof AxiosError) {
          if (err?.response?.data?.error) {
            setServerErrorMsg(err?.response?.data?.error);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: `${theme.palette.customBackground}`,
    border: `${theme.palette.modelBorderColor}`,
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    backdropFilter: "blur(4px)",
  };

  const outlineFieldStyle = {
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      background: theme.palette.mode==='light'? '#fff':`${theme.palette.inputBackground}`,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.modelInputBorderColor}`,
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.modelInputBorderColor}`,
        },
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.modelInputBorderColor}`,
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.mode==='light'?"#000":"#fff",
      "&.Mui-focused": {
        color: theme.palette.mode==='light'?"#000":"#fff",
      },
    },
  };

  const inputStyle = { padding: "14px 14px", borderRadius: "15px", color: theme.palette.mode==='light'? "#000":"#fff", }; 

  return (
    <Modal
      open={showUnitEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="edit-unit-modal"
    >
      <>
        <SpinnerLoader loading={loading} />
        <Box sx={{ ...style, pt: "10px", px: "15px", width: "500px" }}>
          <Box
            sx={{}}
            justifyContent={"right"}
            display="flex"
            onClick={() => setShowUnitEditModal(false)}
          >
            <CancelIcon sx={{ color: theme.palette.mode==='light'? "#b7b7b7":"#fff" }} />
          </Box>
          <Typography className="modal-modal-title" variant="h6" component="h6">
            {t("Edit Unit Information")}
          </Typography>
          <Formik
            initialValues={editUnitFields}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => onHandleSubmit(values)}
            onReset={() => {}}
          >
            {({ values, touched, resetForm }) => (
              <Form>
                <Field name={`title`}>
                  {({
                    field,
                    form,
                    meta,
                  }: {
                    field: any;
                    form: any;
                    meta: any;
                  }) => (
                    <Box className="formGroup unitInputWrapper">
                      <TextField
                        variant="outlined"
                        notched={true}
                        type="text"
                        {...field}
                        {...props}
                        sx={outlineFieldStyle}
                        inputProps={{ style: inputStyle }}
                        fullWidth
                        label={t("Title")}
                      />
                      {meta.touched && meta.error && (
                        <Typography className="error-msg">
                          {meta.error}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Field>
                <Field name={`address2`}>
                  {({
                    field,
                    form,
                    meta,
                  }: {
                    field: any;
                    form: any;
                    meta: any;
                  }) => (
                    <Box className="formGroup unitInputWrapper">
                      <TextField
                        notched={true}
                        type="text"
                        {...field}
                        {...props}
                        sx={outlineFieldStyle}
                        fullWidth
                        label={t("Address 2 (Unit Number)")}
                        inputProps={{ style: inputStyle }}
                      />
                      {meta.touched && meta.error && (
                        <Typography className="error-msg">
                          {meta.error}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Field>
                <Field name={`floor`}>
                  {({
                    field,
                    form,
                    meta,
                  }: {
                    field: any;
                    form: any;
                    meta: any;
                  }) => (
                    <Box className="formGroup unitInputWrapper">
                      <TextField
                        notched={true}
                        type="text"
                        {...field}
                        {...props}
                        sx={outlineFieldStyle}
                        fullWidth
                        label={t("Floor")}
                        inputProps={{ style: inputStyle }}
                      />
                      {meta.touched && meta.error && (
                        <Typography className="error-msg">
                          {meta.error}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Field>
                <Field name={`ownershipShare`}>
                  {({
                    field,
                    form,
                    meta,
                  }: {
                    field: any;
                    form: any;
                    meta: any;
                  }) => (
                    <Box className="formGroup unitInputWrapper">
                      <TextField
                        notched={true}
                        type="text"
                        {...field}
                        {...props}
                        sx={outlineFieldStyle}
                        fullWidth
                        label={t("Ownership Share")}
                        inputProps={{ style: inputStyle }}
                      />
                      {meta.touched && meta.error && (
                        <Typography className="error-msg">
                          {meta.error}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Field>
                {renderServerError(serverError)}
                <Box sx={{ p: 2 }} justifyContent={"center"} display="flex">
                  <Button
                    variant="contained"
                    className="button"
                    sx={{
                      background: "#256098",
                      color: "#fff",
                      border: "1px solid #256098",
                      fontSize: "20px",
                      fontWeight: "400",
                      padding: "2px 16px",
                      borderRadius: "8px",
                      textTransform: " capitalize",
                      boxShadow: "unset",
                      "&:hover": {
                        background: "#256098",
                        boxShadow: "unset",
                      },
                    }}
                    type="submit"
                  >
                    {t("Submit")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    </Modal>
  );
};

export default EditUnitModal;
