import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import { imageFileExtensions } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";

interface AttachmentPreviewProps {
  imageObj: any;
  isOpen: boolean;
  onClose: () => void;
  theme: any;
}

const AttachmentPreviewModal: React.FC<AttachmentPreviewProps> = ({
  imageObj,
  isOpen,
  onClose,
  theme,
}) => {
  const { imageUrl, fileName, fileExtension } = imageObj;
  const { t } = useTranslation();
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = imageUrl.split("/").pop() || "downloaded_image";
    link.style.display = "none";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="imagePreviewWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Box sx={{ display: "flex" }}>
          {imageFileExtensions.includes(fileExtension) ? (
            <img src={imageUrl} alt="Selected Image" />
          ) : (
            <Box
              sx={{
                border: `0.5px solid ${theme.palette.textWhite}`,
                borderRadius: "8px",
                padding: "5px 0",
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                sx={{ color: `${theme.palette.textWhite}` }}
              >
                {t("Preview Not Available")}
              </Typography>
            </Box>
          )}
        </Box>
        <Button
          variant="outlined"
          onClick={handleDownload}
          className="preview-download "
        >
          <DownloadIcon sx={{ marginRight: "6px" }} />
          {t("Download")}
        </Button>
      </Box>
    </Modal>
  );
};

export default AttachmentPreviewModal;
