import React from "react";
import { Box, Button, Select, MenuItem, useTheme } from "@mui/material";
import { useFormik } from "formik";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const EditTransactionBudget = (props: any) => {
  const {
    budgetId,
    transactionId,
    editBudgetHandler,
    updateBudgetHandler,
    updateFieldProgress,
    budgets,
  } = props;
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      budgetId: budgetId || "",
    },
    onSubmit: async (values) => {
      const updatePayload = {
        transactionId,
        budgetId: values.budgetId,
      };
      updateBudgetHandler(updatePayload);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Box
        className="edit-title-wrap support-edit"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Select
          fullWidth
          className="selectbox-document"
          name="budgetId"
          value={formik.values.budgetId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="standard"
          sx={{
            fontSize: "15px",
            height: "44px",
            "&.MuiInput-root": {
              margin: "0 !important",
            },
            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          {budgets?.map((budget: any) => (
            <MenuItem
              key={budget.budgetId}
              value={budget.budgetId}
              onClick={(event) => event.stopPropagation()}
            >
              {budget.name}
            </MenuItem>
          ))}
        </Select>
        <Box className="title-action-icons">
          <Button className="icon" type="submit">
            {updateFieldProgress ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "green",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <DoneIcon
                fontSize="medium"
                sx={{ color: `${theme.palette.activeColor}` }}
              />
            )}
          </Button>
          <Button className="icon" onClick={() => editBudgetHandler(false)}>
            <CloseIcon
              fontSize="medium"
              sx={{ color: `${theme.palette.activeColor}` }}
            />
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default EditTransactionBudget;
