import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  AppBar,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createCommonAssetAPI,
  deleteCommonAsset,
  getAllCommonAssets,
  getAssetComponents,
  updateCommonAssetAPI,
} from "../../../Redux/Feature/Asset-slice";
import Toast from "../../../Component/Common/Toast/Toast";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { Clear, Search } from "@mui/icons-material";
import DeleteCommonAssetModal from "../../../Component/Modals/AssetModals/CommonAssetsModals/DeleteCommonAssetModal";

import CommonAssetModal from "../../../Component/Modals/AssetModals/CommonAssetsModals/CommonAssetModal";
import CommonAssetDirectorySkeleton from "../../../Component/Skeletons/CommonAssetDirectorySkeleton";
import { assetCategories } from "../../../Utils/assets";
import {
  getAssociation,
  updateCommonCount,
} from "../../../Redux/Feature/Auth-slice";
import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES } from "../../../Utils/utils";
import CommonAssetProfile from "./CommonAssetProfile";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AssetComponentList from "./AssetComponentList";

const assetCategoryMap = assetCategories.reduce((map: any, category: any) => {
  map[category.key] = category.value;
  return map;
}, {});

const CommonAssetsDirectory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const { commonAssetDirectory, isFetching } = useSelector(
    (state: RootState) => state.Asset
  );
  const [tab, setTab] = useState(0);

  const [isCreateCommonAssetModalOpen, setIsCreateCommonAssetModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [showDeleteCommonAssetModal, setShowDeleteCommonAssetModal] =
    useState<boolean>(false);
  const [selectedCommonAsset, setSelectedCommonAsset] = useState<any | null>(
    null
  );

  const [rowSelect, setRowSelected] = useState<any>([]);
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const toggleDrawer = (value: boolean) => {
    setRightDrawer(value);
  };

  const openCreateCommonAssetComponent = () => {
    setIsCreateCommonAssetModalOpen(true);
  };

  const closeCreateCommonAssetComponent = async () => {
    setIsCreateCommonAssetModalOpen(false);
  };

  useEffect(() => {
    fetchCommonAssets();
  }, [currentAssociation?.associationId]);

  const fetchAssetComponents = async (assetId: string) => {
    // setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getAssetComponents({
            commonAssetId: assetId,
            associationId: currentAssociation?.associationId,
          })
        );
        if (response?.payload?.statusCode === 200) {
          // setCommonAssets(commonAssetDirectory);
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching all common assets:", error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchCommonAssets = async () => {
    setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getAllCommonAssets({
            associationId: currentAssociation?.associationId,
          })
        );
        if (response?.payload?.statusCode === 200) {
          // setCommonAssets(commonAssetDirectory);
        } else if (response.error) {
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching all common assets:", error);
    } finally {
      setLoading(false);
    }
  };

  const createCommonAssetHandler = async (createAssetPayload: any) => {
    try {
      setLoading(true);
      const response: any = await dispatch(
        createCommonAssetAPI(createAssetPayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset Created"), "success");
        await dispatch(getAssociation(currentAssociation.associationId));
        const newCommonCount = currentAssociation.commonCount + 1;
        dispatch(updateCommonCount(newCommonCount));
      } else if (response.error) {
        showToast(t("Error"), "error");
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  const updateCommonAssetHandler = async (updateAssetPayload: any) => {
    try {
      const response: any = await dispatch(
        updateCommonAssetAPI({
          id: updateAssetPayload.assetId,
          commonAssetsDirObj: updateAssetPayload.payload,
        })
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Asset Updated"), "success");
        setRowSelected([{ ...rowSelect[0], ...updateAssetPayload.payload }]);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleOpenRemoveCommonAssetModal = (asset: any): void => {
    setSelectedCommonAsset(asset);
    setShowDeleteCommonAssetModal(true);
  };

  const handleRemoveCommonAsset = async () => {
    try {
      if (selectedCommonAsset) {
        setLoading(true);
        const response: any = await dispatch(
          deleteCommonAsset(selectedCommonAsset?.assetId)
        );
        setShowDeleteCommonAssetModal(false);
        if (response?.payload?.statusCode === 200) {
          showToast(t("Asset Deleted"), "success");
          await dispatch(getAssociation(currentAssociation.associationId));
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  const assetsColumn = [
    {
      id: "title",
      label: t("Title"),
      width: "330px",
      minWidth: "330px",
      maxWidth: "330px",
    },

    {
      id: "establishedDate",
      label: t("Established Date"),
      width: "140px",
      minWidth: "140px",
      maxWidth: "140px",
    },
    {
      id: "Category",
      label: t("Category"),
      width: "120px",
      minWidth: "120px",
      maxWidth: "200px",
    },
    {
      id: "Priority",
      label: t("Priority"),
      width: "120px",
      minWidth: "120px",
      maxWidth: "200px",
    },
    {
      id: "Action",
      label: t("Action"),
      width: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
  ];

  const filteredAssetColumns = isBoardRole
    ? assetsColumn
    : assetsColumn?.filter((column) => column.id !== "Action");

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedAssets = stableSort(
    commonAssetDirectory,
    getComparator(order, orderBy)
  );

  const filteredAssets = sortedAssets?.filter((asset: any) => {
    if (!asset) {
      return false;
    }
    const { title, establishedDate, priority, assetCategory } = asset;
    const lowerCaseSearch = search.toLowerCase();
    return (
      title.toLowerCase().includes(lowerCaseSearch) ||
      assetCategory.toString().toLowerCase().includes(lowerCaseSearch) ||
      priority.toString().toLowerCase().includes(lowerCaseSearch) ||
      // usefulLife.toString().toLowerCase().includes(lowerCaseSearch)||
      establishedDate.includes(lowerCaseSearch)
    );
  });

  const paginatedData = filteredAssets.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClearSearch = () => {
    setSearch("");
  };

  const handleRowClick = (params: any) => {
    const isButtonClicked = (window.event?.target as Element)?.closest(
      ".remove-common-asset"
    );
    if (!isButtonClicked) {
      const selectedRecords: any = [params];
      setRowSelected(selectedRecords);
      toggleDrawer(true);
      fetchAssetComponents(selectedRecords[0]?.assetId);
    }
  };

  if (isFetching) {
    return <CommonAssetDirectorySkeleton />;
  }
  const handleBackClick = () => {
    setRowSelected([]);
  };

  return (
    <>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <DeleteCommonAssetModal
        isOpen={showDeleteCommonAssetModal}
        onClose={() => setShowDeleteCommonAssetModal(false)}
        onDelete={handleRemoveCommonAsset}
        loading={loading}
      />
      <Box>
        {rowSelect?.length > 0 ? (
          // <CommonAssetDrawer
          //   rightDrawer={rightDrawer}
          //   toggleDrawer={toggleDrawer}
          //   rowSelect={rowSelect}
          //   updateCommonAssetHandler={updateCommonAssetHandler}
          // />
          <>
            <AppBar
              position="static"
              sx={{
                backgroundColor: theme.palette.background.default,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="settings tabs"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: `${theme.palette.activeColor}`,
                  },
                }}
              >
                <Tab
                  label={
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          tab === 0
                            ? `${theme.palette.activeColor}`
                            : theme.palette.text.primary,
                      }}
                    >
                      {t("Reserve Details")}
                    </Typography>
                  }
                />
                <Tab
                  label={
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          tab === 1
                            ? `${theme.palette.activeColor}`
                            : theme.palette.text.primary,
                      }}
                    >
                      {t("Component List")}
                    </Typography>
                  }
                />
              </Tabs>
            </AppBar>
            <Button
              // variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBack />}
              sx={{
                color: `${theme.palette.text.primary}`,
                borderColor: `${theme.palette.text.primary}`,
                mt: "16px",
                // minWidth: "40px",
                // minHeight: "40px",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            ></Button>
            <Box
              className="ccr document document-wrapper"
              sx={{ paddingTop: "0 !important" }}
            >
              <Box className="rule__title">
                <Typography className="rule__title__heading page_title">
                  {rowSelect[0]?.title} {t("Profile")}
                </Typography>
              </Box>
              {tab === 0 && (
                <CommonAssetProfile
                  rowSelect={rowSelect}
                  updateCommonAssetHandler={updateCommonAssetHandler}
                  loading={loading}
                />
              )}
              {tab === 1 && (
                <AssetComponentList
                  commonAsset={rowSelect}
                ></AssetComponentList>
              )}
            </Box>
          </>
        ) : (
          <Box
            className="ccr document document-wrapper"
            sx={{ paddingTop: "0 !important" }}
          >
            <Box className="rule__title">
              <Typography className="rule__title__heading page_title">
                {t("Common Assets Directory")}
              </Typography>
            </Box>
            <Box
              className="TableConnect document-wrapper  new-support-table"
              sx={{
                border: "unset !important",
              }}
            >
              <Box
                sx={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <OutlinedInput
                  sx={{
                    margin: "0 !important",
                    border: "unset !important",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    "& .MuiInputBase-input": {
                      padding: "6px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#20c1f5",
                    },
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#20c1f5",
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#20c1f5",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  placeholder={t("Search")}
                  value={search}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {search ? (
                        <IconButton onClick={handleClearSearch} edge="end">
                          <Clear style={{ color: "#20c1f5" }} />
                        </IconButton>
                      ) : (
                        <IconButton edge="end">
                          <Search style={{ color: "#20c1f5" }} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
                {isBoardRole && (
                  <Box sx={{ textAlign: "end", marginBottom: "12px" }}>
                    <Button
                      className="btn add-rule btn-white"
                      variant="outlined"
                      onClick={openCreateCommonAssetComponent}
                    >
                      {t("Add Common Asset")}
                    </Button>
                  </Box>
                )}
              </Box>
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={9}>
                  <Box className="articlePart">
                    <CommonAssetModal
                      loading={loading}
                      isOpen={isCreateCommonAssetModalOpen}
                      onClose={closeCreateCommonAssetComponent}
                      createAssetHandler={createCommonAssetHandler}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box className="TableConnectInner">
                    <Box className="document-wrap gridTable">
                      <Paper
                        sx={{
                          width: "100%",
                          mb: 2,
                          border: "1px solid #20c1f5",
                        }}
                      >
                        <Box>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead sx={{ background: "black" }}>
                                <TableRow>
                                  {filteredAssetColumns?.map(
                                    (column, index) => (
                                      <TableCell
                                        sx={{ padding: "16px 8px" }}
                                        key={column.id}
                                        style={{
                                          width: column.minWidth,
                                          maxWidth: column.maxWidth,
                                          minWidth: column.minWidth,
                                        }}
                                        sortDirection={
                                          orderBy === column.id ? order : false
                                        }
                                      >
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : "asc"
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {paginatedData?.length === 0 ? (
                                  <TableRow>
                                    <TableCell colSpan={6} align="center">
                                      {t("No Components Found")}
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  paginatedData.map((row: any) => (
                                    <TableRow
                                      onClick={() => handleRowClick(row)}
                                      key={row.id}
                                      sx={{
                                        "&. MuiTableCell-root": {
                                          height: "70px",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row.title}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {row.establishedDate !== "Invalid Date"
                                          ? row.establishedDate
                                          : t("N/A")}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {t(assetCategoryMap[row.assetCategory])}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          height: "60px",
                                          padding: "4px 8px",
                                        }}
                                      >
                                        {t(row.priority)}
                                      </TableCell>
                                      {isBoardRole && (
                                        <TableCell
                                          sx={{
                                            height: "60px",
                                            padding: "4px 8px",
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            className="btn btn-theme btn-red remove-common-asset"
                                            sx={{ width: "80% !important" }}
                                            onClick={() =>
                                              handleOpenRemoveCommonAssetModal(
                                                row
                                              )
                                            }
                                          >
                                            {t("Remove")}
                                          </Button>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            sx={{ background: "black" }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={sortedAssets.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("Rows per page")}
                          />
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default CommonAssetsDirectory;
