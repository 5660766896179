import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  LinkBubbleMenu,
  TableBubbleMenu,
  RichTextEditor,
  RichTextEditorRef,
} from "mui-tiptap";
import EditorMenuControls from "./EditorMenuControls";
import { RootState } from "../../../Redux/Store";
import { useSelector } from "react-redux";
import useExtensions from "./useExtensions";
import { useTranslation } from "react-i18next";

interface CreateRuleComponentProps {
  isOpen: boolean;
  onClose: () => void;
  closeModelOnCrossClick: (closeActionValue: boolean) => void;
  ruleSet: string;
  lastRuleId: string;
  createRuleHandler: (createRulePayload: any) => Promise<void>;
  loading: boolean;
  heading: string;
}

interface CreateRuleComponentPropsWithSelectedItem
  extends CreateRuleComponentProps {
  selectedItem: any;
  updateRuleHandler: (updateRulePayload: any) => Promise<void>;
  editRuleHandler: (isActive: boolean) => void;
}

const CreateRuleComponent: React.FC<
  CreateRuleComponentProps | CreateRuleComponentPropsWithSelectedItem
> = (props) => {
  const {
    closeModelOnCrossClick,
    loading,
    ruleSet,
    lastRuleId,
    createRuleHandler,
    selectedItem,
    updateRuleHandler,
    editRuleHandler,
    heading,
  } = props as CreateRuleComponentPropsWithSelectedItem;
  const theme = useTheme();
  const { t } = useTranslation();
  const rteRef = useRef<RichTextEditorRef>(null);
  const extensions = useExtensions({
    placeholder: t("Add your own content here..."),
  });
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
    text: Yup.string().required(t("Text is required")),
  });
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      text: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { title, text } = values;
      const createRulePayload = {
        title: title,
        text: text,
        ruleSet: ruleSet,
        parentRuleId: lastRuleId,
        associationId: currentAssociation?.associationId,
      };
      const updateRulePayload = {
        ruleId: selectedItem && selectedItem?.ruleId,
        payload: {
          text: text,
          title: title,
        },
      };
      try {
        if (selectedItem) {
          await updateRuleHandler(updateRulePayload);
          editRuleHandler(false);
        } else {
          await createRuleHandler(createRulePayload);
          formik.resetForm();
          closeModelOnCrossClick(false);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    if (selectedItem) {
      editRuleHandler(false);
    } else {
      closeModelOnCrossClick(false);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      formik.setValues({
        title: selectedItem.title || "",
        text: selectedItem.text || "",
      });
    } else {
      formik.resetForm();
    }
  }, [selectedItem]);

  return (
    <>
      <Box
        className="add-document create-new-rule"
        sx={{ marginBottom: "30px", padding: "20px" }}
      >
        <Typography variant="h6" gutterBottom className="document-title">
          {selectedItem
            ? t("Update Rule for ") + `${heading}`
            : t("Create Rule for ") + `${heading}`}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ marginBottom: "16px" }}>
            <OutlinedInput
              id="title"
              name="title"
              placeholder={t("Title")}
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && !!formik.errors.title}
              className="add-document-title"
            />
            {formik.errors.title && formik.touched.title && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.title}
              </Typography>
            )}
          </Box>
          <Box sx={{ marginBottom: "20px" }}>
            <RichTextEditor
              className="rich-text-editor"
              ref={rteRef}
              content={selectedItem && selectedItem.text}
              extensions={extensions}
              onUpdate={(editor) => {
                const htmlContent = rteRef.current?.editor?.getHTML();
                if (htmlContent !== undefined) {
                  const trimmedContent = htmlContent.trim();
                  const containsOnlyEmptyTags = /^<p><\/p>$/.test(
                    trimmedContent
                  );
                  if (containsOnlyEmptyTags) {
                    formik.setFieldValue("text", "");
                  } else {
                    formik.setFieldValue("text", htmlContent);
                  }
                }
              }}
              renderControls={() => <EditorMenuControls />}
              RichTextFieldProps={{
                variant: "outlined",
              }}
            >
              {() => (
                <>
                  <LinkBubbleMenu />
                  <TableBubbleMenu />
                </>
              )}
            </RichTextEditor>
            {formik.errors.text && formik.touched.text && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.text}
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <Button
              type="submit"
              variant="outlined"
              className="browseButton submitButton btn-white"
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: `${theme.palette.loaderColor}`,
                    marginRight: "6px",
                  }}
                />
              )}
              {selectedItem ? t("Update") : t("Submit")}
            </Button>
            <Button
              onClick={() => handleCloseModal()}
              className="browseButton ccr-close-btn"
              sx={{ marginLeft: "15px" }}
            >
              {t("Close")}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateRuleComponent;
