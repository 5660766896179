import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../Component/Modals/HeaderModals/Context/ModalContext";
import {
  getAssociation,
  personAssociations,
  setCurrentAssociation,
  UpdatePerson,
} from "../../Redux/Feature/Auth-slice";
import CreateAssociationModal from "../../Component/Modals/HeaderModals/CreateAssociationModal";
import MiniDrawer from "../Menu/Menu";
import Select from "@mui/material/Select";
import SpinnerLoader from "../../Component/Common/SpinnerLoader/SpinnerLoader";
import { getAllAssetByAssociationId } from "../../Redux/Feature/Ticket-slice";
import Toast from "../../Component/Common/Toast/Toast";
import "./header.scss";
import { MEMBER_TYPE } from "../../Utils/utils";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { toggleTheme } from "../../Redux/Feature/Theme-slice";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [, setActiveMenu] = useState<any>(null);
  const [, setSelectedMenuItem] = useState<any>(null);
  const [createAssociationModal, setCreateAssociationModal] =
    useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [mode, setMode] = useState(true);
  const userData = useSelector((state: any) => state.Auth.user);
  const currentAssociation = useSelector(
    (state: any) => state.Auth.currentAssociation
  );
  const userAssociations = useSelector(
    (state: any) => state.Auth.userAssociations
  );

  const defaultAssociation = useSelector(
    (state: any) => state.Auth.defaultAssociation
  );
  const [testMode, setTestMode] = useState<boolean>(
    JSON.parse(localStorage.getItem("testMode") || "false")
  );

  useEffect(() => {
    if (userAssociations?.length === 0) {
      fetchPersonAssociations();
    }
  }, [pathname]);
  const handleEnvironmentChange = async (event: any) => {
    const newTestMode = event.target.value === "true";
    setTestMode(newTestMode);
    localStorage.setItem("testMode", JSON.stringify(newTestMode));

    const baseURL = newTestMode
      ? process.env.REACT_APP_BACKEND_URL_TEST
      : process.env.REACT_APP_BACKEND_URL;

    axios.defaults.baseURL = baseURL;
    const response = await dispatch(
      personAssociations({ personId: "", email: userData.email })
    );
    if (response?.payload?.data?.length === 0) {
      navigate("/setting");
    }
  };

  const { setShow } = useModalContext();

  const isVendorRole = currentAssociation?.role === MEMBER_TYPE.VENDOR;

  useEffect(() => {
    setMode(userData?.darkMode || false);
  }, [userData?.darkMode]);

  useEffect(() => {
    setSelectedMenuItem(location?.pathname?.substring(1));
    setActiveMenu(location?.pathname?.split("/")[1]);
  }, [location]);

  useEffect(() => {
    if (pathname === "/account") {
      dispatch(
        getAllAssetByAssociationId({ id: currentAssociation.associationId })
      );
    } else if (pathname === "/supporttickets") {
      dispatch(
        getAllAssetByAssociationId({ id: currentAssociation.associationId })
      );
    }
  }, [currentAssociation]);

  useEffect(() => {
    fetchPersonAssociations();
  }, []);
  useEffect(() => {
    if (defaultAssociation) {
      dispatch(setCurrentAssociation({ ...defaultAssociation }));
    }
  }, [defaultAssociation, dispatch]);

  const fetchPersonAssociations = async () => {
    if (typeof userData === "object" && userData) {
      await dispatch(
        personAssociations({ personId: "", email: userData.email })
      );
    }
  };

  const handleCurrentAssociations = async (e: any) => {
    if (
      e.target.value &&
      e.target.value.length > 0 &&
      e.target.value !== "Add Association"
    ) {
      setLoading(true);
      e?.preventDefault();
      const itemData = userAssociations.find(
        (association: any) => association.associationId === e.target.value
      );
      if (itemData?.associationId) {
        const response = await dispatch(
          getAssociation(itemData?.associationId)
        );
        dispatch(
          setCurrentAssociation({ ...itemData, ...response?.payload?.data })
        );
      }
      setLoading(false);
    }
  };

  function handleClick() {
    setShow({ hideDialog: true });
  }

  const openCreateAssociationModal = () => {
    setCreateAssociationModal(true);
  };

  const closeAddAssociationModal = async () => {
    setCreateAssociationModal(false);
    if (
      !currentAssociation ||
      Object.entries(currentAssociation).length === 0
    ) {
      fetchPersonAssociations();
    }
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const onChangeTheme = async (event: any) => {
    setLoading(true);
    const resp = await dispatch(UpdatePerson({ darkMode: !userData.darkMode }));
    if (resp?.payload?.statusCode === 200) {
      dispatch(toggleTheme());
      showToast(t("Theme updated successfully"), "success");
    } else {
      showToast(t("Error"), "error");
    }
    setLoading(false);
  };
  return (
    <>
      <CreateAssociationModal
        isOpen={createAssociationModal}
        onClose={closeAddAssociationModal}
        showToast={showToast}
      />
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <SpinnerLoader loading={loading} />

      <Box className={"HeaderMainMenu"}>
        <Grid
          container
          className="HeaderMainMenu__container"
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className="HeaderMainMenu__logo">
            <MiniDrawer />
            <Typography variant="h6" className="logo">
              Safe
              <span className="DropdownFieldScss">
                <div className="dropdown-container">
                  <div className="dropdown-list">
                    <FormControl variant="standard" fullWidth>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={currentAssociation?.associationId}
                        onChange={(event) => handleCurrentAssociations(event)}
                        label="Add Association"
                        sx={{
                          minWidth: 185,
                          "&::before": {
                            borderBottomColor: `${theme.palette.text.primary}`,
                          },
                        }}
                        classes={{ select: "selectedElement" }}
                        renderValue={(value) =>
                          `${
                            currentAssociation && currentAssociation?.title
                              ? currentAssociation?.title
                              : "Add Association"
                          }`
                        }
                        defaultValue={"Add Association"}
                        MenuProps={{
                          sx: {
                            "& .MuiMenu-paper": {
                              backgroundColor: `${theme.palette.textWhite}`,
                              color: "text.dark",
                            },
                            "& .css-olsweu-MuiButtonBase-root-MuiButton-root": {
                              color: `${theme.palette.textWhite}`,
                              border: `1px solid ${theme.palette.activeColor}`,
                              width: "100%",
                              backgroundColor: `${theme.palette.activeColor}`,
                              display: "block",
                              textAlign: "center",
                              margin: "8px",
                              "&:hover": {
                                color: `${theme.palette.textWhite}`,
                              },
                            },
                          },
                        }}
                      >
                        {userAssociations?.map((association: any) => (
                          <MenuItem
                            key={association.associationId}
                            value={association.associationId}
                            sx={{
                              color: `${theme.palette.textBlack}`,
                              backgroundColor: `${theme.palette.textWhite}`,
                              textTransform: "capitalize",
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {association.title}
                              </Grid>
                              <Grid item xs={4}>
                                {t(association.role)}
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                        <MenuItem value="Add Association">
                          <Button
                            fullWidth
                            className="add-registration-button1"
                            onClick={openCreateAssociationModal}
                            disableRipple
                          >
                            {t("Add Association")}
                          </Button>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </span>
            </Typography>
          </Box>
          <Select
            value={String(testMode)}
            onChange={handleEnvironmentChange}
            displayEmpty
          >
            <MenuItem value="false">{t("Live")}</MenuItem>
            <MenuItem value="true">{t("Test")}</MenuItem>
          </Select>
          <Box className="HeaderMainMenu__mode" alignContent={"flex-end"}>
            {testMode ? (
              <div className="mode test-mode">
                <p>{t("Test Mode")}</p>
              </div>
            ) : null}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isVendorRole ? (
              <></>
            ) : (
              <Box
                className="HeaderMainMenu__btn"
                alignContent={"flex-end"}
                sx={{ marginLeft: "10px" }}
              >
                {userAssociations?.length > 0 && (
                  <button
                    className="e-control e-btn button btn-theme"
                    id="targetButton1"
                    onClick={handleClick.bind(this)}
                  >
                    {t("Create Ticket")}
                  </button>
                )}
              </Box>
            )}
            {mode ? (
              <LightModeOutlinedIcon
                sx={{
                  color: "text.primary",
                  marginLeft: "16px",
                  fontSize: "38px",
                  cursor: "pointer",
                }}
                onClick={onChangeTheme}
              />
            ) : (
              <DarkModeOutlinedIcon
                sx={{
                  color: `${theme.palette.lightModeIconColor}`,
                  marginLeft: "16px",
                  fontSize: "38px",
                  cursor: "pointer",
                }}
                onClick={onChangeTheme}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Header;
