import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { translations } from "./Utils/utils";
import store from "./Redux/Store"; // Adjust this import based on where your Redux store is defined

const getDefaultLanguage = () => {
  const state = store.getState();
  const language = state.Auth?.user?.language;
  const userLang = navigator.language.slice(0, 2);

  if (
    language &&
    translations.some((translation: any) => translation.key === language)
  ) {
    return language;
  }

  return translations.some((translation: any) => translation.key === userLang)
    ? userLang
    : "en";
};

const defaultLanguage = getDefaultLanguage();
const fallbackLng = "en";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,
    fallbackLng,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: (lngs: any, namespaces: any) => {
        const path = `/translations/${lngs[0]}.json`;
        return path;
      },
    },
  });

export default i18n;
