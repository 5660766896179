import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Paper,
  TextField,
  Autocomplete,
  Popper,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../Redux/Store";
import { createAssociation } from "../../../Redux/Feature/Auth-slice";
import { updateUserAssociationList } from "../../../Redux/Feature/Auth-slice";
import SpinnerLoader from "../../Common/SpinnerLoader/SpinnerLoader";
import { COUNTRY_CODES } from "../../../Utils/utils";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

interface CreateAssociationModalProps {
  isOpen: boolean;
  onClose: () => void;
  showToast: (message: any, toastType: string) => void;
}

const roleOptions = [
  { title: "Board", value: "board" },
  { title: "Manager", value: "manager" },
];

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "1.3vw",
  },
}));
const CreateAssociationModal: React.FC<CreateAssociationModalProps> = ({
  isOpen,
  onClose,
  showToast,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Association Title is required")),
    role: Yup.string().required(t("User Role is required")),
    country: Yup.string().required(t("Country is required")),
  });
  const isFetching = useSelector(
    (state: RootState) => state?.Association?.isFetching
  );

  const autocompleteChipStyle = {
    "& .MuiInputBase-root": {
      border: "var(--mui-palette-inputBorderColor)",
    },
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "var(--mui-palette-autoCompleteBackground)",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: "var(--mui-palette-autocompletetextcolor)",
    },
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      file: null as File | null,
      role: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { role, title, country } = values;

      const associationPayload = {
        role: role,
        associationTitle: title,
        personEmail: userData?.email,
        country: country,
      };
      dispatch(createAssociation(associationPayload))
        .then((response: any) => {
          dispatch(updateUserAssociationList(response?.payload?.data));
          resetForm();
          onClose();
          showToast(t("Successfully created association"), "success");
          if (response?.payload?.data.length === 1) {
            sessionStorage.removeItem("displayIntroBanner");
          }
        })
        .catch((error: any) => {
          console.error("Error occurred:", error);
          showToast(t("Error"), "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <SpinnerLoader loading={loading} />
      <Modal open={isOpen} onClose={handleCloseModal} sx={{ zIndex: 100 }}>
        <Box
          className="add-document add-association-model"
          sx={{ width: "500px !important", paddingRight: "40px" }}
        >
          <Box
            sx={{ m: "20px 30px 40px" }}
            justifyContent={"center"}
            display="flex"
            onClick={() => handleCloseModal()}
          >
            <Typography
              className="modal-modal-title"
              variant="h4"
              component="h4"
            >
              {t("Create Association")}
            </Typography>
            <CancelIcon
              className="support-ticket-cross-icon"
              sx={{
                color: theme.palette.mode === "light" ? "#b7b7b7" : "#fff",
              }}
            />
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <div
              className="e-input-group"
              style={{ marginBottom: "20px", width: "100%" }}
            >
              <label
                style={{
                  color: `${theme.palette.text.primary}`,
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                {t("Association Name")}
              </label>
              <input
                id="title"
                name="title"
                placeholder={t("Association Name")}
                style={{ width: "100%" }}
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.title && !!formik.errors.title}
                className="request-support-field"
              />
              {formik.errors.title && formik.touched.title && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.title}
                </Typography>
              )}
            </div>
            <Box
              className="select-role formDropdownField"
              sx={{
                width: "100% !important",
                marginBottom: "10px",
              }}
            >
              <label
                style={{
                  color: `${theme.palette.text.primary}`,
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                {t("Role")}
              </label>
              <Select
                displayEmpty
                sx={{ width: "75%", height: "44px" }}
                placeholder={t("Select Role")}
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.role && !!formik.errors.role}
                MenuProps={{
                  sx: {
                    "& .MuiMenu-paper": {
                      backgroundColor:
                        "var(--mui-palette-autoCompleteBackground)",
                      // color: `${theme.palette.textBlack}`,
                    },
                    "& .MuiSelect-select": {
                      color: `${theme.palette.text.primary}`,
                      border: `${theme.palette.modelInputBorderColor}`,
                    },
                    "& .MuiMenuItem-root.Mui-disabled": {
                      opacity: 0.7,
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "rgba(0, 0, 0, 0.16)",
                      color: `${theme.palette.text.primary}`,
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  {t("Select Role")}
                </MenuItem>
                {roleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(option.title)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {formik.errors.role && formik.touched.role && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.role}
              </Typography>
            )}
            <Typography
              color={`${theme.palette.text.primary}`}
              sx={{ marginBottom: "10px", marginTop: "16px" }}
            >
              {t("Select Country")}
            </Typography>

            <Box sx={{ marginBottom: "16px" }}>
              <Autocomplete
                options={COUNTRY_CODES}
                sx={autocompleteChipStyle}
                getOptionLabel={(option: any) => t(option?.value)}
                defaultValue={selectedCountry}
                getOptionKey={(option: any) => option?.key}
                filterSelectedOptions
                PopperComponent={MuiDropDownPopper}
                id="selectCountry"
                className="country-select"
                onChange={(event, newValue: any) => {
                  setSelectedCountry(newValue);
                  formik.setFieldValue(
                    "country",
                    newValue ? newValue?.key : ""
                  );
                }}
                value={selectedCountry || null}
                onBlur={formik.handleBlur}
                PaperComponent={(props) => (
                  <Paper sx={autoCompletePaperStyle} {...props} />
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t("Select Country")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor}`, // default border color
                        },
                        "&:hover fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor}`, // border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: `${theme.palette.modelInputBorderColor} !important`, // border color when focused
                        },
                      },
                    }}
                  />
                )}
              />
              {formik.errors.country && formik.touched.country && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.country}
                </Typography>
              )}
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <div className="SubmitBtn ticketModel__footer">
                <Button
                  className="sBtn e-control e-btn"
                  id="targetButton1"
                  type="submit"
                  disabled={isFetching === true}
                >
                  {loading ? (
                    <CircularProgress
                      size={23}
                      sx={{ color: `${theme.palette.textWhite}` }}
                    />
                  ) : (
                    t("Submit")
                  )}
                </Button>
              </div>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateAssociationModal;
