import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useDispatch } from "react-redux";
import { getAllAssetByAssociationId } from "../../../Redux/Feature/Ticket-slice";
import { useTranslation } from "react-i18next";

const EditBudgetAsset = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationSchema = yup.object({
    assetId: yup.string().required(t("Asset is required")),
  });
  const {
    asset,
    setEditAsset,
    budgetId,
    showToast,
    updateBudgetHandler,
    setUpdateAssetProgress,
    updateAssetProgress,
    assets,
    associationId,
    monthYear,
  } = props;
  const [assetId, setAssetId] = useState<string>("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assetId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setUpdateAssetProgress(true);
      const obj = {
        budgetId,
        assetId: values.assetId,
        monthYear: monthYear,
      };
      const response = await dispatch(updateBudgetHandler(obj));
      setUpdateAssetProgress(false);
      setEditAsset(false);
      if (response?.payload?.statusCode === 200) {
        showToast(t("Budget Updated"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    },
  });

  useEffect(() => {
    fetchAssets();
    if (asset && asset.assetId) {
      setAssetId(asset.assetId);
    }
    formik.setValues({ assetId: assetId });
  }, [asset]);

  const fetchAssets = async () => {
    const response = await dispatch(
      getAllAssetByAssociationId({ id: associationId })
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        className="edit-title-wrap support-edit"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <Autocomplete
          id="assetId"
          options={assets}
          getOptionLabel={(option) => option.title}
          value={
            assets.find(
              (asset: any) => asset.assetId === formik.values.assetId
            ) || null
          }
          onChange={(event, newValue) => {
            formik.setFieldValue("assetId", newValue?.assetId || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select Asset"
              fullWidth
            />
          )}
          sx={{ width: "100%" }}
        />
        <Button
          type="submit"
          variant="contained"
          className="button"
          sx={{ ml: "10px" }}
          disabled={updateAssetProgress}
        >
          {updateAssetProgress ? <CircularProgress size={24} /> : t("Submit")}
        </Button>
        <Button
          onClick={() => setEditAsset(false)}
          variant="contained"
          className="button"
          sx={{ ml: "10px" }}
          disabled={updateAssetProgress}
        >
          {" "}
          {t("Cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditBudgetAsset;
