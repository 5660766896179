import React, { useEffect, useState } from "react";
import { Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { MEMBER_TYPE } from "../../../Utils/utils";
import {
  setBannerVisibility,
  hideBanner,
} from "../../../Redux/Feature/Banner-slice";
import { useTranslation } from "react-i18next";

interface BannerItem {
  data: string | JSX.Element;
  title: string;
}

interface BannerProps {
  items: BannerItem[];
  theme: any;
}

const Banner: React.FC<BannerProps> = ({ items, theme }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const darkMode = useSelector((state: any) => state.Auth.user.darkMode);
  const userData = useSelector((state: any) => state.Auth.user);
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const isBannerVisible = useSelector(
    (state: RootState) => state.Banner.isVisible
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const handleDotClick = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    getFilteredItems(currentAssociation);
  }, [
    currentAssociation?.associationId,
    currentAssociation?.commonCount,
    currentAssociation?.unitSubscriptionCount,
    userData?.name,
  ]);

  const getFilteredItems = async (obj: any) => {
    const allFilteredItems = items?.filter((item, index) => {
      if (index === 0 && !userData.name) {
        return true;
      }
      if (index === 1 && isBoardRole && obj.commonCount === 0) {
        return true;
      }
      if (index === 2 && isBoardRole && obj.unitSubscriptionCount === 0) {
        return true;
      }
      return false;
    });
    setFilteredItems(allFilteredItems);
    const displayIntroBanner = sessionStorage.getItem("displayIntroBanner");
    if (displayIntroBanner !== "false" || displayIntroBanner === null) {
      dispatch(setBannerVisibility(allFilteredItems?.length > 0));
    }
  };

  if (!isBannerVisible) {
    return null;
  }
  const handleCloseClick = () => {
    dispatch(hideBanner());
  };
  return (
    <div className="banner-main">
      <div>
        <Typography variant="h4">
          {t(filteredItems[activeStep]?.title)}
        </Typography>
        <Typography
          component="p"
          variant="h6"
          style={{ whiteSpace: "pre-line" }}
        >
          (t({filteredItems[activeStep]?.data}))
        </Typography>
      </div>
      <div className="banner-icon">
        <div className="banner-stepper">
          {Array.from({ length: filteredItems?.length }).map((_, index) => (
            <Button
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: darkMode
                  ? index === activeStep
                    ? `${theme.palette.textWhite}`
                    : `${theme.palette.dotColor}`
                  : index === activeStep
                  ? `${theme.palette.textBlack}`
                  : `${theme.palette.bannerDotColor}`,
                margin: "0 6px",
                padding: 0,
                minWidth: 0,
              }}
            />
          ))}
        </div>
        <IconButton onClick={() => handleCloseClick()}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Banner;
