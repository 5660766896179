import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./settingModal.scss";
import { useTranslation } from "react-i18next";

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  isOpen,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <Box className="deleteAccountWrapper">
        <Box sx={{ textAlign: "end" }} onClick={onClose}>
          <CancelIcon className="close-icon" />
        </Box>
        <Typography
          id="delete-account-modal-description"
          sx={{ mt: 2, marginBottom: "30px" }}
        >
          {t("Are you sure you want to delete this Account?")}
        </Typography>
        <Button
          variant="outlined"
          onClick={onDelete}
          className="deletemodalButtonYes"
        >
          {t("Yes")}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          className="deletemodalButtonCancel"
        >
          {t("No")}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteAccountModal;
