import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../Redux/Store";
import { stripeSafeSubscriptionSession } from "../../../../Redux/Feature/Association-slice";
// import { updateUserAssociationList } from "../../../../Redux/Feature/Auth-slice";
import SpinnerLoader from "../../../Common/SpinnerLoader/SpinnerLoader";
import { useTranslation } from "react-i18next";
import { PRICE_TIERS } from "../../../../Utils/utils";
interface CreateSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  showToast: (message: any, toastType: string) => void;
  associationId: string;
}

const subscriptionOptions = [
  { title: "Premium", value: PRICE_TIERS.PREMIUM },
  { title: "Elite", value: PRICE_TIERS.ELITE },
];

const CreateSubscriptionModal: React.FC<CreateSubscriptionModalProps> = ({
  isOpen,
  onClose,
  showToast,
  associationId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    unitCount: Yup.string().required(t("Association Unit Count is required")),
    subscriptionType: Yup.string().required(t("Subscription Type is required")),
  });
  const isFetching = useSelector(
    (state: RootState) => state?.Association?.isFetching
  );

  const formik = useFormik({
    initialValues: {
      unitCount: undefined,
      subscriptionType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { unitCount, subscriptionType } = values;

      const subscriptionPayload = {
        subscriptionType: subscriptionType,
        unitSubscriptionCount: Number(unitCount),
        country: "US",
        url: window.location.href,
        associationId: associationId,
      };

      dispatch(stripeSafeSubscriptionSession(subscriptionPayload))
        .then((response: any) => {
          resetForm();
          onClose();
          if (response?.payload?.data?.url) {
            window.location.href = response?.payload?.data?.url;
          }
        })
        .catch((error: any) => {
          console.error("Error occurred:", error);
          showToast(t("Error"), "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <SpinnerLoader loading={loading} />
      <Modal open={isOpen} onClose={handleCloseModal} sx={{ zIndex: 100 }}>
        <Box
          className="add-document add-association-model"
          sx={{ width: "500px !important", paddingRight: "40px" }}
        >
          <Box
            sx={{ m: "20px 30px 40px" }}
            justifyContent={"center"}
            display="flex"
            onClick={() => handleCloseModal()}
          >
            <p
              style={{
                color: `${theme.palette.text.primary}`,
                fontSize: "30px",
                fontWeight: "600",
              }}
            >
              {t("Subscribe to Safe")}
            </p>
            <CancelIcon className="support-ticket-cross-icon" />
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <div
              className="e-input-group"
              style={{ marginBottom: "20px", width: "100%" }}
            >
              <label
                style={{
                  color: `${theme.palette.text.primary}`,
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                {t("Unit Count")}
              </label>
              <input
                id="unitCount"
                name="unitCount"
                placeholder={t("Unit Count")}
                style={{ width: "100%" }}
                value={formik.values.unitCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.unitCount && !!formik.errors.unitCount}
                className="request-support-field"
              />
              {formik.errors.unitCount && formik.touched.unitCount && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.unitCount}
                </Typography>
              )}
            </div>
            <Box
              className="select-role formDropdownField"
              sx={{
                width: "100% !important",
                marginBottom: "10px",
              }}
            >
              <label
                style={{
                  color: `${theme.palette.text.primary}`,
                  marginBottom: "10px",
                  display: "block",
                }}
              >
                {t("Subscription Type")}
              </label>
              <Select
                displayEmpty
                sx={{ width: "75%", height: "44px" }}
                placeholder={t("Select Subscription Type")}
                id="subscriptionType"
                name="subscriptionType"
                value={formik.values.subscriptionType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.subscriptionType &&
                  !!formik.errors.subscriptionType
                }
                MenuProps={{
                  sx: {
                    "& .MuiMenu-paper": {
                      backgroundColor: `${theme.palette.textWhite}`,
                      color: `${theme.palette.textBlack}`,
                    },
                    "& .MuiSelect-select": {
                      color: `${theme.palette.textWhite}`,
                    },
                    "& .MuiMenuItem-root.Mui-disabled": {
                      opacity: 0.7,
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "rgba(0, 0, 0, 0.16)",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  {t("Select Subscription Type")}
                </MenuItem>
                {subscriptionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(option.title)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {formik.errors.subscriptionType &&
              formik.touched.subscriptionType && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.subscriptionType}
                </Typography>
              )}
            <Box sx={{ marginTop: "30px" }}>
              <div className="SubmitBtn ticketModel__footer">
                <Button
                  className="sBtn e-control e-btn"
                  id="targetButton1"
                  type="submit"
                  disabled={isFetching === true}
                >
                  {loading ? (
                    <CircularProgress
                      size={23}
                      sx={{ color: `${theme.palette.textWhite}` }}
                    />
                  ) : (
                    t("Submit")
                  )}
                </Button>
              </div>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateSubscriptionModal;
