import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  useTheme,
  InputAdornment,
  TextField,
  Autocomplete,
  Paper,
  Popper,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../Redux/Store";
import { useSelector } from "react-redux";
import moment from "moment";
import { MEMBER_TYPE, PRIORITY } from "../../../Utils/utils";
import { assetCategories } from "../../../Utils/assets";
import DatePickerComp from "../../../Component/Common/DatePicker/DatePicker";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "1.3vw",
  },
}));

const CreateCommonAsset = (props: any) => {
  const {
    onClose,
    createAssetHandler,
    loading,
    rowSelect,
    updateCommonAssetHandler,
  } = props;
  const isEditMode = rowSelect && rowSelect?.length > 0;
  const theme = useTheme();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
    description: Yup.string().required(t("Description is required")),
    onHandValue: Yup.number()
      .typeError(t("On Hand Value must be a number"))
      .positive()
      .integer(),
    presentValue: Yup.number()
      .typeError(t("Present Value must be a number"))
      .positive()
      .integer(),
    unitCost: Yup.number()
      .typeError(t("Unit Cost must be a number"))
      .positive()
      .integer(),
    quantity: Yup.number()
      .typeError(t("Quantity must be a number"))
      .positive()
      .integer(),
    priority: Yup.string().required(t("Priority is required")),
    assetCategory: Yup.string().required(t("Asset Category is required")),
  });
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const isGeneralRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER ||
    currentAssociation?.role === MEMBER_TYPE.GENERAL;

  let errorColor;
  errorColor = theme.palette.error.main;

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      onHandValue: "",
      presentValue: "",
      remainingLife: "",
      estimatedLife: "",
      unitCost: "",
      quantity: "",
      totalCost: "",
      establishedDate: null,
      nextReplacementYear: null,
      fundingGoalYear: null,
      measurement: "",
      priority: "",
      assetCategory: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const {
        title,
        description,
        onHandValue,
        presentValue,
        unitCost,
        quantity,
        establishedDate,
        nextReplacementYear,
        fundingGoalYear,
        measurement,
        priority,
        assetCategory,
      } = values;
      try {
        if (isEditMode) {
          const updateCommonAssetPayload: any = {
            assetId: rowSelect && rowSelect[0]?.assetId,
            payload: {
              title,
              description,
              onHandValue: Number(onHandValue),
              presentValue: Number(presentValue),
              unitCost: Number(unitCost),
              quantity: Number(quantity),
              measurement,
              priority,
              assetCategory,
            },
          };

          if (establishedDate && establishedDate !== "Invalid Date") {
            updateCommonAssetPayload.payload.establishedDate =
              moment(establishedDate).format("MM-DD-YYYY");
          }
          if (nextReplacementYear && nextReplacementYear !== "Invalid Date") {
            updateCommonAssetPayload.payload.nextReplacementYear =
              moment(nextReplacementYear).format("MM-DD-YYYY");
          }
          if (fundingGoalYear && fundingGoalYear !== "Invalid Date") {
            updateCommonAssetPayload.payload.fundingGoalYear =
              moment(fundingGoalYear).format("MM-DD-YYYY");
          }

          await updateCommonAssetHandler(updateCommonAssetPayload);
        } else {
          const createCommonAssetPayload: any = {
            title,
            description,
            onHandValue: Number(onHandValue),
            presentValue: Number(presentValue),
            unitCost: Number(unitCost),
            quantity: Number(quantity),
            measurement,
            priority,
            type: "common",
            assetCategory,
            associationId: currentAssociation?.associationId,
          };

          if (establishedDate) {
            createCommonAssetPayload.establishedDate =
              moment(establishedDate).format("MM-DD-YYYY");
          }

          if (nextReplacementYear) {
            createCommonAssetPayload.nextReplacementYear =
              moment(nextReplacementYear).format("MM-DD-YYYY");
          }

          if (fundingGoalYear) {
            createCommonAssetPayload.fundingGoalYear =
              moment(fundingGoalYear).format("MM-DD-YYYY");
          }

          await createAssetHandler(createCommonAssetPayload);
          resetForm();
        }
        if (onClose) {
          onClose();
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },
  });
  const [selectedAssetCategory, setSelectedAssetCategory] = useState<any>(null);
  useEffect(() => {
    if (rowSelect && rowSelect?.length > 0) {
      const assetData = rowSelect[0];
      const selAssetCategory = assetCategories.find(
        (category) => category.key === rowSelect[0].assetCategory
      );
      setSelectedAssetCategory(selAssetCategory);
      formik.setValues({
        title: assetData.title || "",
        description: assetData.description || "",
        onHandValue: assetData.onHandValue || "",
        presentValue: assetData.presentValue || "",
        remainingLife: assetData.remainingLife || "",
        estimatedLife: assetData.estimatedLife || "",
        unitCost: assetData.unitCost || "",
        quantity: assetData.quantity || "",
        totalCost: assetData.totalCost || "",
        establishedDate: assetData.establishedDate || null,
        nextReplacementYear: assetData.nextReplacementYear || null,
        fundingGoalYear: assetData.fundingGoalYear || null,
        measurement: assetData.measurement || "",
        priority: assetData.priority || "",
        assetCategory: assetData.assetCategory || "",
      });
    }
  }, [rowSelect]);

  const autocompleteChipStyle = {
    width: "50%",
  };

  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };
  return (
    <>
      <Box
        className="add-document create-new-rule common-asset"
        sx={{ marginBottom: "30px" }}
      >
        <Typography
          variant="h6"
          gutterBottom
          className="document-title"
          sx={{ marginBottom: "20px" }}
        >
          {isEditMode && t("Edit Common Asset")}
        </Typography>
        <form onSubmit={formik.handleSubmit} className="asset-drawer-form">
          <Grid container spacing={2} sx={{}}>
            <Grid item xs={12} lg={12} className="input-asset-border">
              <Box sx={{ marginBottom: "16px" }}>
                <label className="mui-text-label-color">{t("Title")}</label>
                <OutlinedInput
                  id="title"
                  name="title"
                  placeholder={t("Title")}
                  fullWidth
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && !!formik.errors.title}
                  className="add-document-title"
                  disabled={!isBoardRole}
                  sx={{ width: "80%" }}
                />
                {formik.errors.title && formik.touched.title && (
                  <Typography color={errorColor}>
                    {formik.errors.title}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} className="input-asset-border">
              <label className="mui-text-label-color">
                {t("Asset Category")}
              </label>
              <Box sx={{ marginBottom: "16px" }}>
                <Autocomplete
                  // multiple
                  options={assetCategories}
                  getOptionLabel={(option: any) => t(option?.value)}
                  defaultValue={selectedAssetCategory}
                  getOptionKey={(option: any) => option?.key}
                  filterSelectedOptions
                  PopperComponent={MuiDropDownPopper}
                  id="assetCategory"
                  className="asset-category-select"
                  onChange={(event, newValue: any) => {
                    setSelectedAssetCategory(newValue);
                    formik.setFieldValue(
                      "assetCategory",
                      newValue ? newValue?.key : ""
                    );
                  }}
                  value={selectedAssetCategory || null}
                  // disableClearable
                  sx={autocompleteChipStyle}
                  disabled={!isBoardRole}
                  onBlur={formik.handleBlur}
                  PaperComponent={(props) => (
                    <Paper sx={autoCompletePaperStyle} {...props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("Select Asset Category")}
                    />
                  )}
                />
                {formik.errors.assetCategory &&
                  formik.touched.assetCategory && (
                    <Typography color={errorColor}>
                      {formik.errors.assetCategory}
                    </Typography>
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} className="input-asset-border">
              <label className="mui-text-label-color">{t("Priority")}</label>
              <Box sx={{ marginBottom: "16px" }}>
                <Select
                  displayEmpty
                  id="priority"
                  name="priority"
                  placeholder={t("Select Priority")}
                  fullWidth
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.priority && !!formik.errors.priority}
                  disabled={!isBoardRole}
                  sx={{ width: "50%" }}
                  MenuProps={{
                    sx: {
                      "& .MuiMenu-paper": {
                        backgroundColor: `var(--mui-palette-customBackground)`,
                        color: `var(--mui-palette-text-primary)`,
                      },
                      "& .MuiSelect-select": {
                        color: `var(--mui-palette-text-primary)`,
                      },
                      "& .MuiMenuItem-root.Mui-disabled": {
                        opacity: 0.7,
                      },
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: `var(--mui-palette-customBackground)`,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t("Select Priority")}
                  </MenuItem>
                  <MenuItem value={PRIORITY.HIGH}>{t("High")}</MenuItem>
                  <MenuItem value={PRIORITY.MEDIUM}>{t("Medium")}</MenuItem>
                  <MenuItem value={PRIORITY.LOW}>{t("Low")}</MenuItem>
                </Select>
                {formik.errors.priority && formik.touched.priority && (
                  <Typography color={errorColor}>
                    {formik.errors.priority}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={12} className="input-asset-border">
              <Box sx={{ marginBottom: "12px" }}>
                <label className="mui-text-label-color">
                  {t("Description")}
                </label>
                <textarea
                  id="description"
                  name="description"
                  placeholder={t("Description")}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!isBoardRole}
                  style={{ width: "80%" }}
                />
                {formik.errors.description && formik.touched.description && (
                  <Typography color={errorColor}>
                    {formik.errors.description}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          {isGeneralRole && (
            <Grid container spacing={2} sx={{}}>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Unit Cost")}
                  </label>
                  <OutlinedInput
                    id="unitCost"
                    name="unitCost"
                    placeholder={t("Unit Cost")}
                    fullWidth
                    value={formik.values.unitCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.unitCost && !!formik.errors.unitCost}
                    className="add-document-title"
                    disabled={!isBoardRole}
                    sx={{ width: "50%" }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  {formik.errors.unitCost && formik.touched.unitCost && (
                    <Typography color={errorColor}>
                      {formik.errors.unitCost}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Measurement")}
                  </label>
                  <OutlinedInput
                    id="measurement"
                    name="measurement"
                    placeholder={t("Measurement")}
                    fullWidth
                    value={formik.values.measurement}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.measurement && !!formik.errors.measurement
                    }
                    className="add-document-title"
                    disabled={!isBoardRole}
                    sx={{ width: "50%" }}
                  />
                  {formik.errors.measurement && formik.touched.measurement && (
                    <Typography color={errorColor}>
                      {formik.errors.measurement}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} className="input-asset-border">
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Quantity")}
                  </label>
                  <OutlinedInput
                    id="quantity"
                    name="quantity"
                    placeholder={t("Quantity")}
                    fullWidth
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.quantity && !!formik.errors.quantity}
                    className="add-common-quantity"
                    disabled={!isBoardRole}
                    sx={{ width: "50%" }}
                  />
                  {formik.errors.quantity && formik.touched.quantity && (
                    <Typography color={errorColor}>
                      {formik.errors.quantity}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Total Cost")}
                  </label>
                  <OutlinedInput
                    id="totalCost"
                    name="totalCost"
                    placeholder={t("Total Cost")}
                    fullWidth
                    value={formik.values.totalCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.totalCost && !!formik.errors.totalCost
                    }
                    className="add-asset-total-cost"
                    disabled={true}
                    inputProps={{ maxLength: 4 }}
                    sx={{ width: "50%" }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  {formik.errors.totalCost && formik.touched.totalCost && (
                    <Typography color={errorColor}>
                      {formik.errors.totalCost}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Established Date")}
                  </label>
                  <DatePickerComp
                    disablePast={false}
                    disableFuture={true}
                    isEditMode={isEditMode}
                    className="custom-datepicker"
                    value={
                      formik.values.establishedDate
                        ? dayjs(formik.values.establishedDate)
                        : null
                    }
                    onChange={(newValue: any) => {
                      const formattedDate = newValue
                        ? newValue.format("MM-DD-YYYY")
                        : null;
                      formik.setFieldValue("establishedDate", formattedDate);
                    }}
                    disabled={!isBoardRole}
                  />
                  {formik.errors.establishedDate &&
                    formik.touched.establishedDate && (
                      <Typography color={errorColor}>
                        {formik.errors.establishedDate}
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className="input-asset-border">
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Funding Goal Year")}
                  </label>
                  <DatePickerComp
                    disablePast={false}
                    disableFuture={false}
                    className="custom-datepicker"
                    isEditMode={isEditMode}
                    value={
                      formik.values.fundingGoalYear
                        ? dayjs(formik.values.fundingGoalYear)
                        : null
                    }
                    onChange={(newValue: any) => {
                      const formattedDate = newValue
                        ? newValue.format("MM-DD-YYYY")
                        : null;
                      formik.setFieldValue("fundingGoalYear", formattedDate);
                    }}
                    disabled={!isBoardRole}
                  />
                  {formik.errors.fundingGoalYear &&
                    formik.touched.fundingGoalYear && (
                      <Typography color={errorColor}>
                        {formik.errors.fundingGoalYear}
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("On Hand Value")}
                  </label>
                  <OutlinedInput
                    id="onHandValue"
                    name="onHandValue"
                    placeholder={t("On Hand Value")}
                    fullWidth
                    value={formik.values.onHandValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.onHandValue && !!formik.errors.onHandValue
                    }
                    className="add-document-title"
                    disabled={!isBoardRole}
                    sx={{ width: "50%" }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  {formik.errors.onHandValue && formik.touched.onHandValue && (
                    <Typography color={errorColor}>
                      {formik.errors.onHandValue}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} className="input-asset-border">
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Present Value")}
                  </label>
                  <OutlinedInput
                    id="presentValue"
                    name="presentValue"
                    placeholder={t("Present Value")}
                    fullWidth
                    value={formik.values.presentValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.presentValue &&
                      !!formik.errors.presentValue
                    }
                    className="add-document-title"
                    disabled={!isBoardRole}
                    sx={{ width: "50%" }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  {formik.errors.presentValue &&
                    formik.touched.presentValue && (
                      <Typography color={errorColor}>
                        {formik.errors.presentValue}
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Remaining Life")}
                  </label>
                  <OutlinedInput
                    id="remainingLife"
                    name="remainingLife"
                    placeholder={t("Remaining Life")}
                    fullWidth
                    value={formik.values.remainingLife}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.remainingLife &&
                      !!formik.errors.remainingLife
                    }
                    className="add-document-title"
                    disabled={true}
                    inputProps={{ maxLength: 4 }}
                    sx={{ width: "50%" }}
                  />
                  {formik.errors.remainingLife &&
                    formik.touched.remainingLife && (
                      <Typography color={errorColor}>
                        {formik.errors.remainingLife}
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Estimated Life")}
                  </label>
                  <OutlinedInput
                    id="estimatedLife"
                    name="estimatedLife"
                    placeholder={t("Estimated Life")}
                    fullWidth
                    value={formik.values.estimatedLife}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.estimatedLife &&
                      !!formik.errors.estimatedLife
                    }
                    className="add-document-title"
                    disabled={true}
                    inputProps={{ maxLength: 4 }}
                    sx={{ width: "50%" }}
                  />
                  {formik.errors.estimatedLife &&
                    formik.touched.estimatedLife && (
                      <Typography color={errorColor}>
                        {formik.errors.estimatedLife}
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={{ marginBottom: "16px" }}>
                  <label className="mui-text-label-color">
                    {t("Next Replacement Year")}
                  </label>
                  <DatePickerComp
                    disablePast={true}
                    disableFuture={false}
                    className="custom-datepicker"
                    isEditMode={isEditMode}
                    value={
                      formik.values.nextReplacementYear
                        ? dayjs(formik.values.nextReplacementYear)
                        : null
                    }
                    onChange={(newValue: any) => {
                      const formattedDate = newValue
                        ? newValue.format("MM-DD-YYYY")
                        : null;
                      formik.setFieldValue(
                        "nextReplacementYear",
                        formattedDate
                      );
                    }}
                    disabled={!isBoardRole}
                  />
                  {formik.errors.nextReplacementYear &&
                    formik.touched.nextReplacementYear && (
                      <Typography color={errorColor}>
                        {formik.errors.nextReplacementYear}
                      </Typography>
                    )}
                </Box>
              </Grid>
            </Grid>
          )}
          {isBoardRole && (
            <Box sx={{ textAlign: "end" }} className="fixed-bottom-btn">
              <Button
                onClick={() => onClose()}
                className="browseButton close-btn"
                sx={{ marginLeft: "15px", marginRight: "0 !important" }}
              >
                {isEditMode ? t("Cancel") : t("Close")}
              </Button>
              <Button
                type="submit"
                variant="outlined"
                className={`browseButton submitButton ${
                  isEditMode ? "btn-white" : ""
                }`}
                sx={{ marginLeft: "12px" }}
              >
                {loading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: isEditMode
                        ? `${theme.palette.loaderColor}`
                        : `${theme.palette.textWhite}`,
                      marginRight: "6px",
                    }}
                  />
                )}
                {isEditMode ? t("Update") : t("Submit")}
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </>
  );
};

export default CreateCommonAsset;
