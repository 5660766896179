import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  TableSortLabel,
  TablePagination,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import "./budgets.scss";
import "../../Settings/setting.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getBudgetsByDate,
  resetDrawer,
  getBudget,
  UpdateBudget,
  deleteBudget,
} from "../../../Redux/Feature/Finance-slice";
import DeleteIcon from "@mui/icons-material/Delete";

import { Clear, Search } from "@mui/icons-material";
import Toast from "../../../Component/Common/Toast/Toast";
import AddBudgetModal from "../../../Component/Modals/FinanceModals/BudgetModals/AddBudgetModal";
import BudgetDrawer from "../../../Component/Drawers/BudgetDrawer";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../../Utils/utils";
import { RootState } from "../../../Redux/Store";
import BudgetSkeleton from "../../../Component/Skeletons/BudgetSkeleton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditBudgetName from "./EditBudgetName";
import EditBudgetAllocated from "./EditBudgetAllocated";
import EditBudgetAsset from "./EditBudgetAsset";
import { getAssetsByPerson } from "../../../Redux/Feature/Auth-slice";
import { all } from "axios";
import { getAllAssetByAssociationId } from "../../../Redux/Feature/Ticket-slice";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../Component/Modals/GeneralModals/ConfirmationModal";
ChartJS.register(ArcElement, Tooltip, Legend);

const Budgets = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const columns = [
    {
      id: "name",
      label: t("Name"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "allocatedAmount",
      label: t("Allocated Amount"),
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
    {
      id: "actualAmount",
      label: t("Actual Amount"),
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
    {
      id: "difference",
      label: t("Difference"),
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
    {
      id: "asset",
      label: t("Asset"),
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      id: "delete",
      label: t("Delete"),
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
  ];
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState<string>("");
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [selectedBudget, setSelectedBudget] = useState<any>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [isAddBudgetModalOpen, setIsAddBudgetModalOpen] = useState(false);
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const budgets = useSelector(
    (state: RootState) => state?.Finances?.budgetsByDate
  );
  const totalAllocatedAmount = useSelector(
    (state: RootState) => state?.Finances?.totalAllocatedAmount
  );
  const totalActualAmount = useSelector(
    (state: RootState) => state?.Finances?.totalActualAmount
  );
  const totalDifference = useSelector(
    (state: RootState) => state?.Finances?.totalDifference
  );

  const isFetching = useSelector(
    (state: RootState) => state?.Finances?.isFetching
  );

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<any>(currentYear);
  const [selectedDuration, setSelectedDuration] = useState<string>("annually");
  const [selectedQuarter, setSelectedQuarter] = useState<number>(1);

  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );

  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;
  const [updateNameProgress, setUpdateNameProgress] = useState(false);
  const [updateAllocatedAmountProgress, setUpdateAllocatedAmountProgress] =
    useState(false);

  const [updateAssetProgress, setUpdateAssetProgress] = useState(false);

  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editName, setEditName] = useState<boolean>(false);
  const [editAllocatedAmount, setEditAllocatedAmount] =
    useState<boolean>(false);
  const [editAsset, setEditAsset] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string>(
    selectedRow?.asset?.assetId
  );

  const editAllocatedAmountHandler = (value: boolean) => {
    setEditAllocatedAmount(value);
  };

  const editAssetHandler = (value: boolean) => {
    setEditAsset(value);
  };

  useEffect(() => {
    fetchAllBudgets();
  }, [
    currentAssociation?.associationId,
    selectedYear,
    selectedDuration,
    selectedQuarter,
    selectedMonth,
  ]);
  const allAssets = useSelector(
    (state: RootState) => state?.SupportTicket?.allAssets
  );
  const fetchAllBudgets = async () => {
    setIsFirstLoad(true);
    const getBudgetsByDatePayload = {
      associationId: currentAssociation?.associationId,
      year: selectedYear,
      month: selectedMonth,
      duration: selectedDuration,
      quarter: selectedQuarter,
    };
    await dispatch(getBudgetsByDate(getBudgetsByDatePayload));
    setIsFirstLoad(false);

    dispatch(
      await getAllAssetByAssociationId(currentAssociation?.associationId)
    );
  };

  const generateMonths = (selectedYear: any) => {
    const months = [
      { value: 1, text: t("January") },
      { value: 2, text: t("February") },
      { value: 3, text: t("March") },
      { value: 4, text: t("April") },
      { value: 5, text: t("May") },
      { value: 6, text: t("June") },
      { value: 7, text: t("July") },
      { value: 8, text: t("August") },
      { value: 9, text: t("September") },
      { value: 10, text: t("October") },
      { value: 11, text: t("November") },
      { value: 12, text: t("December") },
    ];

    if (selectedYear === new Date().getFullYear()) {
      return months?.filter(
        (month) => month.value <= new Date().getMonth() + 1
      );
    } else {
      return months;
    }
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const handleAddBudgetModalOpen = () => {
    setIsAddBudgetModalOpen(true);
  };

  const handleAddBudgetModalClose = () => {
    setIsAddBudgetModalOpen(false);
  };

  if (isFirstLoad && isFetching) {
    return <BudgetSkeleton />;
  }
  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const sortedBudgets = stableSort(budgets, getComparator(order, orderBy));
  const filteredBudgets = sortedBudgets?.filter((tx: any) => {
    if (!tx) {
      return false;
    }
    const { allocatedAmount, name, assetName } = tx;
    const lowerCaseSearch = search?.toLowerCase();
    return (
      name?.toLowerCase().includes(lowerCaseSearch) ||
      allocatedAmount == search ||
      assetName?.toLowerCase().includes(lowerCaseSearch)
    );
  });

  let paginatedData: any = filteredBudgets?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const years = Array.from(
    { length: new Date().getFullYear() - currentYear + 1 },
    (_, index) => new Date().getFullYear() - index
  )?.map((year) => ({ value: year, text: year.toString() }));
  const monthsForSelectedYear = generateMonths(selectedYear);
  const durations = [
    { value: "annually", text: t("Annually") },
    { value: "quarterly", text: t("Quarterly") },
    { value: "monthly", text: t("Monthly") },
  ];
  const quarters = [
    { value: 1, text: t("Q1") },
    { value: 2, text: t("Q2") },
    { value: 3, text: t("Q3") },
    { value: 4, text: t("Q4") },
  ];
  const handleClearSearch = () => {
    setSearch("");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const handleDurationChange = (e: any) => {
    const selectedDurationObject = e.target.value;
    setSelectedDuration(selectedDurationObject);
  };

  const handleQuarterChange = (e: any) => {
    const selectedQuarterObject = e.target.value;
    setSelectedQuarter(selectedQuarterObject);
  };

  const handleYearChange = (e: any) => {
    const selectedYearObject = e.target.value;
    setSelectedYear(selectedYearObject);
    // const newMonths = generateMonths(selectedYearObject);
    // setSelectedMonth(newMonths[0]?.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleMonthChange = (e: any) => {
    const selectedMonthObject = e.target.value;
    setSelectedMonth(selectedMonthObject);
  };

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  const openRemoveModal = (budgetId: any): void => {
    setSelectedBudget(budgetId);
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to remove this budget?");
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const updateNameHandler = async (updatePayload: any) => {
    try {
      setUpdateNameProgress(true);
      const response = await dispatch(UpdateBudget(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setSelectedRow(response?.payload?.data);
        showToast(t("Budget Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateNameProgress(false);
      setLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateNameProgress(false);
      setLoading(false);
    }
  };

  const updateAllocatedAmountHandler = async (updatePayload: any) => {
    try {
      setUpdateAllocatedAmountProgress(true);
      const response = await dispatch(UpdateBudget(updatePayload));
      if (response?.payload?.statusCode === 200) {
        setSelectedRow(response?.payload?.data);
        showToast(t("Budget Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateAllocatedAmountProgress(false);
      setLoading(false);
      editAllocatedAmountHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateAllocatedAmountProgress(false);
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleDrawer = async (value: boolean) => {
    setRightDrawer(value);
    dispatch(resetDrawer());
  };

  // const handleRowClick = async (row: any) => {
  //   // setUpdateInProgress(true);
  //   const isInputClicked =
  //     (window.event?.target as Element)?.tagName.toLowerCase() === "input";
  //   if (!isInputClicked) {
  //     const isIconClicked = (window.event?.target as Element)?.closest(".icon");
  //     if (!isIconClicked) {
  //       const isEditIconClicked = (window.event?.target as Element)?.closest(
  //         ".support-edit-icon"
  //       );
  //       if (!isEditIconClicked) {
  //         setSelectedRow(row);
  //         dispatch(
  //           await getBudget({
  //             budgetId: row.budgetId,
  //             year: selectedYear,
  //             month: selectedMonth,
  //             duration: selectedDuration,
  //             quarter: selectedQuarter,
  //           })
  //         );
  //         dispatch(
  //           await getAllAssetByAssociationId(currentAssociation?.associationId)
  //         );
  //         toggleDrawer(true);
  //       }
  //     }
  //   }
  // };

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      const response = await dispatch(deleteBudget(selectedBudget?.budgetId));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Budget Removed"), "success");
      } else if (response.error) {
        showToast(t("Budget Not Removed"), "error");
      }
    } catch (err) {
    } finally {
      closeConfirmationModal();
      setLoading(false);
    }
  };
  return (
    <div className="profile">
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmation={confirmation}
      />
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <AddBudgetModal
        isOpen={isAddBudgetModalOpen}
        onClose={handleAddBudgetModalClose}
        associationId={currentAssociation?.associationId}
        setIsFirstLoad={setIsFirstLoad}
      />

      <Box className="budgetsBox__title">
        <Typography className="budgets__title page_title">
          {t("Budgets")}
        </Typography>
      </Box>

      <Grid container direction="row">
        <Grid item xs={6}>
          <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                  {t("Total Allocated Amount")}:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  color: totalAllocatedAmount ?? 0 >= 0 ? "green" : "red",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {totalAllocatedAmount != null &&
                  totalAllocatedAmount !== undefined &&
                  !isNaN(totalAllocatedAmount)
                    ? formatter.format(totalAllocatedAmount)
                    : formatter.format(0)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                  {t("Total Actual Amount")}:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  color: totalActualAmount ?? 0 >= 0 ? "red" : "green",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {totalActualAmount != null &&
                  totalActualAmount !== undefined &&
                  !isNaN(totalActualAmount)
                    ? formatter.format(totalActualAmount)
                    : formatter.format(0)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: `${theme.palette.activeColor}` }}>
                  {t("Total Budget Difference")}:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  color: totalDifference ?? 0 >= 0 ? "green" : "red",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {totalDifference != null &&
                  totalDifference !== undefined &&
                  !isNaN(totalDifference)
                    ? formatter.format(totalDifference)
                    : formatter.format(0)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              }}
            >
              {isBoardRole && (
                <Button
                  className="btn add-member btn-white"
                  variant="outlined"
                  onClick={handleAddBudgetModalOpen}
                  sx={{ padding: "6px 16px" }}
                >
                  {t("Add Budget")}
                </Button>
              )}
              <Box>
                <OutlinedInput
                  sx={{
                    margin: "0 !important",
                    padding: "6px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${theme.palette.activeColor}`,
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${theme.palette.activeColor}`,
                        borderWidth: "1px",
                      },
                    },
                  }}
                  placeholder={t("Search")}
                  value={search}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {search ? (
                        <IconButton
                          onClick={handleClearSearch}
                          edge="end"
                          className="search-icon-button-hover"
                        >
                          <Clear
                            style={{
                              color: `${theme.palette.activeColor}`,
                            }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          className="search-icon-button-hover"
                        >
                          <Search
                            style={{
                              color: `${theme.palette.activeColor}`,
                            }}
                          />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                className="gridTableFilterInner"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  flexGrow: 1,
                  color: `${theme.palette.activeColor}`,
                  marginBottom: "24px",
                }}
              >
                <Typography>{t("Duration")}:</Typography>
                <Box className="DropdownFieldScss" sx={{ width: 100 }}>
                  <FormControl variant="standard" fullWidth>
                    <Select
                      labelId="duration-select-label"
                      id="duration-select"
                      value={selectedDuration}
                      onChange={handleDurationChange}
                      displayEmpty
                    >
                      {durations.map((duration) => (
                        <MenuItem key={duration.value} value={duration.value}>
                          {duration.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography>{t("Year")}:</Typography>
                <Box className="DropdownFieldScss" sx={{ width: 100 }}>
                  <FormControl variant="standard" fullWidth>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                      displayEmpty
                    >
                      {years.map((year) => (
                        <MenuItem key={year.value} value={year.value}>
                          {year.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {selectedDuration === "monthly" && (
                  <>
                    <Typography>{t("Month")}:</Typography>
                    <Box className="DropdownFieldScss" sx={{ width: 100 }}>
                      <FormControl variant="standard" fullWidth>
                        <Select
                          labelId="month-select-label"
                          id="month-select"
                          value={selectedMonth}
                          onChange={handleMonthChange}
                          displayEmpty
                        >
                          {monthsForSelectedYear.map((month) => (
                            <MenuItem key={month.value} value={month.value}>
                              {month.text ?? "Select Month"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}

                {selectedDuration === "quarterly" && (
                  <>
                    <Typography>{t("Quarter")}:</Typography>
                    <Box className="DropdownFieldScss" sx={{ width: 100 }}>
                      <FormControl variant="standard" fullWidth>
                        <Select
                          labelId="quarter-select-label"
                          id="quarter-select"
                          value={selectedQuarter}
                          onChange={handleQuarterChange}
                          displayEmpty
                        >
                          {quarters.map((quarter) => (
                            <MenuItem key={quarter.value} value={quarter.value}>
                              {quarter.text ?? "Select Quarter"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        className="TableConnect board_wrapper board-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="board-wrap gridTable">
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {columns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            {t("No Budgets Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedData?.map((row: any) => (
                          <TableRow
                            key={row.budgetId}
                            // onClick={() => handleRowClick(row)}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            {" "}
                            <TableCell
                              sx={{ height: "64px", padding: "4px 8px" }}
                            >
                              {editName &&
                              selectedRow?.budgetId === row?.budgetId ? (
                                <EditBudgetName
                                  setUpdateNameProgress={setUpdateNameProgress}
                                  updateNameProgress={updateNameProgress}
                                  name={row?.name}
                                  budgetId={row?.budgetId}
                                  showToast={showToast}
                                  updateBudgetHandler={UpdateBudget}
                                  setEditName={setEditName}
                                  monthYear={`${selectedMonth}${selectedYear}`}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    minHeight: "28px",
                                  }}
                                >
                                  <span className="edit-document-text">
                                    {row?.name}
                                  </span>
                                  {!editName && isBoardRole && (
                                    <Button
                                      sx={{ justifyContent: "end" }}
                                      className="support-edit-icon"
                                      onClick={(e) => {
                                        setEditName(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      <ModeEditIcon
                                        fontSize="small"
                                        sx={{
                                          color: `${theme.palette.activeColor}`,
                                        }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ height: "64px", padding: "4px 8px" }}
                            >
                              {editAllocatedAmount &&
                              selectedRow?.budgetId === row?.budgetId ? (
                                <EditBudgetAllocated
                                  setUpdateAllocatedProgress={
                                    setUpdateAllocatedAmountProgress
                                  }
                                  updateAllocatedProgress={
                                    updateAllocatedAmountProgress
                                  }
                                  allocatedAmount={row?.allocatedAmount}
                                  budgetId={row?.budgetId}
                                  showToast={showToast}
                                  updateBudgetHandler={UpdateBudget}
                                  editAllocatedHandler={
                                    editAllocatedAmountHandler
                                  }
                                  monthYear={`${selectedMonth}${selectedYear}`}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    minHeight: "28px",
                                  }}
                                >
                                  <span className="edit-document-text">
                                    {row.allocatedAmount &&
                                    !isNaN(row.allocatedAmount)
                                      ? formatter.format(row.allocatedAmount)
                                      : formatter.format(0)}
                                  </span>
                                  {!editName &&
                                    isBoardRole &&
                                    selectedDuration === "monthly" && (
                                      <Button
                                        sx={{ justifyContent: "end" }}
                                        className="support-edit-icon"
                                        onClick={(e) => {
                                          setEditAllocatedAmount(true);
                                          setSelectedRow(row);
                                        }}
                                      >
                                        <ModeEditIcon
                                          fontSize="small"
                                          sx={{
                                            color: `${theme.palette.activeColor}`,
                                          }}
                                        />
                                      </Button>
                                    )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    row.actualAmount >= 0 ? "green" : "red",
                                }}
                              >
                                {row.actualAmount && !isNaN(row.actualAmount)
                                  ? formatter.format(row.actualAmount)
                                  : formatter.format(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: row.difference >= 0 ? "green" : "red",
                                }}
                              >
                                {row.difference && !isNaN(row.difference)
                                  ? formatter.format(row.difference)
                                  : formatter.format(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                                maxWidth: "100px",
                              }}
                            >
                              {editAsset &&
                              selectedRow?.budgetId === row?.budgetId ? (
                                <EditBudgetAsset
                                  setUpdateAssetProgress={
                                    setUpdateAssetProgress
                                  }
                                  updateAssetProgress={updateAssetProgress}
                                  assetId={row?.asset}
                                  budgetId={row?.budgetId}
                                  showToast={showToast}
                                  updateBudgetHandler={UpdateBudget}
                                  editAssetHandler={editAssetHandler}
                                  assets={allAssets}
                                  associationId={
                                    currentAssociation?.associationId
                                  }
                                  setEditAsset={setEditAsset}
                                  monthYear={`${selectedMonth}${selectedYear}`}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    minHeight: "28px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        row?.asset?.priority === "Low"
                                          ? "green"
                                          : row?.asset?.priority === "High"
                                          ? "red"
                                          : theme.palette.text.primary,
                                    }}
                                  >
                                    {row?.asset?.title
                                      ? row?.asset?.title
                                      : "---"}
                                  </Typography>
                                  {!editAsset && isBoardRole && (
                                    <Button
                                      sx={{ justifyContent: "end" }}
                                      className="support-edit-icon"
                                      onClick={(e) => {
                                        setEditAsset(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      <ModeEditIcon
                                        fontSize="small"
                                        sx={{
                                          color: `${theme.palette.activeColor}`,
                                        }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "60px",
                                padding: "4px 8px",
                              }}
                            >
                              {/* {isBoardRole && ( */}
                              <Button
                                // variant="outlined"
                                // className="btn btn-theme"
                                sx={{
                                  color: `${theme.palette.text.primary}`,
                                  // top: 0,
                                  // right: 0,
                                  // maxWidth: "120px",
                                  // width: "auto",
                                }}
                                onClick={() => {
                                  openRemoveModal(row?.budgetId);
                                }}
                                disabled={!isBoardRole}
                              >
                                <DeleteIcon />
                              </Button>
                              {/* )} */}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredBudgets?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
          <BudgetDrawer
            selectedRow={selectedRow}
            rightDrawer={rightDrawer}
            toggleDrawer={toggleDrawer}
            setRightDrawer={setRightDrawer}
            editName={editName}
            updateAllocatedAmountHandler={updateAllocatedAmountHandler}
            updateAllocatedAmountProgress={updateAllocatedAmountProgress}
            editAllocatedAmount={editAllocatedAmount}
            editAllocatedAmountHandler={editAllocatedAmountHandler}
            updateNameHandler={updateNameHandler}
            updateNameProgress={updateNameProgress}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
          ></BudgetDrawer>
        </Box>
      </Box>
    </div>
  );
};

export default Budgets;
