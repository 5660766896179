export const getMonthName = (date: any) => {
  const dateObj = new Date(date); // 2009-11-10
  const month = dateObj.toLocaleString("default", { month: "short" });
  return month;
};

export const getDayName = (date: any) => {
  const dateObj = new Date(date); // 2009-11-10
  const day = dateObj.toLocaleString("default", { day: "numeric" });
  return day;
};

export const getYear = (date: any) => {
  const dateObj = new Date(date); // 2009-11-10
  const day = dateObj.toLocaleString("default", { year: "numeric" });
  return day;
};

export const generateFullDate = (date: any) => {
  const day = getDayName(date);
  const month = getMonthName(date);
  const year = getYear(date);
  return `${day}-${month}-${year}`;
};
