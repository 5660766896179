import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: "light",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      if (state.darkMode === "light") {
        localStorage.setItem("zayukTheme", "dark");
        state.darkMode = "dark";
      } else {
        localStorage.setItem("zayukTheme", "light");
        state.darkMode = "light";
      }
    },
    updateTheme: (state) => {
      let theme: any = localStorage.getItem("zayukTheme");
      state.darkMode = theme;
    },
  },
});

export const { toggleTheme, updateTheme } = themeSlice.actions;

export default themeSlice.reducer;
