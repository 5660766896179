import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import "../../Settings/setting.scss";
import "./association.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { stripeSafeUpdateSession } from "../../../Redux/Feature/Association-slice";
import {
  setCurrentAssociation,
  UpdateAssociation,
} from "../../../Redux/Feature/Auth-slice";
import Toast from "../../../Component/Common/Toast/Toast";
import AssociationSkeleton from "../../../Component/Skeletons/AssociationSkeleton";
import { MEMBER_TYPE, PRICE_TIERS } from "../../../Utils/utils";
import AssociationDetailField from "../../../Component/Common/DetailFields/AssociationDetailField";
import AddressDetailField from "../../../Component/Common/DetailFields/AddressDetailField";
import CreateSubscriptionModal from "../../../Component/Modals/AssociationModals/Details/CreateSubscriptionModal";
import { StripeAssociationConnectSession } from "../../../Redux/Feature/Finance-slice";
import { useTranslation } from "react-i18next";
export const capitalizeFirstLetter = (string: string | undefined | null) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Association = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false); // New loading state for subscription change

  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);

  const getSubscriptionType = (
    subscriptionType: string | undefined
  ): string => {
    if (subscriptionType) {
      if (subscriptionType.startsWith(PRICE_TIERS.PREMIUM)) return t("Premium");
      if (subscriptionType.startsWith(PRICE_TIERS.ELITE)) return t("Elite");
    }
    return t("Standard");
  };
  const mapRef: any = useRef(null);
  const autocomplete: any = useRef(null);
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const openCreateSubscriptionModal = () => {
    setSubscriptionModal(true);
  };
  const closeAddSubscriptionModal = async () => {
    setSubscriptionModal(false);
  };
  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const [associationPageData, setAssociationPageData] = useState<any>({
    title: currentAssociation?.title,
    unitSubscriptionCount: currentAssociation?.unitSubscriptionCount,
    subscriptionId: currentAssociation?.subscriptionId,
    email: currentAssociation?.email,
    accounts: currentAssociation?.accounts?.data,
    address: currentAssociation?.address,
    subscriptionType: currentAssociation?.subscriptionType,
    subscriptionStatus: currentAssociation?.subscriptionStatus,
    stripeConnectId: currentAssociation?.stripeConnectId,
  });

  const [editAddress, setEditAddress] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAssociationPageData({
      ...associationPageData,
      [name]: value,
    });
  };
  useEffect(() => {
    setAssociationPageData({
      title: currentAssociation?.title,
      unitSubscriptionCount: currentAssociation?.unitSubscriptionCount,
      subscriptionId: currentAssociation?.subscriptionId,
      accounts: currentAssociation?.accounts?.data,
      email: currentAssociation?.email,
      address: currentAssociation?.address,
      detailsSubmitted: currentAssociation?.detailsSubmitted,
      subscriptionType: currentAssociation?.subscriptionType,
      subscriptionStatus: currentAssociation?.subscriptionStatus,
      stripeConnectId: currentAssociation?.stripeConnectId,
    });
  }, [currentAssociation]);

  const setUpdatedAssociationData = () => {
    setAssociationPageData({
      title: currentAssociation?.title,
      unitSubscriptionCount: currentAssociation?.unitSubscriptionCount,
      subscriptionId: currentAssociation?.subscriptionId,
      accounts: currentAssociation?.accounts?.data,
      address: currentAssociation?.address,
      email: currentAssociation?.email,
      detailsSubmitted: currentAssociation?.detailsSubmitted,
      subscriptionType: currentAssociation?.subscriptionType,
      subscriptionStatus: currentAssociation?.subscriptionStatus,
      stripeConnectId: currentAssociation?.stripeConnectId,
    });
  };
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleSubscriptionChange = async () => {
    if (
      associationPageData?.subscriptionType &&
      (associationPageData?.subscriptionType?.startsWith(PRICE_TIERS.ELITE) ||
        associationPageData?.subscriptionType?.startsWith(PRICE_TIERS.PREMIUM))
    ) {
      const payload = {
        url: window.location.href,
        unitSubscriptionCount: associationPageData?.unitSubscriptionCount,
        associationId: currentAssociation?.associationId,
        subscriptionType: associationPageData?.subscriptionType,
      };
      setSubscriptionLoading(true);
      dispatch(stripeSafeUpdateSession(payload)).then((res: any) => {
        if (res?.payload?.data?.url) {
          setSubscriptionLoading(false);
          window.location.href = res?.payload?.data?.url;
        }
        setSubscriptionLoading(false);
      });
    } else {
      openCreateSubscriptionModal();
    }
  };

  const handleSaveEmail = async () => {
    try {
      setEditEmail(false);
      if (userData) {
        const payload = {
          email: associationPageData?.email,
          associationId: currentAssociation?.associationId,
        };
        const response = await dispatch(UpdateAssociation(payload));

        if (response?.payload?.statusCode === 200) {
          showToast(t("Association Updated"), "success");

          dispatch(
            setCurrentAssociation({
              ...currentAssociation,
              email: associationPageData?.email,
            })
          );
        } else if (response.error) {
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {}
  };

  const handleNewTitle = async () => {
    try {
      setEditTitle(false);
      if (userData) {
        const payload = {
          title: associationPageData?.title,
          associationId: currentAssociation?.associationId,
        };
        const response = await dispatch(UpdateAssociation(payload));

        if (response?.payload?.statusCode === 200) {
          showToast(t("Association Updated"), "success");

          dispatch(
            setCurrentAssociation({
              ...currentAssociation,
              title: associationPageData?.title,
            })
          );
        } else if (response.error) {
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {}
  };

  const handlePlaceChanged = async () => {
    if (autocomplete.current) {
      const place = autocomplete.current.getPlace();
      if (!place || !place?.geometry || !place?.geometry?.location) {
        showToast(
          t("Please select an address from the dropdown list"),
          "error"
        );
        return;
      }
      const addressData: any = {};
      addressData.address = place?.formatted_address;
      var bounds = place.geometry.viewport.toJSON();

      addressData.latitude = (bounds.north + bounds.south) / 2;
      addressData.longitude = (bounds.east + bounds.west) / 2;
      addressData.latitudeDelta = bounds.north - bounds.south;
      addressData.longitudeDelta = bounds.east - bounds.west;

      if (mapRef?.current) {
        mapRef?.current?.panTo(place?.geometry?.location);
      }
      place.address_components.forEach((component: any) => {
        const types = component.types;

        types.forEach((type: any) => {
          switch (type) {
            case "street_number": {
              addressData.streetNumber = `${component.long_name}`;
              break;
            }
            case "route": {
              addressData.streetName = component.short_name;
              break;
            }
            case "locality":
              addressData.city = component.long_name;
              break;
            case "administrative_area_level_1":
              addressData.state = component.long_name;
              break;
            case "country":
              addressData.country = component.long_name;
              break;
            case "postal_code":
              addressData.postalCode = component.long_name;
              break;
            default:
              break;
          }
        });
      });
      const payload = {
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        postalCode: addressData.postalCode,
        country: addressData.country,
        associationId: currentAssociation?.associationId,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };

      setEditAddress(!editAddress);
      const response = await dispatch(UpdateAssociation(payload));

      if (response?.payload?.statusCode === 200) {
        showToast(t("Association Updated"), "success");
        setAssociationPageData({
          ...associationPageData,
          address: addressData.address,
        });
      } else {
        showToast(t("Error"), "error");
      }
    }
  };
  const subscriptionStatus = capitalizeFirstLetter(
    associationPageData?.subscriptionStatus
  );

  const handleStripeFinancialConnectionsSession = async () => {
    await dispatch(
      StripeAssociationConnectSession(currentAssociation?.associationId)
    ).then((response: any) => {
      if (response?.payload?.data?.accountLink?.url) {
        window.location.href = response?.payload?.data?.accountLink?.url;
      }
      if (response?.payload?.statusCode !== 200) setLoading(false);
    });
  };
  if (loading) {
    return <AssociationSkeleton />;
  }
  return (
    <>
      <CreateSubscriptionModal
        isOpen={subscriptionModal}
        onClose={closeAddSubscriptionModal}
        showToast={showToast}
        associationId={currentAssociation?.associationId}
      />
      <div className="profile">
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />
        <Typography
          className="association__title page_title"
          sx={{ color: `${theme.palette.activeColor}` }}
        >
          {t("Association Details")}
        </Typography>

        {associationPageData?.stripeConnectId &&
          isBoardRole &&
          associationPageData?.subscriptionType &&
          associationPageData?.subscriptionType.startsWith(PRICE_TIERS.ELITE) &&
          (associationPageData?.detailsSubmitted ? (
            <Button
              variant="contained"
              color="primary"
              href={
                "https://dashboard.stripe.com/" +
                associationPageData.stripeConnectId +
                "/dashboard"
              }
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textTransform: "none" }}
            >
              {t("Payment Dashboard")}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStripeFinancialConnectionsSession}
              sx={{ textTransform: "none" }}
            >
              {t("Connect Stripe")}
            </Button>
          ))}

        <Box className="association__container">
          <Box className="association__form profile__form">
            <AssociationDetailField
              isEditField={editTitle}
              fieldLabel={t("Association Title")}
              fieldValue={associationPageData?.title}
              fieldName={"title"}
              placeholder={t("Edit Name")}
              handleChange={handleChange}
              setisEditField={setEditTitle}
              handleSaveField={handleNewTitle}
              isBoardRole={isBoardRole}
              resetUserDataOnCancel={setUpdatedAssociationData}
            />
            <AddressDetailField
              isEditField={editAddress}
              fieldLabel={t("Address")}
              fieldValue={associationPageData?.address}
              setisEditField={setEditAddress}
              handleSaveField={handlePlaceChanged}
              isBoardRole={isBoardRole}
              autocomplete={autocomplete}
            />

            {/* Code for Email */}
            <AssociationDetailField
              isEditField={editEmail}
              fieldLabel={"Association Email"}
              fieldValue={associationPageData?.email}
              fieldName={"email"}
              placeholder={"Edit Email"}
              handleChange={handleChange}
              setisEditField={setEditEmail}
              handleSaveField={handleSaveEmail}
              isBoardRole={isBoardRole}
              resetUserDataOnCancel={setUpdatedAssociationData}
            />
            {isBoardRole && (
              <Box className="formGroup">
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Typography
                    className="formlabel"
                    sx={{ color: "text.primary", mr: "10px" }}
                  >
                    {t("Subscription")}:
                  </Typography>
                </Box>
                <Typography
                  className="formlabel"
                  sx={{
                    color: "text.primary",
                    position: "relative",
                    marginRight: "10px",
                  }}
                >
                  {t(subscriptionStatus)}
                </Typography>
                <Typography
                  className="formlabel"
                  sx={{
                    color: "text.primary",
                    position: "relative",
                    marginRight: "10px",
                  }}
                >
                  {getSubscriptionType(associationPageData?.subscriptionType)}
                </Typography>

                <Box
                  sx={{ display: "flex", justifyContent: "end", width: "100%" }}
                >
                  <Button
                    variant="contained"
                    className="btn"
                    onClick={handleSubscriptionChange}
                    sx={{
                      width: "auto !important",
                      paddingX: "16px !important",
                    }}
                    disabled={subscriptionLoading}
                  >
                    {subscriptionLoading ? (
                      <CircularProgress
                        size={24}
                        sx={{ color: `${theme.palette.textWhite}` }}
                      />
                    ) : (
                      t("Manage")
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Association;
