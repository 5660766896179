import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MeetingService from "../Service/Meeting-service";
import { stat } from "fs";

export const getMeetingSummaries: any = createAsyncThunk(
  "MeetingSummaries/get",
  async (obj: string, thunkAPI) => {
    const data = await MeetingService.getMeetingSummaries(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getMeeting: any = createAsyncThunk(
  "Meeting/get",
  async (obj: string, thunkAPI) => {
    const data = await MeetingService.getMeeting(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getMeetings: any = createAsyncThunk(
  "Meetings/get",
  async (obj: string, thunkAPI) => {
    const data = await MeetingService.getMeetings(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const NotifyMeeting: any = createAsyncThunk(
  "NotifyMeeting/get",
  async (obj: string, thunkAPI) => {
    const data = await MeetingService.NotifyMeeting(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateMeeting: any = createAsyncThunk(
  "Meeting/put",
  async (reqPayload, thunkAPI) => {
    const data = await MeetingService.updateMeeting(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createMeeting: any = createAsyncThunk(
  "Meeting/post",
  async (reqPayload, thunkAPI) => {
    const data = await MeetingService.createMeeting(reqPayload);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const deleteMeeting: any = createAsyncThunk(
  "Meeting/delete",
  async (obj: any, thunkAPI) => {
    const data = await MeetingService.deleteMeeting(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
interface MeetingSlice {
  isFetching: boolean;
  error: string;
  successMessage: string;
  isError: boolean;
  isSuccess: boolean;
  meetings: any[];
  meetingSummaries: any[];
}

const initialState: MeetingSlice = {
  isFetching: false,
  error: "",
  successMessage: "",
  isError: false,
  isSuccess: false,
  meetings: [],
  meetingSummaries: [],
};

const MeetingSlice = createSlice({
  name: "Meeting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMeetingSummaries.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMeetingSummaries.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.meetingSummaries = action?.payload?.data;
      })
      .addCase(getMeetingSummaries.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(updateMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateMeeting.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const meetingIndex = state.meetings.findIndex(
          (meeting) => meeting.id === action?.payload?.data?.meetingId
        );
        state.meetings[meetingIndex] = {
          ...state.meetings[meetingIndex],
          ...action?.payload?.data,
        };
      })
      .addCase(updateMeeting.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const meetingIndex = state.meetings.findIndex(
          (meeting) => meeting.id === action.payload.data.id
        );

        if (meetingIndex !== -1) {
          state.meetings[meetingIndex] = {
            ...state.meetings[meetingIndex],
            ...action.payload.data,
          };
        } else {
          state.meetings.push(action.payload.data);
        }
      })
      .addCase(getMeeting.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getMeetings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getMeetings.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.meetings = action?.payload?.data;
      })
      .addCase(getMeetings.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(NotifyMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(NotifyMeeting.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(NotifyMeeting.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(createMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createMeeting.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        let meeting = action?.payload.data;

        const [hourString, minute, second] =
          action?.payload.data?.startTime.split(":");
        let hour = parseInt(hourString, 10);
        hour = hour % 12 || 12;
        const formattedHour = hour.toString().padStart(2, "0");
        meeting.startTime = `${formattedHour}:${minute}`;

        state.meetings = [...state.meetings, meeting];

        state.error = "";
      })
      .addCase(createMeeting.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(deleteMeeting.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteMeeting.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.meetings = state.meetings.filter(
          (meeting) => meeting?.id !== Number(action?.payload?.data?.meetingId)
        );
      })
      .addCase(deleteMeeting.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default MeetingSlice;
