import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box className="mainFooter">
      <div
        style={{
          maxWidth: "350px",
          margin: "0 auto",
        }}
      >
        <Typography className="mainFooter__text" sx={{ width: "320px" }}>
          {t("Copyright ©2024 - Zayuk Safe. | All rights reserved")}
        </Typography>
      </div>
    </Box>
  );
};

export default Footer;
