import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Typography,
  FormLabel,
  Modal,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import SpinnerLoader from "../../../Common/SpinnerLoader/SpinnerLoader";
import { changeUnitPerson } from "../../../../Redux/Feature/Asset-slice";
import EditUnitPersonConfirmModal from "./EditPersonConfirmModal";
import { MEMBER_TYPE } from "../../../../Utils/utils";
import { RootState } from "../../../../Redux/Store";
import { useTranslation } from "react-i18next";

interface UnitPersonField {
  email: string;
  name: string;
  role: string;
}

const EditUnitPersonModal = (props: any) => {
  const {
    editPerson,
    showPersonModal,
    setShowPersonModal,
    associationId,
    showToast,
  } = props;
  const { unitPersons, assetId, role } = editPerson;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = React.useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const [, setNewOwnerLimit] = useState<number>(2);
  const [, setNewTenantLimit] = useState<number>(2);
  const [, setOwnerCount] = useState<number>(0);
  const [, setTenantCount] = useState<number>(0);
  const [predefinedEmails, setPredefinedEmails] = useState<
    Array<string | null>
  >([]);
  const [showEditPersonConfirmModal, setShowEditPersonConfirmModal] =
    useState<boolean>(false);
  const [personArray, setPersonArray] = useState<Array<UnitPersonField>>([]);

  const isFetching = useSelector((state: RootState) => state.Asset.isFetching);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: `${theme.palette.customBackground}`,
    border: `${theme.palette.modelBorderColor}`,
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    backdropFilter: "blur(4px)",
  };

  useEffect(() => {
    if (unitPersons.length > 0) {
      const ownerCount = unitPersons.filter(
        (person: any) =>
          person.role === MEMBER_TYPE.GENERAL ||
          person.role === MEMBER_TYPE.BOARD
      ).length;
      const tenantCount = unitPersons.filter(
        (person: any) => person.role === MEMBER_TYPE.TENANT
      ).length;
      setOwnerCount(ownerCount);
      setTenantCount(tenantCount);
      setNewOwnerLimit(2 - ownerCount);
      setNewTenantLimit(2 - tenantCount);
      const allEmail = unitPersons.map(
        (person: UnitPersonField) => person.email
      );
      setPredefinedEmails(allEmail);
    }
  }, [assetId]);

  const validationSchema = Yup.object({
    persons: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t("Name required")),
          email: Yup.string()
            .required(t("Email required"))
            .email(t("Enter valid email"))
            .test(
              "unique-email",
              t("Email already exists"),
              (value) => !predefinedEmails.includes(value)
            ),
        })
      )
      .test(
        "max-owners-and-tenants",
        t("You can only have up to 2 owners and 2 tenants"),
        (person) => {
          if (!person) return true;
          const ownerCount = person.filter(
            (person: any) =>
              person.role === MEMBER_TYPE.GENERAL ||
              person.role === MEMBER_TYPE.BOARD
          ).length;
          const tenantCount = person.filter(
            (person: any) => person.role === MEMBER_TYPE.TENANT
          ).length;
          return ownerCount <= 2 || tenantCount <= 2;
        }
      ),
  });
  const getTheFinalObject = async (oldArray: any, newArray: any) => {
    const newPerson = newArray.filter((person1: UnitPersonField) => {
      const result = oldArray.findIndex(
        (person2: any) => person2.email === person1.email
      );
      if (result === -1) return person1;
      return;
    });
    const requestObject = {
      assetId,
      person: newPerson[0],
      change: "add",
    };
    return requestObject;
  };

  const updatePersonValueHandler = async () => {
    try {
      const newPerson = personArray.map((person: UnitPersonField) => ({
        name: person.name,
        email: person.email,
        role: person.role,
      }));
      const oldPerson = await unitPersons.map((person: UnitPersonField) => ({
        name: person.name,
        email: person.email,
        role: person.role,
      }));

      let requestObject: any = await getTheFinalObject(oldPerson, newPerson);

      for (const key in requestObject) {
        if (requestObject[key].length === 0) delete requestObject[key];
      }
      const response = await dispatch(changeUnitPerson(requestObject));
      if (response?.payload?.statusCode === 200) {
        showToast(t("The Unit's new Person has been updated"), "success");
      } else {
        showToast(t("Error while updating"), "error");
      }

      setShowPersonModal(false);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        setServerError(error.response.status);
        if (err instanceof AxiosError) {
          if (err?.response?.data?.error) {
            setServerErrorMsg(err?.response?.data?.error);
          }
        }
      }
    }
  };

  const renderServerError = (errorCode: Number) => {
    switch (errorCode) {
      case 500:
        return <p style={{ color: "#DE1344" }}>Server Error</p>;
      case 400:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 401:
        return <p style={{ color: "#DE1344" }}>{t("Unauthorized")}</p>;
      case 403:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      case 404:
        return <p style={{ color: "#DE1344" }}>{serverErrorMsg}</p>;
      default:
        return "";
    }
  };

  const onHandleSubmit = async (array: any) => {
    setShowEditPersonConfirmModal(true);
    setPersonArray(array);
  };

  const outlineFieldStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--mui-palette-text-primary)", // Default border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--mui-palette-text-primary)", // Border color on focus
        borderWidth: "0.713px solid",
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      background:
        theme.palette.mode === "light"
          ? "#fff"
          : `${theme.palette.inputBackground}`,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.modelInputBorderColor}`,
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.modelInputBorderColor}`,
        },
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.modelInputBorderColor}`,
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.mode === "light" ? "#000" : "#fff",
      "&.Mui-focused": {
        color: theme.palette.mode === "light" ? "#000" : "#fff",
      },
    },
  };

  const inputStyle = {
    padding: "14px 14px",
    borderRadius: "15px",
    color: theme.palette.mode === "light" ? "#000" : "#fff",
  };

  const closeEditPersonConfirmModal = () => {
    setShowEditPersonConfirmModal(false);
  };

  return (
    <Modal
      open={showPersonModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="edit-person-unit"
    >
      <>
        <SpinnerLoader loading={loading} />
        <EditUnitPersonConfirmModal
          isOpen={showEditPersonConfirmModal}
          onClose={closeEditPersonConfirmModal}
          onConfirm={updatePersonValueHandler}
          emails={personArray.map((person) => person?.email)}
          isFetching={isFetching}
        />
        <Box sx={{ ...style, pt: "10px", px: "15px" }}>
          <Box
            sx={{}}
            justifyContent={"right"}
            display="flex"
            onClick={() => setShowPersonModal(false)}
          >
            <CancelIcon
              sx={{
                color: theme.palette.mode === "light" ? "#b7b7b7" : "#fff",
              }}
            />
          </Box>
          <Typography
            sx={{
              color: theme.palette.mode === "light" ? "000" : "#FFF",
              textTransform: "capitalize",
            }}
            variant="h6"
            component="h6"
          >
            {t("Add Person to Unit")}
          </Typography>
          <Formik
            initialValues={{
              persons: [{ email: "", name: "", role: role }],
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onHandleSubmit(values.persons)}
            render={({ values, touched }) => (
              <Form>
                <FieldArray
                  name="persons"
                  render={() => {
                    const persons = values.persons;
                    return (
                      <>
                        {persons && persons.length > 0
                          ? persons.map((person: any, index: any) => (
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                                key={index}
                              >
                                {/* <FormLabel
                                  sx={{
                                    marginLeft: "0.71em",
                                    marginTop: "-0.71em",
                                    paddingLeft: "0.44em",
                                    paddingRight: "0.44em",
                                    zIndex: 2,
                                    backgroundColor: "#20c1f5",
                                    position: "absolute",
                                    fontSize: "0.75em",
                                    width: "auto",
                                    color: "#FFF",
                                  }}
                                >
                                  {person.name}
                                </FormLabel> */}
                                <Box
                                  sx={{
                                    paddingTop: "4px",
                                    paddingBottom: "24px",
                                  }}
                                >
                                  <Grid container sx={{ alignItems: "center" }}>
                                    <Grid item xs={12}>
                                      <Box className="formGroup unitInputWrapper">
                                        <Field
                                          placeholder="Name"
                                          name={`persons.${index}.name`}
                                        >
                                          {({
                                            field,
                                            form,
                                            meta,
                                          }: {
                                            field: any;
                                            form: any;
                                            meta: any;
                                          }) => (
                                            <Box className="formGroup">
                                              <TextField
                                                type="text"
                                                {...field}
                                                {...props}
                                                fullWidth
                                                label={t("First & Last Name")}
                                                sx={outlineFieldStyle}
                                                inputProps={{
                                                  style: inputStyle,
                                                }}
                                                variant="outlined"
                                              />
                                              {meta.touched && meta.error && (
                                                <Typography className="error-msg">
                                                  {meta.error}
                                                </Typography>
                                              )}
                                            </Box>
                                          )}
                                        </Field>
                                      </Box>
                                      <Box
                                        className="formGroup unitInputWrapper"
                                        sx={{ marginBottom: "0 !important" }}
                                      >
                                        <Field
                                          placeholder="Email"
                                          name={`persons.${index}.email`}
                                        >
                                          {({
                                            field,
                                            form,
                                            meta,
                                          }: {
                                            field: any;
                                            form: any;
                                            meta: any;
                                          }) => (
                                            <Box
                                              className="formGroup"
                                              sx={{
                                                marginBottom: "0 !important",
                                              }}
                                            >
                                              <TextField
                                                type="text"
                                                {...field}
                                                {...props}
                                                fullWidth
                                                label={t("Email")}
                                                sx={outlineFieldStyle}
                                                inputProps={{
                                                  style: inputStyle,
                                                }}
                                              />
                                              {meta.touched && meta.error && (
                                                <Typography className="error-msg">
                                                  {meta.error}
                                                </Typography>
                                              )}
                                            </Box>
                                          )}
                                        </Field>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Typography>
                            ))
                          : null}
                        {renderServerError(serverError)}
                        <Box
                          sx={{ p: 2 }}
                          justifyContent={"center"}
                          display="flex"
                        >
                          <Button
                            variant="contained"
                            className="button"
                            sx={{
                              background: "#256098",
                              color: "#fff",
                              border: "1px solid #256098",
                              fontSize: "20px",
                              fontWeight: "400",
                              padding: "2px 16px",
                              borderRadius: "8px",
                              textTransform: " capitalize",
                              boxShadow: "unset",
                              "&:hover": {
                                background: "#256098",
                                boxShadow: "unset",
                              },
                            }}
                            type="submit"
                          >
                            {t("Submit")}
                          </Button>
                        </Box>
                      </>
                    );
                  }}
                />
              </Form>
            )}
          />
        </Box>
      </>
    </Modal>
  );
};

export default EditUnitPersonModal;
