import http from "../api";

const putTickets = async (mapObj: any) => {
  try {
    const response = await http.put(`/update-map-tickets`, mapObj);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const putAssociations = async (mapObj: any) => {
  try {
    const response = await http.put(`/update-map-associations`, mapObj);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const uploadAssociationProfilePicture = async (obj: any) => {
  try {
    const response = await http.post(
      `/upload-association-profile-picture`,
      obj
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const updateAssociationDetails = async (mapDetails: any) => {
  try {
    const response = await http.put(`/update-association-details`, mapDetails);
    return response?.data;
  } catch (e) {
    return e;
  }
};
const MapServices = {
  putAssociations,
  updateAssociationDetails,
  uploadAssociationProfilePicture,
  putTickets,
};

export default MapServices;
