import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../Service/Auth-service";

export const personAssociations: any = createAsyncThunk(
  "user/personAssociations",
  async ({ personId, email }: { personId: any; email: any }, thunkAPI) => {
    const data = await authService.personAssociations(personId, email);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const personDetails: any = createAsyncThunk(
  "user/getUserDetails",
  async (userId: any, thunkAPI) => {
    const data = await authService.personDetails(userId);
    if (data.statusCode === 200) {
      return data.data;
    } else {
      throw thunkAPI.rejectWithValue;
    }
  }
);

export const changeUserDefaultAssociation: any = createAsyncThunk(
  "user/changeUserDefaultAssociation",
  async (associationIdObj, thunkAPI) => {
    const data = await authService.changeUserDefaultAssociation(
      associationIdObj
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const removeProfilePicture: any = createAsyncThunk(
  "user/removeProfilePicture",
  async (id: any, thunkAPI) => {
    const data = await authService.removeProfilePicture({ id });
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updatePersonSubscription: any = createAsyncThunk(
  "UpdatePersonSubscription/put",
  async (obj, thunkAPI) => {
    const data = await authService.UpdatePerson(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdatePersonName: any = createAsyncThunk(
  "UpdatePersonName/put",
  async (obj, thunkAPI) => {
    const data = await authService.UpdatePerson(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdatePersonEmail: any = createAsyncThunk(
  "UpdatePersonEmail/put",
  async (obj, thunkAPI) => {
    const data = await authService.UpdatePerson(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdatePerson: any = createAsyncThunk(
  "UpdatePerson/put",
  async (obj, thunkAPI) => {
    const data = await authService.UpdatePerson(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const authDeleteAccount: any = createAsyncThunk(
  "authDeleteAccount/delete",
  async (personId: string, thunkAPI) => {
    const data = await authService.authDeleteAccount(personId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const GetAllRules: any = createAsyncThunk(
  "GetAllRules/get",
  async (id: string, thunkAPI) => {
    const data = await authService.getAllRules(id);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAssetsByPerson: any = createAsyncThunk(
  "getAssetsByPerson/get",
  async (associationId: any, thunkAPI) => {
    const data = await authService.getAssetsByPerson(associationId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const UpdateAssociation: any = createAsyncThunk(
  "UpdateAssociation/post",
  async (associationObject, thunkAPI) => {
    const data = await authService.UpdateAssociation(associationObject);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAssociation: any = createAsyncThunk(
  "GetAssociation/get",
  async (associationId, thunkAPI) => {
    const data = await authService.getAssociation(associationId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createAssociation: any = createAsyncThunk(
  "CreateAssociation/post",
  async (associationObject, thunkAPI) => {
    const data = await authService.createAssociation(associationObject);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const stripeResidentBillingSession: any = createAsyncThunk(
  "stripeResidentBillingSession/post",
  async (subscriptionObject, thunkAPI) => {
    const data = await authService.stripeResidentBillingSession(
      subscriptionObject
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const uploadVerificationPicture: any = createAsyncThunk(
  "uploadVerificationPicture/put",
  async (key, thunkAPI) => {
    try {
      const data = await authService.uploadVerificationPicture(key);
      if (data.statusCode === 200) {
        return data;
      } else {
        throw new Error(data?.response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeVerificationPicture: any = createAsyncThunk(
  "user/removeVerificationPicture",
  async (id: any, thunkAPI) => {
    const data = await authService.removeVerificationPicture({ id });
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

const authSlice = createSlice({
  name: "Auth",
  initialState: {
    user: {
      email: "",
      role: "",
      id: "",
      name: "",
      profileImageUrl: "",
      verificationDocument: false,
      dateOfBirth: "",
      darkMode: false,
      subscribed: true,
      phone: "",
      emergencyContactName: "",
      emergencyContactPhone: "",
      emergencyContactEmail: "",
      address: "",
      verifier: false,
      organizationId: "",
      language: "",
    },
    userAssociations: [],
    currentOrganization: {},
    currentAssociation: {
      commonCount: 0,
      subscriptionStatus: "",
      subscriptionType: "",
      detailsSubmitted: false,
      title: "",
      address: "",
      email: "",
      associationId: "",
      role: "",
      lat: 0,
      lng: 0,
      unitSubscriptionCount: 0,
      stripeConnectId: "",
      subscriptionId: "",
      accounts: { data: {} },
    },
    associationId: "",
    isLogin: false,
    isFetching: false,
    error: "",
    successMessage: "",
    isError: false,
    isSuccess: false,
    message: null,
    defaultAssociation: "",
    allRules: [],
    allAssets: [],
  },
  reducers: {
    updateCommonCount: (state, action) => {
      state.currentAssociation.commonCount = action.payload;
    },
    updateUser: (state: any) => {
      state.isLogin = true;
      state.user = JSON.parse(localStorage.getItem("user") || "");
    },
    logoutUser: (state: any) => {
      state.isLogin = false;
      state.user = {
        email: "",
        role: "",
        id: "",
        name: "",
        dateOfBirth: "",
        profileImageUrl: "",
        verificationDocument: false,
        darkMode: false,
        subscribed: true,
        phone: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
        emergencyContactEmail: "",
        address: "",
        language: "",
      };
      localStorage.clear();
      state.userAssociations = [];
      state.currentAssociation = {
        commonCount: 0,
        subscriptionStatus: "",
        subscriptionType: "",
        detailsSubmitted: false,
        title: "",
        address: "",
        email: "",
        associationId: "",
        role: "",
        lat: 0,
        lng: 0,
        unitSubscriptionCount: 0,
        stripeConnectId: "",
        subscriptionId: "",
        accounts: { data: {} },
      };
      state.defaultAssociation = {};
      state.allRules = [];
    },
    setCurrentAssociation: (state, action) => {
      state.currentAssociation = action.payload;
    },

    clearMessage: (state) => {
      state.successMessage = "";
      state.message = null;
      state.error = "";
    },
    updateUserAssociationList: (state, action) => {
      state.userAssociations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssociation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAssociation.fulfilled, (state, action) => {
        state.currentAssociation = {
          ...state.currentAssociation,
          subscriptionStatus: action?.payload?.data?.subscriptionStatus,
          subscriptionType: action?.payload?.data?.subscriptionType,
          detailsSubmitted: action?.payload?.data?.detailsSubmitted,
        };
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(getAssociation.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(createAssociation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createAssociation.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(createAssociation.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(personDetails.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(personDetails.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        const thisUser = {
          address: action?.payload?.address,
          profileImageUrl: action?.payload?.profilePicture,
          verificationDocument: action?.payload?.verificationDocument,
          verificationDocumentType: action?.payload?.verificationDocumentType,
          middleInitial: action?.payload?.middleInitial,
          name: action?.payload?.name,
          email: action?.payload?.email,
          id: action?.payload?.id,
          darkMode: action?.payload?.darkMode,
          subscribed: action?.payload?.subscribed,
          role: action?.payload?.role,
          phone: action?.payload?.phone,
          emergencyContactName: action?.payload?.emergencyContactName,
          emergencyContactPhone: action?.payload?.emergencyContactPhone,
          emergencyContactEmail: action?.payload?.emergencyContactEmail,
          dateOfBirth: action?.payload?.dateOfBirth,
          verifier: action?.payload?.verifier,
          organizationId: action?.payload?.organizationId,
          language: action?.payload?.language,
        };
        localStorage.setItem("user", JSON.stringify(thisUser));
        state.user = thisUser;
        state.isFetching = false;
      })
      .addCase(personDetails.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(uploadVerificationPicture.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(uploadVerificationPicture.fulfilled, (state, action) => {
        const thisUser = {
          ...state.user,
          verificationDocument: action?.payload?.data,
        };
        localStorage.setItem("user", JSON.stringify(thisUser));
        state.user = thisUser;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(uploadVerificationPicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(removeVerificationPicture.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(removeVerificationPicture.fulfilled, (state, action) => {
        const thisUser = {
          ...state.user,
          verificationDocument: false,
        };
        localStorage.setItem("user", JSON.stringify(thisUser));
        state.user = thisUser;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(removeVerificationPicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(personAssociations.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(personAssociations.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        const filteredObject =
          action?.payload?.data?.find(
            (association: any) => association.isDefaultAssociation
          ) || {};

        state.isFetching = false;
        state.userAssociations = action?.payload?.data;
        state.defaultAssociation = filteredObject;
      })
      .addCase(personAssociations.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.message;
      });

    builder
      .addCase(removeProfilePicture.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(removeProfilePicture.fulfilled, (state, action) => {
        let user = JSON.parse(localStorage.getItem("user") || "");
        const updatedUser = { ...user, profileImageUrl: "" };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        state.user = updatedUser;
        state.successMessage = action?.payload.message;
        state.isFetching = false;
      })
      .addCase(removeProfilePicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updatePersonSubscription.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(updatePersonSubscription.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        let user = JSON.parse(localStorage.getItem("user") || "");
        const updatedUser = {
          ...user,
          ...action.payload.data,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        state.user = updatedUser;
        state.isFetching = false;
      })
      .addCase(updatePersonSubscription.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(UpdatePersonName.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(UpdatePersonName.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        let user = JSON.parse(localStorage.getItem("user") || "");
        const updatedUser = {
          ...user,
          ...action.payload.data,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        state.user = updatedUser;
        state.isFetching = false;
      })
      .addCase(UpdatePersonName.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(UpdatePersonEmail.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(UpdatePersonEmail.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        let user = JSON.parse(localStorage.getItem("user") || "");
        const updatedUser = {
          ...user,
          ...action.payload.data,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        state.user = updatedUser;
        state.isFetching = false;
      })
      .addCase(UpdatePersonEmail.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(UpdatePerson.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(UpdatePerson.fulfilled, (state, action) => {
        state.successMessage = action?.payload.message;
        let user = JSON.parse(localStorage.getItem("user") || "");
        const updatedUser = {
          ...user,
          ...action.payload.data,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        state.user = updatedUser;
        state.isFetching = false;
      })
      .addCase(UpdatePerson.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(GetAllRules.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetAllRules.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.allRules = action?.payload?.data;
      })
      .addCase(GetAllRules.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getAssetsByPerson.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAssetsByPerson.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";

        state.allAssets = action?.payload?.data;
      })
      .addCase(getAssetsByPerson.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(authDeleteAccount.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(authDeleteAccount.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(authDeleteAccount.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(UpdateAssociation.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(UpdateAssociation.fulfilled, (state, action) => {
        const updatedData = action?.payload?.data;
        state.successMessage = action?.payload.message;
        state.isFetching = false;
        state.error = "";
        let allUsersAssociation:any = state.userAssociations;
        if (updatedData) {
          const updatedAssociations = allUsersAssociation.map((data:any) => {
            if(data.associationId === updatedData.associationId) data.title = updatedData.title;
            return data;
          });
          state.userAssociations = updatedAssociations;
          state.currentAssociation = {
            ...state.currentAssociation,
            title: updatedData.title || state.currentAssociation.title,
            address: updatedData.address || state.currentAssociation.address,
            email: updatedData.email || state.currentAssociation.email,
          };
        }
      })
      .addCase(UpdateAssociation.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(stripeResidentBillingSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(stripeResidentBillingSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
      })
      .addCase(stripeResidentBillingSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export const {
  updateUser,
  logoutUser,
  clearMessage,
  setCurrentAssociation,
  updateUserAssociationList,
} = authSlice.actions;

export const { updateCommonCount } = authSlice.actions;
export default authSlice;
