import { Box, Button, Typography, TextField, Grid } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const AddressDetailField = (props: any) => {
  const {
    isEditField,
    fieldLabel,
    fieldValue,
    setisEditField,
    handleSaveField,
    isBoardRole,
    autocomplete,
    isNameEdit,
  } = props;

  const { t } = useTranslation();

  return (
    <Box className="formGroup">
      <Grid container spacing={2}>
        <Grid item md={10} lg={10}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid item md={4} lg={4}>
              <Typography
                className="formlabel"
                sx={{ color: "text.primary", mr: "10px" }}
              >
                {t(fieldLabel)}:
              </Typography>
            </Grid>
            <Grid item md={8} lg={8}>
              {!isEditField ? (
                <Typography
                  className="formlabel"
                  sx={{ color: "text.primary" }}
                >
                  {t(fieldValue)}
                </Typography>
              ) : (
                <Box className="formGroup_inner">
                  <div className="input_build_wrapper">
                    <Autocomplete
                      onLoad={(auto) => (autocomplete.current = auto)}
                      onPlaceChanged={handleSaveField}
                    >
                      <TextField
                        type="text"
                        placeholder={t("Enter address")}
                        variant="outlined"
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "primary.main",
                            },
                            "&:hover fieldset": {
                              borderColor: "primary.dark",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "primary.dark",
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "10px",
                          },
                        }}
                      />
                    </Autocomplete>{" "}
                  </div>
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          {isBoardRole || isNameEdit ? (
            <>
              {!isEditField ? (
                <Button
                  sx={{
                    width: "auto !important",
                    paddingX: "16px !important",
                  }}
                  variant="contained"
                  className="btn"
                  onClick={() => setisEditField(!isEditField)}
                >
                  {t("Edit")}
                </Button>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Button
                    sx={{
                      width: "auto !important",
                      paddingX: "16px !important",
                    }}
                    variant="contained"
                    className="btn"
                    onClick={() => setisEditField(!isEditField)}
                  >
                    {t("Cancel")}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressDetailField;
