import "./SpinnerLoader.scss";

const SpinnerLoader = (props: any) => {
  const { loading } = props;
  if (loading) {
    return <div className="loading">Loading&#8230;</div>;
  } else {
    return <></>;
  }
};

export default SpinnerLoader;
