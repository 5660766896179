import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  TextField,
  useTheme,
  Grid,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody,
  Table,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import "./vendors.scss";
import { useDispatch, useSelector } from "react-redux";
import "../../Settings/setting.scss";
import { RootState } from "../../../Redux/Store";
import { Clear, Search } from "@mui/icons-material";
import ProfileImageTemplate from "../../../Component/Common/ProfileImageTemplate/ProfileImageTemplate";
import { getVendors, removeVendor } from "../../../Redux/Feature/Roster-slice";
import { stripeAssociationBillingSession } from "../../../Redux/Feature/Association-slice";
import AddVendorModal from "../../../Component/Modals/AssociationModals/Vendors/AddVendorModal";
import AddManagerModal from "../../../Component/Modals/AssociationModals/Vendors/AddManagerModal";
import RemoveVendorModal from "../../../Component/Modals/AssociationModals/Vendors/RemoveVendorModal";
import AddPaymentModal from "../../../Component/Modals/AssociationModals/Vendors/AddPaymentModal";
import { vendorTypes } from "../../../Utils/assets";
import VendorSkelton from "../../../Component/Skeletons/VendorSkeleton";
import Toast from "../../../Component/Common/Toast/Toast";
import { useTranslation } from "react-i18next";
interface Vendor {
  profilePicture: string;
  name: string;
  email: string;
  phoneNumber: string;
  id: string;
  role: string;
  serviceType: string;
  organization: any;
}

const Vendors = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<string>("");
  const { t } = useTranslation();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isAddManagerModalOpen, setAddManagerModalOpen] = useState(false);
  const [isAddVendorModalOpen, setAddVendorModalOpen] = useState(false);
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";

  const vendorsColumns = [
    {
      id: "profilePicture",
      label: t("Profile Image"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "name",
      label: t("Name"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "type",
      label: t("Type"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "organization",
      label: t("Organization"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "payments",
      label: t("Payments"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "Action",
      label: t("Action"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
  ];

  const filteredColumns = isBoardRole
    ? vendorsColumns
    : vendorsColumns.filter(
        (column) => column.id !== "Action" && column.id !== "payments"
      );

  const AllVendors = useSelector(
    (state: RootState) => state?.Manage?.allVendors
  );
  const AllManagers = useSelector(
    (state: RootState) => state?.Manage?.allManagers
  );

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  useEffect(() => {
    fetchAllVendors();
  }, [currentAssociation?.associationId]);

  const showToast = (message: any, type: "success" | "error") => {
    setToastMessage(message);
    setToastType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleAddManagerModalOpen = () => {
    setAddManagerModalOpen(true);
  };

  const handleAddManagerModalClose = () => {
    setAddManagerModalOpen(false);
  };
  const handlePaymentModalOpen = (member: Vendor) => {
    setSelectedVendor(member);
    if (!member?.organization || !member?.organization?.stripeConnectId) {
      showToast(t("Vendor must add payment details"), "error");
    } else {
      setSelectedVendor(member);
      setPaymentModalOpen(true);
    }
  };

  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);
    setSelectedVendor(null);
  };
  const handleAddVendorModalOpen = () => {
    setAddVendorModalOpen(true);
  };

  const handleAddVendorModalClose = () => {
    setAddVendorModalOpen(false);
  };
  const fetchAllVendors = async () => {
    // setLoading(true);
    if (currentAssociation) {
      await dispatch(getVendors(currentAssociation?.associationId));
      setIsInitialLoad(false);
    }
    // setLoading(false);
  };

  const [loading, setLoading] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleServiceTypeNameDisplay = (serviceType: string) => {
    for (let i = 0; i < vendorTypes.length; i++) {
      if (vendorTypes[i].key === serviceType) {
        return vendorTypes[i].value;
      }
    }
    return t("Property Management Services");
  };

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClearSearch = () => {
    setSearch("");
  };
  const customBoardNameCell = (member: any) => {
    const displayName: any =
      typeof member.name === "undefined" ||
      member.name === "undefined undefined" ||
      member.name === null
        ? t("Name not set")
        : member.name;

    return displayName;
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const openRemoveModal = (member: Vendor) => {
    setRemoveModalOpen(true);
    setSelectedVendor(member);
  };

  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
    setSelectedVendor(null);
  };

  // const handleAssociationPayment = () => {
  //   if (selectedVendor) {
  //     handlePaymentModalOpen(selectedVendor);
  //   }
  // };

  const handleRemoveVendor = async () => {
    setLoading(true);
    try {
      if (selectedVendor) {
        await dispatch(removeVendor(selectedVendor));
      }
      closeRemoveModal();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const allProviders = [
    ...(Array.isArray(AllManagers) ? AllManagers : []),
    ...(Array.isArray(AllVendors) ? AllVendors : []),
  ];

  let emails: string[] = [];
  if (allProviders.length > 0) {
    allProviders?.map((provider: any) => {
      if (provider && provider.email) {
        emails.push(provider.email);
      }
    });
  }

  const sortedMembers = stableSort(allProviders, getComparator(order, orderBy));

  const filteredVendors = sortedMembers?.filter((vendor: any) => {
    if (!vendor) {
      return false;
    }
    const { name, email, serviceType } = vendor;
    const lowerCaseSearch = search?.toLowerCase();
    return lowerCaseSearch
      ? name?.toLowerCase().includes(lowerCaseSearch) ||
          serviceType?.toLowerCase().includes(lowerCaseSearch)
      : true;
  });

  const paginatedData = filteredVendors?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  if (isInitialLoad && isFetching) {
    return <VendorSkelton />;
  }
  return (
    <div className="profile">
      <Box className="vendorsBox__title">
        <Typography className="vendors__title page_title">Vendors</Typography>
      </Box>
      <Toast
        type={toastType as "success" | "error"}
        open={openToast}
        message={`${toastMessage}`}
        handleClose={handleCloseToast}
      />
      <AddManagerModal
        isOpen={isAddManagerModalOpen}
        onClose={handleAddManagerModalClose}
        associationId={currentAssociation?.associationId}
        emails={emails}
        setIsInitialLoad={setIsInitialLoad}
      />
      <AddVendorModal
        isOpen={isAddVendorModalOpen}
        onClose={handleAddVendorModalClose}
        associationId={currentAssociation?.associationId}
        emails={emails}
        setIsInitialLoad={setIsInitialLoad}
      />
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        onClose={handlePaymentModalClose}
        stripeConnectId={selectedVendor?.organization?.stripeConnectId}
      />
      <Box
        className="TableConnect vendor_wrapper vendor-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="vendor-wrap gridTable">
            <Box
              sx={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <OutlinedInput
                    sx={{
                      margin: "0 !important",
                      border: "unset !important",
                      "& .MuiInputBase-input": {
                        padding: "6px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${theme.palette.activeColor}`,
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${theme.palette.activeColor}`,
                        },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${theme.palette.activeColor}`,
                          borderWidth: "1px",
                        },
                      },
                    }}
                    placeholder={t("Search")}
                    value={search}
                    onChange={handleSearchChange}
                    endAdornment={
                      <InputAdornment position="end">
                        {search ? (
                          <IconButton
                            onClick={handleClearSearch}
                            edge="end"
                            className="search-icon-button-hover"
                          >
                            <Clear
                              style={{ color: `${theme.palette.activeColor}` }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            edge="end"
                            className="search-icon-button-hover"
                          >
                            <Search
                              style={{ color: `${theme.palette.activeColor}` }}
                            />
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  {isBoardRole && (
                    <Button
                      className="btn add-member btn-white"
                      variant="outlined"
                      onClick={handleAddVendorModalOpen}
                    >
                      {t("Add Vendor")}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  {isBoardRole && (
                    <Button
                      className="btn add-member btn-white"
                      variant="outlined"
                      onClick={handleAddManagerModalOpen}
                    >
                      {t("Add Manager")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {filteredColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            {t("No Vendors Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedData?.map((row: any) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&. MuiTableCell-root": {
                                height: "70px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              <ProfileImageTemplate
                                profilePicture={row.profilePicture}
                              />
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {customBoardNameCell(row)}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {handleServiceTypeNameDisplay(row.serviceType)}
                            </TableCell>
                            <TableCell
                              sx={{ height: "60px", padding: "4px 8px" }}
                            >
                              {row?.organization?.title}
                            </TableCell>
                            {isBoardRole && (
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Button
                                  variant="outlined"
                                  className="btn btn-theme"
                                  onClick={() => handlePaymentModalOpen(row)}
                                >
                                  {t("Payments")}
                                </Button>
                              </TableCell>
                            )}
                            {isBoardRole && (
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Button
                                  variant="outlined"
                                  className="btn btn-theme btn-red remove-btn"
                                  onClick={() => openRemoveModal(row)}
                                >
                                  {t("Remove")}
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredVendors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
      <RemoveVendorModal
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onRemove={handleRemoveVendor}
      />
    </div>
  );
};
export default Vendors;
