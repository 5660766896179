import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, CircularProgress, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CommentFormProps {
  setCommentData: React.Dispatch<React.SetStateAction<any>>;
  commentData: any;
  rowSelect: any;
  handlePostComment: (commentData: any) => void;
  loading: boolean;
  showToast: (message: string, type: string) => void;
}

const CommentForm: React.FC<CommentFormProps> = ({
  commentData,
  handlePostComment,
  loading,
  showToast,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(t("Please add a comment")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      await postCommentHandler({
        ...commentData,
        comment: values.comment,
      });
    },
  });

  const postCommentHandler = async (commentObj: any) => {
    try {
      handlePostComment(commentObj);
      showToast(t("Comment Added Successfully"), "success");
      formik.resetForm();
    } catch (error) {
      showToast(t("Error"), "error");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="e-input-group">
        <TextField
          className="e-input"
          type="text"
          placeholder={t("Add a comment...")}
          variant="standard"
          fullWidth
          id="comment"
          name="comment"
          sx={{
            "& .MuiInput-root::before": {
              borderBottom: "0",
            },
            "& .MuiInput-root:hover::before": {
              borderBottom: "0 !important",
            },
            "& .MuiInput-root::after": {
              borderBottom: "0",
            },
            "& .MuiInput-root .MuiInput-input:focus": {
              borderColor: `${theme.palette.text.primary} !important`,
            },
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          value={formik.values.comment}
          onChange={(event) => {
            const { name } = event.target;
            formik.handleChange(event);
            if (name === "comment") {
              formik.setFieldTouched(name, true, false);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          className="comment-form-btn e-control e-btn"
          id="targetButton1"
          type="submit"
        >
          {loading ? (
            <CircularProgress
              size={23}
              sx={{ color: `${theme.palette.text.primary}` }}
            />
          ) : (
            t("Submit")
          )}
        </Button>
      </div>
    </form>
  );
};

export default CommentForm;
