import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Grid,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { AxiosError } from "axios";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { addUnitInsideBuilding } from "../../../../Redux/Feature/Asset-slice";
import { useTranslation } from "react-i18next";

const AddUnitModal = (props: any) => {
  const {
    showAddUnitModal,
    setShowAddUnitModal,
    associationId,
    showToast,
    buildingId,
  } = props;
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = React.useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const addUnitValidation = Yup.object({
    title: Yup.string(),
    floor: Yup.number()
      .integer(t("* Not valid number."))
      .typeError(t("* Not valid number."))
      .moreThan(-6, t("Floor values can't be less than -5"))
      .required(t("Floor is required")),
    address2: Yup.string()
      .required(t("Address2 is required"))
      .min(1)
      .max(30, t("address2 maximum 30 characters")),
    ownershipShare: Yup.number()
      .typeError(t("* Not valid number."))
      .required(t("Ownership Share is required")),
  });
  const handlerUnitSubmit = async (values: any) => {
    setLoading(true);
    try {
      const response = await dispatch(
        addUnitInsideBuilding({
          ...values,
          type: "unit",
          buildingId: buildingId,
          associationId: associationId,
        })
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Unit Added Successfully"), "success");
      } else {
        showToast(t("Unit Add Failed"), "error");
      }
      setShowAddUnitModal(false);
      setLoading(false);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        setServerError(error.response.status);
        if (err instanceof AxiosError) {
          if (err?.response?.data?.error) {
            setServerErrorMsg(err?.response?.data?.error);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const renderServerError = (errorCode: Number) => {
    switch (errorCode) {
      case 500:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>Server Error</p>
        );
      case 400:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      case 401:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {t("Unauthorized")}
          </p>
        );
      case 403:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      case 404:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      default:
        return "";
    }
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "rgba(0, 105, 202, 0.6901960784)",
    border: `1px solid ${theme.palette.modelBorderColor}`,
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    backdropFilter: "blur(4px)",
    width: "calc(100% - 28px) !important",
    maxWidth: "750px",
  };

  return (
    <Modal
      open={showAddUnitModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add-unit-dialog"
    >
      <>
        <Box
          sx={{ ...style, pt: "10px", px: "15px" }}
          className="add-unit-modal"
        >
          <Box className="scroll-wrap">
          <Box
            sx={{m: "20px 0px 40px 30px"}}
            justifyContent={"space-between"}
            display="flex"
            alignItems={"center"}
            onClick={() => setShowAddUnitModal(false)}
          >
            <p
                  style={{
                    color: `${theme.palette.text.primary}`,
                    fontSize: "30px",
                    fontWeight: "600",
                    textAlign: "center",
                    flex: "1",
                  }}
                >
                  {t("Add Unit Information")}
                </p>
            {/* <Typography
          id="modal-modal-title"
          variant="h6"
          component="h6"
          align="center"
          className="add-unit-title"
          sx={{
            margin: "20px 30px 40px",
            fontSize: "30px",
          }}
        >
          {t("Add Unit Information")}
        </Typography> */}
            <CancelIcon
              sx={{                
                color: theme.palette.mode==='light'? "#b7b7b7":"#fff",
                width: "34px",
                height: "34px",
              }}
            />
          </Box>          
          <Formik
            initialValues={{
              title: "",
              floor: 0,
              ownershipShare: 0,
              address2: "",
            }}
            validationSchema={addUnitValidation}
            enableReinitialize={true}
            onSubmit={(values) => handlerUnitSubmit(values)}
            onReset={() => {}}
          >
            {({}) => (
              <Box sx={{ padding: "0 10px" }}>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ mb: "6px" }}>
                        <Field name="title">
                          {({
                            field,
                            form,
                            meta,
                          }: {
                            field: any;
                            form: any;
                            meta: any;
                          }) => (
                            <Box className="unitsBox-fieldBox">
                              <span className="unitsBox-field">
                                <label style={{color: "var(--mui-palette-text-primary)"}}>{t("Title")}:</label>
                                <Box>
                                  <TextField
                                    variant="standard"
                                    type="text"
                                    {...field}
                                    {...props}
                                  />
                                  {meta.touched && meta.error && (
                                    <Typography className="error-msg">
                                      {meta.error}
                                    </Typography>
                                  )}
                                </Box>
                              </span>
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ mb: "6px" }}>
                        <Field name="address2">
                          {({
                            field,
                            form,
                            meta,
                          }: {
                            field: any;
                            form: any;
                            meta: any;
                          }) => (
                            <Box className="unitsBox-fieldBox">
                              <span className="unitsBox-field">
                                <label style={{color: "var(--mui-palette-text-primary)"}}>{t("Address 2 (Unit Number)")}:</label>
                                <Box>
                                  <TextField
                                    variant="standard"
                                    type="text"
                                    {...field}
                                    {...props}
                                  />
                                  {meta.touched && meta.error && (
                                    <Typography className="error-msg">
                                      {meta.error}
                                    </Typography>
                                  )}
                                </Box>
                              </span>
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ mb: "6px" }}>
                        <Field name="floor">
                          {({
                            field,
                            form,
                            meta,
                          }: {
                            field: any;
                            form: any;
                            meta: any;
                          }) => (
                            <Box className="unitsBox-fieldBox">
                              <span className="unitsBox-field">
                                <label style={{color: "var(--mui-palette-text-primary)"}}>{t("Floor")}:</label>
                                <Box>
                                  <TextField
                                    variant="standard"
                                    type="text"
                                    {...field}
                                    {...props}
                                  />
                                  {meta.touched && meta.error && (
                                    <Typography className="error-msg">
                                      {meta.error}
                                    </Typography>
                                  )}
                                </Box>
                              </span>
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ mb: "6px" }}>
                        <Field name="ownershipShare">
                          {({
                            field,
                            form,
                            meta,
                          }: {
                            field: any;
                            form: any;
                            meta: any;
                          }) => (
                            <Box className="unitsBox-fieldBox">
                              <span className="unitsBox-field">
                                <label style={{color: "var(--mui-palette-text-primary)"}}>{t("Ownership Share")}:</label>
                                <Box>
                                  <TextField
                                    variant="standard"
                                    {...field}
                                    {...props}
                                  />

                                  {meta.touched && meta.error && (
                                    <Typography className="error-msg">
                                      {meta.error}
                                    </Typography>
                                  )}
                                </Box>
                              </span>
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </Grid>
                  </Grid>

                  <FieldArray
                    name="persons"
                    render={() => {
                      return (
                        <>
                          <div className="SubmitBtn ticketModel__footer">
                            <Button
                              variant="contained"
                              className="sBtn e-control e-btn"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  size={33}
                                  sx={{ color: `${theme.palette.textWhite}` }}
                                />
                              ) : (
                                t("Submit")
                              )}
                            </Button>
                          </div>
                        </>
                      );
                    }}
                  />
                  {renderServerError(serverError)}
                </Form>
              </Box>
            )}
          </Formik>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default AddUnitModal;
