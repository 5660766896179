import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Link,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../Redux/Store";
import { CreateComponent } from "../../../../Redux/Feature/Asset-slice";
import { useTranslation } from "react-i18next";

interface CreateComponentModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
  commonAssetId: string;
}

const CreateComponentModal: React.FC<CreateComponentModalProps> = ({
  isOpen,
  onClose,
  associationId,
  commonAssetId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { title } = values;

      const payload = {
        title: title,
        type: "component",
        commonAssetId: commonAssetId,
        associationId: associationId,
      };
      dispatch(CreateComponent(payload))
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          resetForm();
          onClose();
          setLoading(false);
          showToast(t("Common Asset Component Added"), "success");
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box
        className="add-document add-association-model"
        sx={{ width: "500px !important", paddingRight: "40px" }}
      >
        <Box
          sx={{ m: "20px 30px 40px" }}
          justifyContent={"center"}
          display="flex"
        >
          <p
            style={{
              color: `${theme.palette.text.primary}`,
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {t("Add Asset Component")}
          </p>
          <Link sx={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
            <CancelIcon className="support-ticket-cross-icon" />
          </Link>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Title")}
            </label>
            <input
              id="title"
              name="title"
              placeholder={t("Component Title")}
              style={{ width: "100%" }}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.title && formik.touched.title && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.title}
              </Typography>
            )}
          </div>

          <Box sx={{ marginTop: "30px" }}>
            <div className="SubmitBtn ticketModel__footer">
              <Button
                className="sBtn e-control e-btn"
                id="targetButton1"
                type="submit"
                disabled={isFetching === true}
              >
                {loading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: `${theme.palette.textWhite}` }}
                  />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateComponentModal;
