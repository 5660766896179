import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Popper,
  Grid,
  TextField,
  useTheme,
  FormControl,
  Autocomplete,
  Skeleton,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { RootState } from "../../Redux/Store";
import { useSelector } from "react-redux";
import AddressDetailField from "../../Component/Common/DetailFields/AddressDetailField";
import { StripeVendorConnectSession } from "../../Redux/Feature/Finance-slice";
import "./setting.scss";
import AssociationDetailField from "../../Component/Common/DetailFields/AssociationDetailField";
import {
  UpdateOrganization,
  UpdateOrganizationService,
} from "../../Redux/Feature/Setting-slice";
import { styled } from "@mui/material/styles";
import { vendorTypes } from "../../Utils/assets";
import CreateOrganizationModal from "../../Component/Modals/SettingsModals/CreateOrganizationModal";
import { useTranslation } from "react-i18next";

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%",
    marginLeft: "-18px",
    marginTop: "1.3vw",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));
const generateStripeDashboardLink = (stripeConnectId: string) => {
  const isTestMode = JSON.parse(localStorage.getItem("testMode") || "false");
  const url = isTestMode
    ? `https://dashboard.stripe.com/${stripeConnectId}/test/dashboard`
    : `https://dashboard.stripe.com/${stripeConnectId}/dashboard`;
  return url;
};

const OrganizationSettings = (props: any) => {
  const {
    organizationPageData,
    setOrganizationPageData,
    currentOrganization,
    selectedServices,
    setSelectedServices,
    showToast,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [subscriptionLoading, setSubscriptionLoading] = useState(false); // New loading state for subscription change
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.Auth.user);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const mapRef: any = useRef(null);
  const [editAddress, setEditAddress] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [loading, setLoading] = useState(false);
  const setUpdatedOrganizationData = () => {
    setOrganizationPageData({
      title: currentOrganization?.title,

      unitSubscriptionCount: currentOrganization?.unitSubscriptionCount,
      subscriptionId: currentOrganization?.subscriptionId,
      accounts: currentOrganization?.accounts?.data,
      address: currentOrganization?.address,
      stripeConnectId: currentOrganization?.stripeConnectId,
    });
  };

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] =
    useState(false);

  const autocompleteChipStyle = {
    marginTop: "0px",
    "& .MuiOutlinedInput-root": {
      paddingRight: "19px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "6px",
    border: "1px solid var(--mui-palette-divider)",
    background:
      theme.palette.mode === "dark"
        ? "var(--mui-palette-sidebarAutoCompleteBg)"
        : "var(--mui-palette-textWhite)",
    padding: "5px 10px",
    "& .MuiAutocomplete-tag": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.background.default
          : "#ccc",
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };

  const autoCompleteTextInputStyle = {
    paddingRight: "0px",
    "& .MuiAutocomplete-inputRoot": {
      background: "transparent",
    },
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
      paddingRight: "46px !important",
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setOrganizationPageData({
      ...organizationPageData,
      [name]: value,
    });
  };

  const handleServiceChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setServicesLoading(true);
    try {
      const response = await dispatch(
        await UpdateOrganizationService({
          organizationId: user?.organizationId,
          services: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedServices(response?.payload?.data?.services);

        showToast(t("Organization Updated"), "success");
        // await getAccountTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        showToast(t("Error"), "error");
      }
      setServicesLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const autocomplete: any = useRef(null);
  const handlePlaceChanged = async () => {
    if (autocomplete.current) {
      const place = autocomplete.current.getPlace();
      if (!place || !place?.geometry || !place?.geometry?.location) {
        showToast(
          t("Please select an address from the dropdown list"),
          "error"
        );
        return;
      }
      const addressData: any = {};
      addressData.address = place?.formatted_address;
      var bounds = place.geometry.viewport.toJSON();

      addressData.latitude = (bounds.north + bounds.south) / 2;
      addressData.longitude = (bounds.east + bounds.west) / 2;
      addressData.latitudeDelta = bounds.north - bounds.south;
      addressData.longitudeDelta = bounds.east - bounds.west;

      if (mapRef?.current) {
        mapRef?.current?.panTo(place?.geometry?.location);
      }
      place.address_components.forEach((component: any) => {
        const types = component.types;

        types.forEach((type: any) => {
          switch (type) {
            case "street_number": {
              addressData.streetNumber = `${component.long_name}`;
              break;
            }
            case "route": {
              addressData.streetName = component.short_name;
              break;
            }
            case "locality":
              addressData.city = component.long_name;
              break;
            case "administrative_area_level_1":
              addressData.state = component.long_name;
              break;
            case "country":
              addressData.country = component.long_name;
              break;
            case "postal_code":
              addressData.postalCode = component.long_name;
              break;
            default:
              break;
          }
        });
      });
      const payload = {
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        postalCode: addressData.postalCode,
        country: addressData.country,
        lat: addressData.latitude,
        lng: addressData.longitude,
        organizationId: user.organizationId,
      };

      setEditAddress(!editAddress);
      const response = await dispatch(UpdateOrganization(payload));

      if (response?.payload?.statusCode === 200) {
        organizationPageData.address = addressData.address;
        showToast(t("Organization Updated"), "success");
      } else {
        showToast(t("Error"), "error");
      }
    }
  };

  const handleStripeFinancialConnectionsSession = async () => {
    const payload = { country: "US" };
    setSubscriptionLoading(true);
    await dispatch(StripeVendorConnectSession(payload)).then(
      (response: any) => {
        if (response?.payload?.data?.accountLink?.url) {
          setSubscriptionLoading(false);
          window.location.href = response?.payload?.data?.accountLink?.url;
        }
        setSubscriptionLoading(false);
        if (response?.payload?.statusCode !== 200) setLoading(false);
      }
    );
  };

  const handleNewTitle = async () => {
    try {
      setEditTitle(false);
      if (user) {
        const payload = {
          title: organizationPageData?.title,
          organizationId: user.organizationId,
        };
        const response = await dispatch(UpdateOrganization(payload));

        if (response?.payload?.statusCode === 200) {
          showToast(t("Organization Updated"), "success");
        } else if (response.error) {
        } else {
          showToast(t("Error"), "error");
        }
      }
    } catch (err) {}
  };

  const handleOpenCreateOrganizationModal = () => {
    setIsCreateOrganizationModalOpen(true);
  };

  const handleCloseCreateOrganizationModal = () => {
    setIsCreateOrganizationModalOpen(false);
  };

  return (
    <Box className="organizationSettings">
      <CreateOrganizationModal
        isOpen={isCreateOrganizationModalOpen}
        onClose={handleCloseCreateOrganizationModal}
        showToast={showToast}
      />
      {!user.organizationId ? (
        <Grid item xs={3}>
          <Button
            className="btn btn-theme"
            sx={{ color: "text.primary", marginBottom: "12px" }}
            onClick={handleOpenCreateOrganizationModal}
          >
            {t("Register Vendor Organization")}
          </Button>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={3}>
              <Button
                variant="contained"
                className="btn"
                onClick={handleStripeFinancialConnectionsSession}
                sx={{
                  width: "auto !important",
                  paddingX: "16px !important",
                }}
                disabled={subscriptionLoading}
              >
                {subscriptionLoading ? (
                  <CircularProgress
                    size={24}
                    sx={{ color: `${theme.palette.textWhite}` }}
                  />
                ) : (
                  t("Connect Finances")
                )}
              </Button>
            </Grid>
            <Grid item xs={3}>
              {organizationPageData?.stripeConnectId && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const stripeDashboardLink = generateStripeDashboardLink(
                      organizationPageData?.stripeConnectId
                    );
                    window.open(stripeDashboardLink, "_blank");
                  }}
                >
                  {t("Go to Payments")}
                </Button>
              )}
            </Grid>
          </Grid>
          <AssociationDetailField
            isEditField={editTitle}
            fieldLabel={"Organization Title"}
            fieldValue={organizationPageData?.title}
            fieldName={"title"}
            placeholder={"Edit Name"}
            handleChange={handleChange}
            setisEditField={setEditTitle}
            handleSaveField={handleNewTitle}
            isBoardRole={true}
            resetUserDataOnCancel={setUpdatedOrganizationData}
          />
          <AddressDetailField
            isEditField={editAddress}
            fieldLabel="Address"
            fieldValue={organizationPageData?.address}
            setisEditField={setEditAddress}
            handleSaveField={handlePlaceChanged}
            autocomplete={autocomplete}
            isNameEdit={true}
          />
          <div className="organizationServices">
            <FormControl variant="standard" fullWidth>
              <Grid container spacing={2}>
                <Grid item md={10} lg={10}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      marginTop: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    <Grid item md={4} lg={4}>
                      <Typography
                        className="formlabel"
                        sx={{ color: "text.primary", mr: "10px" }}
                      >
                        {t("Services")}
                      </Typography>
                    </Grid>

                    <Grid item md={8} lg={8}>
                      {servicesLoading || isUpdateInProgress ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={56}
                        />
                      ) : (
                        <Autocomplete
                          multiple
                          options={vendorTypes}
                          getOptionLabel={(option) => t(option.value) || ""}
                          defaultValue={selectedServices}
                          getOptionKey={(option: any) => option?.key}
                          filterSelectedOptions
                          sx={autocompleteChipStyle}
                          PopperComponent={MuiDropDownPopper}
                          id="servicesId"
                          onChange={handleServiceChange}
                          value={selectedServices || null}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={autoCompleteTextInputStyle}
                            />
                          )}
                          PaperComponent={(props) => (
                            <Paper sx={autoCompletePaperStyle} {...props} />
                          )}
                        />
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </FormControl>
          </div>
        </>
      )}
    </Box>
  );
};

export default OrganizationSettings;
