import http from "../api";

interface UnitUpdateField {
  description: string | null;
  title: string;
  floor: string;
  address2: string;
  ownershipShare: string;
}

const CreateAsset = async (BuildingData: any) => {
  try {
    const response = await http.post(`/create-asset`, { ...BuildingData });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getAssetComponents = async (payload: any) => {
  try {
    const response = await http.get(
      `/get-common-asset-components/?commonAssetId=${payload.commonAssetId}&associationId=${payload.associationId}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getAllBuildingDirectory = async (associationIdObj: any) => {
  try {
    const response = await http.post(`/get-directory`, { ...associationIdObj });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const changeUnitPersonAPI = async (unitId: string, unitPersonObj: any) => {
  try {
    const response = await http.put(
      `change-unit-person/${unitId}`,
      unitPersonObj
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const deleteComponentAPI = async (componentId: any) => {
  try {
    const response = await http.delete(`/delete-asset/${componentId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const deleteUnitAPI = async (unitId: any) => {
  try {
    const response = await http.delete(`/delete-asset/${unitId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const deleteBuildingAPI = async (assetId: any) => {
  try {
    const response = await http.delete(`/delete-asset/${assetId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const UpdateAsset = async (assetId: string, assetObj: any) => {
  try {
    const response = await http.put(`/put-asset/${assetId}`, assetObj);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const updateCommonAssetsDirectory = async (
  id: string,
  commonAssetsDirObj: any
) => {
  try {
    const response = await http.put(`/put-asset/${id}`, {
      ...commonAssetsDirObj,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getAllCommonAssetsDirectory = async (associationIdObj: any) => {
  try {
    const response = await http.post(`/get-common-directory`, {
      ...associationIdObj,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const deleteCommonAssetAPI = async (assetId: any) => {
  try {
    const response = await http.delete(`/delete-asset/${assetId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const AssetServices = {
  CreateAsset,
  getAllBuildingDirectory,
  changeUnitPersonAPI,
  deleteUnitAPI,
  deleteBuildingAPI,
  UpdateAsset,
  updateCommonAssetsDirectory,
  getAllCommonAssetsDirectory,
  deleteCommonAssetAPI,
  getAssetComponents,
  deleteComponentAPI,
};
export default AssetServices;
