import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import PublicFooter from "../Footer/Footer";
import { useDispatch } from "react-redux";
import { updateUser, logoutUser } from "../../Redux/Feature/Auth-slice";
import { useAuth0 } from "@auth0/auth0-react";
import "./layout.scss";
import Banner from "../../Component/Common/Banner/Banner";
import { bannerData } from "../../Utils/utils";

const Layout = () => {
  const { isAuthenticated, logout, isLoading } = useAuth0();
  const [, setDisplayLogoutAlert] = useState(false);
  const theme = useTheme();

  let timer: any;
  let expirationEpochTimeNumber: any;

  const currentEpochTime = Math.floor(new Date().getTime() / 1000);
  const expirationEpochTime = localStorage.getItem("safe-token-expiration");
  if (expirationEpochTime) {
    expirationEpochTimeNumber = parseInt(expirationEpochTime, 10);
  }

  const epochTimeDiff = expirationEpochTimeNumber - currentEpochTime;

  const handleLogoutTimer = () => {
    timer = setTimeout(async () => {
      await createButtonAlert();
      resetTimer();
      handleLogout();
    }, epochTimeDiff * 1000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    resetTimer();
    handleLogoutTimer();
    return () => {
      resetTimer();
    };
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    initializeLoginSession();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false && isLoading === false) {
      handleLogout();
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const initializeLoginSession = () => {
    const token = localStorage.getItem("safe-token");
    if (typeof token === "string" && token && token?.length > 0) {
      let user = JSON.parse(localStorage.getItem("user") || "{}");
      user.loginSessionFlag = true;
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(updateUser());
    }
  };

  const createButtonAlert = async () => {
    setDisplayLogoutAlert(true);
  };

  return (
    <Box sx={{ color: "background.default" }}>
      <Box className="WrapperScreen">
        <Header />
        <div className="WrapperScreen_content">
          <Banner items={bannerData} theme={theme}  />
          <Outlet />
        </div>
        <PublicFooter />
      </Box>
    </Box>
  );
};

export default Layout;
