import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Governance from "../Service/Governance-service";

interface GovernanceState {
  allVotes: any[];
  getRuleSet: any[];
  selectedVote: any;
  getByLawsData: any[];
  getCCRsData: any[];
  getRulesAndRegulationData: any[];
  getPoliciesData: any[];
  isFetching: boolean;
  successMessage: string;
  error: string;
}

const initialState: GovernanceState = {
  allVotes: [],
  selectedVote: {},
  getRuleSet: [],
  getByLawsData: [],
  getCCRsData: [],
  getRulesAndRegulationData: [],
  getPoliciesData: [],
  isFetching: false,
  successMessage: "",
  error: "",
};

export const createVote: any = createAsyncThunk(
  "createVote/post",
  async (votePayload: any, thunkAPI) => {
    const data = await Governance.createVote(votePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getVotes: any = createAsyncThunk(
  "getVotes/get",
  async (associationId: string, thunkAPI) => {
    const data = await Governance.getVotes(associationId);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const NotifyVote: any = createAsyncThunk(
  "NotifyMeeting/get",
  async (obj: string, thunkAPI) => {
    const data = await Governance.NotifyVote(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getRuleSet = createAsyncThunk(
  "getRuleSet/get",
  async (
    { associationId, ruleSet }: { associationId: string; ruleSet: string },
    thunkAPI
  ) => {
    const data = await Governance.getRuleSet(associationId, ruleSet);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createRule = createAsyncThunk(
  "createRule/post",
  async (createRulePayload: any, thunkAPI) => {
    const data = await Governance.createRule(createRulePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getByLaws = createAsyncThunk(
  "getByLaws/get",
  async (
    { associationId, ruleSet }: { associationId: string; ruleSet: string },
    thunkAPI
  ) => {
    const data = await Governance.getRuleSet(associationId, ruleSet);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createByLaw = createAsyncThunk(
  "createByLaw/post",
  async (createRulePayload: any, thunkAPI) => {
    const data = await Governance.createRule(createRulePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getCCRs = createAsyncThunk(
  "getCCRs/get",
  async (
    { associationId, ruleSet }: { associationId: string; ruleSet: string },
    thunkAPI
  ) => {
    const data = await Governance.getRuleSet(associationId, ruleSet);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createCCR = createAsyncThunk(
  "createCCR/post",
  async (createRulePayload: any, thunkAPI) => {
    const data = await Governance.createRule(createRulePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getRulesAndRegulation = createAsyncThunk(
  "getRulesAndRegulation/get",
  async (
    { associationId, ruleSet }: { associationId: string; ruleSet: string },
    thunkAPI
  ) => {
    const data = await Governance.getRuleSet(associationId, ruleSet);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createRulesAndRegulation = createAsyncThunk(
  "createRulesAndRegulation/post",
  async (createRulePayload: any, thunkAPI) => {
    const data = await Governance.createRule(createRulePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getPolicies = createAsyncThunk(
  "getPolicies/get",
  async (
    { associationId, ruleSet }: { associationId: string; ruleSet: string },
    thunkAPI
  ) => {
    const data = await Governance.getRuleSet(associationId, ruleSet);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const createPolicies = createAsyncThunk(
  "createPolicies/post",
  async (createRulePayload: any, thunkAPI) => {
    const data = await Governance.createRule(createRulePayload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateVote: any = createAsyncThunk(
  "updateVote/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateVote(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleForCCR: any = createAsyncThunk(
  "updateRuleForCCR/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRule(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleForByLaw: any = createAsyncThunk(
  "updateRuleForByLaw/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRule(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleForRulesAndRegulation: any = createAsyncThunk(
  "updateRuleForRulesAndRegulation/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRule(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleForPolicy: any = createAsyncThunk(
  "updateRuleForPolicy/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRule(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const deleteRuleForCCR = createAsyncThunk(
  "deleteRuleForCCR/delete",
  async (ruleId: string, thunkAPI) => {
    try {
      const data = await Governance.deleteRule(ruleId);
      if (data?.statusCode === 200) {
        return { ...data, ruleId };
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteRuleForByLaw = createAsyncThunk(
  "deleteRuleForByLaw/delete",
  async (ruleId: string, thunkAPI) => {
    try {
      const data = await Governance.deleteRule(ruleId);
      if (data?.statusCode === 200) {
        return { ...data, ruleId };
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteRuleForRulesAndRegulation = createAsyncThunk(
  "deleteRuleForRulesAndRegulation/delete",
  async (ruleId: string, thunkAPI) => {
    try {
      const data = await Governance.deleteRule(ruleId);
      if (data?.statusCode === 200) {
        return { ...data, ruleId };
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteRuleForPolicy = createAsyncThunk(
  "deleteRuleForPolicy/delete",
  async (ruleId: string, thunkAPI) => {
    try {
      const data = await Governance.deleteRule(ruleId);
      if (data?.statusCode === 200) {
        return { ...data, ruleId };
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateRuleSetOrderForByLaw: any = createAsyncThunk(
  "updateRuleSetOrderForByLaw/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRuleSetOrder(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleSetOrderForCCRs: any = createAsyncThunk(
  "updateRuleSetOrderForCCRs/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRuleSetOrder(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateRuleSetOrderForRulesAndRegulation: any = createAsyncThunk(
  "updateRuleSetOrderForRulesAndRegulation/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRuleSetOrder(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const updateRuleSetOrderForPolicy: any = createAsyncThunk(
  "updateRuleSetOrderForPolicy/put",
  async (obj: any, thunkAPI) => {
    const data = await Governance.updateRuleSetOrder(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const deleteVote: any = createAsyncThunk(
  "Meeting/delete",
  async (obj: any, thunkAPI) => {
    const data = await Governance.deleteVote(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

const GovernanceSlice = createSlice({
  name: "Governance",
  initialState,
  reducers: {
    resetDrawer: (state) => {
      state.selectedVote = {
        budgetId: "",
        name: "",
        allocatedAmount: 0,
        associationId: "",
        creatorId: "",
        month: 0,
        year: 0,
        asset: "",
        voters: {},
        ballots: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRuleSet.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getRuleSet.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.getRuleSet = action.payload.data;
      })
      .addCase(getRuleSet.rejected, (state, action) => {
        state.isFetching = false;
        state.getRuleSet = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(NotifyVote.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(NotifyVote.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
      })
      .addCase(NotifyVote.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateVote.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateVote.fulfilled, (state, action) => {
        const updatedData = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedVoteIndex = state.allVotes.findIndex(
          (vote: any) => vote.voteId === updatedData.voteId
        );
        if (updatedVoteIndex !== -1) {
          state.allVotes[updatedVoteIndex] = updatedData;
        }
      })
      .addCase(updateVote.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(createRule.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createRule.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const existingRuleIndex = state.getByLawsData.findIndex(
          (rule: any) => rule.ruleId === action.payload.data.ruleId
        );
        if (existingRuleIndex === -1) {
          state.getRuleSet.push(action.payload.data);
        }
      })
      .addCase(createRule.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(deleteVote.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteVote.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.allVotes = state.allVotes.filter(
          (vote) => vote?.voteId !== action?.payload?.data?.voteId
        );
      })
      .addCase(deleteVote.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(createVote.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createVote.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.allVotes.push(action.payload.data);
      })
      .addCase(createVote.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(getVotes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getVotes.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        if (action?.payload?.data?.votes) {
          state.allVotes = action?.payload?.data?.votes;
        } else {
          state.allVotes = [];
        }
      })
      .addCase(getVotes.rejected, (state, action) => {
        state.isFetching = false;
        state.allVotes = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(getByLaws.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getByLaws.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.getByLawsData = action.payload.data;
      })
      .addCase(getByLaws.rejected, (state, action) => {
        state.isFetching = false;
        state.getByLawsData = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(createByLaw.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createByLaw.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const existingByLawIndex = state.getByLawsData.findIndex(
          (bylaw: any) => bylaw.ruleId === action.payload.data.ruleId
        );
        if (existingByLawIndex === -1) {
          state.getByLawsData.push(action.payload.data);
        }
      })
      .addCase(createByLaw.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(getCCRs.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCCRs.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.getCCRsData = action.payload.data;
      })
      .addCase(getCCRs.rejected, (state, action) => {
        state.isFetching = false;
        state.getCCRsData = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(createCCR.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createCCR.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const existingCCRIndex = state.getCCRsData.findIndex(
          (ccr: any) => ccr.ruleId === action.payload.data.ruleId
        );
        if (existingCCRIndex === -1) {
          state.getCCRsData.push(action.payload.data);
        }
      })
      .addCase(createCCR.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(getRulesAndRegulation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getRulesAndRegulation.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.getRulesAndRegulationData = action.payload.data;
      })
      .addCase(getRulesAndRegulation.rejected, (state, action) => {
        state.isFetching = false;
        state.getRulesAndRegulationData = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(createRulesAndRegulation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createRulesAndRegulation.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const existingRuleAndRegulationIndex =
          state.getRulesAndRegulationData.findIndex(
            (ruleandregulation: any) =>
              ruleandregulation.ruleId === action.payload.data.ruleId
          );
        if (existingRuleAndRegulationIndex === -1) {
          state.getRulesAndRegulationData.push(action.payload.data);
        }
      })
      .addCase(createRulesAndRegulation.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(getPolicies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.getPoliciesData = action.payload.data;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.isFetching = false;
        state.getPoliciesData = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(createPolicies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createPolicies.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const existingPolicyIndex = state.getPoliciesData.findIndex(
          (policy: any) => policy.ruleId === action.payload.data.ruleId
        );
        if (existingPolicyIndex === -1) {
          state.getPoliciesData.push(action.payload.data);
        }
      })
      .addCase(createPolicies.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    // Update Rule For CCR
    builder
      .addCase(updateRuleForCCR.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleForCCR.fulfilled, (state, action) => {
        const updatedData = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedCCRIndex = state.getCCRsData.findIndex(
          (ccr: any) => ccr.ruleId === updatedData.ruleId
        );
        if (updatedCCRIndex !== -1) {
          state.getCCRsData[updatedCCRIndex] = updatedData;
        }
      })
      .addCase(updateRuleForCCR.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    // Update Rule For ByLaw
    builder
      .addCase(updateRuleForByLaw.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleForByLaw.fulfilled, (state, action) => {
        const updatedData = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedByLawIndex = state.getByLawsData.findIndex(
          (bylaw: any) => bylaw.ruleId === updatedData.ruleId
        );
        if (updatedByLawIndex !== -1) {
          state.getByLawsData[updatedByLawIndex] = updatedData;
        }
      })
      .addCase(updateRuleForByLaw.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    // Update Rule For Rules and Regulation
    builder
      .addCase(updateRuleForRulesAndRegulation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleForRulesAndRegulation.fulfilled, (state, action) => {
        const updatedData = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedRulesAndRegulationIndex =
          state.getRulesAndRegulationData.findIndex(
            (ruleandregulation: any) =>
              ruleandregulation.ruleId === updatedData.ruleId
          );
        if (updatedRulesAndRegulationIndex !== -1) {
          state.getRulesAndRegulationData[updatedRulesAndRegulationIndex] =
            updatedData;
        }
      })
      .addCase(updateRuleForRulesAndRegulation.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    // Update Rule For Policy
    builder
      .addCase(updateRuleForPolicy.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleForPolicy.fulfilled, (state, action) => {
        const updatedData = action.payload.data;
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        const updatedPolicyIndex = state.getPoliciesData.findIndex(
          (policy: any) => policy.ruleId === updatedData.ruleId
        );
        if (updatedPolicyIndex !== -1) {
          state.getPoliciesData[updatedPolicyIndex] = updatedData;
        }
      })
      .addCase(updateRuleForPolicy.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    //Delete Rule for CCR
    builder
      .addCase(deleteRuleForCCR.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteRuleForCCR.fulfilled, (state, action) => {
        const deletedRuleId = action.payload.ruleId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.getCCRsData = state.getCCRsData.filter(
          (ccr: any) => ccr.ruleId !== deletedRuleId
        );
      })
      .addCase(deleteRuleForCCR.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    //Delete Rule for By Law
    builder
      .addCase(deleteRuleForByLaw.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteRuleForByLaw.fulfilled, (state, action) => {
        const deletedRuleId = action.payload.ruleId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.getByLawsData = state.getByLawsData.filter(
          (bylaw: any) => bylaw.ruleId !== deletedRuleId
        );
      })
      .addCase(deleteRuleForByLaw.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    //Delete Rule for Rules and Regulation
    builder
      .addCase(deleteRuleForRulesAndRegulation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteRuleForRulesAndRegulation.fulfilled, (state, action) => {
        const deletedRuleId = action.payload.ruleId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.getRulesAndRegulationData =
          state.getRulesAndRegulationData.filter(
            (ruleandregulation: any) =>
              ruleandregulation.ruleId !== deletedRuleId
          );
      })
      .addCase(deleteRuleForRulesAndRegulation.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    //Delete Rule for Policy
    builder
      .addCase(deleteRuleForPolicy.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteRuleForPolicy.fulfilled, (state, action) => {
        const deletedRuleId = action.payload.ruleId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.getPoliciesData = state.getPoliciesData.filter(
          (policy: any) => policy.ruleId !== deletedRuleId
        );
      })
      .addCase(deleteRuleForPolicy.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(updateRuleSetOrderForByLaw.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleSetOrderForByLaw.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const updatedRuleOrder = action.payload.data;
        state.getByLawsData = updatedRuleOrder;
      })
      .addCase(updateRuleSetOrderForByLaw.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(updateRuleSetOrderForCCRs.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleSetOrderForCCRs.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const updatedRuleOrder = action.payload.data;
        state.getCCRsData = updatedRuleOrder;
      })
      .addCase(updateRuleSetOrderForCCRs.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(updateRuleSetOrderForRulesAndRegulation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(
        updateRuleSetOrderForRulesAndRegulation.fulfilled,
        (state, action) => {
          state.isFetching = false;
          state.successMessage = action.payload.message;
          const updatedRuleOrder = action.payload.data;
          state.getRulesAndRegulationData = updatedRuleOrder;
        }
      )
      .addCase(
        updateRuleSetOrderForRulesAndRegulation.rejected,
        (state, action) => {
          state.isFetching = false;
          if (
            action.payload &&
            typeof action.payload === "object" &&
            "message" in action.payload
          ) {
            state.error = action.payload.message as string;
          } else {
            state.error = "Unknown error occurred";
          }
        }
      );

    builder
      .addCase(updateRuleSetOrderForPolicy.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateRuleSetOrderForPolicy.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        const updatedRuleOrder = action.payload.data;
        state.getPoliciesData = updatedRuleOrder;
      })
      .addCase(updateRuleSetOrderForPolicy.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { resetDrawer } = GovernanceSlice.actions;

export default GovernanceSlice;
