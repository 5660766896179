import {
  Box,
  Typography,
  Drawer,
  useTheme,
  Button,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  Popper,
  Paper,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  FormControl,
  Switch,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../Pages/Settings/setting.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DrawerDescription from "../Common/DetailFields/DrawerDescription";
import { updateVote } from "../../Redux/Feature/Governance-slice";
import DrawerTitle from "../Common/DetailFields/DrawerTitle";
import DrawerAmount from "../Common/DetailFields/DrawerAmount";
import DatePickerComp from "../Common/DatePicker/DatePicker";
import dayjs from "dayjs";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { styled } from "@mui/material/styles";
import { NotifyVote, deleteVote } from "../../Redux/Feature/Governance-slice";

import { MEMBER_TYPE } from "../../Utils/utils";
import ConfirmationModal from "../Modals/GeneralModals/ConfirmationModal";
ChartJS.register(ArcElement, Tooltip, Legend);

const MuiDropDownPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    width: "105%", // Adjust the width as needed
    // width: "unset",
    marginLeft: "-18px",
    marginTop: "0.9vw",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));
const VoteDrawer = (props: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateDescriptionProgress, setUpdateDescriptionProgress] =
    useState(false);
  const [updateTitleProgress, setUpdateTitleProgress] = useState(false);
  const [vendorsLoading, setVendorsLoading] = useState<boolean>(false);
  const [updateAmountProgress, setUpdateAmountProgress] = useState(false);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [isStartDateMode, setIsStartDateMode] = useState(false);
  const [isEndDateMode, setIsEndDateMode] = useState(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [editAmount, setEditAmount] = useState<boolean>(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState("");

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    selectedRow,
    setSelectedRow,
    rightDrawer,
    toggleDrawer,
    showToast,
    owners,
  } = props;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const groupOptions = [
    { value: MEMBER_TYPE.BOARD, label: "Board" },
    { value: MEMBER_TYPE.GENERAL, label: "General" },
  ];
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";
  const allRules = useSelector((state: RootState) => state?.Auth?.allRules);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const editDescriptionHandler = (value: boolean) => {
    setEditDescription(value);
  };
  const handleRuleChange = async (event: any) => {
    const ruleId = event.target.value;

    const response = await dispatch(
      updateVote({
        voteId: selectedRow?.voteId,
        payload: {
          ruleId,
        },
      })
    );
    if (response?.payload?.statusCode === 200) {
      setSelectedRow({
        ...selectedRow,
        ruleId: ruleId,
      });
      showToast(t("Vote Updated"), "success");
    } else if (response.error) {
      showToast(t("Vote Not Updated"), "error");
    }
  };

  const handleConfirmation = async () => {
    // setLoading(true);
    try {
      if (selectedRow) {
        const payload = {
          voteId: selectedRow.voteId,
          associationId: currentAssociation?.associationId,
        };

        if (
          confirmation ===
          "Are you sure you want to notify all owners of this vote?"
        ) {
          const response = await dispatch(NotifyVote(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(
              t(`${selectedRow?.votingGroup} members notified of vote`),
              "success"
            );
          } else if (response.error) {
            showToast(t("Vote Notification Not Sent"), "error");
          }
        } else {
          const response = await dispatch(deleteVote(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(t("Vote Removed"), "success");
          } else if (response.error) {
            showToast(t("Vote Not Removed"), "error");
          }
          toggleDrawer(false);
          setSelectedRow(null);
        }
      }
    } catch (err) {
    } finally {
      closeConfirmationModal();
      // setLoading(false);
    }
  };
  const editAmountHandler = (value: boolean) => {
    setEditAmount(value);
  };

  const editTitleHandler = (value: boolean) => {
    setEditTitle(value);
  };

  const autocompleteChipStyle = {
    marginTop: "0px",
    "& .MuiOutlinedInput-root": {
      paddingRight: "19px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "-10px !important",
    },
    "& input": {
      padding: "0 !important",
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    borderRadius: "6px",
    border: "1px solid var(--mui-palette-divider)",
    background:
      theme.palette.mode === "dark"
        ? "var(--mui-palette-sidebarAutoCompleteBg)"
        : "var(--mui-palette-textWhite)",
    padding: "5px 10px",
    "& .MuiAutocomplete-tag": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.background.default
          : "#ccc",
      "& svg": {
        color: "#676767 !important",
      },
    },
    "& .MuiAutocomplete-listbox": {
      background: "#fff !important",
    },
  };
  const autoCompletePaperStyle = {
    width: "100%",
    background: "#fff",
    color: `${theme.palette.text.secondary}`,
    fontSize: "25px",
    display: "block",
    textAlign: "center",
    "&:hover": {
      color: `${theme.palette.formText}`,
    },
  };

  const autoCompleteTextInputStyle = {
    paddingRight: "0px",
    "& .MuiAutocomplete-inputRoot": {
      background: "transparent",
    },
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
      paddingRight: "46px !important",
    },
  };

  const handleGroupChange = async (event: any) => {
    const votingGroup = event.target.value;
    const response = await dispatch(
      updateVote({
        voteId: selectedRow?.voteId,
        payload: {
          votingGroup,
        },
      })
    );
    if (response?.payload?.statusCode === 200) {
      setSelectedRow({
        ...selectedRow,
        votingGroup: votingGroup,
      });
      showToast(t("Vote Updated"), "success");
    } else if (response.error) {
      showToast(t("Vote Not Updated"), "error");
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const updateAmountHandler = async (updatePayload: any) => {
    try {
      setUpdateAmountProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedRow?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRow({
          ...selectedRow,
          amount: updatePayload?.payload?.amount,
        });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateAmountProgress(false);
      setEditAmount(false);
    } catch (error) {
    } finally {
      setUpdateAmountProgress(false);
    }
  };

  const updateTitleHandler = async (updatePayload: any) => {
    try {
      // setEditTitleHandler(true);
      setUpdateTitleProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedRow?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRow({
          ...selectedRow,
          title: updatePayload?.payload?.title,
        });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateTitleProgress(false);
      setEditTitle(false);
    } catch (error) {
    } finally {
      setUpdateTitleProgress(false);
    }
  };
  const updateDescriptionHandler = async (updatePayload: any) => {
    try {
      setUpdateDescriptionProgress(true);
      const response = await dispatch(
        updateVote({ voteId: selectedRow?.voteId, ...updatePayload })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRow({
          ...selectedRow,
          description: updatePayload?.payload?.description,
        });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateDescriptionProgress(false);
      editDescriptionHandler(false);
    } catch (error) {
    } finally {
      setUpdateDescriptionProgress(false);
    }
  };
  const openNotifyModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to notify all owners of this vote?");
  };
  const openRemoveModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to remove this vote?");
  };

  const handlePersonChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;

    setVendorsLoading(true);
    try {
      setUpdateDescriptionProgress(true);
      const response = await dispatch(
        updateVote({
          voteId: selectedRow?.voteId,
          payload: { persons: newValue },
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRow({
          ...selectedRow,
          persons: newValue,
        });
        showToast(t("Vote Updated"), "success");
      } else if (response.error) {
        showToast(t("Vote Not Updated"), "error");
      }
      setUpdateDescriptionProgress(false);
      editDescriptionHandler(false);

      setVendorsLoading(false);
    } catch (error) {
    } finally {
      setVendorsLoading(false);
    }
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmation={confirmation}
      />
      <Drawer
        anchor={"right"}
        open={rightDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          zIndex: "9",
          "& .MuiBackdrop-root": {
            opacity: "0 !important",
          },
        }}
      >
        <div
          className="detailsSidebar e-control e-sidebar e-lib e-right e-touch e-open e-transition"
          style={{ width: "280px" }}
        >
          <Box className="sidebarVote__details">
            <div className="detailsSidebar__head">
              <Box
                className="sticky-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="cross" onClick={() => toggleDrawer(false)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </div>
              </Box>

              <div className="title-div">
                {editTitle ? (
                  <DrawerTitle
                    title={selectedRow?.title}
                    editHandler={editTitleHandler}
                    idField="voteId"
                    idValue={selectedRow?.voteId}
                    updateHandler={updateTitleHandler}
                    updateProgress={updateTitleProgress}
                  />
                ) : (
                  <>
                    <Typography variant="h5" component="h5">
                      {t("Title")}: {selectedRow?.title || ""}
                    </Typography>
                    <Button onClick={() => editTitleHandler(true)}>
                      <ModeEditIcon
                        fontSize="medium"
                        sx={{ color: `${theme.palette.activeColor}` }}
                      />
                    </Button>
                  </>
                )}
              </div>
              <Grid item>
                <Typography variant="h6" sx={{ marginTop: "20px" }}>
                  {t("Type")} {selectedRow?.type}
                </Typography>
              </Grid>

              <Grid container direction={"row"}>
                <Grid item xs={12} lg={6} md={6}>
                  <Grid
                    container
                    direction={"column"}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">{t("Start Date")}</Typography>
                    </Grid>
                    <Grid item>
                      <DatePickerComp
                        disablePast={false}
                        disableFuture={false}
                        isEditMode={isStartDateMode}
                        className="custom-datepicker"
                        value={
                          selectedRow?.startDate
                            ? dayjs(selectedRow?.startDate)
                            : null
                        }
                        onChange={async (newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          const response = await dispatch(
                            updateVote({
                              voteId: selectedRow?.voteId,
                              payload: {
                                startDate: formattedDate,
                              },
                            })
                          );
                          if (response?.payload?.statusCode === 200) {
                            showToast(t("Vote Updated"), "success");
                          } else if (response.error) {
                            showToast(t("Vote Not Updated"), "error");
                          }
                        }}
                        // style={{ width: "100%", height: "50px" }} // Make the date picker full width
                        disabled={!isBoardRole}
                      />
                      {/* {formik.errors.establishedDate &&
                    formik.touched.establishedDate && (
                      <Typography color={errorColor}>
                        {formik.errors.establishedDate}
                      </Typography>
                    )} */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction={"column"}
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item>
                      <Typography variant="h6">{t("End Date")}</Typography>
                    </Grid>
                    <Grid item>
                      <DatePickerComp
                        disablePast={true}
                        disableFuture={false}
                        isEditMode={isEndDateMode}
                        className="custom-datepicker"
                        value={
                          selectedRow?.endDate
                            ? dayjs(selectedRow?.endDate)
                            : null
                        }
                        onChange={async (newValue: any) => {
                          const formattedDate = newValue
                            ? newValue.format("MM-DD-YYYY")
                            : null;
                          const response = await dispatch(
                            updateVote({
                              voteId: selectedRow?.voteId,
                              payload: {
                                endDate: formattedDate,
                              },
                            })
                          );
                          if (response?.payload?.statusCode === 200) {
                            showToast(t("Vote Updated"), "success");
                          } else if (response.error) {
                            showToast(t("Vote Not Updated"), "error");
                          }
                        }}
                        disabled={!isBoardRole}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="sideGroup">
                <div className="formDropdownField">
                  <InputLabel id="votingGroup">
                    <Typography
                      variant="h5"
                      component="h5"
                      marginTop={"20px"}
                      sx={{ color: `${theme.palette.activeColor}` }}
                    >
                      {t("Voting Group")}
                    </Typography>
                  </InputLabel>
                  <Select
                    labelId="votingGroup"
                    id="votingGroup"
                    value={selectedRow?.votingGroup}
                    placeholder="Select Voting Group"
                    name="votingGroup"
                    displayEmpty
                    onChange={handleGroupChange}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      sx: {
                        "& .MuiMenu-paper": {
                          backgroundColor: `${theme.palette.textWhite}`,
                          color: `${theme.palette.textBlack}`,
                        },
                        "& .MuiMenuItem-root.Mui-disabled": {
                          opacity: 0.7,
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: `${theme.palette.selectedItem}`,
                        },
                        "& .MuiSvgIcon-root-MuiSelect-icon": {
                          color: `${theme.palette.textWhite}`,
                        },
                      },
                    }}
                  >
                    {groupOptions?.map((group: any) => (
                      <MenuItem
                        className="SelectBox"
                        key={group.value}
                        value={group.value}
                      >
                        {t(group.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                {editDescription ? (
                  <DrawerDescription
                    description={selectedRow?.description?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )}
                    editHandler={editDescriptionHandler}
                    idField="voteId"
                    idValue={selectedRow?.voteId}
                    updateHandler={updateDescriptionHandler}
                    updateProgress={updateDescriptionProgress}
                  />
                ) : (
                  <>
                    <div className="SectionBox_title">
                      <label>{t("Description")}:</label>
                      <Button onClick={() => editDescriptionHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </div>
                    <textarea
                      value={
                        selectedRow?.description !== undefined &&
                        selectedRow?.description !== ""
                          ? selectedRow?.description?.replace(
                              /(<([^>]+)>)/gi,
                              ""
                            )
                          : t("No data")
                      }
                      onChange={(e) => {
                        const updatedRowSelect = [...selectedRow];
                        updatedRowSelect[0].description = e.target.value;
                        setSelectedRow(updatedRowSelect);
                      }}
                      disabled={!editDescription}
                    ></textarea>
                  </>
                )}
              </div>

              {selectedRow?.type === "Position Change" && (
                <div className="sideVendors">
                  <FormControl variant="standard" fullWidth>
                    <div className="SectionBox_title">
                      <label>{t("Persons")}:</label>
                    </div>
                    {vendorsLoading || isUpdateInProgress ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <Box style={{ overflow: "visible" }}>
                        <Autocomplete
                          PopperComponent={MuiDropDownPopper}
                          sx={autocompleteChipStyle}
                          multiple
                          id="personsId"
                          options={owners?.filter(
                            (owner: any) =>
                              !selectedRow?.persons?.some(
                                (person: any) =>
                                  person.personId === owner.personId
                              )
                          )}
                          getOptionLabel={(option) => option?.name || ""}
                          defaultValue={selectedRow?.persons}
                          getOptionKey={(option: any) => option?.personId}
                          onChange={handlePersonChange}
                          value={selectedRow?.persons}
                          disableClearable
                          filterSelectedOptions
                          PaperComponent={(props) => (
                            <Paper sx={autoCompletePaperStyle} {...props} />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={autoCompleteTextInputStyle}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </FormControl>
                </div>
              )}
              {selectedRow?.type === "Assessment" && (
                <div className="title-div">
                  {editAmount ? (
                    <DrawerAmount
                      amount={selectedRow?.amount}
                      editHandler={setEditAmount}
                      idField="voteId"
                      updateHandler={updateAmountHandler}
                      idValue={selectedRow?.voteId}
                      updateProgress={updateAmountProgress}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" component="h5">
                        {t("Amount")}:{" "}
                        {selectedRow?.amount !== null &&
                        selectedRow?.amount !== undefined &&
                        !isNaN(selectedRow?.amount)
                          ? formatter.format(selectedRow?.amount)
                          : formatter.format(0)}
                      </Typography>
                      <Button onClick={() => editAmountHandler(true)}>
                        <ModeEditIcon
                          fontSize="medium"
                          sx={{ color: `${theme.palette.activeColor}` }}
                        />
                      </Button>
                    </>
                  )}
                </div>
              )}
              {selectedRow?.type === "Rule Change" && (
                <div className="sideRules">
                  <div className="formDropdownField">
                    <InputLabel id="ruleId">
                      <Typography
                        variant="h5"
                        component="h5"
                        marginTop={"20px"}
                        sx={{ color: `${theme.palette.activeColor}` }}
                      >
                        {t("Rule")}
                      </Typography>
                    </InputLabel>
                    <Select
                      labelId="ruleId"
                      id="ruleId"
                      value={selectedRow?.ruleId}
                      placeholder="Select Rule"
                      name="ruleId"
                      displayEmpty
                      onChange={handleRuleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            backgroundColor: `${theme.palette.textWhite}`,
                            color: `${theme.palette.textBlack}`,
                          },
                          "& .MuiMenuItem-root.Mui-disabled": {
                            opacity: 0.7,
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: `${theme.palette.selectedItem}`,
                          },
                          "& .MuiSvgIcon-root-MuiSelect-icon": {
                            color: `${theme.palette.textWhite}`,
                          },
                        },
                      }}
                    >
                      {allRules?.map((rule: any) => (
                        <MenuItem
                          className="SelectBox"
                          key={rule.ruleId}
                          value={rule.ruleId}
                        >
                          {rule.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              {isBoardRole && (
                <>
                  <Button
                    variant="outlined"
                    className="btn btn-theme"
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 160,
                      maxWidth: "120px",
                      width: "auto",
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      openNotifyModal(selectedRow?.id);
                    }}
                  >
                    {t("Notify")}
                  </Button>
                  <Button
                    variant="outlined"
                    className="btn btn-theme btn-red remove-btn"
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 20,
                      maxWidth: "120px",
                      width: "auto",
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      openRemoveModal(selectedRow?.id);
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </>
              )}
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default VoteDrawer;
