import "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    activeColor: Palette["primary"];
    todo: Palette["primary"];
    inProgress: Palette["primary"];
    done: Palette["primary"];
    invalid: Palette["primary"];
    formText: Palette["primary"];
    textWhite: Palette["primary"];
    textBlack: Palette["primary"];
    deleteText: Palette["primary"];
    modalColor: Palette["primary"];
    closeIcon: Palette["primary"];
    submitButton: Palette["primary"];
    removeButton: Palette["primary"];
    textAreaBackground: Palette["primary"];
    transparent: Palette["primary"];
    placeholder: Palette["primary"];
    selectItemBackground: Palette["primary"];
    radioItemBackground?: PaletteOptions["primary"];
    customBackground: Palette["primary"];
    inputBackground: Palette["primary"];
    hoverBackground: Palette["primary"];
    boxShadow: Palette["primary"];
    continueButton: Palette["primary"];
    logoutBackground: Palette["primary"];
    continueBoxShadow: Palette["primary"];
    menuTabBackground: Palette["primary"];
    drawerBackground: Palette["primary"];
    inActiveText: Palette["primary"];
    activeText: Palette["primary"];
    iconColor: Palette["primary"];
    drawerIconColor: Palette["primary"];
    drawerMenuBorder: Palette["primary"];
    inActiveMenu: Palette["primary"];
    serverError: Palette["primary"];
    unitButton: Palette["primary"];
    selectedItem: Palette["primary"];
    tableHeaderFooter: Palette["primary"];
    paginationRootBg: Palette["primary"];
    muiTableHeadBg: Palette["primary"];
    crossBackground: Palette["primary"];
    sidebarAutoCompleteBg: Palette["primary"];
    calenderBg: Palette["primary"];
    editCalenderBg: Palette["primary"];
    lightModeIconColor: Palette["primary"];
    autoCompleteBg: Palette["primary"];
    carotColor: Palette["primary"];
    lightModeBg: Palette["primary"];
    loaderColor: Palette["primary"];
    PhoneInputCountry: Palette["primary"];
    mapaccordianborder: Palette["primary"];
    mapaccordianbg: Palette["primary"];
    dotColor: Palette["primary"];
    bannerDotColor: Palette["primary"];
    modelBorderColor: Palette["primary"];
    modelInputBorderColor: Palette["primary"];
  }

  interface PaletteOptions {
    activeColor?: PaletteOptions["primary"];
    todo?: PaletteOptions["primary"];
    inProgress?: PaletteOptions["primary"];
    done?: PaletteOptions["primary"];
    invalid?: PaletteOptions["primary"];
    formText?: PaletteOptions["primary"];
    textWhite?: PaletteOptions["primary"];
    textBlack?: PaletteOptions["primary"];
    deleteText?: PaletteOptions["primary"];
    modalColor?: PaletteOptions["primary"];
    closeIcon?: PaletteOptions["primary"];
    submitButton?: PaletteOptions["primary"];
    removeButton?: PaletteOptions["primary"];
    textAreaBackground?: PaletteOptions["primary"];
    transparent?: PaletteOptions["primary"];
    placeholder?: PaletteOptions["primary"];
    selectItemBackground?: PaletteOptions["primary"];
    radioItemBackground?: PaletteOptions["primary"];
    customBackground?: PaletteOptions["primary"];
    hoverBackground?: PaletteOptions["primary"];
    inputBackground?: PaletteOptions["primary"];
    boxShadow?: PaletteOptions["primary"];
    continueButton?: PaletteOptions["primary"];
    logoutBackground?: PaletteOptions["primary"];
    continueBoxShadow: Palette["primary"];
    menuTabBackground?: PaletteOptions["primary"];
    drawerBackground?: PaletteOptions["primary"];
    inActiveText: PaletteOptions["primary"];
    activeText: PaletteOptions["primary"];
    iconColor: PaletteOptions["primary"];
    drawerIconColor: PaletteOptions["primary"];
    drawerMenuBorder: PaletteOptions["primary"];
    inActiveMenu: PaletteOptions["primary"];
    serverError: PaletteOptions["primary"];
    unitButton: PaletteOptions["primary"];
    selectedItem: PaletteOptions["primary"];
    tableHeaderFooter: PaletteOptions["primary"];
    paginationRootBg: PaletteOptions["primary"];
    muiTableHeadBg: PaletteOptions["primary"];
    crossBackground: PaletteOptions["primary"];
    sidebarAutoCompleteBg: PaletteOptions["primary"];
    calenderBg: PaletteOptions["primary"];
    editCalenderBg: PaletteOptions["primary"];
    lightModeIconColor: PaletteOptions["primary"];
    autoCompleteBg: PaletteOptions["primary"];
    carotColor: PaletteOptions["primary"];
    lightModeBg: PaletteOptions["primary"];
    loaderColor: PaletteOptions["primary"];
    PhoneInputCountry: PaletteOptions["primary"];
    mapaccordianborder: PaletteOptions["primary"];
    mapaccordianbg: PaletteOptions["primary"];
    dotColor: PaletteOptions["primary"];
    bannerDotColor: PaletteOptions["primary"];
    modelBorderColor: PaletteOptions["primary"];
    modelInputBorderColor: PaletteOptions["primary"];
  }
}

export const getDesignTokens = (mode: any) => {
  const muiBreakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      custom: 800,
    },
  };

  return {
    typography: {
      fontFamily: "Open Sans",
    },
    palette: {
      mode,
      tableHeaderFooter: "#4b4949",
      paginationRootBg: "#282828",
      muiTableHeadBg: "#dedede",
      primary: {
        main: mode === "light" ? "#fff" : "#000",
      },
      divider: mode === "light" ? "#ccc" : "#575757",
      PhoneInputCountry: mode === "light" ? "#333" : "#cccccc",
      background: {
        default: mode === "light" ? "#fff" : "#000",
        paper: mode === "light" ? "#fbbf24" : "#000e21",
        header: mode === "light" ? "#F2F2F2" : "#272727",
        error: "#FF0000",
        button: "#20c1f5",
        filter: mode === "light" ? "#fff" : "#2d2d2d",
        tableColor: mode === "light" ? "#F2F2F2" : "#2E2E2E",
      },
      text: {
        primary: mode === "light" ? "#000" : "#fff",
        secondary: mode === "light" ? "#27272a" : "#71717a",
        
      },
      autocompletetextcolor: mode === "light" ? "#000" : "#fff",
      invalid: "#f40000",
      activeColor: "#20c1f5",
      inProgress: "#ffc107",
      todo: "#FF0000",
      done: "#28a745",
      sectionBox: mode === "light" ? "#fff" : "#0d0d0d",
      borderLeft: mode === "light" ? "#ccc" : "#30809a",
      valid: "#228C22",
      formText: mode === "light" ? "#000" : "#333",
      textWhite: "#fff",
      textBlack: "#000",
      deleteText: "#ff2205",
      modalColor: "#0069CAB0",
      // closeIcon: "#70a8ba",
      submitButton:
        mode === "light" ? "rgba(32, 193, 245, 0.1490196078)" : "#256098",
      submitBorder: mode === "light" ? "#20C1F5" : "#256098",
      submitDisabled: "#002a51",
      removeButton: "#FF220526",
      textAreaBackground: mode==='light'? '#fff':"#212121",
      transparent: { main: "transparent" },
      placeholder: "#c0c0c0",
      selectItemBackground: "#ccd2cd",
      radioItemBackground: "#ffffff30",
      customBackground:  mode === "light" ? "rgb(255 255 255)" : "rgb(24 24 24)",
      modelBorderColor: { primary: mode === "light" ? "1px solid #bbbbbb" : "1px solid #464646"} ,
      modalBorderColor: { primary: mode === "light" ? "1px solid #bbbbbb" : "1px solid #464646"} ,
      modelInputBorderColor: mode === "light" ? "0.713px solid #a1a1a1" : "0.713px solid #fff",
      inputBorderColor: mode === "light" ? "0.713px solid #a1a1a1" : "0.713px solid #fff",
      inputBackground: mode==='light'? '#fff':"rgba(29, 29, 29, 0.71)",
      hoverBackground: "rgb(0 105 202 / 69%)",
      boxShadow: "rgba(0, 0, 0, 0.54)",
      continueButton: "#000e21",
      logoutBackground: "rgba(0, 105, 202, 0.90)",
      continueBoxShadow: "rgba(0, 0, 0, 0.1)",
      menuTabBackground: mode === "light" ? "#20c1f5" : "#43434366",
      drawerBackground: mode === "light" ? "#fff" : "#202020",
      inActiveText: mode === "light" ? "#828282" : "#fff",
      activeText: mode === "light" ? "#fff" : "#20c1f5",
      iconColor: mode === "light" ? "#a1a1a1" : "#fff",
      drawerIconColor: "#b4b4b4",
      drawerMenuBorder: "#d9d9d9",
      inActiveMenu: "rgb(202 202 202 / 24%)",
      serverError: "#DE1344",
      unitButton: "#256098",
      selectedItem: "rgba(0, 0, 0, 0.16)",
      crossBackground: mode === "light" ? "#fff" : "#181717",
      sidebarAutoCompleteBg:
        mode === "light" ? "#d6d6d6" : "rgba(29, 29, 29, 0.71)",
      calenderBg: mode === "light" ? "#fff" : "#15314a",
      editCalenderBg: mode === "light" ? "#fff" : "#3e3e3e",
      lightModeIconColor: "#9f9a9a",
      autoCompleteBg: "#15314a",
      carotColor: "#b0b0b0",
      lightModeBg: mode === "light" ? "#fff" : "#20c1f526",
      loaderColor: mode === "light" ? "#20c1f5" : "#fff",
      mapaccordianborder:mode === "light" ? "#d6d6d6" : "#2d2d2d",
      mapaccordianbg:"#f5f5f5",
      dotColor:"#575757",
      bannerDotColor:"#ddd",
      closeIcon: mode === "light" ?"#b7b7b7":"#fff",
      autoCompleteBackground: mode === "light" ? "#fff" : "#000",
      inputAssetBorder: {
        primary: mode === "light" ? "#c4c4c4" : "#4d4d4d",
      }
    },
    breakpoints: muiBreakpoints,
  };
};
