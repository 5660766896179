import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  useTheme,
  Link,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../Redux/Store";
import { AddBudget } from "../../../../Redux/Feature/Finance-slice";
import { useTranslation } from "react-i18next";

interface AddBudgetModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
  setIsFirstLoad: (value: boolean) => void;
}

const AddBudgetModal: React.FC<AddBudgetModalProps> = ({
  isOpen,
  onClose,
  associationId,
  setIsFirstLoad,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [, setOpenToast] = useState<boolean>(false);
  const [, setToastMessage] = useState<string>("");
  const [, setToastType] = useState("success");

  const { t } = useTranslation();

  const isFetching = useSelector(
    (state: RootState) => state?.Association?.isFetching
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Budget Name is required")),
    allocatedAmount: Yup.number()
      .typeError(t("Allocated Amount must be a number"))
      .required(t("Allocated Amount is required")),
  });
  const formik = useFormik({
    initialValues: {
      allocatedAmount: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setIsFirstLoad(true);
      const { allocatedAmount, name } = values;

      const budgetPayload = {
        allocatedAmount: allocatedAmount,
        name: name,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        associationId: associationId,
      };
      dispatch(AddBudget(budgetPayload))
        .then((response: any) => {
          if (response?.payload?.statusCode === 200) {
            showToast(t("Successfully added Budget"), "success");
          } else {
            showToast(t("Error"), "error");
          }
        })
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          resetForm();
          setIsFirstLoad(false);
          onClose();
          setLoading(false);
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box
        className="add-document add-association-model"
        sx={{ width: "500px !important", paddingRight: "40px" }}
      >
        <Box
          sx={{ m: "20px 30px 40px" }}
          justifyContent={"center"}
          display="flex"
        >
          <p
            style={{
              color: `${theme.palette.text.primary}`,
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {t("Add Budget")}
          </p>
          <Link sx={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
            <CancelIcon className="support-ticket-cross-icon" />
          </Link>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Budget Name")}
            </label>
            <input
              id="name"
              name="name"
              placeholder={t("Budget Name")}
              style={{ width: "100%" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.name && formik.touched.name && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.name}
              </Typography>
            )}
          </div>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Allocated Amount")}
            </label>
            <input
              id="allocatedAmount"
              name="allocatedAmount"
              placeholder={t("Allocated Amount")}
              style={{ width: "100%" }}
              value={formik.values.allocatedAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.allocatedAmount &&
              formik.touched.allocatedAmount && (
                <Typography color={`${theme.palette.invalid}`}>
                  {formik.errors.allocatedAmount}
                </Typography>
              )}
          </div>

          <Box sx={{ marginTop: "30px" }}>
            <div className="SubmitBtn ticketModel__footer">
              <Button
                className="sBtn e-control e-btn"
                id="targetButton1"
                type="submit"
                disabled={isFetching === true}
              >
                {loading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: `${theme.palette.textWhite}` }}
                  />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddBudgetModal;
