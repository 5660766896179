import { Box } from "@mui/material";
import CreateCommonAsset from "./CreateCommonAsset";

const CommonAssetDrawer = (props: any) => {
  const { loading, rowSelect, updateCommonAssetHandler, asset } = props;
  const isEditMode = rowSelect && rowSelect?.length > 0;

  return (
    <div>
      <Box>
        <div>
          <CreateCommonAsset
            loading={loading}
            // onClose={() => toggleDrawer(false)}
            updateCommonAssetHandler={updateCommonAssetHandler}
            rowSelect={isEditMode ? rowSelect : null}
          />
        </div>
      </Box>
    </div>
  );
};

export default CommonAssetDrawer;
