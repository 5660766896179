import { Box, Grid, Skeleton } from "@mui/material";

const MyAccountSkelton = (props: any) => {
  return (
    <div className="myAccount">
      <Box className="myAccountBox_title" sx={{ mx: 4 }}>
        <Box>
          <Skeleton
            variant={"text"}
            width={800}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
            }}
          />
          <Skeleton
            variant={"text"}
            width={200}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
          <Grid container spacing={3} justifyContent="center">
            {[...Array(3)].map((_, index) => (
              <Grid item key={index}>
                <Skeleton
                  variant={"text"}
                  width={350}
                  height={350}
                  sx={{
                    backgroundColor: "rgb(64 64 64 / 34%)",
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Skeleton
            variant={"text"}
            width={360}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
          <Skeleton
            variant={"text"}
            width={360}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginLeft: "auto",
              marginTop: "30px",
            }}
          />
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default MyAccountSkelton;
