import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./vendorModal.scss";
import { StripeAssociationPaymentSession } from "../../../../Redux/Feature/Roster-slice";
import { useTranslation } from "react-i18next";

interface RemoveMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  stripeConnectId: string;
}

const AddPaymentModal: React.FC<RemoveMemberModalProps> = ({
  isOpen,
  onClose,
  stripeConnectId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const currentAssociation = useSelector(
    (state: any) => state?.Auth?.currentAssociation
  );
  const isFetching = useSelector((state: any) => state?.Manage?.isFetching);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(t("Manager Name is required")),
    amount: Yup.number().required(t("Amount is required")),
  });
  const formik = useFormik({
    initialValues: {
      description: "",
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { description, amount } = values;
      const associationPayload = {
        description: description,
        amount: amount,
        associationId: currentAssociation?.associationId,
        stripeConnectId: stripeConnectId,
        url: window.location.href,
      };
      dispatch(StripeAssociationPaymentSession(associationPayload))
        .then((response: any) => {
          resetForm();
          onClose();
          if (response?.payload?.data?.url) {
            window.location.href = response?.payload?.data?.url;
          }
        })
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          setLoading(false);
          showToast(t("Successfully initiated payment"), description);
        });
    },
  });

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box
        className="add-document add-association-model"
        sx={{ width: "500px !important", paddingRight: "40px" }}
      >
        <Box
          sx={{ m: "20px 30px 40px" }}
          justifyContent={"center"}
          display="flex"
          onClick={() => handleCloseModal()}
        >
          <p
            style={{
              color: `${theme.palette.textWhite}`,
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {t("Add Payment")}
          </p>
          <CancelIcon className="support-ticket-cross-icon" />
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.textWhite}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Description")}
            </label>
            <input
              id="description"
              name="description"
              placeholder={t("Payment Description")}
              style={{ width: "100%" }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.description && formik.touched.description && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.description}
              </Typography>
            )}
          </div>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.textWhite}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Amount")}
            </label>
            <input
              id="amount"
              name="amount"
              placeholder={t("Payment Amount")}
              style={{ width: "100%" }}
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.amount && formik.touched.amount && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.amount}
              </Typography>
            )}
          </div>

          <Box sx={{ marginTop: "30px" }}>
            <div className="SubmitBtn ticketModel__footer">
              <Button
                className="sBtn e-control e-btn"
                id="targetButton1"
                type="submit"
                disabled={isFetching === true}
              >
                {loading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: `${theme.palette.textWhite}` }}
                  />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddPaymentModal;
