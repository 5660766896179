import http from "../api";

const getRuleSet = async (associationId: string, ruleSet: string) => {
  try {
    const response = await http.get(
      `/get-rule-set/${associationId}/${ruleSet}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};
const createRule = async (createRulePayload: any) => {
  try {
    const response = await http.post(`/create-rule`, createRulePayload);
    return response?.data;
  } catch (e) {
    return e;
  }
};
const createVote = async (createVotePayload: any) => {
  try {
    const response = await http.post(`/create-vote`, createVotePayload);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getVote = async (id: any) => {
  try {
    const response = await http.get(`/get-vote/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getVotes = async (associationId: string) => {
  try {
    const response = await http.get(
      `/get-all-votes/?associationId=${associationId}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const createBallot = async (data: any) => {
  try {
    const response = await http.post(`/create-ballot`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const NotifyVote = async (obj: any) => {
  try {
    const response = await http.post(
      `/notify-vote/${obj.voteId}?associationId=${obj.associationId}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const updateVote = async (obj: any) => {
  try {
    const voteId = obj.voteId;
    const payload = obj.payload;
    const response = await http.put(`/update-vote/${voteId}`, { ...payload });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const updateRule = async (obj: any) => {
  try {
    const ruleId = obj.ruleId;
    const payload = obj.payload;
    const response = await http.put(`/update-rule/${ruleId}`, payload);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const deleteRule = async (ruleId: any) => {
  try {
    let response = await http.delete(`/delete-rule/${ruleId}`);
    return response.data;
  } catch (e) {
    return e;
  }
};

const updateRuleSetOrder = async (obj: any) => {
  try {
    const ruleId = obj.ruleId;
    const payload = obj.payload;
    const response = await http.put(
      `/update-rule-set-order/${ruleId}`,
      payload
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};
const deleteVote = async (obj: any) => {
  try {
    const response = await http.delete(
      `/delete-vote/${obj.voteId}?associationId=${obj.associationId}`
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
const Governance = {
  getRuleSet,
  createRule,
  updateRule,
  deleteRule,
  updateRuleSetOrder,
  getVotes,
  createVote,
  updateVote,
  createBallot,
  getVote,
  NotifyVote,
  deleteVote,
};
export default Governance;
