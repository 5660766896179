import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import "./myaccount.scss";
import {
  HandleMyAccountSupportTicketAttachments,
  HandleMyAccountSupportTicketPrivatePublicToggle,
  HandleMyAccountSupportTicketStatus,
  HandleMyAccountSupportTicketType,
  getMyAccount,
  getRequestMyAccountComments,
  postMyAccountCommentRequest,
  updateMyAccountSupportTicketTextFields,
  updateTicket,
  resetTicketDrawerState,
} from "../../Redux/Feature/MyAccount-slice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { generateFullDate } from "../../Utils/dateTime";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditSupportTicketTableTitle from "../Tickets/components/EditSupportTicketTableTitle";
import Toast from "../../Component/Common/Toast/Toast";
import EditSupportTicketStatus from "../Tickets/components/EditSupportTicketStatus";
import { Clear, Search } from "@mui/icons-material";
import SupportTicketDrawer from "../../Component/Drawers/TicketDrawer";
import MyAccountSkelton from "../../Component/Skeletons/MyAccountSkelton";
import { getAllAssetByAssociationId } from "../../Redux/Feature/Ticket-slice";
import { getVendors } from "../../Redux/Feature/Roster-slice";
import {
  GetAllRules,
  stripeResidentBillingSession,
  getAssetsByPerson,
} from "../../Redux/Feature/Auth-slice";
import { useTranslation } from "react-i18next";
import { MEMBER_TYPE, PRICE_TIERS } from "../../Utils/utils";
import VoteModal from "../../Component/Modals/GovernanceModals/Vote/VoteModal";
// import Elite
function isPrivate(row: any) {
  if (row.isPrivate === true) {
    return "No";
  } else {
    return "Yes";
  }
}

const MyAccount = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [updateAccountFieldProgress, setUpdateAccountFieldProgress] =
    useState<boolean>(false);
  const [updateAccountTitleProgress, setUpdatAccountTitleProgress] =
    useState(false);
  const [
    updateAccountDescriptionProgress,
    setUpdateAccountDescriptionProgress,
  ] = useState(false);

  const userData = useSelector((state: any) => state.Auth.user);

  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const [voteModalOpen, setVoteModalOpen] = useState<boolean>(false);

  const mySupportTickets = useSelector(
    (state: any) => state?.MyAccount?.allMySupportTickets
  );
  const myAssets = useSelector(
    (state: RootState) => state?.MyAccount?.myPersonalAssets
  );

  const isOwner =
    currentAssociation?.role === MEMBER_TYPE.GENERAL ||
    currentAssociation?.role === MEMBER_TYPE.BOARD;

  const ticketData: any = useSelector(
    (state: any) => state?.MyAccount?.commentData
  );
  const isFetchingRequest: any = useSelector(
    (state: RootState) => state?.MyAccount?.isFetchingRequest
  );
  const isFetching = useSelector(
    (state: RootState) => state?.MyAccount?.isFetching
  );

  const { t } = useTranslation();

  const [rowSelect, setRowSelected] = useState<any>([]);
  const [commentData, setCommentData] = useState<any>([
    {
      comment: "",
      associationId: currentAssociation.associationId,
      assetId: "",
    },
  ]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false); // New loading state for subscription change

  const [allCommentData, setAllCommentData] = useState<any>([]);
  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [selectedRule, setSelectedRule] = useState<string[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<string[]>([]);
  const [supportTicketData, setSupportTicketData] = useState<any>([]);
  const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] =
    useState(false);
  const [selectedSupportTicketId, setSelectedSupportTicketId] = useState<
    string | null
  >(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>({
    imageUrl: "",
    fileName: "",
    fileExtension: "",
  });
  const [isAttachmentPreviewOpen, setIsAttachmentPreviewOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const [vendorsLoading, setVendorsLoading] = useState<boolean>(false);
  const [rulesLoading, setRulesLoading] = useState<boolean>(false);
  const [rightDrawer, setRightDrawer] = useState<boolean>(false);
  const [assetId, setAssetId] = useState("");
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [selectedVote, setSelectedVote] = useState<any>(null);
  const [selectedVoteAsset, setSelectedVoteAsset] = useState<string>("");
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [editAccountSupportTicketId, setEditAccountSupportTicketId] = useState<
    string | null
  >(null);
  const [
    editAccountStatusSupportTicketId,
    setEditAccountStatusSupportTicketId,
  ] = useState<string | null>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setRightDrawer(false);

    fetchAllAccountData();
  }, [currentAssociation?.associationId]);

  const fetchAllAccountData = async () => {
    if (currentAssociation) {
      setCommentData((prevData: any) => [
        {
          comment: "",
          associationId: currentAssociation?.associationId,
        },
      ]);
      setAssetsLoading(true);
      setVendorsLoading(true);
      setRulesLoading(true);
      try {
        await dispatch(getMyAccount(currentAssociation?.associationId));
        // if (accountResponse.error) {
        //   handleError(accountResponse.payload.data);
        // }
        await dispatch(
          getAllAssetByAssociationId({ id: currentAssociation.associationId })
        );
        // if (assetResponse.error) {
        //   handleError(assetResponse.payload.data);
        // }
        await dispatch(getVendors(currentAssociation.associationId));
        // if (vendorResponse.error) {
        //   handleError(vendorResponse.payload.data);
        // }
        setVendorsLoading(false);
        await dispatch(GetAllRules(currentAssociation.associationId));
        // if (ruleResponse.error) {
        //   handleError(ruleResponse.payload.data);
        // }
        setRulesLoading(false);
        await dispatch(getAssetsByPerson(currentAssociation?.associationId));
        // if (allPersonAssets.error) {
        //   handleError(allPersonAssets.payload.data);
        // }
        setAssetsLoading(false);
      } finally {
        setAssetsLoading(false);
        setVendorsLoading(false);
        setRulesLoading(false);
      }
    }
  };

  const editTitleHandler = (value: boolean) => {
    setEditTitle(value);
  };

  const editDescriptionHandler = (value: boolean) => {
    setEditDescription(value);
  };
  const toggleDrawer = async (value: boolean) => {
    setRightDrawer(value);
    dispatch(resetTicketDrawerState());
  };
  const handleImageClick = (imageUrl: string, fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    setSelectedImageUrl({
      imageUrl,
      fileName,
      fileExtension,
    });
    setIsAttachmentPreviewOpen(true);
  };

  const openAddAttachmentModal = (supportTicketId: any) => {
    setIsAddAttachmentModalOpen(true);
    setSelectedSupportTicketId(supportTicketId);
  };

  const closeAddAttachmentModal = async (uploadedAttachments: string[]) => {
    try {
      setIsAddAttachmentModalOpen(false);
      if (uploadedAttachments?.length > 0) {
        const updatedAttachments = [...ticketData.attachments];
        const transformedAttachments = updatedAttachments.map((attachment) => ({
          s3Key: attachment.key,
        }));
        const attachments = uploadedAttachments.map((key) => ({ s3Key: key }));
        const mergedAttachments = transformedAttachments.concat(attachments);

        const updatedRow = {
          ...rowSelect[0],
          attachments: mergedAttachments,
        };

        setRowSelected([updatedRow]);
        const response = await dispatch(
          HandleMyAccountSupportTicketAttachments(updatedRow)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
        await dispatch(
          getRequestMyAccountComments({ id: rowSelect[0]?.ticketId })
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const getAccountTicketById = async (ticketId: string) => {
    try {
      const response: any = await dispatch(
        getRequestMyAccountComments({ id: ticketId })
      );

      if (response.payload.statusCode === 200) {
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateInProgress(false);
    } catch (error) {
      setUpdateInProgress(false);
      console.error("Error fetching ticket data:", error);
    }
  };

  const handleAssetChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setAssetsLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          assets: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedAsset(response?.payload?.data?.assets);
        showToast(t("Ticket Updated"), "success");
        await getAccountTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setAssetsLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setAssetsLoading(false);
    }
  };
  const handleVendorChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setVendorsLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          vendors: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedVendor(response?.payload?.data?.vendors);
        showToast(t("Ticket Updated"), "success");
        await getAccountTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setVendorsLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setVendorsLoading(false);
    }
  };

  const handleRuleChange = async (e: any, newValue: any) => {
    const {
      target: { value },
    } = e;
    setRulesLoading(true);
    try {
      const response = await dispatch(
        await updateTicket({
          ticketId: rowSelect[0]?.ticketId,
          rules: newValue,
        })
      );
      if (response?.payload?.statusCode === 200) {
        setSelectedRule(response?.payload?.data?.rules);
        showToast(t("Ticket Updated"), "success");
        await getAccountTicketById(rowSelect[0]?.ticketId);
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setRulesLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setRulesLoading(false);
    }
  };

  const handleRemoveAttachment = async (attachmentIndex: number) => {
    try {
      const updatedAttachments = [...ticketData.attachments];
      updatedAttachments.splice(attachmentIndex, 1);
      const transformedAttachments = updatedAttachments.map((attachment) => ({
        s3Key: attachment.key,
      }));
      const updatedRow = {
        ...rowSelect[0],
        attachments: transformedAttachments,
      };
      setRowSelected([updatedRow]);
      const response = await dispatch(
        HandleMyAccountSupportTicketAttachments(updatedRow)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      await dispatch(
        getRequestMyAccountComments({ id: rowSelect[0]?.ticketId })
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClick = () => {
    if (rowSelect.length > 0) {
      const selectedSupportTicketId = rowSelect[0].ticketId;
      openAddAttachmentModal(selectedSupportTicketId);
    }
  };

  useEffect(() => {
    let rules = [];
    let vendors = [];
    let assets = [];
    if (ticketData?.allComments !== undefined) {
      const sortedComments = [...ticketData?.allComments]?.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      if (ticketData?.allComments?.length > 0)
        setAllCommentData(sortedComments);
    }
    if (ticketData?.rules && ticketData?.rules?.length > 0) {
      rules = ticketData?.rules;
    }
    if (ticketData?.vendors && ticketData?.vendors?.length > 0) {
      vendors = ticketData?.vendors;
    }
    if (ticketData?.assets && ticketData?.assets?.length > 0) {
      assets = ticketData?.assets;
    }
    setSelectedRule(rules);
    setSelectedVendor(vendors);
    setSelectedAsset(assets);
  }, [ticketData]);

  useEffect(() => {
    if (mySupportTickets !== undefined) {
      const sortedSupportTicketData = [...mySupportTickets]?.sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      if (sortedSupportTicketData?.length > 0)
        setSupportTicketData(sortedSupportTicketData);
    }
  }, [mySupportTickets]);

  const handleValueChange = async (e: any) => {
    try {
      const selectedStatus = e.target;
      if (rowSelect?.length > 0) {
        const updatedSupportTicket = {
          ...rowSelect[0],
          status: selectedStatus.value,
        };
        setRowSelected([updatedSupportTicket]);
        const response = await dispatch(
          HandleMyAccountSupportTicketStatus(updatedSupportTicket)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleTypeChange = async (e: any) => {
    try {
      const selectedType = e.target;
      if (rowSelect?.length > 0) {
        const updatedSupportTicket = {
          ...rowSelect[0],
          type: selectedType.value,
        };
        setRowSelected([updatedSupportTicket]);
        const response = await dispatch(
          HandleMyAccountSupportTicketType(updatedSupportTicket)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleVisibilityToggle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (rowSelect?.length > 0) {
        const updatedIsPrivate = event.target.checked;
        const updatedRow = { ...rowSelect[0], isPrivate: updatedIsPrivate };
        setRowSelected([updatedRow]);
        const response = await dispatch(
          HandleMyAccountSupportTicketPrivatePublicToggle(updatedRow)
        );
        if (response?.payload?.statusCode === 200) {
          showToast(t("Ticket Updated"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleRowClick = (params: any) => {
    setUpdateInProgress(true);
    const isInputClicked =
      (window.event?.target as Element)?.tagName.toLowerCase() === "input";
    if (!isInputClicked) {
      const isIconClicked = (window.event?.target as Element)?.closest(".icon");
      if (!isIconClicked) {
        const isEditIconClicked = (window.event?.target as Element)?.closest(
          ".support-edit-icon"
        );
        if (!isEditIconClicked) {
          const selectedTicket: any = [params];
          setRowSelected(selectedTicket);
          setCommentData({
            ticketId: selectedTicket[0]?.ticketId,
            personName: userData.name,
            personId: userData.id,
            date: new Date(),
            comment: "",
          });
          toggleDrawer(true);
          setAllCommentData([]);
          dispatch(
            getRequestMyAccountComments({
              id: selectedTicket[0]?.ticketId,
            })
          )
            .then((res: any) => {
              if (res.payload.statusCode === 200) {
                setAssetId(res.payload.data.assetId);
              }
              setUpdateInProgress(false);
            })
            .catch((err: any) => {
              setUpdateInProgress(false);
            });
        }
      }
    }
  };

  const myAccountSupportTicketColumns = [
    {
      id: "title",
      label: t("Title"),
      width: "500px",
      minWidth: "500px",
      maxWidth: "500px",
      cellClassName: "support-title-cell",
    },
    {
      id: "type",
      label: t("Type"),
      width: "110px",
      minWidth: "110px",
      maxWidth: "110px",
      headerClassName: "support-ticket-header",
    },
    {
      id: "status",
      label: t("Status"),
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      id: "date",
      label: t("Date"),
      width: "90px",
      minWidth: "90px",
      maxWidth: "90px",
    },

    {
      id: "isPrivate",
      label: t("Public?"),
      width: "90px",
      minWidth: "90px",
      maxWidth: "90px",
    },
  ];

  const editAccountSupportTicketTitleHandler = (value: boolean) => {
    setEditAccountSupportTicketId(null);
    return;
  };

  const editAccountStatusHandler = (value: boolean) => {
    setEditAccountStatusSupportTicketId(null);
    return;
  };
  const updateAccountSupportTicketTitleHandler = async (updatePayload: any) => {
    try {
      setUpdateAccountFieldProgress(true);
      const response = await dispatch(
        updateMyAccountSupportTicketTextFields(updatePayload)
      );
      if (response?.payload?.statusCode === 200) {
        setEditAccountSupportTicketId(null);
        setRowSelected(response?.payload?.data);
        showToast(t("Ticket Updated"), "success");
        setUpdateAccountFieldProgress(false);
      } else if (response.error) {
        handleError(response.payload.data);
        setEditAccountSupportTicketId(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateAccountFieldProgress(false);
    }
  };

  const updateAccountSupportTicketStatusHandler = async (
    updatePayload: any
  ) => {
    try {
      setUpdateAccountFieldProgress(true);
      const response = await dispatch(
        HandleMyAccountSupportTicketStatus(updatePayload)
      );
      if (response?.payload?.statusCode === 200) {
        setRowSelected(response?.payload?.data);
        setEditAccountStatusSupportTicketId(null);
        showToast(t("Ticket Updated"), "success");
        setUpdateAccountFieldProgress(false);
      } else if (response.error) {
        handleError(response.payload.data);
        setEditAccountStatusSupportTicketId(null);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateAccountFieldProgress(false);
    }
  };

  const updateTitleHandler = async (updatePayload: any) => {
    try {
      setUpdatAccountTitleProgress(true);
      const response = await dispatch(
        updateMyAccountSupportTicketTextFields(updatePayload)
      );
      if (response?.payload?.statusCode === 200) {
        setRowSelected([response?.payload?.data]);
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdatAccountTitleProgress(false);
      setLoading(false);
      editTitleHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdatAccountTitleProgress(false);
      setLoading(false);
    }
  };

  const updateDescriptionHandler = async (updatePayload: any) => {
    try {
      setUpdateAccountDescriptionProgress(true);
      const response = await dispatch(
        updateMyAccountSupportTicketTextFields(updatePayload)
      );
      if (response?.payload?.statusCode === 200) {
        setRowSelected([response?.payload?.data]);
        showToast(t("Ticket Updated"), "success");
      } else if (response.error) {
        handleError(response.payload.data);
      }
      setUpdateAccountDescriptionProgress(false);
      setLoading(false);
      editDescriptionHandler(false);
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setUpdateAccountDescriptionProgress(false);
      setLoading(false);
    }
  };

  const handlePostComment = async (commentData: any) => {
    if (commentData.comment) {
      try {
        setLoading(true);
        const response = await dispatch(
          postMyAccountCommentRequest(commentData)
        );
        if (response?.payload?.statusCode === 200) {
        } else if (response.error) {
          handleError(response.payload.data);
          setLoading(false);
        }
        await dispatch(
          getRequestMyAccountComments({ id: rowSelect[0]?.ticketId })
        );
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Comment data is empty.");
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(
            t("Unauthorized: You are not authorized to perform this action."),
            "error"
          );
          break;
        case 403:
          showToast(
            t("Forbidden: You don't have permission to access this resource."),
            "error"
          );
          break;
        case 404:
          showToast(
            t("Not Found: The requested resource was not found."),
            "error"
          );
          break;
        case 500:
          showToast(
            t("Internal Server Error: Something went wrong on the server."),
            "error"
          );
          break;
        default:
          showToast(
            t("An unexpected error occurred. Please try again later."),
            "error"
          );
          break;
      }
    }
  };

  const getStatusColor = (status: any) => {
    switch (status) {
      case "In Progress":
        return `${theme.palette.inProgress}`;
      case "To Do":
        return `${theme.palette.todo}`;
      default:
        return `${theme.palette.done}`;
    }
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleVoteModalOpen = (payload: any) => {
    setSelectedVote(payload?.vote);
    setSelectedVoteAsset(payload?.assetId);
    setVoteModalOpen(true);
  };
  const handleVoteModalClose = () => {
    setVoteModalOpen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const sortedData = stableSort(
    mySupportTickets,
    getComparator(order, orderBy)
  );

  const filteredData = sortedData.filter((ticket: any) => {
    if (!ticket) {
      return false;
    }
    const { title, status, type, isPrivate } = ticket;
    const formattedDate = moment(ticket.date).format("ll");
    const lowerCaseSearch = search.toLowerCase();

    const isPrivateString = isPrivate ? "No" : "Yes";
    return (
      title.toLowerCase().includes(lowerCaseSearch) ||
      status.toLowerCase().includes(lowerCaseSearch) ||
      type.toLowerCase().includes(lowerCaseSearch) ||
      formattedDate.includes(lowerCaseSearch) ||
      isPrivateString.toLowerCase().includes(lowerCaseSearch)
    );
  });

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClearSearch = () => {
    setSearch("");
  };

  const handleInvoiceClick = async (asset: any) => {
    if (
      currentAssociation?.subscriptionType &&
      currentAssociation?.subscriptionType.startsWith(PRICE_TIERS.ELITE)
    ) {
      setSubscriptionLoading(true);
      const payload = {
        associationId: currentAssociation?.associationId,
        assetId: asset?.assetId,
        url: window.location.href,
      };
      dispatch(stripeResidentBillingSession(payload)).then((response: any) => {
        if (
          response?.payload?.statusCode === 200 &&
          response?.payload?.data?.url
        ) {
          showToast(t("Payments Portal Linked"), "success");
          setSubscriptionLoading(false);
          window.location.href = response?.payload?.data?.url;
        } else {
          showToast(
            t("Your association has not set up billing portals"),
            "error"
          );
          setSubscriptionLoading(false);
        }
      });
    }
  };

  if (isFetching) {
    return <MyAccountSkelton />;
  }

  return (
    <div className="myAccount">
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      {myAssets.length > 0 ? (
        <div>
          <Typography variant="h4" className="heading-class" align="center">
            {t("My Units")}
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            className="gridAssetTable"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {myAssets.map((asset: any) => (
                <Grid item xs={4} sx={{ mb: "10px" }}>
                  <Card className={"my-account-unit-card"}>
                    <CardHeader
                      title={asset.title}
                      align="center"
                      sx={{
                        padding: "18px 20px 6px",
                        fontWeight: "bold",
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Building Address")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ marginBottom: "10px", fontWeight: "300" }}
                      >
                        {asset.address}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Address 2 (Unit Number)")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ fontWeight: "300" }}
                      >
                        {asset.address2}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={`${theme.palette.text.primary}`}
                        sx={{
                          fontSize: "16px",
                          marginBottom: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("Ownership Share")}:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={`${theme.palette.text.primary}`}
                        sx={{ fontWeight: "300" }}
                      >
                        {asset.ownershipShare} %
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        {currentAssociation?.subscriptionType &&
                          currentAssociation?.subscriptionType.startsWith(
                            PRICE_TIERS.ELITE
                          ) &&
                          isOwner && (
                            <Button
                              variant="contained"
                              className="btn"
                              onClick={() => handleInvoiceClick(asset)}
                              sx={{
                                width: "auto !important",
                                paddingX: "16px !important",
                              }}
                              disabled={subscriptionLoading}
                            >
                              {subscriptionLoading ? (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: `${theme.palette.textWhite}`,
                                    fontWeight: "bold",
                                  }}
                                />
                              ) : (
                                t("Invoices")
                              )}
                            </Button>
                          )}
                      </Box>

                      {isOwner && (
                        <Box>
                          <Typography
                            variant="h6"
                            color={`${theme.palette.text.primary}`}
                            align="center"
                            sx={{
                              fontSize: "16px",
                              marginBottom: "4px",
                              marginTop: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {t("Open Ballots")}
                          </Typography>
                          {asset?.openVotes?.length > 0 ? (
                            asset?.openVotes?.map((vote: any) => (
                              // <Typography
                              //   variant="body2"
                              //   color={`${theme.palette.text.primary}`}
                              //   sx={{ fontWeight: "300" }}
                              // >
                              //   {vote.title}
                              // </Typography>
                              <Button
                                variant="contained"
                                className="btn"
                                onClick={() =>
                                  handleVoteModalOpen({
                                    vote,
                                    assetId: asset.assetId,
                                  })
                                }
                                sx={{
                                  width: "auto !important",
                                  paddingX: "16px !important",
                                }}
                              >
                                {vote.title}
                              </Button>
                            ))
                          ) : (
                            <Typography>{t("No Ballots")}</Typography>
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      ) : null}
      <div
        style={{
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        <p className="my-support-ticket-title page_title">{t("My Tickets")}</p>
      </div>

      <div id="wrapper">
        {rowSelect?.length > 0 && (
          <SupportTicketDrawer
            isUpdateInProgress={isUpdateInProgress}
            updateFieldProgress={updateAccountFieldProgress}
            updateTitleProgress={updateAccountTitleProgress}
            updateDescriptionProgress={updateAccountDescriptionProgress}
            rightDrawer={rightDrawer}
            toggleDrawer={toggleDrawer}
            rowSelect={rowSelect}
            setRowSelected={setRowSelected}
            editTitle={editTitle}
            editTitleHandler={editTitleHandler}
            updateTitleHandler={updateTitleHandler}
            handleTypeChange={handleTypeChange}
            handleVisibilityToggle={handleVisibilityToggle}
            handleValueChange={handleValueChange}
            editDescription={editDescription}
            editDescriptionHandler={editDescriptionHandler}
            updateDescriptionHandler={updateDescriptionHandler}
            commentDataById={ticketData}
            handleImageClick={handleImageClick}
            handleRemoveAttachment={handleRemoveAttachment}
            selectedImageUrl={selectedImageUrl}
            isAttachmentPreviewOpen={isAttachmentPreviewOpen}
            setIsAttachmentPreviewOpen={setIsAttachmentPreviewOpen}
            handleClick={handleClick}
            isAddAttachmentModalOpen={isAddAttachmentModalOpen}
            closeAddAttachmentModal={closeAddAttachmentModal}
            selectedSupportTicketId={selectedSupportTicketId}
            setCommentData={setCommentData}
            commentData={commentData}
            handleAssetChange={handleAssetChange}
            handleVendorChange={handleVendorChange}
            handleRuleChange={handleRuleChange}
            assets={selectedAsset}
            vendors={selectedVendor}
            rules={selectedRule}
            handlePostComment={handlePostComment}
            loading={loading}
            rulesLoading={rulesLoading}
            assetsLoading={assetsLoading}
            vendorsLoading={vendorsLoading}
            allCommentData={allCommentData}
            isFetchingRequest={isFetchingRequest}
            showToast={showToast}
          />
        )}
        <div>
          <Box>
            <VoteModal
              isOpen={voteModalOpen}
              onClose={handleVoteModalClose}
              vote={selectedVote}
              voterId={selectedVoteAsset}
            />
            <Box
              className="TableDark TableConnect support-table new-support-table"
              sx={{
                overflow: "unset !important",
                // border:"unset !important",
                marginTop: "50px",
              }}
            >
              <Box sx={{ marginBottom: "16px", textAlign: "end" }}>
                <OutlinedInput
                  sx={{
                    margin: "0 !important",
                    border: "unset !important",
                    "& .MuiInputBase-input": {
                      padding: "6px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${theme.palette.activeColor}`,
                    },
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${theme.palette.activeColor}`,
                      },
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${theme.palette.activeColor}`,
                        borderWidth: "1px",
                      },
                    },
                  }}
                  placeholder={t("Search")}
                  value={search}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {search ? (
                        <IconButton
                          onClick={handleClearSearch}
                          edge="end"
                          className="search-icon-button-hover"
                        >
                          <Clear
                            style={{ color: `${theme.palette.activeColor}` }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          className="search-icon-button-hover"
                        >
                          <Search
                            style={{ color: `${theme.palette.activeColor}` }}
                          />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </Box>
              <Paper
                sx={{
                  width: "100%",
                  mb: 2,
                  border: `1px solid ${theme.palette.activeColor}`,
                }}
              >
                <Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {myAccountSupportTicketColumns.map(
                            (column, index) => (
                              <TableCell
                                sx={{ padding: "16px 8px" }}
                                key={column.id}
                                style={{
                                  width: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                }}
                                sortDirection={
                                  orderBy === column.id ? order : false
                                }
                              >
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id ? order : "asc"
                                  }
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedData.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              {t("No Ticket Found")}
                            </TableCell>
                          </TableRow>
                        ) : (
                          paginatedData.map((row: any) => (
                            <TableRow
                              key={row.id}
                              onClick={() => handleRowClick(row)}
                              sx={{
                                "&. MuiTableCell-root": {
                                  height: "70px",
                                },
                              }}
                            >
                              <TableCell
                                sx={{ height: "64px", padding: "4px 8px" }}
                              >
                                {editAccountSupportTicketId ===
                                row?.ticketId ? (
                                  <EditSupportTicketTableTitle
                                    updateFieldProgress={
                                      updateAccountFieldProgress
                                    }
                                    title={row?.title || ""}
                                    editTitleHandler={
                                      editAccountSupportTicketTitleHandler
                                    }
                                    ticketId={row?.ticketId}
                                    updateSupportTicketHandler={
                                      updateAccountSupportTicketTitleHandler
                                    }
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      minHeight: "28px",
                                    }}
                                  >
                                    <span className="edit-document-text">
                                      {row?.title}
                                    </span>
                                    {editAccountSupportTicketId == null && (
                                      <Button
                                        sx={{ justifyContent: "end" }}
                                        className="support-edit-icon"
                                        onClick={(e) => {
                                          setEditAccountSupportTicketId(
                                            row?.ticketId
                                          );
                                          setEditAccountStatusSupportTicketId(
                                            null
                                          );
                                        }}
                                      >
                                        <ModeEditIcon
                                          fontSize="small"
                                          sx={{
                                            color: `${theme.palette.activeColor}`,
                                          }}
                                        />
                                      </Button>
                                    )}
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ height: "64px", padding: "4px 8px" }}
                              >
                                {t(row.type)}
                              </TableCell>
                              <TableCell
                                sx={{ height: "64px", padding: "4px 8px" }}
                              >
                                {editAccountStatusSupportTicketId ===
                                row?.ticketId ? (
                                  <EditSupportTicketStatus
                                    updateFieldProgress={
                                      updateAccountFieldProgress
                                    }
                                    status={row.status || ""}
                                    editStatusHandler={editAccountStatusHandler}
                                    updateStatusHandler={
                                      updateAccountSupportTicketStatusHandler
                                    }
                                    ticketId={row?.ticketId}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: getStatusColor(row.status),
                                        opacity: 1,
                                      }}
                                    >
                                      {t(row.status)}
                                    </span>
                                    {editAccountStatusSupportTicketId ==
                                      null && (
                                      <Button
                                        sx={{ justifyContent: "end" }}
                                        className="support-edit-icon"
                                        onClick={() => {
                                          setEditAccountStatusSupportTicketId(
                                            row?.ticketId
                                          );
                                          setEditAccountSupportTicketId(null);
                                        }}
                                      >
                                        <ModeEditIcon
                                          fontSize="small"
                                          sx={{
                                            color: `${theme.palette.activeColor}`,
                                          }}
                                        />
                                      </Button>
                                    )}
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ height: "64px", padding: "4px 8px" }}
                              >
                                {generateFullDate(row.date)}
                              </TableCell>

                              <TableCell
                                sx={{ height: "64px", padding: "4px 8px" }}
                              >
                                {isPrivate(row)}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                        {paginatedData?.length === 1 && (
                          <TableRow style={{ height: 40 }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("Rows per page")}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
