import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MapService from "../Service/Map-service";

export const putTickets: any = createAsyncThunk(
  "putTickets/put",
  async (obj: any, thunkAPI) => {
    const data = await MapService.putTickets(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);
export const putAssociations: any = createAsyncThunk(
  "PutAssociations/put",
  async (obj: any, thunkAPI) => {
    const data = await MapService.putAssociations(obj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const uploadAssociationProfilePicture: any = createAsyncThunk(
  "uploadAssociationProfilePicture/put",
  async (key, thunkAPI) => {
    try {
      const data = await MapService.uploadAssociationProfilePicture(key);
      if (data.statusCode === 200) {
        return data;
      } else {
        throw new Error(data?.response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateAssociationDetails: any = createAsyncThunk(
  "updateAssociationDetails/put",
  async (mapDetails: any, thunkAPI) => {
    const data = await MapService.updateAssociationDetails(mapDetails);
    if (data?.statusCode === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data?.response);
    }
  }
);

interface MapState {
  centerLat: number;
  centerLng: number;
  associations: any[];
  tickets: any[];
  uploadAssociationProfilePicture: any;
  buildings: any;
  isFetching: boolean;
  error: string;
  successMessage: string;
}

const MapSlice = createSlice({
  name: "Map",
  initialState: {
    centerLat: 32.715736,
    centerLng: -117.161087,
    associations: [],
    tickets: [],
    uploadAssociationProfilePicture: null,
    buildings: [],
    isFetching: false,
    error: "",
    successMessage: "",
  } as MapState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putTickets.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(putTickets.fulfilled, (state, action) => {
        state.successMessage = action?.payload?.message;
        state.isFetching = false;
        state.tickets = action?.payload?.data;
      })
      .addCase(putTickets.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(putAssociations.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(putAssociations.fulfilled, (state, action) => {
        state.successMessage = action?.payload?.message;
        state.isFetching = false;
        state.associations = action?.payload?.data;
      })
      .addCase(putAssociations.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(updateAssociationDetails.pending, (state) => {
        state.isFetching = true;
        state.successMessage = "";
        state.error = "";
      })
      .addCase(updateAssociationDetails.fulfilled, (state, action) => {
        state.successMessage = action?.payload?.message;
        state.isFetching = false;

        const updatedAssociation = action?.payload?.data;

        const index = state.associations.findIndex(
          (association) =>
            association.associationId === updatedAssociation.associationId
        );

        if (index !== -1) {
          state.associations[index] = {
            ...state.associations[index],
            ...updatedAssociation,
          };
        }
      })
      .addCase(updateAssociationDetails.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(uploadAssociationProfilePicture.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(uploadAssociationProfilePicture.fulfilled, (state, action) => {
        const {
          url: uploadedProfilePicURL,
          association: { associationId },
        } = action.payload.data;
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.error = "";

        // Create a new array with updated associations
        const updatedAssociations = state.associations.map((association) =>
          association.associationId === associationId
            ? { ...association, profile: uploadedProfilePicURL }
            : association
        );

        const newAssociation = updatedAssociations.filter(
          (association) => association.associationId === associationId
        );

        // Update the state with the new array
        state.associations = updatedAssociations;
      })
      .addCase(uploadAssociationProfilePicture.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default MapSlice;
