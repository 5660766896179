import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import "./governance.scss";
import { AppDispatch, RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  createCCR,
  deleteRuleForCCR,
  getCCRs,
  updateRuleForCCR,
  updateRuleSetOrderForCCRs,
} from "../../Redux/Feature/Governance-slice";
import Toast from "../../Component/Common/Toast/Toast";
import CreateRuleComponent from "../../Component/Modals/GovernanceModals/CreateRuleComponent";
import DeleteRuleModal from "../../Component/Modals/GovernanceModals/DeleteRuleModal";
import UpdownRuleList from "./Component/UpdownRuleList";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../Utils/utils";
import RuleListItem from "./Component/RuleListItem";
import SkeletonLoader from "../../Component/Skeletons/SkeletonLoader";
import { useTranslation } from "react-i18next";

const Ccrs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const CCRs = useSelector(
    (state: RootState) => state?.Governance?.getCCRsData
  );
  const [iscreateRuleComponentOpen, setIsCreateRuleComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCCRsItem, setSelectedCCRsItem] = useState<any>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [editRule, setEditRule] = useState<boolean>(false);
  const [isDeleteRuleCCRModalOpen, setIsDeleteRuleCCRModalOpen] =
    useState<boolean>(false);

  const editRuleHandler = (value: boolean, rule?: any) => {
    setEditRule(value);
    setIsCreateRuleComponent(false);
    if (rule) {
      setSelectedCCRsItem(rule);
    }
  };

  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const openCreateRuleComponent = () => {
    setIsCreateRuleComponent(true);
    setEditRule(false);
  };

  const closeCreateRuleComponent = async () => {
    setIsCreateRuleComponent(false);
  };

  const closeEditRuleComponent = async () => {
    setEditRule(false);
  };

  useEffect(() => {
    fetchCCRs();

    const savedSelectedItem = localStorage.getItem("selectedCCRItem");
    if (savedSelectedItem) {
      setSelectedCCRsItem(JSON.parse(savedSelectedItem));
    }
  }, [currentAssociation?.associationId]);

  const fetchCCRs = async () => {
    setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getCCRs({
            associationId: currentAssociation?.associationId,
            ruleSet: "CCR",
          })
        );
        if (response?.payload?.statusCode === 200) {
          setSelectedCCRsItem(response?.payload?.data[0]);
        } else if (response.error) {
          handleError(response.payload.data);
          setSelectedCCRsItem(null);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching CCRs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = async (ccr: any) => {
    setSelectedCCRsItem(ccr);
    localStorage.setItem("selectedCCRItem", JSON.stringify(ccr));
    editRuleHandler(false);
  };

  const createRuleCCRsHandler = async (createRulePayload: any) => {
    try {
      setLoading(true);
      const response: any = await dispatch(createCCR(createRulePayload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Created"), "success");
        const createdCCRData = response?.payload?.data;
        setSelectedCCRsItem(createdCCRData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setIsCreateRuleComponent(false);
    }
  };

  const updateRuleCCRsHandler = async (updateRulePayload: any) => {
    try {
      setLoading(true);
      const response = await dispatch(updateRuleForCCR(updateRulePayload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Updated"), "success");
        const updatedCCRData = response?.payload?.data;
        setSelectedCCRsItem(updatedCCRData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteRuleCCRModal = (rule: any): void => {
    setSelectedCCRsItem(rule);
    setIsDeleteRuleCCRModalOpen(true);
  };

  const updateRuleSetOrderCCRsHandler = async (
    updateRuleSetOrderPayload: any
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        updateRuleSetOrderForCCRs(updateRuleSetOrderPayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Set Updated"), "success");
        const updatedRuleSetOrderForCCRs = response?.payload?.data;
        setSelectedCCRsItem(updatedRuleSetOrderForCCRs[0]);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRuleForCCR = async () => {
    setLoading(true);
    try {
      if (selectedCCRsItem) {
        const response: any = await dispatch(
          deleteRuleForCCR(selectedCCRsItem?.ruleId)
        );
        setIsDeleteRuleCCRModalOpen(false);
        const updatedCCRsList = CCRs.filter(
          (ccr: any) => ccr.ruleId !== response?.payload?.ruleId
        );
        const newSelectedCCRsItem =
          updatedCCRsList.length > 0 ? updatedCCRsList[0] : null;
        setSelectedCCRsItem(newSelectedCCRsItem);
        if (response?.payload?.statusCode === 200) {
          showToast(t("Rule Deleted"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  return (
    <>
      <Box
        className="ccr document document-wrapper"
        sx={{ paddingTop: "0 !important" }}
      >
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />
        <Box className="rule__title">
          <Typography className="rule__title__heading page_title">
            {t("Covenants, Codes, and Restrictions")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sx={{ paddingTop: "0 !important" }}>
            {isBoardRole && (
              <Box
                sx={{
                  textAlign: "end",
                  marginBottom: "12px",
                  visibility: !iscreateRuleComponentOpen ? "visible" : "hidden",
                }}
              >
                <Button
                  className="btn add-rule btn-white"
                  variant="outlined"
                  onClick={openCreateRuleComponent}
                >
                  {t("Create Rule")}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <UpdownRuleList
              ruleList={CCRs}
              handleItemClick={handleItemClick}
              selectedItem={selectedCCRsItem}
              ruleSet="CCR"
              updateRuleSetOrderHandler={updateRuleSetOrderCCRsHandler}
              heading={t("Covenants, Codes, and Restrictions")}
              route="ccrs"
              loading={loading}
              isBoardRole={isBoardRole}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Box sx={{ paddingLeft: "0 !important" }} className="articlePart">
              {iscreateRuleComponentOpen && (
                <CreateRuleComponent
                  isOpen={iscreateRuleComponentOpen}
                  onClose={closeCreateRuleComponent}
                  closeModelOnCrossClick={setIsCreateRuleComponent}
                  ruleSet="CCR"
                  loading={loading}
                  createRuleHandler={createRuleCCRsHandler}
                  lastRuleId={
                    CCRs?.length > 0 ? CCRs[CCRs?.length - 1].ruleId : "root"
                  }
                  heading={t("Covenants, Codes, and Restrictions")}
                />
              )}
              <Box className="SectionBox by-law-sections">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexDirection: "column",
                  }}
                >
                  {editRule ? (
                    <CreateRuleComponent
                      isOpen={editRule}
                      onClose={closeEditRuleComponent}
                      closeModelOnCrossClick={setEditRule}
                      ruleSet="CCR"
                      loading={loading}
                      createRuleHandler={createRuleCCRsHandler}
                      updateRuleHandler={editRule && updateRuleCCRsHandler}
                      lastRuleId={
                        CCRs?.length > 0
                          ? CCRs[CCRs?.length - 1].ruleId
                          : "root"
                      }
                      selectedItem={editRule ? selectedCCRsItem : null}
                      editRuleHandler={editRule && editRuleHandler}
                      heading={t("Covenants, Codes, and Restrictions")}
                    />
                  ) : (
                    <>
                      {CCRs && CCRs?.length > 0 ? (
                        CCRs.map((ccr) => (
                          <RuleListItem
                            key={ccr.ruleId}
                            ruleItem={ccr}
                            isBoardRole={isBoardRole}
                            loading={loading}
                            editRuleHandler={editRuleHandler}
                            handleOpenDeleteRuleModal={
                              handleOpenDeleteRuleCCRModal
                            }
                          />
                        ))
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          {loading ? (
                            <>
                              <SkeletonLoader
                                variants={[
                                  { type: "text", width: 210 },
                                  { type: "text", width: 500 },
                                  {
                                    type: "rectangular",
                                    width: 500,
                                    height: 60,
                                  },
                                  { type: "rounded", width: 500, height: 60 },
                                ]}
                              />
                            </>
                          ) : (
                            <Box className="no-rule-found">
                              <Typography
                                sx={{ color: "#fff", textAlign: "left" }}
                              >
                                {t("No CCR Rule found")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <DeleteRuleModal
          isOpen={isDeleteRuleCCRModalOpen}
          onClose={() => setIsDeleteRuleCCRModalOpen(false)}
          onRemove={handleDeleteRuleForCCR}
          heading={t("Covenants, Codes, and Restrictions")}
        />
      </Box>
    </>
  );
};

export default Ccrs;
