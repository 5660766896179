import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AssetServices from "../Service/Asset-service";

export const CreateComponent: any = createAsyncThunk(
  "CreateComponent/post",
  async (ComponentData, thunkAPI) => {
    const data = await AssetServices.CreateAsset(ComponentData);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addBuildingAPI: any = createAsyncThunk(
  "addBuildingAPI/post",
  async (BuildingData, thunkAPI) => {
    const data = await AssetServices.CreateAsset(BuildingData);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addUnitInsideBuilding: any = createAsyncThunk(
  "addUnitInsideBuilding/post",
  async (BuildingUnitData, thunkAPI) => {
    const data = await AssetServices.CreateAsset(BuildingUnitData);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAllBuildingDirectory: any = createAsyncThunk(
  "getAllBuildings/get",
  async (associationIdObj, thunkAPI) => {
    const data = await AssetServices.getAllBuildingDirectory(associationIdObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createCommonAssetAPI: any = createAsyncThunk(
  "createCommonAssetAPI/post",
  async (commonAssetsDirObj, thunkAPI) => {
    const data = await AssetServices.CreateAsset(commonAssetsDirObj);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAllCommonAssets: any = createAsyncThunk(
  "getAllCommonAssets/get",
  async (associationIdObj, thunkAPI) => {
    const data = await AssetServices.getAllCommonAssetsDirectory(
      associationIdObj
    );
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const deleteUnit: any = createAsyncThunk(
  "deleteUnit/delete",
  async (unitId, thunkAPI) => {
    const data = await AssetServices.deleteUnitAPI(unitId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const deleteBuilding: any = createAsyncThunk(
  "deleteBuilding/delete",
  async (buildingId, thunkAPI) => {
    const data = await AssetServices.deleteBuildingAPI(buildingId);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const updateBuilding: any = createAsyncThunk(
  "updateBuilding/put",
  async (buildingData: any, thunkAPI) => {
    try {
      const data = await AssetServices.UpdateAsset(
        buildingData.assetId,
        buildingData
      );
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateUnit: any = createAsyncThunk(
  "updateUnit/put",
  async (unitData: any, thunkAPI) => {
    try {
      const data = await AssetServices.UpdateAsset(unitData.assetId, unitData);
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getAssetComponents: any = createAsyncThunk(
  "getAssetComponents/get",
  async (assetId: string, thunkAPI) => {
    const data = await AssetServices.getAssetComponents(assetId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const changeUnitPerson: any = createAsyncThunk(
  "changeUnitPerson/put",
  async (unitPersonData: any, thunkAPI) => {
    try {
      const data = await AssetServices.changeUnitPersonAPI(
        unitPersonData.assetId,
        unitPersonData
      );
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCommonAssetComponent = createAsyncThunk(
  "deleteCommonAssetComponent/delete",
  async (assetId: string, thunkAPI) => {
    try {
      const data = await AssetServices.deleteCommonAssetAPI(assetId);
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCommonAsset = createAsyncThunk(
  "deleteCommonAsset/delete",
  async (assetId: string, thunkAPI) => {
    try {
      const data = await AssetServices.deleteCommonAssetAPI(assetId);
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateCommonAssetAPI = createAsyncThunk(
  "updateCommonAsset/put",
  async (
    { id, commonAssetsDirObj }: { id: string; commonAssetsDirObj: any },
    thunkAPI
  ) => {
    try {
      const data = await AssetServices.updateCommonAssetsDirectory(
        id,
        commonAssetsDirObj
      );
      if (data?.statusCode === 200) {
        return data;
      } else {
        throw thunkAPI.rejectWithValue(data?.response);
      }
    } catch (error) {
      throw error;
    }
  }
);
interface AssetState {
  commonAssetDirectory: any[];
  isFetching: boolean;
  error: string;
  buildingDirectory: any[];
  successMessage: string;
  componentList: any[];
  isError: boolean;
  isSuccess: boolean;
  unitCount: number;
  unitSubscriptionCount: number;
}

const AssetSlice = createSlice({
  name: "assets",
  initialState: {
    isFetching: false,
    error: "",
    buildingDirectory: [],
    unitCount: 0,
    componentList: [],
    unitSubscriptionCount: 0,
    commonAssetDirectory: [],
    successMessage: "",
    isError: false,
    isSuccess: false,
  } as AssetState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetComponents.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAssetComponents.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.componentList = action?.payload?.data;
      })
      .addCase(getAssetComponents.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(CreateComponent.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(CreateComponent.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        if (state.componentList && state.componentList.length > 0) {
          state.componentList = [...state.componentList, action?.payload?.data];
        } else {
          state.componentList = [action?.payload?.data];
        }
      })
      .addCase(CreateComponent.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(addBuildingAPI.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addBuildingAPI.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        if (state.buildingDirectory && state.buildingDirectory.length > 0) {
          state.buildingDirectory = [
            ...state.buildingDirectory,
            action?.payload?.data,
          ];
        } else {
          state.buildingDirectory = [action?.payload?.data];
        }
      })
      .addCase(addBuildingAPI.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(addUnitInsideBuilding.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addUnitInsideBuilding.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        const updatedBuildingDirectory = state.buildingDirectory.map(
          (building: any) => {
            if (building.assetId === action.payload.data.buildingId) {
              return {
                ...building,
                units: building.units
                  ? [...building.units, action.payload.data.unit]
                  : [action.payload.data.unit],
              };
            }
            return building;
          }
        );

        state.buildingDirectory = updatedBuildingDirectory;

        const updatedUnitCount = state.unitCount + 1;
        state.unitCount = updatedUnitCount;
      })
      .addCase(addUnitInsideBuilding.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getAllBuildingDirectory.pending, () => {})
      .addCase(getAllBuildingDirectory.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        state.buildingDirectory = action?.payload?.data?.directory;
        if (state.unitCount) {
          state.unitCount = action?.payload?.data?.unitCount;
        } else {
          state.unitCount = 0;
        }
        state.unitSubscriptionCount =
          action?.payload?.data?.unitSubscriptionCount;
      })
      .addCase(getAllBuildingDirectory.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(createCommonAssetAPI.pending, () => {})
      .addCase(createCommonAssetAPI.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.commonAssetDirectory = [
          ...state.commonAssetDirectory,
          action?.payload?.data,
        ];
      })
      .addCase(createCommonAssetAPI.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getAllCommonAssets.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllCommonAssets.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.commonAssetDirectory = action?.payload?.data;
      })
      .addCase(getAllCommonAssets.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(changeUnitPerson.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeUnitPerson.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        const { change, person, assetId } = action.payload.data;

        const updatedBuildingDirectory = state.buildingDirectory.map(
          (building) => {
            const updatedUnits = building.units.map((unit: any) => {
              if (unit.assetId === assetId) {
                if (change === "add") {
                  return {
                    ...unit,
                    persons: [...unit.persons, person],
                  };
                } else if (change === "remove") {
                  return {
                    ...unit,
                    persons: unit.persons.filter(
                      (p: any) => p.email !== person.email
                    ),
                  };
                }
              }
              return unit;
            });
            return { ...building, units: updatedUnits };
          }
        );
        state.buildingDirectory = updatedBuildingDirectory;
      })
      .addCase(changeUnitPerson.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(deleteCommonAssetComponent.pending, () => {})
      .addCase(deleteCommonAssetComponent.fulfilled, (state, action) => {
        const deletedAssetId = action.payload.data.assetId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.componentList = state.componentList.filter(
          (asset: any) => asset.assetId !== deletedAssetId
        );
      })
      .addCase(deleteCommonAssetComponent.rejected, (state, action) => {
        state.isFetching = false;
        state.componentList = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(deleteCommonAsset.pending, () => {})
      .addCase(deleteCommonAsset.fulfilled, (state, action) => {
        const deletedAssetId = action.payload.data.assetId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.commonAssetDirectory = state.commonAssetDirectory.filter(
          (asset: any) => asset.assetId !== deletedAssetId
        );
      })
      .addCase(deleteCommonAsset.rejected, (state, action) => {
        state.isFetching = false;
        state.commonAssetDirectory = [];
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(updateCommonAssetAPI.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateCommonAssetAPI.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        const updatedAsset = action?.payload?.data;
        state.commonAssetDirectory = state.commonAssetDirectory.map((asset) =>
          asset.assetId === updatedAsset.assetId ? updatedAsset : asset
        );
      })
      .addCase(updateCommonAssetAPI.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(deleteUnit.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteUnit.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action.payload.message;
        state.error = "";

        const updatedBuildingDirectory = state.buildingDirectory.map(
          (building: any) => {
            if (building.units) {
              return {
                ...building,
                units: building.units.filter(
                  (unit: any) => unit.assetId !== action?.payload?.data?.assetId
                ),
              };
            }
            return building;
          }
        );
        state.buildingDirectory = updatedBuildingDirectory;
        const updatedUnitCount = state.unitCount - 1;
        state.unitCount = updatedUnitCount;
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });

    builder
      .addCase(deleteBuilding.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteBuilding.fulfilled, (state, action) => {
        const deletedAssetId = action.payload.data.assetId;
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        const buildingDirectoryArray = Array.isArray(state.buildingDirectory)
          ? state.buildingDirectory
          : Array.from(state.buildingDirectory);

        state.buildingDirectory = buildingDirectoryArray.filter(
          (asset: any) => asset.assetId !== deletedAssetId
        );
      })
      .addCase(deleteBuilding.rejected, (state, action) => {
        state.isFetching = false;
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          state.error = action.payload.message as string;
        } else {
          state.error = "Unknown error occurred";
        }
      });
    builder
      .addCase(updateBuilding.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateBuilding.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        const updatedBuildingDirectory = state.buildingDirectory.map(
          (building) => {
            if (building?.assetId === action?.payload?.data?.assetId) {
              return {
                ...building,
                title: action?.payload?.data?.title ?? building?.title,
                address: action?.payload?.data?.address ?? building?.address,
                city: action?.payload?.data?.city ?? building?.city,
                postalCode:
                  action.payload.data.postalCode ?? building.postalCode,
                country: action?.payload?.data?.country ?? building?.country,
                state: action?.payload?.data?.state ?? building?.state,
              };
            }
            return building;
          }
        );

        state.buildingDirectory = updatedBuildingDirectory;
      })
      .addCase(updateBuilding.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(updateUnit.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateUnit.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";

        const updatedBuildingDirectory = state.buildingDirectory.map(
          (building: any) => {
            const updatedUnits = building?.units?.map((unit: any) => {
              if (unit.assetId === action?.payload?.data?.assetId) {
                return {
                  ...unit,
                  persons: action?.payload?.data?.persons ?? unit?.persons,
                  address2: action?.payload?.data?.address2 ?? unit?.address2,
                  floor: action.payload.data.floor ?? unit.floor,
                  title: action?.payload?.data?.title ?? unit?.title,
                  ownershipShare:
                    action?.payload?.data?.ownershipShare ??
                    unit?.ownershipShare,
                };
              }
              return unit;
            });

            return {
              ...building,
              units: updatedUnits,
            };
          }
        );
        state.buildingDirectory = updatedBuildingDirectory;

        state.buildingDirectory = updatedBuildingDirectory;
      })
      .addCase(updateUnit.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default AssetSlice;
