import { Box, Button, Typography, TextField, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const AssociationDetailField = (props: any) => {
  const {
    isEditField,
    fieldLabel,
    fieldValue,
    fieldName,
    placeholder,
    handleChange,
    setisEditField,
    handleSaveField,
    isBoardRole,
    resetUserDataOnCancel,
  } = props;

  const handleCancel = () => {
    setisEditField(false);
    resetUserDataOnCancel();
  };

  const { t } = useTranslation();
  return (
    <Box className="formGroup">
      <Grid container spacing={2}>
        <Grid item md={10} lg={10}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid item md={4} lg={4}>
              <Typography
                className="formlabel"
                sx={{ color: "text.primary", mr: "10px" }}
              >
                {t(fieldLabel)}:
              </Typography>
            </Grid>
            <Grid item md={8} lg={8}>
              {!isEditField ? (
                <Typography
                  className="formlabel"
                  sx={{ color: "text.primary" }}
                >
                  {t(fieldValue)}
                </Typography>
              ) : (
                <Box className="formGroup_inner">
                  <TextField
                    type="text"
                    fullWidth
                    name={fieldName}
                    placeholder={placeholder}
                    value={fieldValue}
                    onChange={(e) => handleChange(e)}
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "text.primary",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "text.primary",
                      },
                      "& .input": {
                        color: "text.primary",
                      },
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          {isBoardRole ? (
            <>
              {!isEditField ? (
                <Button
                  variant="contained"
                  className="btn"
                  onClick={() => setisEditField(!isEditField)}
                >
                  {t("Edit")}
                </Button>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Button
                    sx={{
                      width: "auto !important",
                      paddingX: "16px !important",
                      mr: "10px",
                    }}
                    variant="contained"
                    className="btn"
                    onClick={handleSaveField}
                  >
                    {t("Save")}
                  </Button>
                  <Button
                    sx={{
                      width: "auto !important",
                      paddingX: "16px !important",
                    }}
                    variant="contained"
                    className="btn"
                    onClick={() => handleCancel()}
                  >
                    {t("Cancel")}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssociationDetailField;
