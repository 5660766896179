"use strict";
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useState, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { putAssociations, putTickets } from "../../Redux/Feature/Map-slice";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import "./map.scss";
import profilePhoto from "../../Assets/Images/PNG/profilePhoto.png";
import MapDrawer from "../../Component/Drawers/MapDrawer";
import EditAssociationDetailModal from "../../Component/Modals/MapModals/EditAssociationDetailModal";
import ProposalTable from "./ProposalTable";
import { useTranslation } from "react-i18next";

function Map() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapReference = useRef<HTMLDivElement>(null);
  const [userLocation, setUserLocation] = useState({
    lat: 32.715736,
    lng: -117.161087,
  });
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [rightDrawer, setRightDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState({
    fieldName: "",
    fieldValue: "",
    associationId: "",
    headingValue: "",
  });
  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const [bounds, setBounds] = useState({
    northEastLat: 0,
    northEastLng: 0,
    southWestLat: 0,
    southWestLng: 0,
  });
  const [selectedView, setSelectedView] = useState("map");
  const [selectedMarkerType, setSelectedMarkerType] = useState<any>("tickets");
  const [selectedMarkers, setSelectedMarkers] = useState<any>([]);

  const [isFetching, setIsFetching] = useState(false);

  const mapInstanceRef = useRef<google.maps.Map | null>(null);

  const isVerifier = useSelector(
    (state: RootState) => state?.Auth?.user?.verifier
  );

  const Associations: any = useSelector(
    (state: RootState) => state?.Map?.associations
  );

  const tickets = useSelector((state: RootState) => state?.Map?.tickets);

  const fetchAssociations = async () => {
    if (isFetching) return;
    setIsFetching(true);
    const center: any = mapInstanceRef.current?.getCenter();
    const zoom = mapInstanceRef.current?.getZoom();
    try {
      if (selectedMarkerType === "tickets") {
        await dispatch(
          putTickets({
            ...bounds,
            lat: center?.lat(),
            lng: center?.lng(),
            zoom,
          })
        );
        setSelectedMarkers(tickets);
      } else if (selectedMarkerType === "associations") {
        await dispatch(
          putAssociations({
            ...bounds,
            lat: center?.lat(),
            lng: center?.lng(),
            zoom,
          })
        );
        setSelectedMarkers(Associations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };
  const fetchMap = async () => {
    if (isFetching) return;
    setIsFetching(true);
    if (userLocation?.lat !== 0 && userLocation?.lng !== 0) {
      await initializeMap();
      if (mapInstanceRef.current) {
        const mapInstance = mapInstanceRef.current;
        google.maps.event.addListener(mapInstance, "idle", () => {
          const mapBounds = mapInstance.getBounds();
          if (mapBounds) {
            const northEast = mapBounds.getNorthEast();
            const southWest = mapBounds.getSouthWest();
            const northEastLat = northEast.lat();
            const northEastLng = northEast.lng();
            const southWestLat = southWest.lat();
            const southWestLng = southWest.lng();
            setBounds({
              northEastLat: northEastLat,
              northEastLng: northEastLng,
              southWestLat: southWestLat,
              southWestLng: southWestLng,
            });
          } else {
            console.error("Bounds are not available.");
          }
        });
      }
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (selectedView === "map") {
      fetchMap();
    }
  }, [selectedView]);

  useEffect(() => {
    fetchAssociations();
  }, [bounds, selectedMarkerType]);

  const editMapFieldHandler = async (
    fieldName: any,
    fieldValue: any,
    associationId: any,
    headingValue: any
  ) => {
    setCurrentField({ fieldName, fieldValue, associationId, headingValue });
    setIsModalOpen(true);
  };

  const addMarkers = async () => {
    const { AdvancedMarkerElement, PinElement } =
      (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

    const markers = selectedMarkers?.map((dataItem: any) => {
      const pinBackground = new PinElement({
        background: dataItem.pinBackgroundColor,
      });
      const marker = new AdvancedMarkerElement({
        map: mapInstanceRef.current,
        position: {
          lat: Number(dataItem.lat),
          lng: Number(dataItem.lng),
        },
        title: dataItem.title,
        content: pinBackground.element,
      });
      marker.addListener("click", async () => {
        const profilePicture = dataItem.profile || profilePhoto;
        infoWindow.setContent(
          `<div class="info-window">
              <span class="close-btn" onclick="this.parentElement.style.display='none';">&times;</span>
              <img src="${profilePicture}" alt="Profile Picture" class="profile-picture">
              <div class="content">
            <table>
              <tr>
                <th>${t("Title")}</th>
                <td>${dataItem.title}</td>
                ${
                  isVerifier
                    ? `<td><button class="mapMarkerField" nameAttr='title' valueAttr='${dataItem.title}' associationId="${dataItem.associationId}" headingValueAttr='Title'>&#10000;</button></td>`
                    : ""
                }
              </tr>
              <tr>
                <th>${t("Verified")}</th>
                <td>
                  ${
                    dataItem.verified
                      ? '<span style="color: green;">&#10003; Verified</span>'
                      : '<span style="color: red;">&#10007; Unverified</span>'
                  }
                  ${
                    isVerifier
                      ? `<td><button class="mapMarkerField" nameAttr='verified' valueAttr='${
                          dataItem.verified ? true : false
                        }' associationId="${
                          dataItem.associationId
                        }" headingValueAttr='Verified'>&#10000;</button></td>`
                      : ""
                  }
                </td>
              </tr>
              <tr>
                <th>${t("Unit Count")}</th>
                <td>${dataItem.unitCount}</td>
               ${
                 isVerifier
                   ? `<td><button class="mapMarkerField" nameAttr='unitCount' valueAttr='${dataItem.unitCount}' associationId="${dataItem.associationId}" headingValueAttr='Unit Count'>&#10000;</button></td>`
                   : ""
               }
              </tr>
              <tr>
                <th>${t("Address")}</th>
                <td>${dataItem.formatted_address}</td>
                ${
                  isVerifier
                    ? `<td><button class="mapMarkerField" nameAttr='formatted_address' valueAttr='${dataItem.formatted_address}' associationId="${dataItem.associationId}" headingValueAttr='Address'>&#10000;</button></td>`
                    : ""
                }
              </tr>
              <tr>
                <th>${t("Year Built")}</th>
                <td>${dataItem.yearBuilt}</td>
              ${
                isVerifier
                  ? `<td><button class="mapMarkerField" nameAttr='yearBuilt' valueAttr='${dataItem.yearBuilt}' associationId="${dataItem.associationId}" headingValueAttr='Year Built'>&#10000;</button></td>`
                  : ""
              }
              </tr>
              <tr>
                <th>${t("Max Fee")}</th>
                <td>${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(dataItem.maxFee)}</td>
              ${
                isVerifier
                  ? `<td><button class="mapMarkerField" nameAttr='maxFee' valueAttr='${dataItem.maxFee}' associationId="${dataItem.associationId}" headingValueAttr='Max Fee'>&#10000;</button></td>`
                  : ""
              }
              </tr>
              <tr>
                <th>${t("Min Fee")}</th>
                <td>${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(dataItem.minFee)}</td>
              ${
                isVerifier
                  ? `<td><button class="mapMarkerField" nameAttr='minFee' valueAttr='${dataItem.minFee}' associationId="${dataItem.associationId}" headingValueAttr='Min Fee'>&#10000;</button></td>`
                  : ""
              }
              </tr>
              <tr>
                <th>${t("URL")}</th>
                <td><a href="${
                  dataItem.associationURL || ""
                }" rel="noreferrer" target="_blank">
                  ${dataItem.associationURL ? "URL" : "Unknown"}
                </a></td>
               ${
                 isVerifier
                   ? `<td><button class="mapMarkerField" nameAttr='associationURL' valueAttr='${dataItem.associationURL}' associationId="${dataItem.associationId}" headingValueAttr='URL'>&#10000;</button></td>`
                   : ""
               }
              </tr>
                <tr>
                    <th>${t("Floors")}</th>
                    <td>${
                      dataItem.stories === "nan" ? "Unknown" : dataItem.stories
                    }</td>
                    ${
                      isVerifier
                        ? `<td><button class="mapMarkerField" nameAttr='stories' valueAttr='${dataItem.stories}' associationId="${dataItem.associationId}" headingValueAttr='Stories'>&#10000;</button></td>`
                        : ""
                    }
                  </tr>
        
                  <tr>
                    <th>${t("Pool")}</th>
                    <td>${
                      dataItem.pool === "nan" ? "Unknown" : dataItem.pool
                    }</td>
                    ${
                      isVerifier
                        ? `<td><button class="mapMarkerField" nameAttr='pool' valueAttr='${dataItem.pool}' associationId="${dataItem.associationId}" headingValueAttr='Pool'>&#10000;</button></td>`
                        : ""
                    }
                  </tr>
     
            </table>
          </div>`
        );
        infoWindow.open(mapInstanceRef.current, marker);

        google.maps.event.addListenerOnce(infoWindow, "domready", function () {
          document
            .querySelectorAll(".mapMarkerField")
            .forEach(function (element) {
              element.addEventListener("click", (event) => {
                const target = event.target as HTMLElement;
                const nameAttr = target.getAttribute("nameattr");
                const valueattr = target.getAttribute("valueattr");
                const associationId = target.getAttribute("associationId");
                const headingValue = target.getAttribute("headingValueAttr");
                editMapFieldHandler(
                  nameAttr,
                  valueattr,
                  associationId,
                  headingValue
                );
              });
            });
        });
      });
      return marker;
    });
    new MarkerClusterer({ markers, map: mapInstanceRef.current });
    setSelectedMarkers(markers);
  };

  const legendDetails = [
    { color: "#ff0000", amount: 10 },
    { color: "#00FFFF", amount: 5 },
    { color: "#00FF00", amount: 1 },
    { color: "#ffffff", amount: 0 },
  ];

  useEffect(() => {
    if (!mapInstanceRef.current || selectedMarkers.length === 0) return;
    addMarkers();
  }, [selectedMarkers, selectedMarkerType]);

  const toggleDrawer = (value: boolean) => {
    setRightDrawer(value);
  };

  const initializeMap = async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
      version: "weekly",
    });

    const { Map } = await loader.importLibrary("maps");

    const mapOptions: google.maps.MapOptions = {
      center: userLocation,
      zoom: 14,
      mapId: "safe-web",
    };

    const mapInstance = new Map(
      mapReference.current as HTMLDivElement,
      mapOptions
    );
    mapInstanceRef.current = mapInstance;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.default,
          padding: "8px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: theme.palette.text.primary }}>
            {t("View")}:
          </Typography>
          <Box className="DropdownFieldScss" sx={{ width: 100, marginLeft: 1 }}>
            <FormControl variant="standard" fullWidth>
              <Select
                labelId="duration-select-label"
                id="duration-select"
                value={selectedView}
                onChange={(e) => setSelectedView(e.target.value as string)}
                displayEmpty
              >
                {[
                  { value: "map", text: "Map" },
                  { value: "table", text: "Table" },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {isFetching && selectedView === "map" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{ maxHeight: "30px" }}
          >
            <Typography
              sx={{ color: theme.palette.text.primary, marginRight: 2 }}
            >
              {t("Loading")}...
            </Typography>
            <CircularProgress color="warning" />
          </Box>
        )}
        {isVerifier && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: theme.palette.text.primary }}>
              {t("Marker")}:
            </Typography>
            <Box
              className="DropdownFieldScss"
              sx={{ width: 100, marginLeft: 1 }}
            >
              <FormControl variant="standard" fullWidth>
                <Select
                  labelId="duration-select-label"
                  id="duration-select"
                  value={selectedMarkerType}
                  onChange={(e) =>
                    setSelectedMarkerType(e.target.value as string)
                  }
                  displayEmpty
                >
                  {[
                    { value: "tickets", text: "Tickets" },
                    { value: "associations", text: "Associations" },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
      {selectedView === "map" ? (
        <Box sx={{ position: "relative", flexGrow: 1 }}>
          <div ref={mapReference} style={{ height: "100%" }}></div>
          {selectedMarkerType === "tickets" && (
            <div className="map-legend">
              <h3>{t("Legend")}</h3>
              <ul>
                {legendDetails.map((detail, index) => (
                  <li key={index}>
                    <span
                      className="legend-color"
                      style={{ backgroundColor: detail.color }}
                    ></span>
                    {detail.amount} {t("tickets")}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Box sx={{ position: "absolute", top: 16, right: 16, zIndex: 10 }}>
            {!rightDrawer && (
              <Box>
                <Button
                  variant="contained"
                  onClick={() => toggleDrawer(true)}
                  sx={{ marginRight: 8 }}
                >
                  {t("Open Drawer")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <ProposalTable />
      )}
      <MapDrawer
        rightDrawer={rightDrawer}
        toggleDrawer={toggleDrawer}
        associations={selectedMarkers}
        isVerifier={isVerifier}
        showToast={showToast}
      />
      <EditAssociationDetailModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentField={currentField}
        theme={theme}
      />
    </Box>
  );
}

export default Map;
