import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import "./governance.scss";
import { AppDispatch, RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  createRulesAndRegulation,
  deleteRuleForRulesAndRegulation,
  getRulesAndRegulation,
  updateRuleForRulesAndRegulation,
  updateRuleSetOrderForRulesAndRegulation,
} from "../../Redux/Feature/Governance-slice";
import Toast from "../../Component/Common/Toast/Toast";
import CreateRuleComponent from "../../Component/Modals/GovernanceModals/CreateRuleComponent";
import DeleteRuleModal from "../../Component/Modals/GovernanceModals/DeleteRuleModal";
import UpdownRuleList from "./Component/UpdownRuleList";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../Utils/utils";
import RuleListItem from "./Component/RuleListItem";
import SkeletonLoader from "../../Component/Skeletons/SkeletonLoader";
import { useTranslation } from "react-i18next";

const RulesAndRegulations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const RulesAndRegulation = useSelector(
    (state: RootState) => state?.Governance?.getRulesAndRegulationData
  );
  const [iscreateRuleComponentOpen, setIsCreateRuleComponent] = useState(false);
  const [selectedRulesAndRegulationsItem, setSelectedRulesAndRegulationsItem] =
    useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [editRule, setEditRule] = useState<boolean>(false);
  const [
    isRuleDeleteRulesAndRegulationsModalOpen,
    setIsRuleDeleteRulesAndRegulationsModalOpen,
  ] = useState<boolean>(false);

  const editRuleHandler = (value: boolean, rule?: any) => {
    setEditRule(value);
    setIsCreateRuleComponent(false);
    if (rule) {
      setSelectedRulesAndRegulationsItem(rule);
    }
  };

  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const openCreateRuleComponent = () => {
    setIsCreateRuleComponent(true);
    setEditRule(false);
  };

  const closeCreateRuleComponent = async () => {
    setIsCreateRuleComponent(false);
  };

  const closeEditRuleComponent = async () => {
    setEditRule(false);
  };

  useEffect(() => {
    fetchRulesAndRegulations();

    const savedSelectedItem = localStorage.getItem(
      "selectedRulesAndRegulationsItem"
    );
    if (savedSelectedItem) {
      setSelectedRulesAndRegulationsItem(JSON.parse(savedSelectedItem));
    }
  }, [currentAssociation?.associationId]);

  const fetchRulesAndRegulations = async () => {
    setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getRulesAndRegulation({
            associationId: currentAssociation?.associationId,
            ruleSet: "RuleAndRegulation",
          })
        );
        if (response?.payload?.statusCode === 200) {
          setSelectedRulesAndRegulationsItem(response?.payload?.data[0]);
        } else if (response.error) {
          handleError(response.payload.data);
          setSelectedRulesAndRegulationsItem(null);
        }
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching rules and regulations:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = async (ruleandregulation: any) => {
    setSelectedRulesAndRegulationsItem(ruleandregulation);
    localStorage.setItem(
      "selectedRulesAndRegulationsItem",
      JSON.stringify(ruleandregulation)
    );
    editRuleHandler(false);
  };

  const createRuleByRuleAndRegulationHandler = async (
    createRulePayload: any
  ) => {
    try {
      setLoading(true);
      const response: any = await dispatch(
        createRulesAndRegulation(createRulePayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Created"), "success");
        const createdRuleAndRegulationData = response?.payload?.data;
        setSelectedRulesAndRegulationsItem(createdRuleAndRegulationData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setIsCreateRuleComponent(false);
    }
  };

  const updateRuleByRuleAndRegulationHandler = async (
    updateRulePayload: any
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        updateRuleForRulesAndRegulation(updateRulePayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Updated"), "success");
        const updatedRuleAndRegulationData = response?.payload?.data;
        setSelectedRulesAndRegulationsItem(updatedRuleAndRegulationData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteRuleAndRegulationModal = (rule: any): void => {
    setSelectedRulesAndRegulationsItem(rule);
    setIsRuleDeleteRulesAndRegulationsModalOpen(true);
  };

  const updateRuleSetOrderRuleAndRegulationHandler = async (
    updateRuleSetOrderPayload: any
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        updateRuleSetOrderForRulesAndRegulation(updateRuleSetOrderPayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Set Updated"), "success");
        const updatedRuleSetOrderForRuleAndRegulation = response?.payload?.data;
        setSelectedRulesAndRegulationsItem(
          updatedRuleSetOrderForRuleAndRegulation[0]
        );
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteRuleForRuleAndRegulation = async () => {
    setLoading(true);
    try {
      if (selectedRulesAndRegulationsItem) {
        const response: any = await dispatch(
          deleteRuleForRulesAndRegulation(
            selectedRulesAndRegulationsItem?.ruleId
          )
        );
        setIsRuleDeleteRulesAndRegulationsModalOpen(false);
        const updatedRuleAndRegulationList = RulesAndRegulation.filter(
          (ruleandregulation: any) =>
            ruleandregulation.ruleId !== response?.payload?.ruleId
        );
        const newSelectedRuleAndRegulationItem =
          updatedRuleAndRegulationList.length > 0
            ? updatedRuleAndRegulationList[0]
            : null;
        setSelectedRulesAndRegulationsItem(newSelectedRuleAndRegulationItem);
        if (response?.payload?.statusCode === 200) {
          showToast(t("Rule Deleted"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };

  return (
    <>
      <Box
        className="ccr document document-wrapper"
        sx={{ paddingTop: "0 !important" }}
      >
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />
        <Box className="rule__title">
          <Typography className="rule__title__heading page_title">
            {t("Rules and Regulations")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sx={{ paddingTop: "0 !important" }}>
            {isBoardRole && (
              <Box
                sx={{
                  textAlign: "end",
                  marginBottom: "12px",
                  visibility: !iscreateRuleComponentOpen ? "visible" : "hidden",
                }}
              >
                <Button
                  className="btn add-rule btn-white"
                  variant="outlined"
                  onClick={openCreateRuleComponent}
                >
                  {t("Create Rule")}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <UpdownRuleList
              ruleList={RulesAndRegulation}
              selectedItem={selectedRulesAndRegulationsItem}
              handleItemClick={handleItemClick}
              ruleSet="RuleAndRegulation"
              heading={t("Rules and Regulations")}
              route="rulesandregulations"
              updateRuleSetOrderHandler={
                updateRuleSetOrderRuleAndRegulationHandler
              }
              loading={loading}
              isBoardRole={isBoardRole}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Box sx={{ paddingLeft: "0 !important" }} className="articlePart">
              {iscreateRuleComponentOpen && (
                <CreateRuleComponent
                  isOpen={iscreateRuleComponentOpen}
                  onClose={closeCreateRuleComponent}
                  closeModelOnCrossClick={setIsCreateRuleComponent}
                  ruleSet="RuleAndRegulation"
                  loading={loading}
                  createRuleHandler={createRuleByRuleAndRegulationHandler}
                  lastRuleId={
                    RulesAndRegulation?.length > 0
                      ? RulesAndRegulation[RulesAndRegulation?.length - 1]
                          .ruleId
                      : "root"
                  }
                  heading={t("Rules and Regulations")}
                />
              )}
              <Box className="SectionBox by-law-sections">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexDirection: "column",
                  }}
                >
                  {editRule ? (
                    <CreateRuleComponent
                      isOpen={editRule}
                      onClose={closeEditRuleComponent}
                      closeModelOnCrossClick={setEditRule}
                      ruleSet="RuleAndRegulation"
                      loading={loading}
                      createRuleHandler={createRuleByRuleAndRegulationHandler}
                      updateRuleHandler={
                        editRule && updateRuleByRuleAndRegulationHandler
                      }
                      lastRuleId={
                        RulesAndRegulation?.length > 0
                          ? RulesAndRegulation[RulesAndRegulation?.length - 1]
                              .ruleId
                          : "root"
                      }
                      selectedItem={
                        editRule ? selectedRulesAndRegulationsItem : null
                      }
                      editRuleHandler={editRule && editRuleHandler}
                      heading={t("Rules and Regulations")}
                    />
                  ) : (
                    <>
                      {RulesAndRegulation && RulesAndRegulation?.length > 0 ? (
                        RulesAndRegulation.map((ruleandregulation) => (
                          <RuleListItem
                            ruleItem={ruleandregulation}
                            isBoardRole={isBoardRole}
                            loading={loading}
                            editRuleHandler={editRuleHandler}
                            handleOpenDeleteRuleModal={
                              handleOpenDeleteRuleAndRegulationModal
                            }
                          />
                        ))
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          {loading ? (
                            <>
                              <SkeletonLoader
                                variants={[
                                  { type: "text", width: 210 },
                                  { type: "text", width: 500 },
                                  {
                                    type: "rectangular",
                                    width: 500,
                                    height: 60,
                                  },
                                  { type: "rounded", width: 500, height: 60 },
                                ]}
                              />
                            </>
                          ) : (
                            <Box className="no-rule-found">
                              <Typography
                                sx={{ color: "#fff", textAlign: "left" }}
                              >
                                {t("No Rule and Regulation found")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <DeleteRuleModal
          isOpen={isRuleDeleteRulesAndRegulationsModalOpen}
          onClose={() => setIsRuleDeleteRulesAndRegulationsModalOpen(false)}
          onRemove={handleDeleteRuleForRuleAndRegulation}
          heading={t("Rules and Regulations")}
        />
      </Box>
    </>
  );
};

export default RulesAndRegulations;
