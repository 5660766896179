import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SpinnerLoader from "../../Common/SpinnerLoader/SpinnerLoader";
import { updateAssociationDetails } from "../../../Redux/Feature/Map-slice";
import { ERROR_MESSAGES } from "../../../Utils/utils";
import Toast from "../../Common/Toast/Toast";
import { useTranslation } from "react-i18next";

interface EditAssociationDetailModalProps {
  open: boolean;
  onClose: () => void;
  theme: any;
  currentField: any;
}

const EditAssociationDetailModal: React.FC<EditAssociationDetailModalProps> = ({
  open,
  onClose,
  theme,
  currentField,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    formFieldName: Yup.string().required(t("* This Field is required")),
  });

  const handleSave = async (formData: any) => {
    try {
      // Convert formData.verified from string to boolean
      if (formData.verified === "true") {
        formData.verified = true;
      } else if (formData.verified === "False") {
        formData.verified = false;
      }
      const response = await dispatch(updateAssociationDetails(formData));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Successfully updated Association"), "success");
        formik.resetForm();
      } else if (response.error) {
        handleError(response?.payload?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    onClose();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      formFieldName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      setLoading(true);
      const { formFieldName } = values;
      const associationPayload = {
        [currentField.fieldName]: formFieldName,
        associationId: currentField.associationId,
      };
      handleSave(associationPayload);
    },
  });

  useEffect(() => {
    if (currentField.fieldValue) {
      formik.setFieldValue("formFieldName", currentField.fieldValue);
    }
  }, [currentField]);

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        message={`${toastMessage}.`}
        handleClose={handleCloseToast}
        type={toastType as "success" | "error"}
      />
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="add-document add-association-model edit-map-association"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <SpinnerLoader loading={loading} />
          <form onSubmit={formik.handleSubmit}>
            <div
              className="e-input-group"
              style={{ marginBottom: "20px", width: "100%", marginTop: "16px" }}
            >
              <Typography
                id="modal-title"
                sx={{
                  textTransform: "capitalize",
                  color: `${theme.palette.textWhite}`,
                }}
              >
                {currentField?.headingValue}
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                className="request-support-field"
                name="formFieldName"
                value={formik.values.formFieldName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={value}
                // onChange={(e) => onValueChange(e.target.value)}
              />
              {formik.errors.formFieldName && formik.touched.formFieldName && (
                <Typography color={`${theme.palette.invalid}`}>
                  {t("* This field is required")}
                </Typography>
              )}
            </div>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Button
                className="edit-map-button btn-border"
                onClick={() => {
                  formik.resetForm();
                  onClose();
                }}
                sx={{ mr: 1 }}
              >
                {t("Cancel")}
              </Button>
              <Button className="edit-map-button " type="submit">
                {t("Save")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditAssociationDetailModal;
