import React from "react";
import profilePhoto from "../../../Assets/Images/PNG/profilePhoto.png";

interface ProfileImageTemplateProps {
  profilePicture: string;
}

const ProfileImageTemplate: React.FC<ProfileImageTemplateProps> = ({
  profilePicture,
}) => {
  if (!profilePicture) {
    return (
      <img
        src={profilePhoto}
        alt="Dummy Profile"
        style={{ width: "40px", height: "40px", borderRadius: "50%" }}
      />
    );
  }

  return (
    <img
      src={profilePicture}
      alt="Profile"
      style={{ width: "40px", height: "40px", borderRadius: "50%" }}
    />
  );
};

export default ProfileImageTemplate;
