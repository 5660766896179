import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Manage from "../Service/Roster-service";
import Governance from "../Service/Governance-service";

interface ManageState {
  allBoards: any[];
  allGeneralMembers: any[];
  allTenants: any[];
  allVendors: any[];
  allManagers: any[];
  boardVotes: any;
  selectedVote: any;
  owners: any[];
  isFetching: boolean;
  successMessage: string;
  error: string;
  boiInformation: any[];
  boiSubmissions: any[];
}

const initialState: ManageState = {
  allBoards: [],
  allGeneralMembers: [],
  allTenants: [],
  allVendors: [],
  allManagers: [],
  boiInformation: [],
  boiSubmissions: [],
  boardVotes: {
    submittedVotes: [],
    openVotes: [],
  },
  selectedVote: {},
  owners: [],
  isFetching: false,
  successMessage: "",
  error: "",
};

export const getOwners: any = createAsyncThunk(
  "getOwners/get",
  async (associationId, thunkAPI) => {
    const data = await Manage.getOwners(associationId);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getBoardVotes: any = createAsyncThunk(
  "getBoardVotes/get",
  async (associationId, thunkAPI) => {
    const data = await Manage.getBoardVotes(associationId);
    if (data.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getAllBoard: any = createAsyncThunk(
  "getAllBoard/get",
  async (associationId, thunkAPI) => {
    const data = await Manage.getAllBoard(associationId);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getGeneralMembers: any = createAsyncThunk(
  "getGeneralMembers/get",
  async (associationId, thunkAPI) => {
    const data = await Manage.getGeneralMembers(associationId);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const createBallot: any = createAsyncThunk(
  "createBallot/post",
  async (data, thunkAPI) => {
    const res = await Governance.createBallot(data);
    if (res?.statusCode === 200) {
      return res?.data;
    } else {
      throw thunkAPI.rejectWithValue(res?.response);
    }
  }
);

export const getVendors: any = createAsyncThunk(
  "getVendors/get",
  async (associationId, thunkAPI) => {
    const data = await Manage.getVendors(associationId);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const removeVendor: any = createAsyncThunk(
  "removeVendor/post",
  async (removeDataObj: any, thunkAPI) => {
    const data = await Manage.removeVendor(removeDataObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addManager: any = createAsyncThunk(
  "addManager/post",
  async (addDataObj: any, thunkAPI) => {
    const data = await Manage.addManager(addDataObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addVendor: any = createAsyncThunk(
  "addVendor/post",
  async (addDataObj: any, thunkAPI) => {
    const data = await Manage.addVendor(addDataObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const removeBoardMember: any = createAsyncThunk(
  "removeBoardMember/put",
  async (removeDataObj: any, thunkAPI) => {
    const data = await Manage.removeBoardMember(removeDataObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const addBoardMember: any = createAsyncThunk(
  "addBoardMember/put",
  async (addMemberObj: any, thunkAPI) => {
    const data = await Manage.addBoardMember(addMemberObj);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const StripeAssociationPaymentSession: any = createAsyncThunk(
  "StripeAssociationPaymentSession/post",
  async (payload: any, thunkAPI) => {
    const data = await Manage.StripeAssociationPaymentSession(payload);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const GetBOIInformation: any = createAsyncThunk(
  "GetBOIInformation/get",
  async (associationId: any, thunkAPI) => {
    const data = await Manage.GetBOIInformation(associationId);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const GetBOISubmissions: any = createAsyncThunk(
  "GetBOISubmissions/get",
  async (associationId: any, thunkAPI) => {
    const data = await Manage.GetBOISubmissions(associationId);
    if (data?.statusCode === 200) {
      return data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

export const getVote: any = createAsyncThunk(
  "getVote/get",
  async (id, thunkAPI) => {
    const data = await Governance.getVote(id);
    if (data?.statusCode === 200) {
      return data?.data;
    } else {
      throw thunkAPI.rejectWithValue(data?.response);
    }
  }
);

const ManageSlice = createSlice({
  name: "Board",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allVendors = action?.payload?.vendors;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getBoardVotes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getBoardVotes.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.error = "";
        state.boardVotes = action?.payload?.data;
        state.boardVotes.openVotes = state.boardVotes.openVotes;
        state.boardVotes.submittedVotes = state.boardVotes.submittedVotes;
      })
      .addCase(getBoardVotes.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(GetBOISubmissions.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetBOISubmissions.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.boiSubmissions = action?.payload?.data;
      })
      .addCase(GetBOISubmissions.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(GetBOIInformation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetBOIInformation.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.boiInformation = action?.payload?.data;
      })
      .addCase(GetBOIInformation.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getOwners.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getOwners.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.owners = action?.payload;
      })
      .addCase(getOwners.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getAllBoard.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllBoard.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allBoards = action?.payload;
      })
      .addCase(getAllBoard.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(createBallot.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createBallot.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.boardVotes.openVotes = state.boardVotes.openVotes.filter(
          (vote: any) => vote.id !== state.selectedVote.id
        );
        state.boardVotes.submittedVotes.push(state.selectedVote);
        state.selectedVote = {};
      })
      .addCase(createBallot.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(getGeneralMembers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getGeneralMembers.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allGeneralMembers = action.payload;
      })
      .addCase(getGeneralMembers.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(addManager.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addManager.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;

        state.allManagers = [...state.allManagers, action.payload.data];
      })
      .addCase(addManager.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(getVote.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getVote.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload.message;
        state.error = "";
        state.selectedVote = action?.payload?.vote;
      })
      .addCase(getVote.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(addVendor.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allVendors = [...state.allVendors, action.payload.data];
      })
      .addCase(addVendor.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(removeVendor.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(removeVendor.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
        state.allVendors = state.allVendors.filter(
          (vendor) => vendor?.personId !== action?.payload?.data?.personId
        );
        state.allManagers = state.allManagers.filter(
          (manager) => manager?.personId !== action?.payload?.data?.personId
        );
      })
      .addCase(removeVendor.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(removeBoardMember.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(removeBoardMember.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
      })
      .addCase(removeBoardMember.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
    builder
      .addCase(addBoardMember.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addBoardMember.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
      })
      .addCase(addBoardMember.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });

    builder
      .addCase(StripeAssociationPaymentSession.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(StripeAssociationPaymentSession.fulfilled, (state, action) => {
        state.isFetching = false;
        state.successMessage = action?.payload?.message;
      })
      .addCase(StripeAssociationPaymentSession.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action?.payload?.data?.message;
      });
  },
});

export default ManageSlice;
