import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import PublicFooter from "../Footer/Footer";

const PublicLayout = () => {
  const theme = useTheme();

  return (
    <Box
      className={
        theme.palette.mode === "dark"
          ? "pageWrapper"
          : "pageWrapper pageWrapper--light"
      }
      sx={{ color: "background.default" }}
    >
      <Box>
        <Outlet />
      </Box>
      <PublicFooter />
    </Box>
  );
};

export default PublicLayout;
