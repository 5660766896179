import http from "../api";

const getMyAccount = async (associationId: any) => {
  try {
    const response = await http.get(`/my-account/${associationId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

const MyAccount = {
  getMyAccount,
};
export default MyAccount;
