import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import "./governance.scss";
import { AppDispatch, RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  createByLaw,
  deleteRuleForByLaw,
  getByLaws,
  updateRuleForByLaw,
  updateRuleSetOrderForByLaw,
} from "../../Redux/Feature/Governance-slice";
import Toast from "../../Component/Common/Toast/Toast";
import CreateRuleComponent from "../../Component/Modals/GovernanceModals/CreateRuleComponent";
import DeleteRuleModal from "../../Component/Modals/GovernanceModals/DeleteRuleModal";
import UpdownRuleList from "./Component/UpdownRuleList";
import { ERROR_MESSAGES, MEMBER_TYPE } from "../../Utils/utils";
import RuleListItem from "./Component/RuleListItem";
import SkeletonLoader from "../../Component/Skeletons/SkeletonLoader";
import { useTranslation } from "react-i18next";

const ByLaws = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );
  const ByLaws = useSelector(
    (state: RootState) => state?.Governance?.getByLawsData
  );
  const [iscreateRuleComponentOpen, setIsCreateRuleComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedByLawItem, setSelectedByLawItem] = useState<any>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");
  const [editRule, setEditRule] = useState<boolean>(false);
  const [isDeleteRuleByLawModalOpen, setIsDeleteRuleByLawModalOpen] =
    useState<boolean>(false);

  const editRuleHandler = (value: boolean, rule?: any) => {
    setEditRule(value);
    setIsCreateRuleComponent(false);
    if (rule) {
      setSelectedByLawItem(rule);
    }
  };

  const isBoardRole =
    currentAssociation?.role === MEMBER_TYPE.BOARD ||
    currentAssociation?.role === MEMBER_TYPE.MANAGER;

  const openCreateRuleComponent = () => {
    setIsCreateRuleComponent(true);
    setEditRule(false);
  };

  const closeCreateRuleComponent = async () => {
    setIsCreateRuleComponent(false);
  };

  const closeEditRuleComponent = async () => {
    setEditRule(false);
  };

  useEffect(() => {
    fetchBylaws();

    const savedSelectedItem = localStorage.getItem("selectedByLawItem");
    if (savedSelectedItem) {
      setSelectedByLawItem(JSON.parse(savedSelectedItem));
    }
  }, [currentAssociation?.associationId]);
  const fetchBylaws = async () => {
    setLoading(true);
    try {
      if (currentAssociation) {
        const response: any = await dispatch(
          getByLaws({
            associationId: currentAssociation?.associationId,
            ruleSet: "ByLaw",
          })
        );
        if (response?.payload?.statusCode === 200) {
          setSelectedByLawItem(response?.payload?.data[0]);
        } else if (response.error) {
          handleError(response.payload.data);
          setSelectedByLawItem(null);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching By Laws:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = async (law: any) => {
    setSelectedByLawItem(law);
    localStorage.setItem("selectedByLawItem", JSON.stringify(law));
    editRuleHandler(false);
  };

  const createRuleByLawHandler = async (createRulePayload: any) => {
    try {
      setLoading(true);
      const response: any = await dispatch(createByLaw(createRulePayload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Created"), "success");
        const createdBylawData = response?.payload?.data;
        setSelectedByLawItem(createdBylawData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setIsCreateRuleComponent(false);
    }
  };

  const updateRuleByLawHandler = async (updateRulePayload: any) => {
    try {
      setLoading(true);
      const response = await dispatch(updateRuleForByLaw(updateRulePayload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Updated"), "success");
        const updatedBylawData = response?.payload?.data;
        setSelectedByLawItem(updatedBylawData);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteRuleByLawModal = (rule: any): void => {
    setSelectedByLawItem(rule);
    setIsDeleteRuleByLawModalOpen(true);
  };

  const updateRuleSetOrderByLawHandler = async (
    updateRuleSetOrderPayload: any
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        updateRuleSetOrderForByLaw(updateRuleSetOrderPayload)
      );
      if (response?.payload?.statusCode === 200) {
        showToast(t("Rule Set Updated"), "success");
        const updatedRuleSetOrderBylaw = response?.payload?.data;
        setSelectedByLawItem(updatedRuleSetOrderBylaw[0]);
      } else if (response.error) {
        handleError(response.payload.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRuleForByLaw = async () => {
    setLoading(true);
    try {
      if (selectedByLawItem) {
        const response: any = await dispatch(
          deleteRuleForByLaw(selectedByLawItem?.ruleId)
        );
        setIsDeleteRuleByLawModalOpen(false);
        const updatedByLawList = ByLaws.filter(
          (bylaw: any) => bylaw.ruleId !== response?.payload?.ruleId
        );
        const newSelectedByLawItem =
          updatedByLawList.length > 0 ? updatedByLawList[0] : null;
        setSelectedByLawItem(newSelectedByLawItem);
        if (response?.payload?.statusCode === 200) {
          showToast(t("Rule Deleted"), "success");
        } else if (response.error) {
          handleError(response.payload.data);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleError = (error: any) => {
    if (error) {
      const status = error.statusCode;
      switch (status) {
        case 400:
          showToast(t("Error"), "error");
          break;
        case 401:
          showToast(t(ERROR_MESSAGES.UNAUTHORIZED), "error");
          break;
        case 403:
          showToast(t(ERROR_MESSAGES.FORBIDDEN), "error");
          break;
        case 404:
          showToast(t(ERROR_MESSAGES.NOT_FOUND), "error");
          break;
        case 500:
          showToast(t(ERROR_MESSAGES.INTERNAL_SERVER_ERROR), "error");
          break;
        default:
          showToast(t(ERROR_MESSAGES.UNEXPECTED_ERROR), "error");
          break;
      }
    }
  };
  return (
    <>
      <Box
        className="ccr document document-wrapper"
        sx={{ paddingTop: "0 !important" }}
      >
        <Toast
          open={openToast}
          message={`${toastMessage}.`}
          handleClose={handleCloseToast}
          type={toastType as "success" | "error"}
        />
        <Box className="rule__title">
          <Typography className="rule__title__heading page_title">
            {t("Bylaws")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sx={{ paddingTop: "0 !important" }}>
            {isBoardRole && (
              <Box
                sx={{
                  textAlign: "end",
                  marginBottom: "12px",
                  visibility: !iscreateRuleComponentOpen ? "visible" : "hidden",
                }}
              >
                <Button
                  className="btn add-rule btn-white"
                  variant="outlined"
                  onClick={openCreateRuleComponent}
                >
                  {t("Create Rule")}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <UpdownRuleList
              ruleList={ByLaws}
              handleItemClick={handleItemClick}
              selectedItem={selectedByLawItem}
              ruleSet="ByLaw"
              heading={t("Bylaws")}
              route="bylaws"
              updateRuleSetOrderHandler={updateRuleSetOrderByLawHandler}
              loading={loading}
              isBoardRole={isBoardRole}
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Box sx={{ paddingLeft: "0 !important" }} className="articlePart">
              {iscreateRuleComponentOpen && (
                <CreateRuleComponent
                  isOpen={iscreateRuleComponentOpen}
                  onClose={closeCreateRuleComponent}
                  closeModelOnCrossClick={setIsCreateRuleComponent}
                  ruleSet="ByLaw"
                  loading={loading}
                  createRuleHandler={createRuleByLawHandler}
                  lastRuleId={
                    ByLaws?.length > 0
                      ? ByLaws[ByLaws?.length - 1].ruleId
                      : "root"
                  }
                  heading={t("Bylaws")}
                />
              )}
              <Box className="SectionBox by-law-sections">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexDirection: "column",
                  }}
                >
                  {editRule ? (
                    <CreateRuleComponent
                      isOpen={editRule}
                      onClose={closeEditRuleComponent}
                      closeModelOnCrossClick={setEditRule}
                      ruleSet="ByLaw"
                      loading={loading}
                      createRuleHandler={createRuleByLawHandler}
                      updateRuleHandler={editRule && updateRuleByLawHandler}
                      lastRuleId={
                        ByLaws?.length > 0
                          ? ByLaws[ByLaws?.length - 1].ruleId
                          : "root"
                      }
                      selectedItem={editRule ? selectedByLawItem : null}
                      editRuleHandler={editRule && editRuleHandler}
                      heading={t("Bylaws")}
                    />
                  ) : (
                    <>
                      {ByLaws && ByLaws?.length > 0 ? (
                        ByLaws.map((byLaw) => (
                          <RuleListItem
                            ruleItem={byLaw}
                            isBoardRole={isBoardRole}
                            loading={loading}
                            editRuleHandler={editRuleHandler}
                            handleOpenDeleteRuleModal={
                              handleOpenDeleteRuleByLawModal
                            }
                          />
                        ))
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          {loading ? (
                            <>
                              <SkeletonLoader
                                variants={[
                                  { type: "text", width: 210 },
                                  { type: "text", width: 500 },
                                  {
                                    type: "rectangular",
                                    width: 500,
                                    height: 60,
                                  },
                                  { type: "rounded", width: 500, height: 60 },
                                ]}
                              />
                            </>
                          ) : (
                            <Box className="no-rule-found">
                              <Typography
                                sx={{ color: "#fff", textAlign: "left" }}
                              >
                                {t("No ByLaw Rule found")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <DeleteRuleModal
          isOpen={isDeleteRuleByLawModalOpen}
          onClose={() => setIsDeleteRuleByLawModalOpen(false)}
          onRemove={handleDeleteRuleForByLaw}
          heading={t("Bylaws")}
        />
      </Box>
    </>
  );
};

export default ByLaws;
