import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  CircularProgress,
} from "@mui/material";
import { AxiosError } from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import "../../../../Component/Modals/AssetModals/assetModal.scss";
import { MEMBER_TYPE } from "../../../../Utils/utils";
import { changeUnitPerson } from "../../../../Redux/Feature/Asset-slice";
import { RootState } from "../../../../Redux/Store";
import { useTranslation } from "react-i18next";

interface UnitPersonField {
  email: string;
  name: string;
}

const UnitPersonList = (props: any) => {
  const {
    isBoardRole,
    unitAsset,
    editPersonClickHandler,
    setLoading,
    associationId,
    showToast,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [serverError, setServerError] = React.useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const [deletePersonData, setDeletePersonData] = useState<UnitPersonField>({
    email: "",
    name: "",
  });
  const isFetching = useSelector((state: RootState) => state.Asset.isFetching);
  const [openAlert, setOpenAlert] = React.useState(false);
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);

  const handleClickClose = () => {
    setOpenAlert(false);
  };

  const deleteUnitPersonHandler = (value: any) => {
    setDeletePersonData(value);
    setOpenAlert(true);
  };

  const confirmDeleteUnitPersonHandler = async () => {
    const requestObject = {
      assetId: unitAsset.assetId,
      person: deletePersonData,
      change: "remove",
    };
    try {
      const response = await dispatch(changeUnitPerson(requestObject));
      if (response?.payload?.statusCode === 200) {
        setOpenAlert(false);
        showToast(t("Removed Person Successfully"), "success");
      } else {
        showToast(t("Error"), "error");
      }

      setOpenAlert(false);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        setServerError(error.response.status);
        if (err instanceof AxiosError) {
          if (err?.response?.data?.error) {
            setServerErrorMsg(err?.response?.data?.error);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const renderServerError = (errorCode: Number) => {
    switch (errorCode) {
      case 500:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {t("Server Error")}
          </p>
        );
      case 400:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      case 401:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {t("Unauthorized")}
          </p>
        );
      case 403:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      case 404:
        return (
          <p style={{ color: `${theme.palette.serverError}` }}>
            {serverErrorMsg}
          </p>
        );
      default:
        return "";
    }
  };
  return (
    <>
      <Modal open={openAlert} onClose={handleClickClose}>
        <Box className="deleteUniWrapper">
          <Box sx={{ textAlign: "end" }} onClick={handleClickClose}>
            <CancelIcon className="close-icon" />
          </Box>
          <Typography
            id="delete-unit-description"
            sx={{ mt: 2, marginBottom: "30px" }}
          >
            {t("Are you sure you want to remove this person?")}
          </Typography>
          <Button
            variant="outlined"
            className="deleteUnitButtonYes"
            onClick={confirmDeleteUnitPersonHandler}
          >
            {isFetching ? (
              <CircularProgress
                size={35}
                sx={{ color: `${theme.palette.textWhite}` }}
              />
            ) : (
              t("Agree")
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClickClose}
            className="deleteUnitButtonCancel"
          >
            {t("Disagree")}
          </Button>
        </Box>
      </Modal>
      <Table>
        <TableHead>
          <TableRow sx={{ border: "1px solid #FFF" }}>
            <TableCell
              colSpan={4}
              sx={{ padding: "0", borderBottomColor: "white" }}
            >
              <Typography alignSelf={"center"} sx={{ p: "6px 12px" }}>
                <span style={{ marginRight: "10px" }}>{t("Unit Persons")}</span>
                {unitAsset?.persons?.filter(
                  (person: any) =>
                    person.role === MEMBER_TYPE.GENERAL ||
                    person.role === MEMBER_TYPE.BOARD
                ).length < 2 && isBoardRole ? (
                  <Button
                    onClick={() =>
                      editPersonClickHandler(
                        unitAsset?.persons,
                        unitAsset.assetId,
                        MEMBER_TYPE.GENERAL
                      )
                    }
                    className="buildingBox__btnBoxItem"
                  >
                    {t("Owner")}
                    <AddCircleIcon
                      style={{ color: "#20c1f5", marginLeft: "6px" }}
                    />
                  </Button>
                ) : null}
                {unitAsset?.persons?.filter(
                  (person: any) => person.role === MEMBER_TYPE.TENANT
                ).length < 2 && isBoardRole ? (
                  <Button
                    sx={{ marginLeft: "10px" }}
                    onClick={() =>
                      editPersonClickHandler(
                        unitAsset?.persons,
                        unitAsset.assetId,
                        MEMBER_TYPE.TENANT
                      )
                    }
                    className="buildingBox__btnBoxItem"
                  >
                    {t("Tenant")}
                    <AddCircleIcon
                      style={{ color: "#20c1f5", marginLeft: "6px" }}
                    />
                  </Button>
                ) : null}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ p: "6px 12px", border: "1px solid #FFF", width: "230px" }}
            >
              <Typography className="accordionDetails__contain">
                {t("Name")}
              </Typography>
            </TableCell>
            <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
              <Typography className="accordionDetails__contain">
                {t("Role")}
              </Typography>
            </TableCell>
            {isBoardRole ? (
              <>
                <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                  <Typography className="accordionDetails__contain">
                    {t("Phone Number")}
                  </Typography>
                </TableCell>
                <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                  <Typography className="accordionDetails__contain">
                    {t("Email")}
                  </Typography>
                </TableCell>
                <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                  <Typography className="accordionDetails__contain">
                    {t("Action")}
                  </Typography>
                </TableCell>
              </>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {unitAsset?.persons?.map((person: any) => {
            return (
              <TableRow>
                <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                  <Typography className="accordionDetails__contain">
                    {person?.name === "undefined undefined"
                      ? "Name Not Set"
                      : person?.name}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: "6px 12px",
                    border: "1px solid #FFF",
                    textTransform: "capitalize",
                  }}
                >
                  <Typography className="accordionDetails__contain">
                    {person?.role}
                  </Typography>
                </TableCell>

                {isBoardRole ? (
                  <>
                    <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                      <Typography className="accordionDetails__contain">
                        {userData?.phone ? userData?.phone : "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                      <Typography className="accordionDetails__contain">
                        <p>{person?.email}</p>
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ p: "6px 12px", border: "1px solid #FFF" }}>
                      <Button onClick={() => deleteUnitPersonHandler(person)}>
                        <DeleteIcon sx={{ color: "#FFF" }} />
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  ""
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {renderServerError(serverError)}
    </>
  );
};

export default UnitPersonList;
