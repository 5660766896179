import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
  Link,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../Redux/Store";
import { addManager } from "../../../../Redux/Feature/Roster-slice";
import { useTranslation } from "react-i18next";

interface CreateAssociationModalProps {
  isOpen: boolean;
  onClose: () => void;
  associationId: string;
  emails: string[];
  setIsInitialLoad: (value: boolean) => void;
}

const AddManagerModal: React.FC<CreateAssociationModalProps> = ({
  isOpen,
  onClose,
  associationId,
  emails,
  setIsInitialLoad,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const userData = useSelector((state: any) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Manager Name is required")),
    email: Yup.string()
      .required(t("Manager email is required"))
      .email(t("Enter valid email"))
      .test(
        "unique-email",
        t("Email already exists"),
        (value) => !emails.includes(value)
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const { email, name } = values;

      const associationPayload = {
        email: email,
        name: name,
        associationId: associationId,
      };
      dispatch(addManager(associationPayload))
        .catch((error: any) => {
          showToast(t("Error"), "error");
        })
        .finally(() => {
          resetForm();
          onClose();
          setLoading(false);
          showToast(t("Successfully added manager"), "success");
        });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  const showToast = (message: any, toastType: string) => {
    setToastMessage(message);
    setOpenToast(true);
    setToastType(toastType);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box
        className="add-document add-association-model"
        sx={{ width: "500px !important", paddingRight: "40px" }}
      >
        <Box
          sx={{ m: "20px 30px 40px" }}
          justifyContent={"center"}
          display="flex"
        >
          <p
            style={{
              color: `${theme.palette.text.primary}`,
              fontSize: "30px",
              fontWeight: "600",
            }}
          >
            {t("Add Manager")}
          </p>
          <Link sx={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
            <CancelIcon className="support-ticket-cross-icon" />
          </Link>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("First & Last Name")}
            </label>
            <input
              id="name"
              name="name"
              placeholder={t("Manager Name")}
              style={{ width: "100%" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.name && formik.touched.name && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.name}
              </Typography>
            )}
          </div>
          <div
            className="e-input-group"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <label
              style={{
                color: `${theme.palette.text.primary}`,
                marginBottom: "10px",
                display: "block",
              }}
            >
              {t("Email")}
            </label>
            <input
              id="email"
              name="email"
              placeholder={t("Manager Email")}
              style={{ width: "100%" }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="request-support-field"
            />
            {formik.errors.email && formik.touched.email && (
              <Typography color={`${theme.palette.invalid}`}>
                {formik.errors.email}
              </Typography>
            )}
          </div>

          <Box sx={{ marginTop: "30px" }}>
            <div className="SubmitBtn ticketModel__footer">
              <Button
                className="sBtn e-control e-btn"
                id="targetButton1"
                type="submit"
                disabled={isFetching === true}
              >
                {loading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: `${theme.palette.textWhite}` }}
                  />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddManagerModal;
