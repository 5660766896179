import { Box, Skeleton } from "@mui/material";

const VendorSkelton = (props: any) => {
  return (
    <div className="vendor">
      <Box className="vendorBox_title" sx={{ mx: 4 }}>
        <Box>
          <Skeleton
            variant={"text"}
            width={360}
            height={100}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
          <Skeleton
            variant={"text"}
            width={200}
            height={50}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
            }}
          />
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{
              backgroundColor: "rgb(64 64 64 / 34%)",
              marginX: "auto",
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default VendorSkelton;
