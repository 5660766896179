import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Feature/Auth-slice";
import { themeSlice } from "./Feature/Theme-slice";
import FinanceSlice from "./Feature/Finance-slice";
import SupportTicketSlice from "./Feature/Ticket-slice";
import MyAccountSlice from "./Feature/MyAccount-slice";
import AssetSlice from "./Feature/Asset-slice";
import SettingSlice from "./Feature/Setting-slice";
import ManageSlice from "./Feature/Roster-slice";
import GovernanceSlice from "./Feature/Governance-slice";
import AssociationSlice from "./Feature/Association-slice";
import MapSlice from "./Feature/Map-slice";
import bannerSlice from "./Feature/Banner-slice";
import MeetingSlice from "./Feature/Meeting-slice";
const Store = configureStore({
  reducer: {
    Auth: authSlice.reducer,
    Theme: themeSlice.reducer,
    Finances: FinanceSlice.reducer,
    SupportTicket: SupportTicketSlice.reducer,
    MyAccount: MyAccountSlice.reducer,
    Asset: AssetSlice.reducer,
    Map: MapSlice.reducer,
    Meeting: MeetingSlice.reducer,
    Setting: SettingSlice.reducer,
    Manage: ManageSlice.reducer,
    Governance: GovernanceSlice.reducer,
    Association: AssociationSlice.reducer,
    Banner: bannerSlice.reducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

export default Store;
