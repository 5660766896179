import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  useTheme,
  Grid,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody,
  Table,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  TableHead,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
  Collapse,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "../../Settings/setting.scss";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../Redux/Store";
import Toast from "../../../Component/Common/Toast/Toast";
import VendorSkelton from "../../../Component/Skeletons/VendorSkeleton";
import "./meetings.scss";
import "../../Settings/setting.scss";
import {
  deleteMeeting,
  getMeeting,
  getMeetings,
  getMeetingSummaries,
  NotifyMeeting,
  updateMeeting,
} from "../../../Redux/Feature/Meeting-slice";
import { Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddMeetingModal from "../../../Component/Modals/MeetingModals/AddMeetingModal";
import ConfirmationModal from "../../../Component/Modals/GeneralModals/ConfirmationModal";
import DatePickerComp from "../../../Component/Common/DatePicker/DatePicker";
import dayjs from "dayjs";
// import { time } from "node:console";

// Utility function to convert 24-hour time to 12-hour time
const convertTo12HourFormat = (time24: string) => {
  const [hours, minutes] = time24?.split(":");
  const period = +hours >= 12 ? "PM" : "AM";
  const hours12 = +hours % 12 || 12;
  return `${hours12}:${minutes} ${period}`;
};
const Meetings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState<string>("");
  const { t } = useTranslation();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string>("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState("success");

  const [loading, setLoading] = useState<boolean>(false);
  const [openSummaryRowId, setOpenSummaryRowId] = useState<string | null>(null);
  const [selectedEdit, setSelectedEdit] = useState<any>({
    item: "",
    isEditing: false,
    type: "",
  });
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<{
    topic: string;
    agenda: string;
    id: string;
    meetingGroup: string;
    startTime: string;
    startDate: string;
    duration: number | null;
    join_url: string;
    start_url: string;
    timezone: string;
  }>({
    topic: "",
    agenda: "",
    id: "",
    meetingGroup: "",
    startTime: "",
    startDate: "",
    duration: null,
    join_url: "",
    start_url: "",
    timezone: "",
  });

  const typeOptions = [
    { key: 8, value: t("Recurring") },
    { key: 2, value: t("One Time") },
  ];
  const meetingColumns = [
    {
      id: "collapse",
      label: t(""),
      width: "20px",
      minWidth: "20px",
      maxWidth: "20px",
    },
    {
      id: "title",
      label: t("Title"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "startTime",
      label: t("Start Time"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "startDate",
      label: t("Start Date"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "duration",
      label: t("Duration"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "nextMeeting",
      label: t("Next Meeting"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
  ];
  const summariesColumns = [
    {
      id: "collapse",
      label: t(""),
      width: "20px",
      minWidth: "20px",
      maxWidth: "20px",
    },
    {
      id: "title",
      label: t("Title"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "startTime",
      label: t("Start Time"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "startDate",
      label: t("Start Date"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      id: "meetingGroup",
      label: t("Meeting Group"),
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
  ];

  const currentAssociation = useSelector(
    (state: RootState) => state?.Auth?.currentAssociation
  );

  const meetings = useSelector((state: RootState) => state?.Meeting?.meetings);
  const summaries = useSelector(
    (state: RootState) => state?.Meeting?.meetingSummaries
  );

  const isBoardRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager";

  const isFetching = useSelector(
    (state: RootState) => state?.Manage?.isFetching
  );

  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [confirmation, setConfirmation] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEdit({
      ...selectedEdit,
      item: event.target.value,
    });
  };

  const toggleStartDateEditMode = async (date: any) => {
    if (selectedEdit?.isEditing) {
      let payload = {
        meetingId: selectedMeeting?.id,
        associationId: currentAssociation?.associationId,
        startDate: date,
        startTime: selectedMeeting.startTime,
        timezone: selectedMeeting?.timezone,
      };

      const response = await dispatch(updateMeeting(payload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Meeting Updated"), "success");
      } else {
        showToast(t("Update Meeting Failed"), "error");
      }
      setSelectedEdit({
        item: "",
        type: "",
        isEditing: !selectedEdit?.isEditing,
      });
      setSelectedMeeting({
        ...selectedMeeting,
        startDate: date,
      });
    } else {
      setSelectedEdit({
        item: selectedMeeting?.startDate,
        type: "startDate",
        isEditing: !selectedEdit?.isEditing,
      });
    }
  };
  const toggleStartTimeEditMode = async () => {
    if (selectedEdit?.isEditing) {
      let payload = {
        meetingId: selectedMeeting?.id,
        associationId: currentAssociation?.associationId,
        startTime: selectedEdit?.item,
        startDate: selectedMeeting?.startDate,
        timezone: selectedMeeting?.timezone,
      };

      const response = await dispatch(updateMeeting(payload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Meeting Updated"), "success");
      } else {
        showToast(t("Update Meeting Failed"), "error");
      }
      setSelectedEdit({
        item: "",
        type: "",
        isEditing: !selectedEdit?.isEditing,
      });
      setSelectedMeeting({
        ...selectedMeeting,
        startTime: selectedEdit?.item,
      });
    } else {
      setSelectedEdit({
        item: selectedMeeting?.startTime,
        type: "startTime",
        isEditing: !selectedEdit?.isEditing,
      });
    }
  };
  const toggleDurationEditMode = async () => {
    if (selectedEdit?.isEditing) {
      let payload = {
        meetingId: selectedMeeting?.id,
        associationId: currentAssociation?.associationId,
        duration: selectedEdit?.item,
      };

      const response = await dispatch(updateMeeting(payload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Meeting Updated"), "success");
      } else {
        showToast(t("Update Meeting Failed"), "error");
      }
      setSelectedEdit({
        item: "",
        type: "",
        isEditing: !selectedEdit?.isEditing,
      });
      setSelectedMeeting({
        ...selectedMeeting,
        duration: selectedEdit?.item,
      });
    } else {
      setSelectedEdit({
        item: selectedMeeting?.duration,
        type: "duration",
        isEditing: !selectedEdit?.isEditing,
      });
    }
  };
  const toggleTopicEditMode = async () => {
    if (selectedEdit?.isEditing) {
      let payload = {
        meetingId: selectedMeeting?.id,
        associationId: currentAssociation?.associationId,
        topic: selectedEdit?.item,
      };

      const response = await dispatch(updateMeeting(payload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Meeting Updated"), "success");
      } else {
        showToast(t("Update Meeting Failed"), "error");
      }
      setSelectedEdit({
        item: "",
        type: "",
        isEditing: !selectedEdit?.isEditing,
      });
      setSelectedMeeting({
        ...selectedMeeting,
        topic: selectedEdit?.item,
      });
    } else {
      setSelectedEdit({
        item: selectedMeeting?.topic,
        type: "topic",
        isEditing: !selectedEdit?.isEditing,
      });
    }
  };
  const toggleAgendaEditMode = async () => {
    if (selectedEdit?.isEditing) {
      let payload = {
        meetingId: selectedMeeting?.id,
        associationId: currentAssociation?.associationId,
        agenda: selectedEdit?.item,
      };

      const response = await dispatch(updateMeeting(payload));
      if (response?.payload?.statusCode === 200) {
        showToast(t("Meeting Updated"), "success");
      } else {
        showToast(t("Update Meeting Failed"), "error");
      }
      setSelectedEdit({
        item: "",
        type: "",
        isEditing: !selectedEdit?.isEditing,
      });
      setSelectedMeeting({
        ...selectedMeeting,
        agenda: selectedEdit?.item,
      });
    } else {
      setSelectedEdit({
        item: selectedMeeting?.agenda,
        type: "agenda",
        isEditing: !selectedEdit?.isEditing,
      });
    }
  };

  const showToast = (message: any, type: "success" | "error") => {
    setToastMessage(message);
    setToastType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
    setToastMessage("");
  };

  const handleAddMeetingModalOpen = () => {
    setIsMeetingModalOpen(true);
  };

  const handleAddMeetingModalClose = () => {
    setIsMeetingModalOpen(false);
  };
  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchMeetings = async () => {
    setLoading(true);
    if (currentAssociation) {
      setIsInitialLoad(false);
      await dispatch(getMeetings(currentAssociation?.associationId));
      await dispatch(getMeetingSummaries(currentAssociation?.associationId));
    }
    setLoading(false);
  };

  const getMeetingTypeLabel = (type: number) => {
    const option = typeOptions.find((option) => option.key === type);
    return option ? option.value : "Unknown Type";
  };
  useEffect(() => {
    fetchMeetings();
  }, [dispatch, currentAssociation]);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const openNotifyModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation(
      "Are you sure you want to notify all owners of this meeting?"
    );
  };
  const openRemoveModal = (meeting: any) => {
    setConfirmationModalOpen(true);
    setConfirmation("Are you sure you want to remove this meeting?");
  };

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      if (selectedMeeting) {
        const payload = {
          meetingId: selectedMeeting?.id,
          associationId: currentAssociation?.associationId,
          startTime: selectedMeeting?.startTime,
          startDate: selectedMeeting?.startDate,
        };
        if (
          confirmation ===
          "Are you sure you want to notify all owners of this meeting?"
        ) {
          const response = await dispatch(NotifyMeeting(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(
              t(`${selectedMeeting?.meetingGroup} members notified of meeting`),
              "success"
            );
          } else if (response.error) {
            showToast(t("Meeting Notification Not Sent"), "error");
          }
        } else {
          const response = await dispatch(deleteMeeting(payload));
          if (response?.payload?.statusCode === 200) {
            showToast(t("Meeting Removed"), "success");
          } else if (response.error) {
            showToast(t("Meeting Not Removed"), "error");
          }
        }
      }
    } catch (err) {
    } finally {
      closeConfirmationModal();
      setLoading(false);
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClearSearch = () => {
    setSearch("");
  };
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const sortedSummaries = stableSort(summaries, getComparator(order, orderBy));
  const sortedMeetings = stableSort(meetings, getComparator(order, orderBy));

  const filteredSummaries = sortedSummaries?.filter((vote: any) => {
    if (!vote) {
      return false;
    }
    const { title, status, type } = vote;
    const lowerCaseSearch = search?.toLowerCase();
    return lowerCaseSearch
      ? title?.toLowerCase().includes(lowerCaseSearch) ||
          type?.toLowerCase().includes(lowerCaseSearch) ||
          status?.toLowerCase().includes(lowerCaseSearch)
      : true;
  });

  const filteredMeetings = sortedMeetings?.filter((vote: any) => {
    if (!vote) {
      return false;
    }
    const { title, status, type } = vote;
    const lowerCaseSearch = search?.toLowerCase();
    return lowerCaseSearch
      ? title?.toLowerCase().includes(lowerCaseSearch) ||
          type?.toLowerCase().includes(lowerCaseSearch) ||
          status?.toLowerCase().includes(lowerCaseSearch)
      : true;
  });

  const paginatedSummaryData = filteredSummaries?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const paginatedMeetingData = filteredMeetings?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  if (isFetching) {
    return <VendorSkelton />;
  }

  const handleSummaryRowOpen = async (row: any) => {
    if (row?.meetingId === openSummaryRowId) {
      setOpenSummaryRowId(null);
    } else if (openSummaryRowId && row?.meetingId !== openSummaryRowId) {
      setOpenSummaryRowId(row?.meetingId);
    } else {
      setOpenSummaryRowId(row?.meetingId);
    }
  };
  const handleRowOpen = async (row: any) => {
    if (row?.id === selectedMeeting.id) {
      setSelectedMeeting({
        topic: "",
        agenda: "",
        id: "",
        meetingGroup: "",
        startTime: "",
        startDate: "",
        duration: null,
        join_url: "",
        start_url: "",
        timezone: "",
      });
    } else {
      setSelectedMeeting({ ...row });
      const payload = {
        associationId: currentAssociation?.associationId,
        meetingId: row.id,
      };
      await dispatch(getMeeting(payload));
    }
  };

  const isAuthorizedRole =
    currentAssociation?.role === "board" ||
    currentAssociation?.role === "manager" ||
    currentAssociation?.role === "general";
  return (
    <div className="profile">
      <Box className="meetingsBox__title">
        <Typography className="meetings__title page_title">
          {t("Active Meetings")}
        </Typography>
      </Box>

      <Box
        className="TableConnect meetings_wrapper meetings-member-table new-support-table"
        sx={{
          border: "unset !important",
        }}
      >
        <Box className="TableConnectInner">
          <Box className="meetings-wrap gridTable">
            {isBoardRole && (
              <Box
                sx={{
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button
                      className="btn add-member btn-white"
                      variant="outlined"
                      onClick={handleAddMeetingModalOpen}
                    >
                      {t("Create Meeting")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
                </Grid>
              </Box>
            )}
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {meetingColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            {column.id !== "collapse" ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={
                                  orderBy === column.id ? order : "asc"
                                }
                                onClick={() => handleSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedMeetingData?.length === 0 ? (
                        <TableRow key={"noVoteRowId"}>
                          <TableCell colSpan={6} align="center">
                            {t("No Meetings Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedMeetingData?.map((row: any) => (
                          <>
                            <TableRow
                              key={row.id}
                              sx={{
                                "&. MuiTableCell-root": {
                                  height: "70px",
                                },
                              }}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleRowOpen(row)}
                                >
                                  {selectedMeeting.id === row.id ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "topic" ? (
                                    <TextField
                                      variant="outlined"
                                      value={selectedEdit?.item}
                                      onChange={(e: any) => handleChange(e)}
                                      fullWidth
                                    />
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(row?.topic)}
                                    </Typography>
                                  )}
                                  {"" !== selectedMeeting?.id &&
                                  selectedMeeting?.id === row?.id ? (
                                    <Button
                                      onClick={toggleTopicEditMode}
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                        mb:
                                          row?.id === selectedMeeting?.id &&
                                          selectedEdit?.isEditing &&
                                          selectedEdit?.type === "topic"
                                            ? "2px"
                                            : "8px",
                                      }}
                                    >
                                      {row?.id === selectedMeeting?.id &&
                                      selectedEdit?.isEditing &&
                                      selectedEdit?.type === "topic"
                                        ? t("Save")
                                        : t("Edit")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "topic" ? (
                                    <Button
                                      onClick={() =>
                                        setSelectedEdit({
                                          item: "",
                                          isEditing: false,
                                          type: "",
                                        })
                                      }
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                      }}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </TableCell>

                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "startTime" ? (
                                    <TextField
                                      variant="outlined"
                                      value={selectedEdit?.item}
                                      onChange={(e: any) => handleChange(e)}
                                      fullWidth
                                      type="time"
                                    />
                                  ) : (
                                    <>
                                      <Typography
                                        variant="body1"
                                        gutterBottom
                                        component="div"
                                      >
                                        {convertTo12HourFormat(row?.startTime)}
                                      </Typography>
                                    </>
                                  )}
                                  {"" !== selectedMeeting?.id &&
                                  selectedMeeting?.id === row?.id ? (
                                    <Button
                                      onClick={toggleStartTimeEditMode}
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                        mb:
                                          row?.id === selectedMeeting?.id &&
                                          selectedEdit?.isEditing &&
                                          selectedEdit?.type === "startTime"
                                            ? "2px"
                                            : "8px",
                                      }}
                                    >
                                      {row?.id === selectedMeeting?.id &&
                                      selectedEdit?.isEditing &&
                                      selectedEdit?.type === "startTime"
                                        ? t("Save")
                                        : t("Edit")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "startTime" ? (
                                    <Button
                                      onClick={() =>
                                        setSelectedEdit({
                                          item: "",
                                          isEditing: false,
                                          type: "",
                                        })
                                      }
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                      }}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "startDate" ? (
                                    <DatePickerComp
                                      disablePast={true}
                                      disableFuture={false}
                                      className="custom-datepicker"
                                      value={
                                        selectedEdit?.item
                                          ? dayjs(selectedEdit?.item)
                                          : null
                                      }
                                      onChange={(newValue: any) => {
                                        const formattedDate = newValue
                                          ? newValue.format("YYYY-MM-DD")
                                          : null;

                                        toggleStartDateEditMode(formattedDate);
                                      }}
                                      disabled={!isBoardRole}
                                    />
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(row?.startDate)}
                                    </Typography>
                                  )}
                                  {"" !== selectedMeeting?.id &&
                                  selectedMeeting?.id === row?.id &&
                                  selectedEdit?.type !== "startDate" ? (
                                    <Button
                                      onClick={toggleStartDateEditMode}
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                        mb: "8px",
                                      }}
                                    >
                                      {t("Edit")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "startDate" ? (
                                    <Button
                                      onClick={() =>
                                        setSelectedEdit({
                                          item: "",
                                          isEditing: false,
                                          type: "",
                                        })
                                      }
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                      }}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "duration" ? (
                                    <TextField
                                      variant="outlined"
                                      value={selectedEdit?.item}
                                      onChange={(e: any) => handleChange(e)}
                                      fullWidth
                                    />
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(row?.duration)} {t("Minutes")}
                                    </Typography>
                                  )}
                                  {"" !== selectedMeeting?.id &&
                                  selectedMeeting?.id === row?.id ? (
                                    <Button
                                      onClick={toggleDurationEditMode}
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                        mb:
                                          row?.id === selectedMeeting?.id &&
                                          selectedEdit?.isEditing &&
                                          selectedEdit?.type === "duration"
                                            ? "2px"
                                            : "8px",
                                      }}
                                    >
                                      {row?.id === selectedMeeting?.id &&
                                      selectedEdit?.isEditing &&
                                      selectedEdit?.type === "duration"
                                        ? t("Save")
                                        : t("Edit")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.id === selectedMeeting?.id &&
                                  selectedEdit?.isEditing &&
                                  selectedEdit?.type === "duration" ? (
                                    <Button
                                      onClick={() =>
                                        setSelectedEdit({
                                          item: "",
                                          isEditing: false,
                                          type: "",
                                        })
                                      }
                                      sx={{
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.text.primary}`,
                                        ml: "16px",
                                      }}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </TableCell>

                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                <a
                                  href={row?.join_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      color: theme.palette.text.primary,
                                      border: `1px solid ${theme.palette.text.primary}`,
                                      // ml: "16px",
                                    }}
                                  >
                                    {t("Join")}
                                  </Button>
                                </a>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={7}
                              >
                                <Collapse
                                  in={selectedMeeting.id === row.id}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ margin: 1, position: "relative" }}>
                                    <Typography
                                      variant="h5"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t("Meeting Type")}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(getMeetingTypeLabel(row?.type))} -{" "}
                                      {t(
                                        capitalizeFirstLetter(row?.meetingGroup)
                                      )}
                                    </Typography>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="h5"
                                        gutterBottom
                                        component="div"
                                      >
                                        {t("Agenda")}
                                      </Typography>
                                      <Button
                                        onClick={toggleAgendaEditMode}
                                        sx={{
                                          color: theme.palette.text.primary,
                                          border: `1px solid ${theme.palette.text.primary}`,
                                          ml: "16px",
                                          mb:
                                            row?.id === selectedMeeting?.id &&
                                            selectedEdit?.isEditing &&
                                            selectedEdit?.type === "agenda"
                                              ? "2px"
                                              : "8px",
                                        }}
                                      >
                                        {row?.id === selectedMeeting?.id &&
                                        selectedEdit?.isEditing &&
                                        selectedEdit?.type === "agenda"
                                          ? t("Save")
                                          : t("Edit")}
                                      </Button>
                                      {row?.id === selectedMeeting?.id &&
                                      selectedEdit?.isEditing &&
                                      selectedEdit?.type === "agenda" ? (
                                        <Button
                                          onClick={() =>
                                            setSelectedEdit({
                                              item: "",
                                              isEditing: false,
                                              type: "",
                                            })
                                          }
                                          sx={{
                                            color: theme.palette.text.primary,
                                            border: `1px solid ${theme.palette.text.primary}`,
                                            ml: "16px",
                                          }}
                                        >
                                          {t("Cancel")}
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                    {row?.id === selectedMeeting?.id &&
                                    selectedEdit?.isEditing &&
                                    selectedEdit?.type === "agenda" ? (
                                      <TextField
                                        variant="outlined"
                                        value={selectedEdit?.item}
                                        onChange={(e: any) => handleChange(e)}
                                        fullWidth
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        gutterBottom
                                        component="div"
                                      >
                                        {row?.agenda !== "" || !row?.agenda
                                          ? row?.agenda
                                          : t("No Agenda")}
                                      </Typography>
                                    )}

                                    {isBoardRole && (
                                      <>
                                        <a
                                          href={row?.start_url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ textDecoration: "none" }}
                                        >
                                          <Button
                                            variant="outlined"
                                            className="btn btn-theme"
                                            sx={{
                                              position: "absolute",
                                              top: 0,
                                              right: 260,
                                              maxWidth: "120px",
                                              width: "auto",
                                            }}
                                            disabled={
                                              !isBoardRole && !row?.start_url
                                            }
                                          >
                                            {t("Start")}
                                          </Button>
                                        </a>
                                        <Button
                                          variant="outlined"
                                          className="btn btn-theme"
                                          sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 130,
                                            maxWidth: "120px",
                                            width: "auto",
                                          }}
                                          onClick={() => {
                                            openNotifyModal(row);
                                          }}
                                          disabled={!isBoardRole}
                                        >
                                          {t("Notify")}
                                        </Button>
                                        <Button
                                          variant="outlined"
                                          className="btn btn-theme btn-red remove-btn"
                                          sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            maxWidth: "120px",
                                            width: "auto",
                                          }}
                                          onClick={() => {
                                            openRemoveModal(row);
                                          }}
                                          disabled={!isBoardRole}
                                        >
                                          {t("Delete")}
                                        </Button>
                                      </>
                                    )}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredMeetings.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
            <Box className="meetingsBox__title">
              <Typography className="meetings__title page_title">
                {t("Past Meeting Summaries")}
              </Typography>
            </Box>
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                border: `1px solid ${theme.palette.activeColor}`,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {summariesColumns?.map((column, index) => (
                          <TableCell
                            sx={{ padding: "16px 8px" }}
                            key={column.id}
                            style={{
                              width: column.minWidth,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            sortDirection={
                              orderBy === column.id ? order : false
                            }
                          >
                            {column.id !== "collapse" ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={
                                  orderBy === column.id ? order : "asc"
                                }
                                onClick={() => handleSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedSummaryData?.length === 0 ? (
                        <TableRow key={"noVoteRowId"}>
                          <TableCell colSpan={5} align="center">
                            {t("No Summaries Found")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        paginatedSummaryData?.map((row: any) => (
                          <>
                            <TableRow
                              key={row.id}
                              onClick={() => handleSummaryRowOpen(row)}
                              sx={{
                                "&. MuiTableCell-root": {
                                  height: "70px",
                                },
                              }}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleSummaryRowOpen(row)}
                                >
                                  {openSummaryRowId === row.meetingId ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                {row?.topic}
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                {row?.startTime}
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                {t(row?.startDate)}
                              </TableCell>
                              <TableCell
                                sx={{ height: "60px", padding: "4px 8px" }}
                              >
                                {t(row?.meetingGroup)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={5}
                              >
                                <Collapse
                                  in={openSummaryRowId === row.meetingId}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ margin: 1, position: "relative" }}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(row?.summaryTitle)}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t(row?.summaryOverview)}
                                    </Typography>
                                    {row?.summaryDetails?.map(
                                      (detail: any, index: any) => (
                                        <Box
                                          key={index}
                                          sx={{ marginBottom: 2, marginTop: 3 }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            component="div"
                                          >
                                            {t(detail.label)}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            gutterBottom
                                            component="div"
                                          >
                                            {t(detail.summary)}
                                          </Typography>
                                        </Box>
                                      )
                                    )}
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      {t("Next Steps")}
                                    </Typography>
                                    {row?.nextSteps?.map(
                                      (step: string, index: number) => (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          gutterBottom
                                          component="div"
                                        >
                                          {t(step)}
                                        </Typography>
                                      )
                                    )}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={filteredMeetings.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("Rows per page")}
                />
              </Box>
            </Paper>
          </Box>
        </Box>{" "}
      </Box>

      <AddMeetingModal
        isOpen={isMeetingModalOpen}
        onClose={handleAddMeetingModalClose}
        associationId={currentAssociation?.associationId}
      />

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmation={confirmation}
      />
      <Toast
        type={toastType as "success" | "error"}
        open={openToast}
        message={`${toastMessage}`}
        handleClose={handleCloseToast}
      />
    </div>
  );
};
export default Meetings;
