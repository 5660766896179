import { Box, Skeleton } from "@mui/material";

const AssociationSkeleton = (props: any) => {
  return (
    <Box className="associationBox_title" sx={{ mx: 4 }}>
      <Box>
        <Skeleton
          variant={"text"}
          width={360}
          height={100}
          sx={{
            backgroundColor: "rgb(64 64 64 / 34%)",
            marginX: "auto",
          }}
        />
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{
            backgroundColor: "rgb(64 64 64 / 34%)",
            marginX: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default AssociationSkeleton;
