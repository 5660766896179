import React from "react";
import { Box, Typography, Skeleton } from '@mui/material';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from '@mui/icons-material/Delete';
import RenderHTMLContent from "./RenderHTMLcontent";


interface RuleListItemProps {
  ruleItem: any;
  isBoardRole: boolean;
  loading: boolean;
  editRuleHandler: (value: boolean, rule?: any) => void;
  handleOpenDeleteRuleModal: (rule: any) => void;
}

const RuleListItem: React.FC<RuleListItemProps> = ({
  ruleItem,
  isBoardRole,
  loading,
  editRuleHandler,
  handleOpenDeleteRuleModal
}) => {
  return (
    <Box key={ruleItem.ruleId} className="rule-div" id={ruleItem.ruleId} sx={{ marginBottom: "30px" }}>
      <Box className="ccr_heading textColorTheme" sx={{ display: "flex" }}>
        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem', backgroundColor: ' rgb(64 64 64 / 34%)' }} width={210} />
        ) : (
          <>
            <Typography sx={{ marginRight: "16px", fontSize: "30px" }}>{ruleItem ? ruleItem.title : "No Rule Title Found"}</Typography>
            {isBoardRole && (
              <Box sx={{ flex: "none" }}>
                <Box onClick={() => editRuleHandler(true, ruleItem)} className="rule-icon">
                  <ModeEditIcon fontSize="medium" />
                </Box>
                <Box onClick={() => handleOpenDeleteRuleModal(ruleItem)} className="rule-icon delete-icon">
                  <DeleteIcon fontSize="medium" />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box className="SectionBox_inner">
        {loading ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: '1rem', backgroundColor: ' rgb(64 64 64 / 34%)' }} width={500} />
            <Skeleton variant="rectangular" sx={{ mb: '5px', backgroundColor: ' rgb(64 64 64 / 34%)' }} width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} sx={{ backgroundColor: ' rgb(64 64 64 / 34%)' }} />
          </>
        ) : (
          <Typography variant="subtitle1" className="SectionBox_content" sx={{ color: "text.primary" }}>
            <RenderHTMLContent htmlContent={ruleItem ? ruleItem.text : "No Rule Text Found"} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default RuleListItem;
