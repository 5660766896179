import axios, { AxiosError } from "axios";
import { NOT_AUTHENTICATED, FORBIDDEN } from "../Utils/errors";

const setAxiosBaseURL = () => {
  const testMode = JSON.parse(localStorage.getItem("testMode") || "false");
  const baseURL = testMode
    ? process.env.REACT_APP_TEST_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL;

  axios.defaults.baseURL = baseURL;
};

setAxiosBaseURL();
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

function handleUnAuthenticatedResponse(error: any) {
  const { response } = error;
  if (response && typeof response !== "undefined") {
    const { status } = response;
    if (status === NOT_AUTHENTICATED || status === FORBIDDEN) {
      localStorage.clear();
      window.location.assign(
        `${window.location.protocol}//${window.location.hostname}`
      );
    }
  }
}

async function errorHandler(error: AxiosError) {
  handleUnAuthenticatedResponse(error);
}

axios.interceptors.request.use(
  (config: any) => {
    const token: any = localStorage.getItem("safe-token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (error: any) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);

const http = {
  request: axios,
  create: axios.create,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default http;
