import React from "react";
import Snackbar from "@mui/material/Snackbar";
import "./Toast.scss";

interface ToastProps {
  open: boolean;
  message: string;
  handleClose: () => void;
  type: "success" | "error";
}

const Toast: React.FC<ToastProps> = ({ open, message, handleClose, type }) => {
  const toastClassName = type === "success" ? "toast-success" : "toast-error";
  return (
    <Snackbar
      className={toastClassName}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={message}
    />
  );
};

export default Toast;
