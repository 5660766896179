import http from "../api";

const getOwners = async (associationId: any) => {
  try {
    const response = await http.get(`/get-owners/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getAllBoard = async (associationId: any) => {
  try {
    const response = await http.get(`/get-board-residents/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getGeneralMembers = async (associationId: any) => {
  try {
    const response = await http.get(`/get-general-residents/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const removeVendor = async (removeVendorObject: any) => {
  try {
    const response = await http.delete(`/remove-vendor`, {
      data: removeVendorObject,
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const GetBOISubmissions = async (associationId: any) => {
  try {
    const response = await http.get(`/get-boi-submissions/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const GetBOIInformation = async (associationId: any) => {
  try {
    const response = await http.get(`/get-boi-information/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getBoardVotes = async (associationId: any) => {
  try {
    const response = await http.get(
      `/get-board-votes/?associationId=${associationId}`
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const addVendor = async (addVendorObject: any) => {
  try {
    const response = await http.post(`/add-vendor`, addVendorObject);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const addManager = async (addManagerObject: any) => {
  try {
    const response = await http.post(`/add-manager`, {
      email: addManagerObject.email,
      name: addManagerObject.name,
      associationId: addManagerObject.associationId,
      serviceType: "management",
    });
    return response?.data;
  } catch (e) {
    return e;
  }
};

const addBoardMember = async (addDataObj: any) => {
  try {
    const response = await http.put(
      `/update-user-role?associationId=${addDataObj.associationId}&personId=${addDataObj.personId}`,
      {
        role: "board",
      }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const removeBoardMember = async (addDataObj: any) => {
  try {
    const response = await http.put(
      `/update-user-role?associationId=${addDataObj.associationId}&personId=${addDataObj.personId}`,
      {
        role: "general",
      }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const getVendors = async (associationId: any) => {
  try {
    const response = await http.get(`/get-vendors/${associationId}`);
    return response?.data;
  } catch (e) {
    return e;
  }
};

const StripeAssociationPaymentSession = async (payload: any) => {
  try {
    const response = await http.post(
      `/stripe-association-payment-session`,
      payload
    );
    return response?.data;
  } catch (e) {
    return e;
  }
};

const Manage = {
  getAllBoard,
  getGeneralMembers,
  addBoardMember,
  removeBoardMember,
  getVendors,
  addManager,
  addVendor,
  removeVendor,
  StripeAssociationPaymentSession,
  getOwners,
  getBoardVotes,
  GetBOIInformation,
  GetBOISubmissions,
};
export default Manage;
